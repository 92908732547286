import React, { useEffect, useState, Suspense } from 'react';
import { Router, Location, Redirect } from '@reach/router';
// import {BrowserRouter as Router, Routes,Route } from 'react-router-dom' 
import ScrollToTopBtn from './menu/ScrollToTop';
import Header from './menu/header';
import Home from './pages/home4';
import NFT from './pages/nftList'
import HomeGrey from './pages/homeGrey';
import Home1 from './pages/home1';
import Home1grey from './pages/home1Grey';
import Home2 from './pages/home2';
import Home2grey from './pages/home2Grey';
import Home3 from './pages/home3';
import Home4 from './pages/home4';
import Home5 from './pages/home5';
import Home6 from './pages/home6';
import Emailverify from './pages/Emailverify'
import Explore from './pages/explore';
import Exploregrey from './pages/exploreGrey';
import Explore2 from './pages/explore2';
import Explore2grey from './pages/explore2Grey';
import ExploreOpensea from './pages/Opensea/explore';
// import Rangking from './pages/rangking';
import RankingRedux from './pages/RankingRedux';
import RankingReduxgrey from './pages/RankingReduxGrey';
import Auction from './pages/Auction';
import Auctiongrey from './pages/AuctionGrey';
// import Helpcenter from './pages/helpcenter';
// import Helpcentergrey from './pages/helpcenterGrey';
import Colection from './pages/colection';
import Colectiongrey from './pages/colectionGrey';
// import ItemDetail from './pages/ItemDetail';
import ItemDetailRedux from './pages/ItemDetailRedux';
import ItemDetailReduxgrey from './pages/ItemDetailReduxGrey';
import Author from './pages/Author';
import Wishlist from './pages/Wishlist'
import AuthorGrey from './pages/AuthorGrey';
import AuthorOpensea from './pages/Opensea/author';
import Wallet from './pages/wallet';
import WalletGrey from './pages/walletGrey';
import Login from './pages/login';
import Logingrey from './pages/loginGrey';
import LoginTwo from './pages/loginTwo';
import LoginTwogrey from './pages/loginTwoGrey';
import Register from './pages/register';
import Registergrey from './pages/registerGrey';
import Price from './pages/price';
import Works from './pages/works';
import News from './pages/news';
import NewsSingle from './pages/newsSingle';
import Create from './pages/create';
import Creategrey from './pages/createGrey';
import Create2 from './pages/create2';
import Create3 from './pages/create3';
import Createoption from './pages/createOptions';
import Activity from './pages/activity';
import Activitygrey from './pages/activityGrey';
import Contact from './pages/contact';
import Contactgrey from './pages/contactGrey';
import ElegantIcons from './pages/elegantIcons';
import EtlineIcons from './pages/etlineIcons';
import FontAwesomeIcons from './pages/fontAwesomeIcons';
import Accordion from './pages/accordion';
// import HelpCenterFAQ from './pages/helpcenter_faq';
// import HelpCenterUserSafety from './pages/helpcenter_usersafety';
// import HelpCenterPartners from './pages/helpcenter_partners';
// import HelpCenterDevelopers from './pages/helpcenter_developers';
// import HelpCenterBuying from './pages/helpcenter_buying';
// import HelpCenterCreating from './pages/helpcenter_creating';
// import HelpCenterGetStarted from './pages/helpcenter_gettingstarted';
// import HelpCenterSelling from './pages/helpcenter_selling';
import Alerts from './pages/alerts';
import Progressbar from './pages/progressbar';
import Tabs from './pages/tabs';
import Minter from './pages/Minter';
import Mintergrey from './pages/MinterGrey';
// import Profile from './pages/Profile';
import Profile from './pages/Profile/index'
import Profile1 from './pages/Profile'
import { getUserData } from '../store/actions/userData';
import { useDispatch } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import ForgotPassword from './pages/forgotPassword'
import CreateReSell from './pages/createResell'
import PageNotFound from './pages/404-page'
import Viewallpage from './pages/Viewallpage';
import Axios from '../api-instance'
import userData from '../userData'
import ProfileItem from '../components/pages/Profile'
import Forgot from './pages/Forgot'
import NotItemDetailRedux from './pages/NotItemDetailRedux'
import ItemDetailContentLoader from './components/itemDetailContentLoader'
import AOS from "aos";
import "aos/dist/aos.css"
import PrivateRoute from './pages/PrivateRoute';
import Itemdetails from './pages/Itemdetails';
import MyProfile from './pages/myProfile';
import Loader from './components/LoaderNew';
import HelpCenter from './pages/HelpCenter/HelpCenter'
import DetailPage from "./pages/HelpCenter/DetailPage";
import GetStarted from './pages/HelpCenter/GetStarted';
import Buying from "./pages/HelpCenter/Buying";
import BuyingPage from "./pages/HelpCenter/BuyingPage";
import ProfileNew from "./pages/HelpCenter/Profile";
import ProfilePage from "./pages/HelpCenter/ProfilePage";
import Createing from "./pages/HelpCenter/Creating";
import CreateingPage from "./pages/HelpCenter/CreatingPage";
import Faq from "./pages/HelpCenter/FAQ";
import FaqPage from "./pages/HelpCenter/FAQPage";
import Selling from "./pages/HelpCenter/Selling";
import SellingPage from "./pages/HelpCenter/SellingPage";
import ResetPassword from "./pages/resetPassword";
import ResetPswd from './ResetPswd';

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id='routerhang'>
        <div key={location.key}>

          <Router location={location}>
            {children}
          </Router>

        </div>
      </div>
    )}
  </Location>
);


const App = () => {

  const dispatch = useDispatch()


  const [initialUserData, setInitialUserData] = useState(null)

  // useEffect(()=>{
  //   const getUserData = async()=>{
  //     try {
  //       const token = window.localStorage.getItem('token')
  //       const {data} = await Axios.get(`/auth/getdata/:${token}`)
  //       setInitialUserData(data?.data)
  //       userData.userData = data?.data
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   getUserData()
  // },[])


  // useEffect(()=>{
  //   const token = window.localStorage.getItem('token')
  //   dispatch(getUserData(token))
  // },[])


  const [header, setHeader] = useState(false)

  useEffect(() => {
    if (window.location.pathname === '/login' || window.location.pathname === '/register') {
      setHeader(true)
    }
    else {
      setHeader(false)
    }
  }, [])


  const [nftAddress, setNftAddress] = useState('')
  const [authorId, setAuthorId] = useState('')

  console.log(nftAddress);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);


  return (
    <div className="wraper">
      <GlobalStyles />
      <Header setHeader={setHeader} />

      {/* <PrivateRoute path="/explore"  component={<Explore2 setNftAddress={setNftAddress} />}/> */}

      <PosedRouter>
        <ScrollTop path="/">
          {/* <NFT exact path="/">
            <Redirect to="/nft" />
          </NFT> */}
          <Home exact path="/">
            <Redirect to="/index" />
          </Home>

          <NFT exact path="/Explore" />
          <Viewallpage exact path="/viewall" />
          <Loader exact path="/loader" />
          
          {/* <HomeGrey path="/homeGrey" /> */}
          {/* <Home1 path="/home1" /> */}
          {/* <Home1grey path="/home1Grey" /> */}
          {/* <Home2 path="/home2" /> */}
          {/* <Home2grey path="/home2Grey" /> */}
          {/* <Home3 path="/home3" /> */}
          {/* <Home4 path="/index" /> */}
          {/* <Home5 path="/home5" /> */}
          <Home6 path="/index" />

          <MyProfile path="/myprofile/:authorId" />
          {/* <Explore path="/exploress" /> */}
          {/* <Exploregrey path="/exploreGrey" /> */}
          {/* <PrivateRoute component={} path="/explore"/> */}
          {/* <Explore2 path="/Items" setNftAddress={setNftAddress} /> */}
          <Explore2 path="/Items" />
          {/* <Explore2grey path="/explore2Grey" /> */}
          {/* <ExploreOpensea path="/exploreOpensea" /> */}
          {/* <RankingRedux path="/rangking" /> */}
          {/* <RankingReduxgrey path="/rangkingGrey" /> */}
          {/* <Auction path="/Auction" /> */}
          {/* <Auctiongrey path="/AuctionGrey" /> */}
          {/* <Helpcenter path="/helpcenter" /> */}
          {/* <Helpcentergrey path="/helpcenterGrey" /> */}
          {/* <Colection path="/colection/:collectionId" /> */}
          {/* <Colectiongrey path="/colectionGrey/:collectionId" /> */}

          <ItemDetailRedux path='/ItemDetail/:nftId' />

          <NotItemDetailRedux path='/NotItemDetail/:nftId' />
          <Itemdetails path='/itemdetails/:nftId' />
          {/* <ItemDetailReduxgrey path="/ItemDetailGrey/:nftId" /> */}
          <Wishlist path='/userprofile/:authorId' />
          <Author path="/Author/:authorId" />
          <Profile path="/Profile/:authorId" />
          <Profile1 path='profile/:authorId' />
          {/* <AuthorGrey path="/AuthorGrey/:authorId" /> */}
          {/* <AuthorOpensea path="/AuthorOpensea" /> */}
          <Wallet path="/wallet" />
          <WalletGrey path="/walletGrey" />
          <Emailverify path="/EmailVerify" />
          {/* <Login path="/loginaa" /> */}
          {/* <Logingrey path="/loginGrey" /> */}
          <LoginTwo path="/login" />
          {/* <LoginTwogrey path="/loginTwoGrey" /> */}
          <Register path="/register" />
          {/* <Registergrey path="/registerGrey" /> */}
          {/* <Price path="/price" /> */}
          {/* <Works path="/works" /> */}
          {/* <News path="/news" /> */}
          {/* <NewsSingle path="/news/:postId" /> */}
          {/* <Create path="/created" />
          <Creategrey path="/createGrey" /> */}

          <Create2 path="/create" />

          {/* <Create3 path="/create3" /> */}
          {/* <Createoption path="/createOptions" /> */}
          <Activity path="/activity/:userId" />
          {/* <Activitygrey path="/activityGrey" /> */}
          {/* <Contact path="/contact" /> */}
          {/* <Contactgrey path="/contactGrey" /> */}
          {/* <ElegantIcons path="/elegantIcons" /> */}
          {/* <EtlineIcons path="/etlineIcons" /> */}
          {/* <FontAwesomeIcons path="/fontAwesomeIcons" /> */}
          {/* <Accordion path="/accordion" /> */}
          {/* <HelpCenterFAQ path="/helpcenter_faq" />
          <HelpCenterUserSafety path="/helpcenter_UserSafety" />
          <HelpCenterPartners path="/helpcenter_partners" />
          <HelpCenterDevelopers path="/helpcenter_developers" />
          <HelpCenterBuying path="/helpcenter_buying" />
          <HelpCenterCreating path="/helpcenter_creating" />
          <HelpCenterGetStarted path="/helpcenter_gettingstarted" />
          <HelpCenterSelling path="/helpcenter_selling" /> */}
          <HelpCenter path="/helpcenter-new" />
          <DetailPage path="/detail-page" />
          <GetStarted path="/getstarted" />
          <Buying path="/buying" />
          <BuyingPage path="/buying-page" />
          <ProfileNew path="/Profile-help" />
          <ProfilePage path="/profile-page" />
          <Createing path="/Createing" />
          <CreateingPage path="/createing-page" />
          <Faq path="/Faq" />
          <FaqPage path="/faq-page" />
          <Selling path="/selling" />
          <SellingPage path="/selling-page" />
          {/* <Alerts path="/alerts" /> */}
          {/* <Progressbar path="/progressbar" /> */}
          {/* <Tabs path="/tabs" /> */}
          {/* <Minter path="/mint" /> */}
          {/* <Mintergrey path="/minter" /> */}
          <ResetPassword path='/resetPassword'/>
          <ForgotPassword path='/forgotPassword'/>
          <Forgot path='/forgot' />
          <CreateReSell path='/create-resell' />
          <PageNotFound path='/404' />
          <ProfileItem path='/Profile' />
          
          <ResetPswd path='/resetpaswd/:id' />
        </ScrollTop>
      </PosedRouter>




      <ScrollToTopBtn />
    </div>
  );
}
export default App;