import React, { useState, useEffect } from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Axios from '../../api-instance';
import moment from 'moment';
import FraktionLoader from '../components/Loader'
import imgs from '../../assets/output-onlinegiftools (1).gif'


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const Activity = function () {

  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);
  const handleBtnClick = (): void => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    setOpenMenu2(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.remove("active");
  };
  const handleBtnClick1 = (): void => {
    setOpenMenu1(!openMenu1);
    setOpenMenu2(false);
    setOpenMenu(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.add("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.remove("active");
  };
  const handleBtnClick2 = (): void => {
    setOpenMenu2(!openMenu2);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.add("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.remove("active");
  };
  const handleBtnClick3 = (): void => {
    setOpenMenu3(!openMenu3);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu2(false);
    setOpenMenu4(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.remove("active");
    document.getElementById("like").classList.add("active");
  };
  const handleBtnClick4 = (): void => {
    setOpenMenu4(!openMenu4);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    setOpenMenu2(false);
    document.getElementById("follow").classList.remove("active");
    document.getElementById("sale").classList.remove("active");
    document.getElementById("offer").classList.add("active");
    document.getElementById("like").classList.remove("active");
  };



  const [isDark, setIsDark] = useState(false)
  useEffect(() => {
    if (localStorage.getItem('mode') === 'dark') {
      setIsDark(true)
    } else if (localStorage.getItem('mode') === 'light') {
      setIsDark(false)
    }
  }, [])



  const [userData, setUserData] = useState('')
  const [buyData, setBuyData] = useState('')
  const [auctionData, setAuctionData] = useState('')
  const [sellData, setSellData] = useState('')
  const [mintingLoader, setMintingLoader] = useState(false)
  useEffect(() => {
    const getData = async () => {

      const token = window.localStorage.getItem("token");

      if (token) {
        setMintingLoader(true)
        const getUserData = await Axios.get(`/auth/getdata/${token}`);
        const getActivity = await Axios.get(`/product?userId=${getUserData?.data?.data?._id}`)
        const getBuyData = await Axios.get(`/product?getOwnerId=${getUserData?.data?.data?._id}`)
        const getSellData = await Axios.get(`/product?userId=${getUserData?.data?.data?._id}&isSold=true`)
        setMintingLoader(false)



        const mintTransaction = getActivity?.data?.data?.sort((a, b) => {
          return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf();
        })


        const buyData = getBuyData?.data?.data?.sort((a, b) => {
          return new Date(b.updatedAt).valueOf - new Date(a.updatedAt).valueOf()
        })


        const sellsData = getSellData?.data?.data?.sort((a, b) => {
          return new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
        })




        setUserData(mintTransaction);
        setBuyData(buyData)
        setSellData(sellsData)
      }
    }
    getData()
  }, [])




  return (
    <>
      {/* 
<div
  style={{
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%",
  }}
>
  <FraktionLoader />
  </div> */}


      {
        isDark ?
          <div className="greyscheme">
            <GlobalStyles />

            <section className='jumbotron breadcumb no-bg'>
              <div className='mainbreadcumb'>
                <div className='container'>
                  <div className='row m-10-hor'>
                    <div className='col-12'>
                      <h1 className='text-center'>Activity</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className='container'>
              <div className='row'>

                <div className="col-md-8">

                  {openMenu && (
                    <ul className="activity-list">
                      <li className="act_follow">
                        <img className="lazy" src="./img/author/author-1.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Monica Lucas</h4>
                          started following <span className='color'>Gayle Hicks</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                      <li className="act_sale">
                        <img className="lazy" src="./img/items/thumbnail-2.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Deep Sea Phantasy</h4>
                          1 edition purchased by <span className='color'>Stacy Long</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                      <li className="act_follow">
                        <img className="lazy" src="./img/author/author-2.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Mamie Barnett</h4>
                          started following <span className='color'>Claude Banks</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                      <li className="act_sale">
                        <img className="lazy" src="./img/items/thumbnail-4.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Two Tigers</h4>
                          1 edition purchased by <span className='color'>Jimmy Wright</span> for 0.02 ETH
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                      <li className="act_like">
                        <img className="lazy" src="./img/items/thumbnail-7.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Cute Astronout</h4>
                          liked by <span className='color'>Nicholas Daniels</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                      <li className="act_offer">
                        <img className="lazy" src="./img/items/thumbnail-5.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Purple Planet</h4>
                          <span className='color'>Franklin Greer</span> offered 0.002 ETH
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                      <li className="act_like">
                        <img className="lazy" src="./img/items/thumbnail-6.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Cute Astronout</h4>
                          liked by <span className='color'>Nicholas Daniels</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                    </ul>
                  )}

                  {openMenu1 && (
                    <ul className="activity-list">
                      <li className="act_follow">
                        <img className="lazy" src="./img/author/author-1.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Monica Lucas</h4>
                          started following <span className='color'>Gayle Hicks</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                      <li className="act_follow">
                        <img className="lazy" src="./img/author/author-2.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Mamie Barnett</h4>
                          started following <span className='color'>Claude Banks</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                    </ul>
                  )}

                  {openMenu2 && (
                    <ul className="activity-list">
                      <li className="act_sale">
                        <img className="lazy" src="./img/items/thumbnail-2.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Deep Sea Phantasy</h4>
                          1 edition purchased by <span className='color'>Stacy Long</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                      <li className="act_sale">
                        <img className="lazy" src="./img/items/thumbnail-4.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Two Tigers</h4>
                          1 edition purchased by <span className='color'>Jimmy Wright</span> for 0.02 ETH
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                    </ul>
                  )}

                  {openMenu3 && (
                    <ul className="activity-list">
                      <li className="act_like">
                        <img className="lazy" src="./img/items/thumbnail-7.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Cute Astronout</h4>
                          liked by <span className='color'>Nicholas Daniels</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                      <li className="act_like">
                        <img className="lazy" src="./img/items/thumbnail-6.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Cute Astronout</h4>
                          liked by <span className='color'>Nicholas Daniels</span>
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                    </ul>
                  )}

                  {openMenu4 && (
                    <ul className="activity-list">
                      <li className="act_offer">
                        <img className="lazy" src="./img/items/thumbnail-5.jpg" alt="" />
                        <div className="act_list_text">
                          <h4>Purple Planet</h4>
                          <span className='color'>Franklin Greer</span> offered 0.002 ETH
                          <span className="act_list_date">
                            10/07/2021, 12:40
                          </span>
                        </div>
                      </li>
                    </ul>
                  )}
                </div>


                <div className="col-md-4">
                  <span className="filter__l">Filter</span>
                  <span className="filter__r" onClick={handleBtnClick}>Reset</span>
                  <div className="spacer-half"></div>
                  <div className="clearfix"></div>
                  <ul className="activity-filter">
                    <li id='sale' className="filter_by_sales" onClick={handleBtnClick2}><i className="fa fa-shopping-basket"></i>Sales</li>
                    <li id='like' className="filter_by_likes" onClick={handleBtnClick3}><i className="fa fa-heart"></i>Likes</li>
                    <li id='offer' className="filter_by_offers" onClick={handleBtnClick4}><i className="fa fa-gavel"></i>Offers</li>
                    <li id='follow' className="filter_by_followings" onClick={handleBtnClick1}><i className="fa fa-check"></i>Followings</li>
                  </ul>
                </div>
              </div>
            </section>

            <Footer />
          </div>
          :
          mintingLoader ?


            // <div class="lds-hourglass" style={{ marginLeft: '50%', marginTop: '25%' }}></div>
            <div style={{ display: 'flex', justifyContent: 'center' }} >
              <img src={imgs} alt="" />
            </div>

            :
            <div className="profile-container-section">
              <GlobalStyles />

              <section className='jumbotron breadcumb no-bg ' style={{ backgroundImage: `url(${'./img/background/subheader.webp'})` }}>
                <div className='mainbreadcumb'>
                  <div className='container'>
                    <div className='row m-10-hor'>
                      <div className='col-12'>
                        <h1 className='text-center black-color'>Activity</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section>


              <section className='container activity-container'>
                <div className='row'>


                  <div className="col-md-4">
                    <span className="filter__l black-color">Filter</span>
                    <span className="filter__r black-color" onClick={handleBtnClick}>Reset</span>
                    <div className="spacer-half"></div>
                    <div className="clearfix"></div>
                    <ul className="activity-filter">
                      <li id='sale' className="filter_by_sales black-color" onClick={handleBtnClick2}><i className="fa fa-shopping-basket"></i>Mint</li>
                      <li id='like' className="filter_by_likes black-color" onClick={handleBtnClick3}><i className="fa fa-heart"></i>Buy</li>
                      <li id='offer' className="filter_by_offers black-color" onClick={handleBtnClick4}><i className="fa fa-gavel"></i>Sale</li>
                      <li id='follow' className="filter_by_followings black-color" onClick={handleBtnClick1}><i className="fa fa-check"></i>Auction</li>
                    </ul>
                  </div>

                  <div className="col-md-8 center-activity-item">

                    {openMenu && (
                      <ul className="activity-list">
                        {
                          userData?.length > 0 ?
                            userData?.map((item, i) => {
                              return (
                                <li className="act_follow black-color">
                                  <img className="lazy" src={item?.ProductImage} alt="" />
                                  <div className="act_list_text black-color">
                                    <h4 className="black-color">{item?.name}</h4>
                                    Minting <span className='color black-color'>{item?.name}</span>
                                    <span className="act_list_date black-color">
                                      {moment(item?.updatedAt).format('llll')}
                                    </span>
                                  </div>
                                </li>
                              )
                            }) :
                            <div className="black-color" style={{ textAlign: 'center' }}>
                              No-data found!
                            </div>
                        }
                      </ul>
                    )}

                    {openMenu1 && (
                      <ul className="activity-list">
                        {
                          buyData?.length > 0 ?
                            buyData?.map((item, i) => {
                              if (item?.isAuction) {
                                return (
                                  <li className="act_follow">
                                    <img className="lazy" src={item?.ProductImage} alt="" />
                                    <div className="act_list_text">
                                      <h4 className="black-color">{item?.name}</h4>
                                      Auction <span className='color black-color'>{item?.name}</span>
                                      <span className="act_list_date black-color">
                                        {moment(item?.updatedAt).format('llll')}
                                      </span>
                                    </div>
                                  </li>
                                )
                              }

                            }) :
                            <div className="black-color" style={{ textAlign: 'center' }}>
                              No-data found!
                            </div>

                        }
                      </ul>
                    )}

                    {openMenu2 && (
                      <ul className="activity-list">
                        {
                          userData?.length > 0 ?
                            userData?.map((item, i) => {
                              return (
                                <li className="act_follow">
                                  <img className="lazy" src={item?.ProductImage} alt="" />
                                  <div className="act_list_text black-color">
                                    <h4 className="black-color">{item?.name}</h4>
                                    Minting <span className='color black-color'>{item?.name}</span>
                                    <span className="act_list_date black-color">
                                      {moment(item?.updatedAt).format('llll')}
                                    </span>
                                  </div>
                                </li>
                              )
                            }) :
                            <div className="black-color" style={{ textAlign: 'center' }}>
                              No-data found!
                            </div>
                        }
                      </ul>
                    )}

                    {openMenu3 && (
                      <ul className="activity-list">
                        {
                          buyData?.length > 0 ?
                            buyData?.map((item, i) => {
                              if (!item?.isAuction) {
                                return (
                                  <li className="act_follow">
                                    <img className="lazy" src={item?.ProductImage} alt="" />
                                    <div className="act_list_text black-color">
                                      <h4 className="black-color">{item?.name}</h4>
                                      Minting <span className='color black-color'>{item?.name}</span>
                                      <span className="act_list_date black-color">
                                        {moment(item?.updatedAt).format('llll')}
                                      </span>
                                    </div>
                                  </li>
                                )
                              }

                            }) :
                            <div className="black-color" style={{ textAlign: 'center' }}>
                              No-data found!
                            </div>
                        }
                      </ul>
                    )}

                    {openMenu4 && (
                      <ul className="activity-list">
                        {
                          sellData?.length > 0 ?
                            sellData?.map((item, i) => {
                              return (
                                <li className="act_follow">
                                  <img className="lazy" src={item?.ProductImage} alt="" />
                                  <div className="act_list_text">
                                    <h4 className="black-color">{item?.name}</h4>
                                    Sale <span className='color black-color'>{item?.name}</span>
                                    <span className="act_list_date black-color">
                                      {moment(item?.updatedAt).format('llll')}
                                    </span>
                                  </div>
                                </li>
                              )


                            }) :
                            <div className="black-color" style={{ textAlign: 'center' }}>
                              No-data found!
                            </div>
                        }
                      </ul>
                    )}
                  </div>


                </div>
              </section>



              <Footer />
            </div>
      }
    </>

  );
}

export default Activity;