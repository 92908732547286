import React from 'react'
import './Loader.css'
import fraktionLogo from '../../assets/loader/loading.gif'

const Loader = ({dayMode}) => {
    return (
        <div className='load_div'>
            <div className='imgloader'>
                <div className='head'>

                </div>
                <h6>
                    Keep Calm Your NFT is on Your Way
                </h6>
                <img src={fraktionLogo} alt="eze-dark" />
               
            </div>
        </div>
    )
}

export default Loader
