import React, { useState, useEffect, useRef } from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import axios from 'axios'
import constant from '../../constants'
import './style.css'

import { ToastContainer, toast } from 'react-toastify';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import 'react-toastify/dist/ReactToastify.css';
import { Link } from '@reach/router';
import validator from 'validator';
import { Modal } from 'rsuite'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
const passwordValidator = require('password-validator');

var schema = new passwordValidator();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const GlobalStylesGrey = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid #ff343f;
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: #ff343f;
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 #ff343f;
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: rgba(33, 36, 40, .9);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{
    color: #fff !important;
    background: #ff343f;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: #ff343f;
  }
  @media only screen and (max-width: 1199px) { 
    .navbar {
      background: #212428;
    }
  }
`;

// Add properties to it
schema
  .is().min(8)                                    // Minimum length 8
  .is().max(30)                                  // Maximum length 100
  .has().uppercase(1)                              // Must have uppercase letters
  .has().lowercase(1)                              // Must have lowercase letters
  .has().digits(1)                                // Must have at least 2 digits
  .has().not().spaces()                           // Should not have spaces
  .is().not().oneOf(['Passw0rd', 'Password123']);

const Register = () => {

  const [isDark, setIsDark] = useState(false)
  useEffect(() => {
    if (localStorage.getItem('mode') === 'dark') {
      setIsDark(true)
    } else if (localStorage.getItem('mode') === 'light') {
      setIsDark(false)
    }
  }, [])

  const [dataSet, setDataSet] = useState({
    name: null,
    email: null,
    username: null,
    phone: null,
    password: null,
    rePassword: null,
    isActive: true
  })


  const [sendPassword, setSendPassword] = useState(false)


  const handlePasswordVerifier = (e) => {
    if (dataSet.password !== e.target.value) {
      setSendPassword(true)
    } else if (dataSet.password === e.target.value) {
      setSendPassword(false)
      const { name, value } = e.target;
      setDataSet((prev) => {
        return {
          ...prev,
          [name]: value
        }
      })
    }
    setRepasswordWarning(false)
  }

  const handleChange = (event) => {


    setNameWarning(false)
    setEmailWarning(false)
    setUserNameWarning(false)
    setEmailValidator(false)
    setPhoneWarning(false)
    setPhoneValidator(false)
    setPasswordWarning(false)
    setRepasswordWarning(false)
    setPasswordValidator(false)
    setSendPassword(false)




    const { name, value } = event.target;

    const phoneRegex = /^\d{7,16}$/;
    if (name === "phone") {
      if (value === "") {
        setPhoneValidator(false);
      } else if (!phoneRegex.test(value)) {
        setPhoneValidator(true);
      } else {
        setPhoneValidator(false);
      }
    }
    setDataSet((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }
  const [registered, setRegistered] = useState(false)



  const [nameWarning, setNameWarning] = useState(null)
  const [userNameWarning, setUserNameWarning] = useState(null)
  const [emailWarning, setEmailWarning] = useState(null)
  const [phoneWarning, setPhoneWarning] = useState(null)
  const [passwordWarning, setPasswordWarning] = useState(null)
  const [repasswordWarning, setRepasswordWarning] = useState(null)
  const [emailValidator, setEmailValidator] = useState(null)
  const [passwordValidator, setPasswordValidator] = useState(null)
  const [emailError, setEmailError] = useState("");
  const [phonevalidator, setPhoneValidator] = useState(null);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordMatches, setPasswordMatches] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const passwordRef = useRef(null)
  const confirmPasswordRef = useRef(null)


  const handleEmailChange = (event) => {
    const email = event.target.value;
    setDataSet((prev) => ({ ...prev, email }));
    setEmailError(email === "" ? "" : validateEmail(email) ? "" : "Invalid email address");
  };

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setDataSet((prev) => ({ ...prev, password: newPassword }));
    setPasswordError(
      newPassword === "" ? "" : validatePassword(newPassword)
        ? ""
        : "Password must be at least 8 characters long."
    );

    setPasswordMatches(newPassword === dataSet.rePassword);
  };

  const validatePassword = (password) => {
    return (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*]/.test(password)
    );
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setDataSet((prev) => ({ ...prev, rePassword: newConfirmPassword }));

    setConfirmPasswordError('');
    setPasswordMatches(dataSet.password === newConfirmPassword);
  };

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    passwordRef.current.type = isPasswordVisible ? 'password' : 'text';
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    confirmPasswordRef.current.type = isConfirmPasswordVisible ? 'password' : 'text';
  };



  const handleSubmit = async (event) => {

    if (dataSet.name === null) {
      return setNameWarning(true)
    }
    if (dataSet.email === null) {
      return setEmailWarning(true)
    }
    if (!validator.isEmail(dataSet.email)) {
      return setEmailValidator(true)
    }
    if (dataSet.username === "") {
      return setUserNameWarning(true)
    }
    if (dataSet.phone === "") {
      return setPhoneWarning(true)
    }
    if (dataSet.password === "") {
      return setPasswordWarning(true)
    }
    if (dataSet.rePassword === "") {
      return setRepasswordWarning(true)
    }
    if (!schema.validate(dataSet.password)) {
      return setPasswordValidator(true)
    }

    if (dataSet.password !== dataSet.rePassword) {
      return setSendPassword(true)
    }

    event.preventDefault();
    try {

      const datas = await axios.post(`${constant.port}/auth/signup`, dataSet, {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        }
      })
      setRegistered(datas?.data?.msg === 'success');
      if (datas?.data?.msg === 'success') {
        toast.success('User Created Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success(datas?.data?.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      // setTimeout(()=>{
      //   window.location.href=`${constant.MainURL}/login`
      // },3000)
    } catch (error) {
      alert(error)
      window.location.reload()
    }
  }

  const toastId = React.useRef(null);
  // if(! toast.isActive(toastId.current)) {
  //   toastId.current = toast('User Created Successfully, Verification mail sended to your mail', {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     });
  // }
  const styles = {
    radioGroupLabel: {
      padding: '8px 12px',
      display: 'inline-block',
      verticalAlign: 'middle'
    }
  };

  const [open, setOpen] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState('static');


  const [hidePassword, setHidePassword] = useState(true)
  const [reHidePassword, setReHidePassword] = useState(true)

  const passwordToggle = () => {
    if (hidePassword) {
      setHidePassword(false)
    } else {
      setHidePassword(true)
    }
  }


  const rePasswordToggle = () => {
    if (reHidePassword) {
      setReHidePassword(false)
    } else {
      setReHidePassword(true)
    }
  }



  return (
    <>
      <ToastContainer style={{ top: "5em" }} />
      {
        registered &&
        <div className="checkout ">
          <div className="maincheckout modalColor">
            <button
              className="btn-close"
              onClick={() => setRegistered(false)}
            >
              x
            </button>
            <div className="heading">
              <h3 className="black-color"> Account Created Successfully.....</h3>
            </div>
            <h1 className="black-color">Hi, {dataSet?.name}</h1>
            <p className="black-color">The verification link sent to this email <span className="blue-color">{dataSet?.email}</span> </p>
            <p className="black-color">Please, Verfiy your E-mail and then Sign-in</p>
            <Link to="/login">
              <button className="btn-main">Login</button>
            </Link>

          </div>
        </div>

      }
      {
        isDark ?
          <div className="greyscheme">
            <GlobalStylesGrey />
            <section className='container'>
              <div className="row">
                <div className='spacer-double'></div>
                <div className="col-md-8 offset-md-2">
                  <h3>Don't have an account? Register now.</h3>

                  <div className="spacer-10"></div>

                  <section name="contactForm" id='contact_form' className="form-border" >

                    <div className="row">

                      <div className="col-md-6">
                        <div className="field-set">
                          <label>Name:</label>
                          <input type='text' name='name' id='name' className="form-control" onChange={handleChange} value={dataSet.name} />
                          {
                            nameWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this name field
                              </p> : null
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label>Email Address:</label>
                          <input type='email' name='email' id='email' className="form-control" onChange={handleEmailChange} value={dataSet.email} />
                          {
                            emailWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this email field
                              </p> : null
                          }
                          {
                            emailError ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this valid email address
                              </p> : null
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label>Choose a Username:</label>
                          <input type='text' name='username' id='username' className="form-control" onChange={handleChange} value={dataSet.username} />
                          {
                            userNameWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this username field
                              </p> : null
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label>Phone:</label>
                          <input type='number' name='phone' id='phone' className="form-control" onChange={handleChange} value={dataSet.phone} />
                          {
                            phoneWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this phone field
                              </p> : null
                          }
                          {phonevalidator ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *Please enter a valid phone number (7 - 16 digits).
                            </p> : <>
                            </>
                          }

                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label>Password:</label>
                          <div className="form-control" style={{ display: 'flex', alignItems: 'center' }}>
                            <input type={passwordRef.current?.type || 'password'} name='password' id='password' onChange={handlePasswordChange} value={dataSet.password}  ref={passwordRef} required className='passwordField' style={{ width: '100%' }} />

                            <IconButton onClick={handleTogglePasswordVisibility}>
                              {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </div>                                        {
                            passwordWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this password field
                              </p> : null
                          }
                          {
                            passwordError ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *{passwordError}
                              </p> : <>
                              </>
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label>Re-enter Password:</label>
                          <div className="form-control" style={{ display: 'flex', alignItems: 'center' }}>
                            <input type={confirmPasswordRef.current?.type || 'confirmpassword'} name='rePassword' id='rePassword' onChange={handleConfirmPasswordChange} value={dataSet.rePassword} ref={confirmPasswordRef} required className='passwordField' style={{ width: '100%' }} />
                            <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                              {isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </div>                                        {
                            repasswordWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this Re-password field
                              </p> : null
                          }
                          {
                            !passwordMatches && dataSet.rePassword ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Password do not match
                              </p> : <>
                              </>
                          }
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div id='submit' className="pull-left">
                          <input onClick={handleSubmit} type='submit' id='send_message' value='Register Now' className="btn btn-main color-2" />
                        </div>

                        <div className="clearfix"></div>
                      </div>

                    </div>
                  </section>
                  <br />
                  <h4>Already Registered? <Link to='/login' style={{ color: 'blue' }}>Sign In</Link></h4>
                </div>

              </div>
            </section>

            <Footer />
          </div>
          :

          <div>
            <GlobalStyles />

            <section className='jumbotron breadcumb no-bg '
            //  style={{background:'linear-gradient(90deg, rgba(9,60,121,1) 0%, rgba(50,152,208,1) 100%)'}}
            >
              <div className='mainbreadcumb'>
                <div className='container'>
                  <div className='row'>
                    <div className="col-md-12 text-center">
                      <h1 className="black-color">Register</h1>
                      <span className="black-color">Signup to discover premium and exclusive NFTs created by global leading artists.</span><br />
                      {/* <span>Anim pariatur cliche </span> */}

                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className='container'>
              <div className="row">

                <div className="col-md-8 offset-md-2">
                  <h3 className="black-color">Don't have an account? Register now.</h3>

                  <div className="spacer-10"></div>

                  <section name="contactForm" id='contact_form' className="form-border" action='#'>

                    <div className="row">

                      <div className="col-md-6">
                        <div className="field-set">
                          <label className="black-color">Name:</label>
                          <input type='text' name='name' id='name' className="form-control" onChange={handleChange} value={dataSet.name} />
                          {
                            nameWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this name field
                              </p> : null
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label className="black-color">Email Address:</label>
                          <input type='email' name='email' id='email' className="form-control" onChange={handleEmailChange} value={dataSet.email} />
                          {
                            emailWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this email field
                              </p> : null
                          }
                          {
                            emailError ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this valid email address
                              </p> : null
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label className="black-color">Choose a Username:</label>
                          <input type='text' name='username' id='username' className="form-control" onChange={handleChange} value={dataSet.username} />
                          {
                            userNameWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this username field
                              </p> : null
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label className="black-color">Phone:</label>
                          <input type='number' name='phone' id='phone' className="form-control" onChange={handleChange} value={dataSet.phone} />
                          {
                            phoneWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this phone field
                              </p> : null
                          }
                          {phonevalidator ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *Please enter a valid phone number (7 - 16 digits).
                            </p> : <>
                            </>
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label className="black-color">Password:</label>
                          <div className="form-control" style={{ display: 'flex', alignItems: 'center' }}>
                            <input type={passwordRef.current?.type || 'password'} name='password' id='password' onChange={handlePasswordChange} value={dataSet.password} ref={passwordRef} required className='passwordField' style={{ width: '100%' }} />

                            <IconButton onClick={handleTogglePasswordVisibility}>
                              {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </div>
                          {
                            passwordWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this password field
                              </p> : null
                          }
                          {
                            passwordError ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *{passwordError}
                              </p> : <>
                              </>
                          }
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label className="black-color">Re-enter Password:</label>
                          <div className="form-control" style={{ display: 'flex', alignItems: 'center' }}>
                            <input type={confirmPasswordRef.current?.type || 'confirmpassword'} name='rePassword' id='rePassword' onChange={handleConfirmPasswordChange} value={dataSet.rePassword} ref={confirmPasswordRef} required className='passwordField' style={{ width: '100%' }} />
                            <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                              {isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </div>
                          {
                            repasswordWarning ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this Re-password field
                              </p> : null
                          }
                          {
                            !passwordMatches && dataSet.rePassword ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Password do not match
                              </p> : <>
                              </>
                          }
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div id='submit' className="pull-left">
                          <input onClick={handleSubmit} type='submit' id='send_message' value='Register Now' className="btn btn-main color-2" />

                        </div>

                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </section>
                  <br />
                  <h4 className="black-color">Already Registered? <Link to='/login' style={{ color: 'blue' }}>Sign In</Link></h4>
                </div>

              </div>
            </section>

            <Footer />
          </div>


      }
    </>
  )
}
export default Register;