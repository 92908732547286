import React, { memo,useEffect,useState } from 'react';
import styled from "styled-components";
import Clock from "./Clock";
import { navigate } from '@reach/router';
import api from '../../core/api';
import { Link } from '@reach/router';
import Avatar from 'react-avatar';
// import { Axios } from '../../core/axios';
import Axios from '../../api-instance'

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({setNftAddress,nftTime,handleView,userData, isApproved,nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad }) => {
  
    


    // if(nft?.favourite){
const [likedOrNot, setLikedOrNot] = useState(false)
      const a =   nft?.favourite?.filter((item,i)=>{
               if (item===userData?._id){
                  setLikedOrNot(true)
               }
        })
        // console.log(a);
    // }

    const [checkTimeOrNot, setCheckTimeOrNot] = useState('')
    const [auctionTime, setAuctionTime] = useState(false)
    const [dollar, setDollar] = useState('')
 
useEffect(()=>{

    
    

    const getData = async()=>{

        try {
            const {data} = await Axios.get(`/admin`)
            nft.admin = data?.data[0]
            setDollar(data?.data[0])
         
         
         
        } catch (error) {
            console.log(error);
        }
    }

    getData()
},[])


const handleAddress = ()=>{
    setNftAddress(nft?.mintTransaction?.transactionHash)
}



    return (
        <div >
            <Link onClick={handleAddress} state={nft} to={`/ItemDetail/${nft.mintTransaction?.transactionHash}`}>
            <div className="nft__item m-0 blue_card">
            { nft?.item_type === 'single_items' ? (
             <div className='icontype'><i className="fa fa-bookmark"></i></div>   
             ) : (  
             <div className='icontype'><i className="fa fa-shopping-basket"></i></div>
                )
            }
             {/* { nft?.created && clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft?.created} />
                    </div>
                } */}
                <Link state={nft?.userData[0]} to={`/profile/${nft?.userData[0]?._id}`}>
                <div className="author_list_pp">
                    <span>
                    {/* onClick={()=> navigateTo(nft?.Link)} */}
                    {
nft?.userData[0]?.profilePicture?
<img className="lazy" src={nft?.userData[0]?.profilePicture} alt=""/>
:
<Avatar name={nft?.userData[0]?.name} size="40" round="50px"/>
                    }
                        <i className="fa fa-check"></i>
                    </span>
                </div>
                </Link>
                <div  className="nft__item_wrap imageBoxContainer" >
                <Outer>
                    <span>
                        <img   src={nft?.ProductImage} className="lazy nft__item_preview imageBox" alt="imagess" />
                    </span>
                </Outer>
                </div>
                { nft?.isAuction&&
      
                    nft?.auctionTime &&
                    !nft?.openBid&&
                   
                    <div className="de_countdown">
                    <Clock deadline={nft?.auctionTime} />
                </div>
                   
                }
                <div className="nft__item_info">
                    <span >
              
                        <h4 className="black-color">{nft?.name}</h4>
                    </span>
                    { nft?.status === 'has_offers' ? (
                            <div className="has_offers">
                                <span className='through black-color'>{nft?.price}</span> {nft?.price} ETH (${(nft?.price*parseFloat(dollar?.amount)).toFixed(5)})
                            </div> 
                        ) : (
                            <div className="nft__item_price black-color">
                                {nft?.price} ETH (${(nft?.price*parseFloat(dollar?.amount)).toFixed(5)})
                                { nft?.status === 'on_auction' && 
                                    <span className="black-color">{nft?.bid}/{nft?.max_bid}</span>
                                }
                            </div>
                        )
                    }
                    <div className="nft__item_action">
                        <span className="black-color">{ nft?.isAuction? 'Place a bid' : 'Buy Now' }</span>
                    </div>
                    <div className="nft__item_like">
                        {
                            likedOrNot?
                            <i className="fa fa-heart" style={{color:'red'}}></i>
                            :
                            <i className="fa fa-heart"></i>
                        }
                        <span className="black-color">{nft?.favourite?.length}</span>
                    </div>                            
                </div> 
            </div>
            
            </Link>
        </div>             
    );
};

export default memo(NftCard);