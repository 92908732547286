import React, { memo, useState, useEffect } from 'react';
import './Myprofiletable.css'
import { navigate } from '@reach/router';
import { styled } from '@mui/material/styles';
import { Link } from '@reach/router';
import api from '../../core/api';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import Axios from '../../api-instance'
const StyledTable = styled(Table)(({ theme }) => ({
    border: "none !important",
    fontFamily: "inherit !important",
    '& thead th': {
        backgroundColor: "white !important",
        fontSize: "14px !important",
        fontFamily: "inherit !important",
        fontWeight: 400,
        color: "#545454 !important",
        border: "none !important",
        textAlign: "left !important",
        padding: "16px !important",
        borderBottom: "1px solid rgba(0,0,0,.1) !important"
    },
}));

// Styled TableCell component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "10px",
    fontFamily: "inherit !important",
    fontWeight: 600,
    fontSize: "16px",
    border: "none !important",

}));

const GridContainer = styled(Grid)(({ theme }) => ({

    display: "flex",


}));
//react functional component
const NftToptable = ({ nfts }) => {
    const [userId, setUserId] = useState(null)
    const [mintedData, setMintedData] = useState([])
    const [userContent, setUserContent] = useState({});
    const [walletAddress, setWalletAddress] = useState('')
    const [currentMetamaskAddress, setCurrentMetamaskAddress] = useState(false)
    const [showcard,setShowcard] = useState(true)
        const navigateTo = (link) => {
        navigate(link);
    }
    
    const getUserData = async (id) => {
        const account = await window.ethereum.request({
          method: "eth_requestAccounts"
        });
        setCurrentMetamaskAddress(account[0])
        
        try {
          const token = window.localStorage.getItem('token')
          const { data } = await Axios.get(`/auth/getuserdata/${id}`);
          setUserId(data?.data?._id);
          setUserContent(data?.data)
          setShowcard(true)
              
        } catch (error) {
          console.log(error);
        }
    
        if (account) {
          setWalletAddress(account[0])
        }
      }
      const closeCard = () => {
        setUserId(null);
        setUserContent({})
      }
    const midIndex = Math.ceil(nfts?.length / 2);
    const nftsFirstHalf = nfts?.slice(0, midIndex);
    const nftsSecondHalf = nfts?.slice(midIndex);
    // const useAudio = (url) => {
    //     const [audio] = useState(new Audio(url));
    //     const [playing, setPlaying] = useState(false);

    //     const toggle = () => setPlaying(!playing);

    //     useEffect(() => {
    //         playing ? audio.play() : audio.pause();
    //       },
    //       [playing]
    //     );

    //     useEffect(() => {
    //       audio.addEventListener('ended', () => setPlaying(false));
    //       return () => {
    //         audio.removeEventListener('ended', () => setPlaying(false));
    //         audio.pause();
    //       };
    //     }, [audio]);

    //     return [playing, toggle];
    // };

    // const [playing, toggle] = useAudio(audioUrl);

    return (
        <>
       <GridContainer>
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                {console.log(nfts, "nft")}
                    <TableContainer >
                        <StyledTable aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>Ranks</TableCell>
                                    <TableCell align='left'>NFT</TableCell>
                                    <TableCell align='left'>Floor Price</TableCell>
                                    <TableCell align='left'>Mint Id</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {nfts?.map((nft, index) => (
                                    
                                    < TableRow key={index} style={{ cursor: "pointer" }}>
                                   
                                        <StyledTableCell>{index + 1}</StyledTableCell>
                                        <StyledTableCell >
                                        <Link  to={`/itemdetails/${nft.mintTransaction?.transactionHash}`}> <div className='imgtextCell' >
                                                <div className='imgCell'>
                                                    <img src={nft?.ProductImage} alt="" />
                                                </div>
                                                <div className='textCell'>
                                                    <p>{nft?.name}</p>
                                                </div>
                                            </div>
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell>  <div className='textCell'>
                                            <p>{nft?.price} {nft?.blockChain}</p>
                                        </div></StyledTableCell>
                                        <StyledTableCell> 
                                         <div className='textCell'>
                                            <p>{nft?.mintId}</p>  
                                        </div>
                                        </StyledTableCell>
                                       
                                    </TableRow>
                                   
                                ))}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                    </Grid>    
                    </GridContainer> 
        </>

    );
};

export default memo(NftToptable);