import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/animated.css";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import './assets/style_grey.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react'

// import { ChakraProvider } from '@chakra-ui/react'

//redux store
import { Provider } from 'react-redux'
import store,{persistor} from './store';

ReactDOM.render(
	// <ChakraProvider>
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
		<App />
		</PersistGate>
	</Provider>
	
	
	, 
	document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();