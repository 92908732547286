import React, { memo, useState, useEffect } from 'react';
import Clock from "./Clock";
import './NFtToptable.css'
import { navigate } from '@reach/router';
import { styled } from '@mui/material/styles';
import api from '../../core/api';
import OwlCarousel from "react-owl-carousel";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';

const StyledTable = styled(Table)(({ theme }) => ({
    border: "none !important",
    fontFamily: "inherit !important",
    '& thead th': {
        backgroundColor: "white !important",
        fontSize: "14px !important",
        fontFamily: "inherit !important",
        fontWeight: 400,
        color: "#545454 !important",
        border: "none !important",
        textAlign: "left !important",
        padding: "16px !important",
        borderBottom: "1px solid rgba(0,0,0,.1) !important"
    },
}));

// Styled TableCell component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "10px",
    fontFamily: "inherit !important",
    fontWeight: 600,
    fontSize: "16px",
    border: "none !important",

}));

const GridContainer = styled(Grid)(({ theme }) => ({

    display: "flex",


}));

//react functional component
const NftTrenttable = ({ nfts, audioUrl, className = 'f-item col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4', height, onImgLoad }) => {
    const midIndex = Math.ceil(nfts.length / 2);
    const nftsFirstHalf = nfts.slice(0, midIndex);
    const nftsSecondHalf = nfts.slice(midIndex);
    const navigateTo = (link) => {
        navigate(link);
    }
    const options = {
        margin: 30,
        loop: true,
        responsiveClass: true,
        nav: true,
        dots: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 3,
            },
            1000: {
                items: 4,
            },
            1400: {
                items: 4,
            },
        },
    };
    // const useAudio = (url) => {
    //     const [audio] = useState(new Audio(url));
    //     const [playing, setPlaying] = useState(false);

    //     const toggle = () => setPlaying(!playing);

    //     useEffect(() => {
    //         playing ? audio.play() : audio.pause();
    //       },
    //       [playing]
    //     );

    //     useEffect(() => {
    //       audio.addEventListener('ended', () => setPlaying(false));
    //       return () => {
    //         audio.removeEventListener('ended', () => setPlaying(false));
    //         audio.pause();
    //       };
    //     }, [audio]);

    //     return [playing, toggle];
    // };

    // const [playing, toggle] = useAudio(audioUrl);

    return (
        <>
            <GridContainer container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                    <TableContainer >
                        <StyledTable aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>Rank</TableCell>
                                    <TableCell align='left'>Collection</TableCell>
                                    <TableCell align='left'>Floor</TableCell>
                                    <TableCell align='left'>Volume</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {console.log(nfts.length)}

                                {nftsSecondHalf.map((nft, index) => (

                                    <TableRow key={index}>
                                        <StyledTableCell>{index}</StyledTableCell>
                                        <StyledTableCell>
                                            <div className='imgtextCell'>
                                                <div className='imgCell'>
                                                    <img src={api.baseUrl + nft.preview_image.url} alt="" />
                                                </div>
                                                <div className='textCell'>
                                                    <p>{nft?.title}</p>

                                                </div>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>  <div className='textCell'>
                                            <p>0.0024 ETH</p>
                                        </div></StyledTableCell>
                                        <StyledTableCell><div className='textCell'>
                                            <p>24 ETH</p>
                                        </div></StyledTableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                    <TableContainer>
                        <StyledTable aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>Rank</TableCell>
                                    <TableCell align='left'>Collection</TableCell>
                                    <TableCell align='left'>Floor</TableCell>
                                    <TableCell align='left'>Volume</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {console.log(nfts.length)}

                                {nftsFirstHalf?.map((nft, index) => (

                                    <TableRow key={index}>
                                        <StyledTableCell>{index}</StyledTableCell>
                                        <StyledTableCell>
                                            <div className='imgtextCell'>
                                                <div className='imgCell'>
                                                    <img src={api.baseUrl + nft.preview_image.url} alt="" />
                                                </div>
                                                <div className='textCell'>
                                                    <p>{nft.title}</p>

                                                </div>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>  <div className='textCell'>
                                            <p>0.0024 ETH</p>
                                        </div></StyledTableCell>
                                        <StyledTableCell><div className='textCell'>
                                            <p>24 ETH</p>
                                        </div></StyledTableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    </TableContainer>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <OwlCarousel

                        className="card-wrapper"
                        {...options}
                        loop={true}
                        margin={10}
                        nav={true}

                        slideBy={1}
                    >

                        <div className="nft_pic">
                            <span>
                                <span className="nft_pic_info">
                                    <span className="nft_pic_title">Glass Cube</span>
                                    <span className="nft_pic_by">Mamie Barnett</span>
                                </span>
                            </span>
                            <div className="nft_pic_wrap">
                                <img src="./img/carousel/crs-1.jpg" className="lazy img-fluid" alt="" />
                            </div>
                        </div>
                        {/* {recent?.map((recentusers, key) => {
                        return <>{loadCardDetailsForUser(recentusers, key)}</>;
                      })} */}
                    </OwlCarousel>
                </Grid>

            </GridContainer>
        </>
        // <div>
        //     <div className="nft__item m-0 glassCards" >
        //         { nft.deadline &&
        //             <div className="de_countdown">
        //                 <Clock deadline={nft.deadline} />
        //             </div>
        //         }
        //         <div className="author_list_pp">
        //             <span onClick={()=> navigateTo(nft.author_link)}>                                    
        //                 <img className="lazy" src={api.baseUrl + nft.author.avatar.url} alt="" style={{width: '50px', height: '50px', objectFit: 'cover'}}/>
        //                 <i className="fa fa-check"></i>
        //             </span>
        //         </div>
        //         <div className="nft__item_wrap" style={{height: `${height}px`}}>
        //             <Outer>
        //             <span>
        //                 <img onLoad={onImgLoad} src={api.baseUrl + nft.preview_image.url} className="lazy nft__item_preview" alt=""/>
        //             </span>
        //             </Outer>
        //             <div className="nft_type_wrap">
        //                 <div onClick={toggle} className="player-container">
        //                     <div className={`play-pause ${playing ? 'pause' : 'play'}`}></div>
        //                 </div>
        //                 <div className={`circle-ripple ${playing ? 'play' : 'init'}`}></div>
        //             </div>
        //         </div>
        //         <div className="nft__item_info">
        //             <span onClick={() => navigateTo(`${nft.nft_link}/${nft.id}`)}>
        //                 <h4>{nft.title}</h4>
        //             </span>
        //             <div className="nft__item_price">
        //                 {nft.price} ETH
        //                 <span>{nft.bid}/{nft.max_bid}</span>
        //             </div>
        //             <div className="nft__item_action">
        //                 <span onClick={() => navigateTo(`${nft.bid_link}/${nft.id}`)}>Place a bid</span>
        //             </div>
        //             <div className="nft__item_like" style={{color:'white'}}>
        //                 <i className="fa fa-heart"></i><span style={{color:'white'}}>{nft.likes}</span>
        //             </div>                            
        //         </div> 
        //     </div>
        // </div>            
    );
};

export default memo(NftTrenttable);