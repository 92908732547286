import React from 'react';
import { Routes, Route, Navigate,Router } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Router>
    <Routes>
    <Route
      {...rest}
      render={props => {
        
        if (localStorage.getItem('token')) {
          return <Component {...props} />;
        } else {
          return <Navigate to="/login" />;
        }
      }}
    />
    </Routes>
    </Router>
  );
};

export default PrivateRoute;