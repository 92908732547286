
const initialState = {
    user:null
}

export const searchReducer = (state=initialState,action)=>{
    
    switch(action.type){
        case 'SEARCH_ITEM':
           
              return state = action
           
            
            break;
            default:
                return state
    }
}