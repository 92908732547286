
import React from 'react'
import image from './404-error-page-not-found.jpg'
const PageNotFound = () => {
    return (
        <div >
          <img src={image} style={{width:"100vw",height:"100vh"}} alt='404'/>
        </div >
    )
}

export default PageNotFound