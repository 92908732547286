import Axios from '../../api-instance'

const initialState = {
    user:null
}

export const userReducer = (state=initialState,action)=>{
    switch(action.type){
        case 'GET_USER':
            // (async()=>{
            //     const token = localStorage.getItem('token')
            //     if(token){
            //         const data = await Axios.get(`/auth/getdata/${action.user}`)
            //         return {
            //             user:data
            //         }
            //     }
            
            //     }
            // )()
            return{
                user:action
            }
            break;
            default:
                return state
    }
}