import React, { memo, useEffect, useState } from 'react';
import NftMusicCard from "./NftMusicCard";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from '@reach/router';
import * as selectors from '../../store/selectors';
import * as actions from '../../store/actions/thunks';
import { clearNfts, clearFilter } from '../../store/actions';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ViewAlltable from './ViewAllTable';
import { shuffleArray } from '../../store/utils';
import Axios from '../../api-instance'

const CustomTabs = styled(Tabs)(({ theme }) => ({


    borderRadius: "10px",
    background: "#f6f6f6",
    display: "flex",
    alignItems: "center",
    minHeight: "48px",
    color: "#030303",

    '& .MuiTab-root': {
        fontSize: '15px',
        fontWeight: 600,
        minHeight: "30px",
        padding: "10px",
        margin: "4px 4px",
        transition: "all 0.3s ease 0s",
        color: "#545454 ",

    },
    '& .Mui-selected': {
        background: "#fff",
        borderRadius: "10px",
        color: "#030303 !important",
        transition: "all 0.3s ease 0s",
        boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    }
    ,
    '& .MuiTabs-indicator': {
        display: "none",
        backgroundColor: 'red',
    },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
    fontSize: '15px',
    // minWidth:"40px !important"
    // fontWeight: 500,
    // transition: "all 0.3s ease 0s"
}));
const RightGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
        display: "flex",
        justifyContent: "center",
    },
    '& .MuiTab-root': {
        fontSize: '15px',
        fontWeight: 600,
        minHeight: "30px",
        minWidth: "40px",

        padding: "10px",
        margin: "4px 4px",
        transition: "all 0.3s ease 0s",
        color: "#545454 ",

    },
    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        width: "90%",
        minHeight: "30px",
        Height: "30px",
        padding: "10px",
        margin: "4px 4px",
        color: "#030303",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        },
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        }
    },
}));
const LeftGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
        display: "flex",
        justifyContent: "center",
    },
    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        width: "90%",
        minHeight: "30px",
        Height: "30px",
        padding: "10px",
        margin: "4px 4px",
        color: "#030303",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        },
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        }
    },
}));
const CenterGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "center",
    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        width: "90%",
        color: "#030303",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        },
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        }
    },

}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function TabPanel(props) {
    const { children, values, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={values !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {values === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function a11yPropss(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const ViewallTabs = ({ showLoadMore = true, shuffle = false, authorId = null }) => {
    const dispatch = useDispatch();
    // const nftItems = useSelector(selectors.nftItems);
    // const nfts = nftItems ? shuffle ? shuffleArray(nftItems) : nftItems : [];
    const [height, setHeight] = useState(0);
    const [value, setValue] = React.useState(0);
    const [values, setValues] = React.useState(4);
    const navigate = useNavigate()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChanges = (event, newValue) => {
        // alert(newValue)
        setValues(newValue);
        if (newValue !== "") {
            getCollection(newValue)
        }

    };

    const [nfts, setNfts] = useState([])

    const getCollection = async (datas) => {
        if (datas === 4) {
            const data = await Axios.get(`/product/collections`)
            setNfts(data?.data?.data)
        } else {
            const data = await Axios.post(`/product/collectionsGet`, { type: datas.toString() })
            setNfts(data?.data?.data)
        }

    }

    useEffect(() => {
        getCollection(values)
    }, [])
    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    }
    const navigationto = () => {
        navigate("/")
    }
    useEffect(() => {
        dispatch(clearNfts());
        dispatch(actions.fetchNftsBreakdown(authorId, true));
    }, [dispatch, authorId]);

    //will run when component unmounted
    useEffect(() => {
        return () => {
            dispatch(clearFilter());
            dispatch(clearNfts());
        }
    }, [dispatch]);

    const loadMore = () => {
        dispatch(actions.fetchNftsBreakdown(authorId, true));
    }

    return (
        <div className='row' >
            <Box >
                {/* sx={{ borderBottom: 1, borderColor: 'divider' }} */}
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={9} xl={9} lg={9}>
                        <Grid container spacing={0}>
                            <CenterGrid item xs={6} sm={2} md={2} xl={2} lg={2}>
                                <Button>Select</Button>
                            </CenterGrid>
                            <leftGrid item xs={12} sm={10} md={10} xl={10} lg={10}>
                                <CustomTabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ "width": "max-content" }}>
                                    <CustomTab label="Trending" {...a11yProps(0)} />
                                    <CustomTab label="Top" {...a11yProps(1)} />
                                </CustomTabs>
                            </leftGrid>

                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
                        <Grid container spacing={0}>
                            <RightGrid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <CustomTabs value={values} onChange={handleChanges} aria-label="basic tabs example" style={{ "width": "max-content" }}>
                                    <CustomTab label="1H" {...a11yProps(0)} />
                                    <CustomTab label="6H" {...a11yPropss(1)} />
                                    <CustomTab label="24H" {...a11yPropss(2)} />
                                    <CustomTab label="7D" {...a11yProps(3)} />
                                    <CustomTab label="All" {...a11yProps(4)} />
                                </CustomTabs>
                            </RightGrid>

                        </Grid>

                    </Grid>
                </Grid>

            </Box>
            <CustomTabPanel value={value} index={0}>
                <ViewAlltable nfts={nfts} />
                {/* {nfts && nfts.map( (nft, index) => (
                <NftToptable nft={nft} audioUrl={nft.audio_url} key={index} onImgLoad={onImgLoad} height={height} />
            ))}
            { showLoadMore && nfts.length <= 20 &&
                <div className='col-lg-12'>
                    <div className="spacer-single"></div>
                    <span onClick={loadMore} className="btn-main lead m-auto">Load More</span>
                </div>
            } */}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <ViewAlltable nfts={nfts} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <ViewAlltable nfts={nfts} />
            </CustomTabPanel>
            {/* <ViewAlltable nfts={nfts} /> */}

        </div>
    );
}

export default ViewallTabs;