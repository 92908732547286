import React, { useEffect, useState } from "react";
import Breakpoint, { BreakpointProvider, setDefaultBreakpoints } from "react-socks";
//import { header } from 'react-bootstrap';
import { Link, Redirect, Router, Location } from '@reach/router';

import useOnclickOutside from "react-cool-onclickoutside";
import logoWhite from '../../assets/logo/NFT_only_white.svg'
// import logoSmall from '../../assets/logo/wallex_white.svg'
import logoSmall from '../../assets/logo/wallex_logo_updated.webp'

import Axios from "../../api-instance";
import Avatar from 'react-avatar';
import constant from '../../constants'
import { useSelector, useDispatch } from 'react-redux'
import { sendSearchData } from '../../store/actions/searchContent'

import { navigate } from "@reach/router";
import Web3 from "web3"
import WalletConnectProvider from '@walletconnect/web3-provider'
import { getUserData } from "../../store/actions/userData";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
// import {CopyToClipboard} from 'react-copy-to-clipboard';

setDefaultBreakpoints([
  { xs: 0 },
  { l: 1199 },
  { xl: 1200 }
]);

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'active' : 'non-active',
      };
    }}
  />
);






const Header = function ({ className, setHeader }) {

  const dispatch = useDispatch()


  const [headerValue, setHeaderValue] = useState('normal')

  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  useEffect(() => {
    if (window.location.pathname === '/') {
      setHeaderValue('navbar_white')
    } else {
      setHeaderValue('normal')
    }
  }, [window.location.href])

  const userDataAuth = useSelector(state => state?.userReducer?.user?.user)

  // console.log(userDataAuth,'userAuth');
  

  const handleChangeHeaderValue = () => {
    setHeaderValue('navbar_white')
  }
  const [isUserAlive, setIsUserAlive] = useState(false)
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };
  const closeMenu1 = () => {
    setOpenMenu1(false);
  };


  const ref = useOnclickOutside(() => {
    closeMenu();
  });
  const ref1 = useOnclickOutside(() => {
    closeMenu1();
  });



  const [showmenu, btn_icon] = useState(false);
  const [showpop, btn_icon_pop] = useState(false);
  const [shownot, btn_icon_not] = useState(false);
  const closePop = () => {
    btn_icon_pop(false);
  };

  const refpop = useOnclickOutside(() => {
    closePop();
  });


  const [landingLogo, setLandingLogo] = useState(null)

  useEffect(() => {
    const getData = async () => {

      const { data } = await Axios.get('/site')

      if (data?.status) {
        setLandingLogo(data?.data[0]?.logo);
      }
      try {
        const header = document.getElementById("myHeader");
        const totop = document.getElementById("scroll-to-top");
        const sticky = header?.offsetTop;
        const scrollCallBack = window.addEventListener("scroll", () => {
          btn_icon(false);
          if (window?.pageYOffset > sticky) {
            header?.classList.add("sticky");
            totop?.classList.add("show");

          } else {
            header.classList.remove("sticky");
            totop.classList.remove("show");
          } if (window?.pageYOffset > sticky) {
            closeMenu();
          }
        });

        return () => {
          window.removeEventListener("scroll", scrollCallBack);
        };




      } catch (error) {
        console.log(error);
      }

    }


    getData()
  }, []);

  const [isDark, setIsDark] = useState(false)


  useEffect(() => {
    if (localStorage.getItem('mode') === 'dark') {
      setIsDark(true)
    }
    else if (localStorage.getItem('mode') === 'light') {
      setIsDark(false)
    }
  }, [])


  const handleToggleMode = () => {
    if (isDark) {
      setIsDark(false)
    } else {
      setIsDark(true)
    }
  }


  const handleToggleIsDark = () => {
    if (!localStorage.getItem('mode')) {
      localStorage.setItem('mode', 'light')
    }



    if (localStorage.getItem('mode') === 'dark') {
      localStorage.setItem('mode', 'light')
      setIsDark(true)
      window.location.reload()
    }
    else if (localStorage.getItem('mode') === 'light') {
      localStorage.setItem('mode', 'dark')
      setIsDark(false)
      window.location.reload()
    }
  }

  const [userData, setUserData] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [metamaskData, setMetamaskData] = useState(false)
  useEffect(() => {
    if (window.localStorage.getItem('useraddress')) {
      setMetamaskData(true)
      setWalletAddress(window.localStorage.getItem('useraddress'))
    }

    // (async()=>{
    //   const web3 = (new Web3(window?.ethereum));
    //   const address = (await web3?.eth?.getAccounts());


    //   if(address?.length===0){
    //    setMetamaskData(false)
    //   }

    //   if(address?.length!==0){
    //    setMetamaskData(true)
    //    setWalletAddress(address[0])
    //   }
    // })()
  }, [])

  const [walletAddress, setWalletAddress] = useState('')
  useEffect(() => {

    const token = window.localStorage.getItem('token')
    const isAuth = window.localStorage.getItem('isAuth')

    if (isAuth === "true") {
      setIsUserAlive(true)
    }

    const getData = async () => {
      try {
        // if(token){
        //   const {data} = await Axios.get(`/auth/getdata/${token}`)
        //   setUserData(data)
        // }

      } catch (error) {
        console.log(error);
      }
if(window.ethereum){


      const web3 = (new Web3(window?.ethereum));



      const address = (await web3?.eth?.getAccounts());


      if (address?.length === 0) {
        setMetamaskData(false)
      }

      if (address?.length !== 0) {
        setMetamaskData(true)
        setWalletAddress(address[0])
      }



      // if(account){
      //   setMetamaskData(true)
      //   setWalletAddress(account[0])
      // }

    }
    }




    getData()
  }, [])


  const handleLogout = () => {
    setIsUserAlive(false)
    window.localStorage.removeItem('token')
    window.localStorage.setItem('isAuth', false)
    window.localStorage.removeItem('useraddress')
    navigate(`/`)

    // window.location = constant.MainURL
    dispatch(getUserData(""))
  }

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }
  const handleCopyClick = () => {

    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(walletAddress)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const handleMyCollection = () => {

    return (
      window.location = `/Author/${userData?.data?._id}`
    )
  }

  const handleEditUserProfile = () => {

    return (
      window.location = `/myprofile/${userDataAuth?.data?._id}`
    )
  }

  const handleEditProfile = () => {

    return (
      window.location = `/Profile/${userDataAuth?.data?._id}`
    )
  }

  const handleEditActivity = () => {
    return (
      window.location = `/activity/${userDataAuth?.data?._id}`
    )
  }


  const handleLogin = () => {
    return (
      navigate(`/login`)
      // window.location = `/login`
    )
  }
  const handleSignUp = () => {
    return (
      navigate(`/register`)
      // window.location = `/register`
    )
  }

  const [searchValue, setSearchValue] = useState('')
  const [searchItem, setSearchItem] = useState(false)

  const handleSearch = (e) => {

    setSearchValue(e.target.value)
    if (e.target.value === '') {
      dispatch(sendSearchData(e.target.value))
    }
  }


  const handleSearchResult = (e) => {

    if (e.key === 'Enter') {
      dispatch(sendSearchData(searchValue))
      navigate('/Explore')
    }
  }

  window.onscroll = function () {
    // if(window.pageYOffset>10){

    //   setHeaderValue('headerColor')
    // }
    if (window.location.pathname === '/') {
      if (window.pageYOffset < 10) {

        setHeaderValue('navbar_white')
      } else {
        // console.log('====================================');
        // console.log('asdsadsad');
        // console.log('====================================');
        setHeaderValue('normal')
      }

    }
  }



  const connectMetamask = async () => {

    if (window?.ethereum) {
      try {
        console.log("asf")
        // const { ethereum } = window
        // const web3 = new Web3(window.ethereum)
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [{ chainId: '0x1' }]
        });
        console.log(addressArray, "asdf")
        window.localStorage.setItem('useraddress', addressArray[0])
        // window.location.href="/"
        setMetamaskData(true)
        setWalletAddress(addressArray[0])

        const obj = {
          status: "👆🏽 Write a message in the text-field above.",
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        console.log(err, "asdf")
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      console.log("errs")
      // return {
      //   address: "",
      //   status: (
      //     <span>
      //       <p>
      //         {" "}
      //         🦊{" "}
      //         <a target="_blank" href={"https://metamask.io/download.html"}>
      //           You must install Metamask, a virtual Ethereum wallet, in your
      //           browser.
      //         </a>
      //       </p>
      //     </span>
      //   ),
      // };
      setIsConnectWalletOpen(!isConnectWalletOpen)
  
      toast.error("Please Install Metamask Extension")
setTimeout(()=>{
  window.open("https://metamask.io/download.html", '_blank');
},4000)
     
    }
  };


  const handleWalletConnect = async () => {
    try {
      //  Create WalletConnect Provider
      const provider = new WalletConnectProvider({
        infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
      });

      //  Enable session (triggers QR Code modal)
      await provider.enable();
    }
    catch (error) {
      console.log(error);
    }
  }


  const [isConnectWalletOpen, setIsConnectWalletOpen] = useState()
  // const [isOnline, setIsOnline] = useState(true)

  // if (window?.ethereum) {
  //   window?.ethereum?.on('accountsChanged', (accounts) => {
  //     setMetamaskData(false)
  //     window.localStorage.removeItem('useraddress')
  //   });
  // }





  // useEffect(()=>{
  //   console.log('====================================');
  //   console.log(navigator.connection);
  //   console.log('====================================');
  // if(!navigator?.onLine){
  // setIsOnline(false)
  // }
  // else{
  //   setIsOnline(true)
  // }
  // },[navigator])

  // window.addEventListener("online", () => {
  //   setIsOnline(true)
  // });

  // window.addEventListener("offline", () => {
  //   setIsOnline(false)
  // });

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [hasGoneOnline, setHasGoneOnline] = useState(false);

  useEffect(() => {
    function handleOnlineStatusChange() {
      setIsOnline(true);
      setHasGoneOnline(prevState => !prevState);

      setTimeout(() => {
        setHasGoneOnline(false)
      }, 5000);

    }

    function handleOfflineStatusChange() {
      setIsOnline(false);
    }

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOfflineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOfflineStatusChange);
    };
  }, []);



  const handlePhantom = async () => {
    try {

      if (window.solana) {
        const res = await window.solana.connect()
        console.log(res.publicKey.toString())



        window.localStorage.setItem('useraddress', res.publicKey.toString())
        // window.location.href="/"
        setMetamaskData(true)
        setWalletAddress(res.publicKey.toString())

      }



    } catch (error) {
      console.log(error);
    }
  }

  // console.log(userDataAuth, "dhgfhfd");
  return (
    <>
    <ToastContainer style={{ top: "5em" }} />
      {/* {
   searchItem?
   <Redirect from="" to="/explore" noThrow  />
 
   :null
 } */}
      {isDark ?
        <header className={`navbar white ${className}`} id="myHeader" style={{ background: ' #fff' }}>
          <div className='container'>
            <div className='row w-100-nav'>
              <div className='logo px-0'>
                <div className='navbar-title navbar-item'>
                  <NavLink to={`/`}>
                    <img
                      src={logoSmall}
                      className="img-fluid d-block"
                      alt="#"
                      width='50%'
                      style={{ marginTop: '-10px' }}
                    />
                    <img
                      src={logoSmall}
                      className="img-fluid d-3"
                      alt="#"
                      width='50%'
                      style={{ marginTop: '-10px' }}
                    />
                    <img
                      src={logoSmall}
                      className="img-fluid d-4"
                      alt="#"
                      width='50%'
                      style={{ marginTop: '-10px' }}
                    />
                    <img
                      src={logoSmall}
                      className="img-fluid d-none"
                      alt="#"
                      width='50%'
                      style={{ marginTop: '-10px' }}
                    />
                  </NavLink>
                </div>
              </div>

              <div className='search'>
                <input id="quick_search" className="xs-hide" name="quick_search" placeholder="search item here..." type="text" />
              </div>

              <BreakpointProvider>
                <Breakpoint l down>
                  {showmenu &&
                    <div className='menu'>
                      <NavLink to={`/`}  >
                        <div className='navbar-item' >
                          <div ref={ref} >

                            <div className="dropdown-custom  btn"
                              onClick={handleBtnClick}

                            >
                              Home
                            </div>


                          </div>

                        </div>
                      </NavLink>
                      <div className='navbar-item'>
                        <div ref={ref1}>
                          <div className="dropdown-custom dropdown-toggle btn"
                            onClick={handleBtnClick1}

                          >
                            Explore
                          </div>
                          {openMenu1 && (
                            <div className='item-dropdown'>
                              <div className="dropdown" onClick={closeMenu1}>
                                <NavLink to={`/Explore`} onClick={() => btn_icon(!showmenu)}>Explore NFTs</NavLink>

                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                  }
                </Breakpoint>

                <Breakpoint xl>
                  <div className='menu'>



                    <div className='navbar-item'>

                      <div ref={ref}>
                        <NavLink to={`/Explore`}>
                          <div className="dropdown-custom btn"
                            onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}>
                            Explore NFT
                            <span className='lines'></span>

                          </div>
                        </NavLink>

                      </div>
                    </div>

                  </div>
                </Breakpoint>
              </BreakpointProvider>

              {
                userDataAuth?.status ?
                  <div className='mainside'>
                    {/* <div className='connect-wal'>
                  <NavLink to="/wallet">Connect Wallet</NavLink>
                </div> */}
                    {
                      metamaskData ?
                        <div className="logoutContainer">
                          {/* <NavLink to="/createOptions">Create</NavLink> */}
                          <NavLink to={`/create`}>Create</NavLink>


                          <div id="de-click-menu-profile" className="de-menu-profile" onClick={() => btn_icon_pop(!showpop)} ref={refpop}>
                            {
                              userDataAuth?.data?.profilePicture ?
                                <img src={userDataAuth?.data?.profilePicture} alt={userDataAuth?.data?.name} style={{ borderRadius: "50%", width: "40px", height: "40px", border: "3px solid #5fdba7" }} /> :
                                <Avatar name={userDataAuth?.data?.name} size="40" round="50px" />
                            }
                            {showpop &&
                              <div className="popshow">
                                <div className="d-name">
                                  <h4 className="importantBlack">{userDataAuth?.data?.name}</h4>

                                </div>
                                {/* <div className="d-balance">
                               <h4>Balance</h4>
                               12.858 ETH
                           </div> */}
                                <div className="d-wallet">
                                  <h4 className="importantBlack">My Wallet</h4>
                                  <span id="wallet" className="d-wallet-address importantBlack" >{walletAddress}</span>
                                  <button id="btn_copy" title="Copy Text" onClick={handleCopyClick}>{isCopied ? 'Copied!' : 'Copy'}</button>
                                </div>
                                <div className="d-line"></div>
                                <ul className="de-submenu-profile">
                                  <li>
                                    <span>
                                      <p onClick={handleEditUserProfile}><i className="fa fa-user"></i> My profile</p>
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <p onClick={handleEditProfile}>  <i className="fa fa-pencil"></i> Edit profile</p>
                                    </span>
                                  </li>
                                  <li>
                                    <span>
                                      <p onClick={handleEditActivity}>  <i className="fa fa-pencil"></i> Activity</p>
                                    </span>
                                  </li>
                                  {/* <li>
                               <span>
                               <p onClick={handleMyCollection}><i className="fa fa-folder-open"></i> My Collection's</p>  
                               </span>
                             </li> */}
                                  <li>

                                    <span>
                                      <p onClick={handleLogout}>   <i className="fa fa-sign-out"></i> Sign out</p>
                                    </span>


                                  </li>
                                </ul>
                              </div>
                            }
                          </div>
                        </div> :
                        <div className='connect-wal'>
                          <NavLink to={`/wallet`}>Connect Wallet</NavLink>
                        </div>
                    }
                  </div>
                  :
                  <div className='mainside'>
                    <div className=' main-btn' >
                      <NavLink to={`/login`}>Sign in</NavLink>
                      <NavLink to={`/register`}>Sign up</NavLink>
                    </div>
                  </div>
              }

              {/* lightmode   */}
              {/* <div className='mainside'>
                <div className='connect-wal'>
                {
                isDark?<p  style={{margin:"10px",fontSize:"2rem",cursor:"pointer"}} onClick={handleToggleIsDark}>🔆</p>:
                 <p  style={{fontSize:"2rem",margin:"10px",cursor:"pointer"}} onClick={handleToggleIsDark}>🌙</p>
              }
         
           </div></div> */}


            </div>


          </div>

        </header> :
        <header className={`navbar header-border-bottom white headerTop ${headerValue} ${className}`} id="myHeader" >
          {
            hasGoneOnline &&
            <div className="online">
              Back to Online.
            </div>
          }
          {


            !isOnline &&
            <div className="offline">
              You're offline right now. Check your connection.
            </div>
          }

          <div className='container'>
            <div className='row w-100-nav'>
              <div className='logo px-0'>
                <div className='navbar-title navbar-item '>
                  <NavLink to={`/`} onClick={handleChangeHeaderValue}>
                    <div className="logo-nft">
                      {

                        true ?
                          <>
                            <img
                              src={logoWhite}
                              alt="#"
                              // width='50px'
                              // height='40px'
                              style={{}}
                            />
                            {/* <div className="vertical-line"></div>
                  <h3 className="whiteColor" style={{marginTop:'20px'}}>
                    NFT
                  </h3> */}
                          </>
                          :
                          <>
                            <img
                              src={logoSmall}
                              alt="#"
                              width='65%'
                              height='65%'
                              style={{ marginTop: '-5px' }}
                            />
                            <div className="vertical-line"></div>
                            <h3 className="lightBlueColor" style={{ marginTop: '20px' }}>
                              NFT
                            </h3>
                          </>

                      }


                    </div>
                  </NavLink>
                </div>
              </div>

              <div style={{ marginLeft: '200px' }} className='search'>
                <input style={{ width: "500px", background: 'white', color: 'black' }} id="quick_search" className="xs-hide mt-6 header-search" name="quick_search" placeholder="search item here..." type="text" onChange={(e) => handleSearch(e)} onKeyDown={handleSearchResult} />


              </div>

              <BreakpointProvider>
                <Breakpoint l down>
                  {showmenu &&
                    <div className='menu'>

                      <NavLink to={`/Explore`}>
                        <div className='navbar-item'>
                          <div ref={ref}>

                            <div className="dropdown-custom  btn whiteColor"
                              onClick={handleBtnClick}
                              style={{ color: "#377DA3", marginLeft: '20px' }}
                            >
                              Explore NFT
                            </div>


                          </div>

                        </div>
                      </NavLink>
                      <NavLink to={`/helpcenter-new`}>
                        <div className='navbar-item'>
                          <div ref={ref}>

                            <div className="dropdown-custom  btn whiteColor"
                              onClick={handleBtnClick}
                              style={{ color: "#377DA3", marginLeft: '20px' }}
                            >
                              FAQ
                            </div>


                          </div>

                          {
                            !userDataAuth?.status ?

                              <div className='connect-wal headerSign' style={{ marginLeft: '20px', marginTop: '40px' }}>
                                {/* {console.log(userDataAuth, "userdata")} */}
                                <Link
                                  to={`/login`}
                                  onClick={() => {
                                    navigate(`/login`)
                                  }}>

                                  <div className="signInBtn">
                                    Login
                                  </div>
                                </Link>

                                <Link
                                  to={`/register`}
                                  onClick={() => {
                                    navigate(`/register`)
                                  }}>
                                  <div className="signUpBtn">
                                    Sign up
                                  </div>
                                </Link>
                              </div>
                              : null
                          }


                        </div>


                      </NavLink>
                    </div>
                  }
                </Breakpoint>

                <Breakpoint xl>
                  <div className='menu' >

                    <div className='navbar-item' style={{ paddingRight: "15px" }}>

                      <div ref={ref}>
                        <NavLink to={`/Explore`} >
                          <div className="dropdown-custom btn "
                            onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}
                            style={{ color: headerValue === "navbar_white" ? "white" : "white" }}
                            onClick={()=>{setIsConnectWalletOpen(false);}}
                          >
                            Explore NFT
                            <span className='lines'></span>

                          </div>
                        </NavLink>

                      </div>
                    </div>

                    <div className='navbar-item' style={{ paddingRight: "15px" }}>

                      <div ref={ref}>
                        <NavLink to={`/helpcenter-new`} >
                          <div className="dropdown-custom btn"
                            onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}
                            //  style={{color:"white"}}
                            style={{ color: headerValue === "navbar_white" ? "white" : "white" }}
                            onClick={()=>{setIsConnectWalletOpen(false);}}
                          >
                            FAQ
                            <span className='lines'></span>

                          </div>
                        </NavLink>

                      </div>
                    </div>

                  </div>
                </Breakpoint>
              </BreakpointProvider>


              <div className="navbarDisplayed">
                {
                  userDataAuth?.status ?
                    <div className='mainside'>

                      {
                        metamaskData ?
                          <div className="logoutContainer">
                            {/* <NavLink to="/createOptions">Create</NavLink> */}
                            <NavLink to={`/create`} style={{ border: '1px solid black' }}>Create</NavLink>
                            <div id="de-click-menu-profile" className="de-menu-profile" onClick={() => btn_icon_pop(!showpop)} ref={refpop}>
                              {
                                userDataAuth?.data?.profilePicture ?
                                  <img src={userDataAuth?.data?.profilePicture} alt={userDataAuth?.data?.name} style={{ borderRadius: "50%", width: "40px", height: "40px", border: "3px solid #5fdba7" }} /> :
                                  <Avatar name={userDataAuth?.data?.name} size="40" round="50px" />
                              }
                              {showpop &&
                                <div className="popshow">
                                  <div className="d-name">
                                    <h4 className="importantBlack">{userDataAuth?.data?.name}</h4>

                                  </div>
                                  {/* <div className="d-balance">
                               <h4>Balance</h4>
                               12.858 ETH
                           </div> */}
                                  <div className="d-wallet">
                                    <h4 className="importantBlack">My Wallet</h4>
                                    <span id="wallet" className="d-wallet-address importantBlack" >{walletAddress}</span>
                                    <button id="btn_copy" title="Copy Text" onClick={handleCopyClick}>{isCopied ? 'Copied!' : 'Copy'}</button>
                                  </div>
                                  <div className="d-line"></div>
                                  <ul className="de-submenu-profile">
                                    <li>
                                      <span>
                                        <p onClick={handleEditUserProfile}><i className="fa fa-user"></i> My profile</p>
                                      </span>
                                    </li>
                                    {/* <li>
                                      <span>
                                        <p onClick={handleEditProfile}>  <i className="fa fa-pencil"></i> Edit profile</p>
                                      </span>
                                    </li> */}
                                    <li>
                                      <span>
                                        <p onClick={handleEditActivity}>  <i className="fa fa-pencil"></i> Activity</p>
                                      </span>
                                    </li>
                                    {/* <li>
                               <span>
                               <p onClick={handleMyCollection}><i className="fa fa-folder-open"></i> My Collection's</p>  
                               </span>
                             </li> */}
                                    <li>

                                      <span>
                                        <p onClick={handleLogout}>   <i className="fa fa-sign-out"></i> Sign out</p>
                                      </span>


                                    </li>
                                  </ul>
                                </div>
                              }
                            </div>
                          </div> :
                          <div className='connect-wal'>
                            {/* <NavLink to="/wallet">Connect Wallet</NavLink> */}
                            <button className="connect-wallet-btn" onClick={() => {setIsConnectWalletOpen(!isConnectWalletOpen); console.log("header function")}}>
                              Connect Wallet
                            </button>
                            &nbsp;&nbsp;&nbsp;
                            <button className="connect-wallet-btn" onClick={handleLogout}>
                              Logout
                            </button>

                            {
                              isConnectWalletOpen &&
                              <div className="wallet-drop-down-container">
                                <div className="wallet-drop-down-first" onClick={connectMetamask}>
                                  <img src="./img/wallet/1.png" alt="" className="mb20" style={{ width: '30px', transform: 'translateY(10px)' }} />
                                  <h5 style={{ margin: "0px" }} className="black-color">Metamask</h5>
                                </div>
                                <div className="wallet-drop-down-second" onClick={handleWalletConnect}>
                                  <img src="./img/wallet/wallet-connect.png" alt="" className="mb20" style={{ width: '30px', transform: 'translateY(10px)' }} />
                                  <h5 style={{ margin: "0px" }} className="black-color">Wallet Connect</h5>
                                </div>
                                {/* <div className="wallet-drop-down-second" onClick={handlePhantom}>
                  <img src="./img/wallet/phantom.png" alt="" className="mb20" style={{width:'30px',transform:'translateY(10px)'}}/>
                      <h5 style={{margin:"0px"}} className="black-color">Phantom</h5>
                 </div> */}
                              </div>

                            }
                          </div>
                      }
                    </div>
                    :
                    <div className='mainside headerNull' >
                      <div className='connect-wal headerSign'>
                        <div onClick={() => handleLogin()}  >
                          <button className="btn-main" id="loginBtn">
                            Login
                          </button>
                        </div>
                        <div onClick={() => handleSignUp()}  >
                          <button className="btn-main whiteColor" id="signUpBtn">
                            Sign up
                          </button>
                        </div>
                      </div>
                    </div>
                }
              </div>

              {/* darkmode */}
              {/* <div className='mainside'>
                <div className='connect-wal'>
          
             
                {
                isDark?<p  style={{margin:"10px",fontSize:"2rem",cursor:"pointer"}} onClick={handleToggleIsDark}>🔆</p>:
                 <p  style={{fontSize:"2rem",margin:"10px",cursor:"pointer"}} onClick={handleToggleIsDark}>🌙</p>
              }
           </div></div> */}

            </div>
            <button className="nav-icon mt-0 " onClick={() => btn_icon(!showmenu)}>
              <div className="menu-line backgroundWhite"></div>
              <div className="menu-line1 backgroundWhite"></div>
              <div className="menu-line2 backgroundWhite"></div>
            </button>

          </div>
        </header>}
    </>
  );
}
export default Header;