import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// const store = createStore(rootReducer, applyMiddleware(thunk));

const persistConfig = {
    key:'auth',
	storage,
}

// const store = configureStore({
//  reducer:persistReducer(persistConfig,storeData)
// })



const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

// export const persistor;

export default store;
