import React, { Component,useState,useEffect } from "react";
import { Link } from '@reach/router';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const GlobalStylesDark = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid #ff343f;
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: #ff343f;
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 #ff343f;
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: rgba(33, 36, 40, .9);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{
    color: #fff !important;
    background: #ff343f;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: #ff343f;
  }
  @media only screen and (max-width: 1199px) { 
    .navbar {
      background: #212428;
    }
  }
`;


const Components = ()=> {

  const [isDark, setIsDark] = useState(false)
  useEffect(()=>{
    if(localStorage.getItem('mode')==='dark'){
       setIsDark(true)  
    }else if(localStorage.getItem('mode')==='light'){
      setIsDark(false)
    }
  },[])


    return (

     <>
     {
       isDark?
       <div className="greyscheme">
       <GlobalStylesDark/>
 
         <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.webp'})`}}>
           <div className='mainbreadcumb'>
             <div className='container'>
               <div className='row m-10-hor'>
                 <div className='col-12'>
                   <h1 className='text-center'>Create Collectible</h1>
                 </div>
               </div>
             </div>
           </div>
         </section>
 
         <section className='container'>
           <div className='row'>
             <div className="col-md-6 offset-md-3">
                 <p>Choose "Single" if you want your collectible to be one of a kind or "Multiple" if you want to sell one collectible times</p>
                 <Link to="/create" className="opt-create">
                     <img src="./img/misc/coll-single.png" alt=""/>
                     <h3>Single</h3>
                 </Link>
                 <Link to="/create3" className="opt-create">
                     <img src="./img/misc/coll-multiple.png" alt=""/>
                     <h3>Multiple</h3>
                 </Link>
             </div>
           </div>
         </section>
 
        
 
         <Footer />
       </div>
       :
       <div>
       <GlobalStyles/>
 
         <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.webp'})`}}>
           <div className='mainbreadcumb'>
             <div className='container'>
               <div className='row m-10-hor'>
                 <div className='col-12'>
                   <h1 className='text-center'>Create Collectible</h1>
                 </div>
               </div>
             </div>
           </div>
         </section>
 
         <section className='container'>
           <div className='row'>
             <div className="col-md-6 offset-md-3">
                 <p>Choose "Single" if you want your collectible to be one of a kind or "Multiple" if you want to sell one collectible times</p>
                 <Link to="/create" className="opt-create">
                     <img src="./img/misc/coll-single.png" alt=""/>
                     <h3>Single</h3>
                 </Link>
                 <Link to="/create3" className="opt-create">
                     <img src="./img/misc/coll-multiple.png" alt=""/>
                     <h3>Multiple</h3>
                 </Link>
             </div>
           </div>
         </section>
 
        
 
         <Footer />
       </div>
     }
     </>
   );
  
}

export default Components