import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from "../../menu/header";
import Footer from "../../components/footer";
import Axios from "../../../api-instance"
import './HelpCenter.css'
// import togglebtn from '../../img/togglebtn.png'
// import Menuclose from '../../img/Menuclose.png'
import { Link } from "@reach/router";
// import customer from '../../img/customer.svg'
// import maptrace from '../../img/maptrace.svg'
// import guarantee from '../../img/guarantee.svg'
// import bestseller from '../../img/bestseller.svg'
// import bill from '../../img/bill.svg'
// import product from '../../img/product.svg'
// import supplychain from '../../img/supplychain.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
  helpcommoninner: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    textAlign: 'left !important'
  }
});

const DetailPage = () => {

  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)
  const [faqData, setFaqData] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState('')
  const [selectedAnswer, setSelectedAnswer] = useState('')

  const [selectedFAQ, setSelectedFAQ] = useState(0); // 

  console.log(selectedFAQ, 'seleted');


  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName);
  };
  const handleCategoryClick1 = (questionId) => {
    const selectedFaq = faqData.find((faq) => faq._id === questionId);
    console.log(selectedFaq, "faqqq")
    if (selectedFaq) {
      setSelectedQuestion(selectedFaq.question);
      setSelectedAnswer(selectedFaq.answer);
    }

    setSelectedFAQ(questionId);
  };


  const faqListData = async () => {
    try {
      const payload = {
        name: 'GetStarted'

      }
      console.log(payload, "payyy")
      const response = await Axios.post('/admin/getquestByName', payload)
      console.log(response, "checkdata")
      if (response && response.status === 200) {
        const deta = response?.data?.data
        setFaqData(deta)
        setSelectedAnswer(deta[0].answer)
      } else {
        console.log("No Data Found")
      }



    } catch (error) {
      console.log(error, "error")

    }
  }

  useEffect(() => {
    faqListData();
  }, []);

  const [list, setList] = useState([
    { name: 'Blockchain' },
    { name: 'Ethereum' },
    { name: 'Etherscan' },
    { name: 'Gas Fees' },
    { name: 'dApp' },
    { name: 'ERC721/ERC1155' },
    { name: 'Collection' },
    { name: 'Royalties' },
    { name: 'Smart Contract' },
    { name: 'Free Minting' },
    { name: 'Metadata' },
    { name: 'Minting' },
    { name: 'Wallet Address' },
    { name: 'Seed Phrase' },
  ])

  const [faq, setFaq] = useState([
    { name: "What are the fees involved in Wallex NFT?" },
    { name: "What is a Web3 Wallet?" },
    { name: "What crypto wallets can I use with Wallex NFT?" },
    { name: "Which Blockchains does Wallex NFT support?" },
    { name: "What is a Non Fungible Token (NFT)?" },

  ])


  const [val, setVal] = useState(0)
  const [selected, setSelected] = useState(false)

  console.log(val, 'val');


  const handleVal = (ind) => {
    setVal(ind)
    setSelectedFAQ(ind)
    setSelected(true)
  }

  const handleCloseSelect = () => {
    setSelected(false)
  }


  return (
    <div className="dashboard-page help-center-main">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="outer-bgcolor-container"
          >
            <Item className={classes.bodyclass}>
              <div className="togglebtn" onClick={openMenuHandler}></div>
              <div
                className="categories-menu"
                id={!menuOpen ? "openmenucls" : ""}
              >
                <div
                  className="togglebtn-close"
                  onClick={closeMenuHandler}
                ></div>
                <div className="categories-menu-inner">
                  {/* <Link><div className='iconic'><img src={customer} alt={customer} /></div>Customer</Link>
    <Link><div className='iconic'><img src={maptrace} alt={maptrace} /></div>Traceability</Link>
    <Link><div className='iconic'><img src={guarantee} alt={guarantee} /></div>Products</Link>
    <Link><div className='iconic'><img src={bestseller} alt={bestseller} /></div>Blockchain</Link>
    <Link><div className='iconic'><img src={bill} alt={bill} /></div>Post-sale</Link>
    <Link><div className='iconic'><img src={product} alt={product} /></div>Billing</Link>
    <Link><div className='iconic'><img src={supplychain} alt={supplychain} /></div>Sellers Authorization </Link> */}
                </div>
              </div>

              <div className="detail-pages-inner-outer">
                <Box sx={{ flexGrow: 1 }}>
                  <div className="breadcrum-helpcenter">
                    <Link to="/helpcenter-new">Help Center</Link> / Get Started
                  </div>

                  <Grid
                    container
                    spacing={0}
                    className={classes.helpcenterbodycontain}
                  >
                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={2}
                      xl={2}
                      id="link-left-side-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h4>Article in this section</h4>

                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                          onClick={() => handleCategoryClick()}
                        >
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          What crypto wallets can I use with Wallex NFT?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          What is a Web3 Wallet?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          What is a Non Fungible Token (NFT)?
                        </Link>
                      </Item>
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="link-left-side-detail-id">
                      {selected ?
                        <>
                          <div style={{ textAlign: 'left', cursor: 'pointer', marginBottom: "30px" }} onClick={handleCloseSelect}>
                            <ArrowBackIcon />
                          </div>

                          <Item className={classes.helpcommoninner}>
                            {val === 0 && <>
                              <h2>What are the fees involved in Wallex NFT?</h2>
                              <h2> Ethereum vs. Other Blockchains</h2>
                              <p>NFT Gas Fees on Ethereum are the fees paid to miners to process transactions on the network. They are calculated based on the amount of computational power required to execute a transaction. NFT Gas Fees are typically quoted in gwei, which is a denomination of ETH. Each gwei is equal to one-billionth of an ETH</p>
                              <p>The average gas fee on Ethereum currently stands at around 10.39 gwei. This means that a transaction that costs 10,000 gas would cost around 0.0001039 ETH. However, NFT Gas Fees can vary significantly depending on the complexity of the transaction and the current network congestion. For example, during times of high network congestion, NFT Gas Fees can spike to several hundred gwei</p>
                              <h3>Alternatives to Ethereum
                              </h3>
                              <p><strong>Solana:</strong> Solana is a high-performance Blockchain capable of processing thousands of transactions per second with remarkable energy efficiency. It boasts NFT marketplaces like Magic Eden and Solanart, making it an attractive option for creators and collectors seeking fast and cost-effective transactions, with NFT Gas Fees as low as 0.000005 SOL</p>
                              <p><strong>Avalanche:</strong> With its ability to handle thousands of transactions per second and strong scalability, Avalanche is another compelling choice. NFT marketplaces such as Kalao and ToFuNFT thrive on this Blockchain, providing a vibrant ecosystem for NFT enthusiasts, with NFT Gas Fees around 0.0001 AVAX</p>
                              <p><strong>Cardano:</strong>Known for its energy-efficient proof-of-stake mechanism and scalability, Cardano has its share of NFT marketplaces, including CNFT and Verlux. It offers a sustainable and efficient platform for NFT creators, with NFT Gas Fees approximately at 0.00012 ADA.</p>
                              <p><strong>Polygon:</strong>As a layer-2 solution for Ethereum, Polygon offers lower NFT Gas Fees and faster transaction speeds. NFT marketplaces have found a home here, providing a seamless experience for NFT enthusiasts looking to avoid high Ethereum NFT Gas Fees, with NFT Gas Fees as low as 0.00001 MATIC.</p>
                              <p><strong>Fantom:</strong>Fantom, known for its high throughput and scalability, can process thousands of transactions per second. NFT marketplaces such as Artion and NFTify have flourished on this Blockchain, offering a competitive alternative to Ethereum, with NFT Gas Fees approximately at 0.00001 FTM.</p>
                              <p><strong>Flow:</strong>Designed specifically for digital collectibles and games, Flow has gained recognition with NFT marketplaces like NBA Top Shot and CryptoKitties. It offers a unique ecosystem for collectors and gamers, with NFT Gas Fees around 0.00001 FLOW.</p>
                              <p><strong>Tezos:</strong> Praised for its security and efficiency within the proof-of-stake realm, Tezos hosts NFT marketplaces and Hic et Nunc. It’s an excellent choice for those seeking a secure and environmentally friendly NFT platform, with NFT Gas Fees at approximately 0.00001 XTZ.</p>
                              <p><strong>WAX:</strong>Tailored for NFTs, WAX features NFT marketplaces such as AtomicHub and SimpleMarket. It’s a Blockchain dedicated to the NFT world, providing specialized services and a thriving marketplace, with NFT Gas Fees around 0.00002 WAXP.</p>

                            </>}

                            {val === 1 && <>
                              <h2>What is a Web3 Wallet?</h2>
                              <p>Web3 wallet is a perfect combination of digital wallet and web3 technology to store, send, receive and manage digital assets including NFTs. Being built on a decentralised network, it gives users easy access to DApps without involving any intermediaries. In a nutshell Web3 wallet is a tool to access the web3 ecosystem.</p>


                            </>}

                            {val === 2 && <>
                              <h2>What crypto wallets can I use with Wallex NFT?</h2>
                              <p>Wallex NFT supports two of the most prominent wallet providers - Metamask and Wallet Connect.</p>
                              <p>Connect your wallet to sign in the nft.wallex.global to start your journey with NFTs.</p>

                              <h4>Setting up your Metamask wallet in Wallex NFT
                              </h4>
                              <p>Navigate to metamask.io and download their wallet browser extension.</p>
                              <p>Enter the wallet browser extension and follow the prompts to set up a new wallet.</p>

                              <p>You will be given a unique SEED PHRASE which is the key to your wallet. Please store this seed phrase in a secured place and whoever has this seed phrase can send any transaction from your wallet and control all assets.</p>

                              <p>Get into the Wallex NFT site and click on the connect wallet button on the top right corner. Now agree to connect to Wallex NFT in the wallet browser extension.</p>
                            </>}

                            {val === 3 && <>
                              <h2>Which Blockchains does Wallex NFT support?</h2>
                              <p>Currently, Wallex NFT supports the following Blockchain networks: Ethereum, Polygon and BNB Chain.</p>

                              <h4>Key Terminologies to know:
                              </h4>
                              <ul>
                                {list.map((row, i) => {
                                  return (
                                    <li>{row.name}</li>
                                  )
                                })}
                              </ul>
                            </>}

                            {val === 4 && <>
                              <h2>What is a Non Fungible Token (NFT)?                        </h2>
                              <p>NFT stands for Non-Fungible Token. It is a type of digital asset that represents ownership or proof of authenticity of a unique item or piece of content, such as artwork, collectibles, music, videos, virtual real estate, and more. Unlike cryptocurrencies like Bitcoin or Ethereum, which are fungible and can be exchanged on a one-to-one basis, each NFT has distinct characteristics and cannot be exchanged on an equal basis.</p>
                            </>}
                          </Item>
                        </> :

                        <>{faq.map((faq, index) => (
                          <div className={index === selectedFAQ ? "display-2 redirect-link-specific active" : "display-2 redirect-link-specific"} onClick={() => handleVal(index)}>
                            <div
                              key={faq.id}
                              // to="#"
                              style={{ cursor: 'pointer' }}
                            // className={index === selectedFAQ ? "redirect-link-specific active" : "redirect-link-specific"}

                            >
                              <p>{faq.name}</p>
                            </div>

                            <div>
                              <NavigateNextIcon />
                            </div>
                          </div>

                        ))}</>}
                    </Grid>

                    {/* <Grid item xs={12} lg={2} xl={2} id="link-left-side-id">
                      <Item className="helpcommoninner">
                        <h4>Article in this section</h4>
                        {faq.map((faq, index) => (
                          <div
                            key={faq.id}
                            to="#"
                            style={{ cursor: 'pointer' }}
                            className={index === selectedFAQ ? "redirect-link-specific active" : "redirect-link-specific"}
                            onClick={() => handleVal(index)}
                          >
                            <p>{faq.name}</p>
                          </div>
                        ))}
                      </Item>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={12} lg={10} xl={10} id="link-left-side-detail-id">
                      <Item className={classes.helpcommoninner}>
                        {selectedQuestion && <h2>{selectedQuestion}</h2>}
                        {selectedAnswer &&  <div dangerouslySetInnerHTML={{ __html: selectedAnswer }}></div>}
                        {val === 0 && <>
                          <h2>What are the fees involved in Wallex NFT?</h2>
                          <h2> Ethereum vs. Other Blockchains</h2>
                          <p>NFT Gas Fees on Ethereum are the fees paid to miners to process transactions on the network. They are calculated based on the amount of computational power required to execute a transaction. NFT Gas Fees are typically quoted in gwei, which is a denomination of ETH. Each gwei is equal to one-billionth of an ETH</p>
                          <p>The average gas fee on Ethereum currently stands at around 10.39 gwei. This means that a transaction that costs 10,000 gas would cost around 0.0001039 ETH. However, NFT Gas Fees can vary significantly depending on the complexity of the transaction and the current network congestion. For example, during times of high network congestion, NFT Gas Fees can spike to several hundred gwei</p>
                          <h3>Alternatives to Ethereum
                          </h3>
                          <p><strong>Solana:</strong> Solana is a high-performance Blockchain capable of processing thousands of transactions per second with remarkable energy efficiency. It boasts NFT marketplaces like Magic Eden and Solanart, making it an attractive option for creators and collectors seeking fast and cost-effective transactions, with NFT Gas Fees as low as 0.000005 SOL</p>
                          <p><strong>Avalanche:</strong> With its ability to handle thousands of transactions per second and strong scalability, Avalanche is another compelling choice. NFT marketplaces such as Kalao and ToFuNFT thrive on this Blockchain, providing a vibrant ecosystem for NFT enthusiasts, with NFT Gas Fees around 0.0001 AVAX</p>
                          <p><strong>Cardano:</strong>Known for its energy-efficient proof-of-stake mechanism and scalability, Cardano has its share of NFT marketplaces, including CNFT and Verlux. It offers a sustainable and efficient platform for NFT creators, with NFT Gas Fees approximately at 0.00012 ADA.</p>
                          <p><strong>Polygon:</strong>As a layer-2 solution for Ethereum, Polygon offers lower NFT Gas Fees and faster transaction speeds. NFT marketplaces have found a home here, providing a seamless experience for NFT enthusiasts looking to avoid high Ethereum NFT Gas Fees, with NFT Gas Fees as low as 0.00001 MATIC.</p>
                          <p><strong>Fantom:</strong>Fantom, known for its high throughput and scalability, can process thousands of transactions per second. NFT marketplaces such as Artion and NFTify have flourished on this Blockchain, offering a competitive alternative to Ethereum, with NFT Gas Fees approximately at 0.00001 FTM.</p>
                          <p><strong>Flow:</strong>Designed specifically for digital collectibles and games, Flow has gained recognition with NFT marketplaces like NBA Top Shot and CryptoKitties. It offers a unique ecosystem for collectors and gamers, with NFT Gas Fees around 0.00001 FLOW.</p>
                          <p><strong>Tezos:</strong> Praised for its security and efficiency within the proof-of-stake realm, Tezos hosts NFT marketplaces and Hic et Nunc. It’s an excellent choice for those seeking a secure and environmentally friendly NFT platform, with NFT Gas Fees at approximately 0.00001 XTZ.</p>
                          <p><strong>WAX:</strong>Tailored for NFTs, WAX features NFT marketplaces such as AtomicHub and SimpleMarket. It’s a Blockchain dedicated to the NFT world, providing specialized services and a thriving marketplace, with NFT Gas Fees around 0.00002 WAXP.</p>

                        </>}

                        {val === 1 && <>
                          <h2>What is a Web3 Wallet?</h2>
                          <p>Web3 wallet is a perfect combination of digital wallet and web3 technology to store, send, receive and manage digital assets including NFTs. Being built on a decentralised network, it gives users easy access to DApps without involving any intermediaries. In a nutshell Web3 wallet is a tool to access the web3 ecosystem.</p>


                        </>}

                        {val === 2 && <>
                          <h2>What crypto wallets can I use with Wallex NFT?</h2>
                          <p>Wallex NFT supports two of the most prominent wallet providers - Metamask and Wallet Connect.</p>
                          <p>Connect your wallet to sign in the nft.wallex.global to start your journey with NFTs.</p>

                          <h4>Setting up your Metamask wallet in Wallex NFT
                          </h4>
                          <p>Navigate to metamask.io and download their wallet browser extension.</p>
                          <p>Enter the wallet browser extension and follow the prompts to set up a new wallet.</p>

                          <p>You will be given a unique SEED PHRASE which is the key to your wallet. Please store this seed phrase in a secured place and whoever has this seed phrase can send any transaction from your wallet and control all assets.</p>

                          <p>Get into the Wallex NFT site and click on the connect wallet button on the top right corner. Now agree to connect to Wallex NFT in the wallet browser extension.</p>
                        </>}

                        {val === 3 && <>
                          <h2>Which Blockchains does Wallex NFT support?</h2>
                          <p>Currently, Wallex NFT supports the following Blockchain networks: Ethereum, Polygon and BNB Chain.</p>

                          <h4>Key Terminologies to know:
                          </h4>
                          <ul>
                            {list.map((row, i) => {
                              return (
                                <li>{row.name}</li>
                              )
                            })}
                          </ul>
                        </>}

                        {val === 4 && <>
                          <h2>What is a Non Fungible Token (NFT)?                        </h2>
                          <p>NFT stands for Non-Fungible Token. It is a type of digital asset that represents ownership or proof of authenticity of a unique item or piece of content, such as artwork, collectibles, music, videos, virtual real estate, and more. Unlike cryptocurrencies like Bitcoin or Ethereum, which are fungible and can be exchanged on a one-to-one basis, each NFT has distinct characteristics and cannot be exchanged on an equal basis.</p>
                        </>}



                      </Item>
                    </Grid> */}

                    {/* <Grid item xs={12} lg={10} xl={10} id="link-left-side-detail-id">
                      <Item className="helpcommoninner">
                        
                      </Item>
                    </Grid> */}

                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={10}
                      xl={10}
                      id="link-left-side-detail-id"
                    > */}
                    {/* <Item className={classes.helpcommoninner}> */}
                    {/* {faqData && faqData?.map((faq, index) => (
                          selectedQuestion === faq.question && <p key={faq._id}>{faq.answer}</p>
                        ))} */}
                    {/* {selectedAnswer}
                        {console.log(selectedAnswer,"answer")}
                        </Item>
                    </Grid> */}
                    {/* <h2>What is a Non Fungible Token (NFT)?</h2>
                        <p>
                          NFT stands for Non-Fungible Token. It is a type of
                          digital asset that represents ownership or proof of
                          authenticity of a unique item or piece of content,
                          such as artwork, collectibles, music, videos, virtual
                          real estate, and more. Unlike cryptocurrencies like
                          Bitcoin or Ethereum, which are fungible and can be
                          exchanged on a one-to-one basis, each NFT has distinct
                          characteristics and cannot be exchanged on an equal
                          basis.
                        </p>

                        <h4>What is a Web3 Wallet?</h4>

                        <p>
                          Web3 wallet is a perfect combination of digital wallet
                          and web3 technology to store, send, receive and manage
                          digital assets including NFTs. Being built on a
                          decentralised network, it gives users easy access to
                          DApps without involving any intermediaries. In a
                          nutshell Web3 wallet is a tool to access the web3
                          ecosystem.
                        </p>
                        <h4>Setting up your Metamask wallet in Wallex NFT</h4>
                        <ol>
                          <li>
                            Navigate to metamask.io and download their wallet
                            browser extension.
                          </li>
                          <li>
                            Enter the wallet browser extension and follow the
                            prompts to set up a new wallet.
                          </li>
                          <li>
                            You will be given a unique SEED PHRASE which is the
                            key to your wallet. Please store this seed phrase in
                            a secured place and whoever has this seed phrase can
                            send any transaction from your wallet and control
                            all assets.
                          </li>
                          <li>
                            Get into the Wallex NFT site and click on the
                            connect wallet button on the top right corner. Now
                            agree to connect to Wallex NFT in the wallet browser
                            extension.
                          </li>
                        </ol>

                        <h4>Which Blockchains does Wallex NFT support?</h4>
                        <p>
                          Currently, Wallex NFT supports the following
                          Blockchain networks: Ethereum, Polygon and BNB Chain.
                        </p>
                        <h4>Key Terminologies to know:</h4>
                        <ul>
                          <li> Blockchain</li>
                          <li> Ethereum</li>
                          <li> Etherscan</li>
                          <li> Gas Fees</li>
                          <li> dApp</li>
                          <li>ERC721/ERC1155</li>
                          <li> Collection</li>
                          <li> Royalties</li>
                          <li> Smart Contract</li>
                          <li>Free Minting</li>
                          <li> Metadata</li>
                          <li> Wallet Address</li>
                          <li> Seed Phrase</li>
                        </ul>
                        <h4>What are the fees involved in Wallex NFT?</h4>
                        <p>
                          At Wallex NFT, we charge the lowest fees possible as
                          we aim to bring in every individual into the NFT
                          community through our platform.
                        </p>
                        <p>
                          We receive 0.5% from the seller and 0.5% from the
                          buyer for every successful sale happening within the
                          marketplace.
                        </p>
                        <h4>
                          How secure is it to connect my wallet to Wallex NFT?
                        </h4> */}

                  </Grid>
                </Box>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Footer />
          </Grid>

        </Grid>
      </Box>
    </div>
  );
}

export default DetailPage;
