import React, { memo, useState, useEffect } from 'react';
import Clock from "./Clock";
import './NFtToptable.css'
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { styled } from '@mui/material/styles';
import api from '../../core/api';
import { Table, Grid } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

const StyledTable = styled(Table)(({ theme }) => ({
  border: "none !important",
  fontFamily: "inherit !important",
  '& thead th': {
    backgroundColor: "white !important",
    fontSize: "14px !important",
    fontFamily: "inherit !important",
    fontWeight: 400,
    color: "#545454 !important",
    border: "none !important",
    textAlign: "left !important",
    padding: "16px !important",
    borderBottom: "1px solid rgba(0,0,0,.1) !important"
  },
}));

// Styled TableCell component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "10px",
  fontFamily: "inherit !important",
  fontWeight: 600,
  fontSize: "16px",
  border: "none !important",

}));

const GridContainer = styled(Grid)(({ theme }) => ({

  display: "flex",


}));
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: '#',
    numeric: true,
    disablePadding: true,
    label: '#',
  },
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    label: 'Fat (g)',
  },
  {
    id: 'carbs',
    numeric: true,
    disablePadding: false,
    label: 'Carbs (g)',
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: 'Protein (g)',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
//react functional component
const ViewAlltable = ({ nfts }) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = nfts.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - nfts.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(nfts, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );
  const navigateTo = (link) => {
    navigate(link);
  }
  const midIndex = Math.ceil(nfts.length / 2);
  const nftsFirstHalf = nfts.slice(0, midIndex);
  const nftsSecondHalf = nfts.slice(midIndex);
  // const useAudio = (url) => {
  //     const [audio] = useState(new Audio(url));
  //     const [playing, setPlaying] = useState(false);

  //     const toggle = () => setPlaying(!playing);

  //     useEffect(() => {
  //         playing ? audio.play() : audio.pause();
  //       },
  //       [playing]
  //     );

  //     useEffect(() => {
  //       audio.addEventListener('ended', () => setPlaying(false));
  //       return () => {
  //         audio.removeEventListener('ended', () => setPlaying(false));
  //         audio.pause();
  //       };
  //     }, [audio]);

  //     return [playing, toggle];
  // };

  // const [playing, toggle] = useAudio(audioUrl);

  return (
    <>
      <GridContainer container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

          <TableContainer >
            <StyledTable aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Rank</TableCell>
                  <TableCell align='left'>Collection</TableCell>
                  <TableCell align='left'>Volume</TableCell>
                  <TableCell align='left'>% Change</TableCell>
                  <TableCell align='left'>Floor Price</TableCell>
                  <TableCell align='left'>Sales</TableCell>
                  <TableCell align='left'></TableCell>
                </TableRow>
              </TableHead>
              {nfts?.length > 0 ?
              <TableBody>

                {nfts?.map((nft, index) => (

                  <TableRow key={index}>
                    <StyledTableCell>{index +1}</StyledTableCell>
                    <StyledTableCell>
                      <div className='imgtextCell'>
                        <div className='imgCell'>
                          <img src={nft?.Image} alt="" />
                        </div>
                        <div className='textCell'>
                          <p>{nft?.Collection_name}</p>

                        </div>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>  <div className='textCell'>
                      <p>0.0024 ETH</p>
                    </div></StyledTableCell>
                    <StyledTableCell><div className='textCell'>
                      <p>24 ETH</p>
                    </div></StyledTableCell>
                    <StyledTableCell>  <div className='textCell'>
                      <p>0.0024 ETH</p>
                    </div></StyledTableCell>
                    <StyledTableCell><div className='textCell'>
                      <p>24 ETH</p>
                    </div></StyledTableCell>
                    <StyledTableCell><div className='textCell'>
                      <StarOutlineRoundedIcon />
                    </div></StyledTableCell>

                  </TableRow>
                ))}
              </TableBody>:
              <span>NO DATA FOUND!!!</span>
              }
            </StyledTable>
          </TableContainer>

        </Grid>

      </GridContainer>
    </>

  );
};

export default memo(ViewAlltable);