import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Header from "../../menu/header";
import Footer from "../../components/footer";
import "./HelpCenter.css";
import Axios from "../../../api-instance";
// import togglebtn from '../../img/togglebtn.png'
// import Menuclose from '../../img/Menuclose.png'
import { Link } from "@reach/router";
// import customer from '../../img/customer.svg'
// import maptrace from '../../img/maptrace.svg'
// import guarantee from '../../img/guarantee.svg'
// import bestseller from '../../img/bestseller.svg'
// import bill from '../../img/bill.svg'
// import product from '../../img/product.svg'
// import supplychain from '../../img/supplychain.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: "0px",
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    padding: "90px 0 !important",
    "@media (max-width: 991.98px)": {
      padding: "30px 0 !important",
    },
  },
  headercls: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
  },

  bodyclass: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    position: "relative",
  },
  helpcommoninner: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    textAlign: "left !important",
  },
});

const DetailPage = () => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true);
  const [faqData2, setFaqData2] = useState([])
  const [selectedCategory2, setSelectedCategory2] = useState('');
  const [selectedQuestion2, setSelectedQuestion2] = useState('')
  const [selectedAnswer2, setSelectedAnswer2] = useState()
  const [selectedFAQ, setSelectedFAQ] = useState(0); // 
  const openMenuHandler = () => {
    setMenuOpen(false);
  };

  const closeMenuHandler = () => {
    setMenuOpen(true);
  };

  const handleCategoryClick2 = (questionId) => {
    const selectedFaq = faqData2.find((faq) => faq._id === questionId);
    console.log(selectedFaq, "faqqq")
    if (selectedFaq) {
      // setSelectedQuestion(selectedFaq.question);
      setSelectedAnswer2(selectedFaq.answer);
    }
    setSelectedFAQ(questionId)
  };

  const faqSellingList = async () => {
    try {
      const payload = {
        name: 'Selling'

      }
      console.log(payload, "payyy")
      const response = await Axios.post('/admin/getquestByName', payload)
      console.log(response, "checkdata")
      if (response && response.status === 200) {
        const deta = response?.data?.data
        setFaqData2(deta)
        setSelectedAnswer2(deta[0].answer)
      } else {
        console.log("No Data Found")
      }



    } catch (error) {
      console.log(error, "error")

    }
  }

  useEffect(() => {
    faqSellingList();
  }, []);

  const [faq, setFaq] = useState([
    { name: "How to manage my offers?" },
    { name: "How to set minimum offers?" },
    { name: "How to sell an NFT at fixed-price?" },
    { name: "Where can I see my Sell history?" },
    { name: "How to list my NFT for sale and what are the fees involved?" },
    { name: "How to sell my NFT at an auction?" },
  ])


  const [val, setVal] = useState(0)
  const [selected, setSelected] = useState(false)
  console.log(val, 'val');


  const handleVal = (ind) => {
    setVal(ind)
    setSelectedFAQ(ind)
    setSelected(true)
  }

  const handleCloseSelect = () => {
    setSelected(false)
  }
  console.log(faqData2, 'faqss');

  return (
    <div className="dashboard-page help-center-main">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="outer-bgcolor-container"
          >
            <Item className={classes.bodyclass}>
              <div className="togglebtn" onClick={openMenuHandler}></div>
              <div
                className="categories-menu"
                id={!menuOpen ? "openmenucls" : ""}
              >
                <div
                  className="togglebtn-close"
                  onClick={closeMenuHandler}
                ></div>
                <div className="categories-menu-inner">
                  {/* <Link><div className='iconic'><img src={customer} alt={customer} /></div>Customer</Link>
    <Link><div className='iconic'><img src={maptrace} alt={maptrace} /></div>Traceability</Link>
    <Link><div className='iconic'><img src={guarantee} alt={guarantee} /></div>Products</Link>
    <Link><div className='iconic'><img src={bestseller} alt={bestseller} /></div>Blockchain</Link>
    <Link><div className='iconic'><img src={bill} alt={bill} /></div>Post-sale</Link>
    <Link><div className='iconic'><img src={product} alt={product} /></div>Billing</Link>
    <Link><div className='iconic'><img src={supplychain} alt={supplychain} /></div>Sellers Authorization </Link> */}
                </div>
              </div>

              <div className="detail-pages-inner-outer">
                <Box sx={{ flexGrow: 1 }}>
                  <div className="breadcrum-helpcenter">
                    <Link to="/helpcenter-new">Help Center</Link> / Selling
                  </div>

                  <Grid
                    container
                    spacing={0}
                    className={classes.helpcenterbodycontain}
                  >
                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={2}
                      xl={2}
                      id="link-left-side-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h4>Article in this section</h4>

                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How to sell an NFT at fixed-price?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How to list my NFT for sale and what are the fees
                          involved?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How to sell my NFT at an auction?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How to manage my offers?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          Where can I see my Sell history?
                        </Link>
                      </Item>
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="link-left-side-detail-id">
                      {selected ?
                        <>
                          <div style={{ textAlign: 'left', cursor: 'pointer', marginBottom: "30px" }} onClick={handleCloseSelect}>
                            <ArrowBackIcon />
                          </div>

                          <Item className={classes.helpcommoninner}>
                            {val === 0 && <>
                              <h2><strong>How to manage my offers?</strong></h2><p>When selling an NFT using Wallex NFT, you might receive offers on your item that you feel are too low for you to seriously consider. To prevent your inbox from getting flooded with these low offers, here are three tools you can use to control and customize your selling experience on Wallex NFT.</p>
                            </>}
                            {val === 1 && <>
                              <h2><strong>Set minimum offers per collection</strong></h2><p>If you don’t want to receive an email for every offer on your item, you can set a minimum offer amount for each collection in your Offer Settings.&nbsp;</p><p>When you set a minimum offer amount, it will override the Minimum Bid Threshold on the Notification page&nbsp;and apply to all offers, including collection offers. This also prevents offers from being visible in the "Offers" section of your item, and in your "Offers received" tab in your Wallex account.&nbsp;</p><p>If you own more than one NFT in a collection, make sure your minimum offer takes into account the lowest offer you would consider for any NFT from that collection.</p>
                            </>}
                            {val === 2 && <>
                              <h2><strong>Selling an NFT using WallexNFT&nbsp;</strong></h2><p>1. On wallexnft, navigate to the top right of the page and click your <strong>Profile icon</strong>.</p><p>2. Select the NFT you would like to sell from your wallet. If you don't have an NFT available to sell, check out our detailed tutorial to get started.</p><p>3. On the top right of the item page, click <strong>List for sale</strong>.</p><p>4. The default sale is a <strong>Fixed Price</strong> sale, where the seller sets the NFT price. You can click the pop-out window to see the option to sell your item using a timed auction.</p>
                            </>}
                            {val === 3 && <>
                              <h2><strong>Where can I see my Sell history?</strong></h2><p>One of the unique benefits of NFTs is the ability to track every transaction on the blockchain. Every NFT has an owner, creator, and history, and this information or "provenance" is verifiable on-chain.&nbsp;</p><p>Each item page has a <strong>Details</strong> section where you can verify details about the contract used to create it.</p>
                            </>}
                            {val === 4 && <>
                              <p>&nbsp;</p><p>There are a couple of one-time fees you'll see when you list an item on Ethereum for the first time. Let's learn more!</p><h2><strong>First-time Ethereum fees</strong></h2><ul><li>You may need to pay gas to allow Seaport to interact with your item (or all items in the collection, if the collection supports it). This has to be done once per collection.</li><li>If this is your first time listing an action, you will also need to approve WETH for use. <strong>WETH (Wrapped ETH)</strong> is an ERC-20 token, equal in value to ETH, that allows users to make pre-authorized bids that can be fulfilled at a later date without any further action from the bidder.&nbsp;</li></ul><p>After those, you'll see a signature request to finalize your listing. In the future, as long as the item was approved in the steps above, you won't have any additional transactions to list that NFT at all, only a signature.</p>
                            </>}
                            {val === 5 && <>
                              <h2><strong>How to sell my NFT at an auction?</strong></h2><p>One of the most popular people to auction off an NFT was Jack Dorsey, co-founder of Twitter. He wasn't the first, but since then we've seen other NFT auctions involving ridiculous amounts of money. Not only is the hype around NFTs becoming real, but a person can now spend millions of dollars on NFT auctions to purchase digital art from the comfort of their home.</p><p>Whether you’re an NFT collector or an NFT creator, understanding how NFT auctions work is one of the first steps to making the most of this industry.</p><p>The process of auctioning an NFT starts with the creation of the NFT.</p><p>After creating your NFT, follow the steps below to auction it on our NFT marketplace:</p><p>1. Click on profile picture, then on&nbsp; “<strong>My NFTs,</strong>”</p><p>2. Scroll down to your collection and click on the NFT you want to auction,</p><p>3. Modify your NFT price to match the final price you would love to get if the auction has bidders to avoid front-run attacks – we’ll explain this later in this article,</p><p>3. Click on more icon and hit “<strong>Start Timed Auction,</strong>”</p><p>4. Indicate the Minimum Bid and the duration of the auction, then click on "<strong>Confirm</strong>,"</p><p>5. A smart contract call will pop up, click “<strong>Approve</strong>,”</p><p>6. Wait a few seconds for confirmation, you will receive a notification at the top of your screen that the auction has been successfully created.</p><p>Hopefully, you've learned that using auctions to sell your NFTs will give you and your users a great result. If you're working on an NFT project and plan to launch it, we recommend being frugal with your time and doing it from an NFT marketplace running on both the Polygon and BSC blockchains, for low or near-zero gas/network fees.</p>
                            </>}
                          </Item>
                        </> :

                        <> 
                          <h4>Article in this section</h4>
                          {faq.map((faq, index) => (
                            <div className={index === selectedFAQ ? "display-2 redirect-link-specific active" : "display-2 redirect-link-specific"}  onClick={() => handleVal(index)} >
                              <div
                                key={faq.id}
                                // to="#"
                                style={{ cursor: 'pointer' }}
                               
                               
                              >
                                <p>{faq.name}</p>
                              </div>
                              <div>
                                <NavigateNextIcon />
                              </div>
                            </div>

                          ))}
                        </>}
                    </Grid>
                    <Grid item xs={12} lg={2} xl={2} id="link-left-side-id">

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10} id="link-left-side-detail-id">
                      <Item className={classes.helpcommoninner}>
                        {/* {selectedQuestion && <h2>{selectedQuestion}</h2>} */}
                        {/* {selectedAnswer2 && <div dangerouslySetInnerHTML={{ __html: selectedAnswer2 }}></div>} */}












                      </Item>
                    </Grid>

                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={10}
                      xl={10}
                      id="link-left-side-detail-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h2>How to sell an NFT at fixed-price?</h2>
                        <h4>
                          How to list my NFT for sale and what are the fees
                          involved?
                        </h4>
                        <h4>How to sell my NFT at an auction?</h4>
                        <h4>How to manage my offers?</h4>
                        <h4>Where can I see my Sell history?</h4>
                      </Item>
                    </Grid> */}
                  </Grid>
                </Box>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <Footer />

          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DetailPage;
