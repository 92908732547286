import React, {useState} from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from '../../menu/header';
import Footer from '../../components/footer';
import './HelpCenter.css'
import HelpCenterBody from './HelpCenterBody';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '0px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const useStyles = makeStyles({
    footercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow:'none !important',
        padding: '90px 0 !important',
        '@media (max-width: 991.98px)' : {
            padding: '30px 0 !important',
          }
    },
    headercls: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow:'none !important',
    },

    bodyclass: {
        background: 'transparent !important',
        borderRadius: '0px !important',
        boxShadow:'none !important',
        position: 'relative'
    }
  });

const HelpCenter = () => {
     
 const classes = useStyles();

 const [menuOpen, setMenuOpen] = useState(true)

 const openMenuHandler = () => {
    setMenuOpen(false)
 }

 const closeMenuHandler = () => {
    setMenuOpen(true)
 }

  return (
    <div className='dashboard-page help-center-main'>
    <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={0}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <Item className={classes.headercls}>
        <Header />
    </Item>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <Item className={classes.bodyclass}>
    {/* <div className='togglebtn' onClick={openMenuHandler}><img src={togglebtn} alt="togglebtn"/></div>
    <div className='categories-menu' id={!menuOpen ? "openmenucls" : ""}>
    <div className='togglebtn-close' onClick={closeMenuHandler}><img src={Menuclose} alt="Menuclose"/></div>
    <div className='categories-menu-inner'>
    <Link><div className='iconic'><img src={customer} alt={customer} /></div>Customer</Link>
    <Link><div className='iconic'><img src={maptrace} alt={maptrace} /></div>Traceability</Link>
    <Link to='/product-menu'><div className='iconic'><img src={guarantee} alt={guarantee} /></div>Products</Link>
    <Link><div className='iconic'><img src={bestseller} alt={bestseller} /></div>Blockchain</Link>
    <Link><div className='iconic'><img src={bill} alt={bill} /></div>Post-sale</Link>
    <Link><div className='iconic'><img src={product} alt={product} /></div>Billing</Link>
    <Link><div className='iconic'><img src={supplychain} alt={supplychain} /></div>Sellers Authorization </Link>
    </div>
    </div> */}
    <HelpCenterBody />
    </Item>
    </Grid>
    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    <Item className={classes.footercls}>

    </Item>
    </Grid> */}
    </Grid>
    </Box>
    <Footer />
    </div>
  )
}

export default HelpCenter;
