import React, { useState, useEffect, useCallback, Suspense } from 'react';
import ColumnNewThreeColRedux from '../components/ColumnNewThreeColRedux';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import CheckboxFilter from '../components/CheckboxFilter';
import axios from 'axios'
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { Grid, Button, Box } from '@mui/material';
import constant from '../../constants'
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import SearchIcon from '@mui/icons-material/Search';
import Explorenfttable from './Explorenfttable'
import './explore.css'
import { Link } from '@reach/router';
import Explorenftcard from './Explorenftcard';
import Exploreimgnftcard from './Explorenimgcard'
//IMPORT DYNAMIC STYLED COMPONENT
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Select from '@mui/material/Select';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import { StyledHeader } from '../Styles';
import NftCard from '../components/NftCard';
import { categories, status, itemsType, collections } from '../components/constants/filters';
import { filterCategories, filterStatus, filterItemsType, filterCollections } from '../../store/actions';

import { useDispatch, useSelector } from 'react-redux';
// import { Axios } from '../../core/axios';
import Axios from '../../api-instance'
import FraktionLoader from '../components/Loader'
// import noDataFound from '../../assets/loader/noDataFound.gif'
// import NotNftCard from '../components/NotNftCard';
import { useLocation } from "@reach/router";
import noDataFound from '../components/loaderNFT.svg'
// import ExContentLoader from 'react-content-loader'
//import ExploreContentLoader from '../components/exploreContentLoader'
import ExploreContentLoader from '../components/LoaderNew'
const RightGrid = styled(Grid)(({ theme }) => ({
  fontSize: '15px',
  display: "flex",
  justifyContent: "flex-end",
  margin: "10px 30px",
  [theme.breakpoints.down('sm')]: {
    display: "flex",
    justifyContent: "center",
  },
}));
const GridContainer = styled(Grid)(({ theme }) => ({

  display: "flex",
  flexWrap: "nowrap",
  alignItems: "center",
  [theme.breakpoints.down('md')]: {
    flexWrap: "wrap",
  },
  '& .MuiSvgIcon-root': {
    margin: "1px 10px",
    fontSize: "30px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "20px"
    },
  }
}));
const LeftGrid = styled(Grid)(({ theme }) => ({
  fontSize: '15px',

  margin: "10px 30px",
  '& p': {
    marginBottom: "1px"
  },
  [theme.breakpoints.down('sm')]: {
    margin: "10px",
    textTlign: "center",
  },

  '& .MuiButton-root': {
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: "10px",
    background: "#f6f6f6",
    width: "90%",
    color: "#030303",
    [theme.breakpoints.down('sm')]: {
      marginTop: "10px",
    },
    "&:hover,&:focus": {
      background: "#fff",
      borderRadius: "10px",
      color: "#030303 !important",
      transition: "all 0.3s ease 0s",
      boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    }
  },

}));
const LeftGrids = styled(Grid)(({ theme }) => ({
  fontSize: '15px',
  display: "flex",
  alignContent: "center",
  margin: "1px 30px",
  '& p': {
    marginRight: "25px",
    marginBottom: "1px"
  }
  ,
  '& span': {

    fontWeight: 500,

  },
  [theme.breakpoints.down('sm')]: {
    '& p': {
      marginRight: "15px",
    }
  },
  '& .MuiButton-root': {
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: "10px",
    background: "#f6f6f6",
    width: "90%",
    color: "#030303",
    [theme.breakpoints.down('sm')]: {
      marginTop: "10px",
    },
    "&:hover,&:focus": {
      background: "#fff",
      borderRadius: "10px",
      color: "#030303 !important",
      transition: "all 0.3s ease 0s",
      boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    }
  },

}));
const CenterGrid = styled(Grid)(({ theme }) => ({
  fontSize: '15px',
  display: "flex",
  justifyContent: "center",
  '& .MuiOutlinedInput-root': {
    height: "40px",
    width: "100%",
    border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: "10px"
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: "transparent"
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: "transparent"
  },
  '& .MuiButton-root': {
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: "10px",
    background: "#f6f6f6",
    width: "90%",
    color: "#030303",
    [theme.breakpoints.down('sm')]: {
      marginTop: "10px",
    },
    "&:hover,&:focus": {
      background: "#fff",
      borderRadius: "10px",
      color: "#030303 !important",
      transition: "all 0.3s ease 0s",
      boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    }
  },

}));
const CenterGrids = styled(Grid)(({ theme }) => ({
  fontSize: '18px',
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  '& .MuiButton-root': {
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: "10px",
    background: "#f6f6f6",
    width: "100px",
    color: "#030303",
    [theme.breakpoints.down('sm')]: {
      marginTop: "10px",
    },
    "&:hover,&:focus": {
      background: "#fff",
      borderRadius: "10px",
      color: "#030303 !important",
      transition: "all 0.3s ease 0s",
      boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    },
    "& h6":{
      margin:"auto"
    }
  },

}));
const CustomTabs = styled(Tabs)(({ theme }) => ({


  borderRadius: "10px",
  background: "#f6f6f6",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "48px",
  color: "#030303",
  '& .MuiTabs-flexContainer': {
    display: "flex",
    justifyContent: "center"
  },

  '& .MuiTab-root': {
    fontSize: '15px',
    fontWeight: 600,
    minHeight: "30px",
    minWidth: "50px",
    padding: "10px",
    margin: "4px 4px",
    transition: "all 0.3s ease 0s",
    color: "#545454 ",

  },
  '& .Mui-selected': {
    background: "#fff",
    borderRadius: "10px",
    color: "#030303 !important",
    transition: "all 0.3s ease 0s",
    boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
  }
  ,
  '& .MuiTabs-indicator': {
    display: "none",
    backgroundColor: 'red',
  },
}));
const CustomTab = styled(Tab)(({ theme }) => ({
  fontSize: '15px',
  '& .MuiSvgIcon-root': {
    margin: "1px 2px",
    fontSize: "20px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "20px"
    },
  }
  // fontWeight: 500,
  // transition: "all 0.3s ease 0s"
}));
const NotNftCard = React.lazy(() => import('../components/NotNftCard'));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;
function a11yProps(index) {
  console.log("index", index)
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREY'; //LIGHT, GREY, RETRO


const Explore = ({ setNftAddress }) => {
  const a = useSelector(state => state)
  const [searchContent, setSearchContent] = useState(false)
  const location = useLocation()
  const finalResult = location.state;
  const [datas, setDatas] = useState([])
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState(0);
  const [show,setShow] = React.useState(false)
  useEffect(() => {
    console.log(finalResult)
    if (finalResult?.data?.products?.length > 0) {
      setDatas(finalResult?.data?.products)
      console.log("lenth",finalResult.data.products)
    }
  }, [finalResult])  
  const [isSold, setIsSold] = useState(false)
  const [height, setHeight] = useState(0);
  const [userData, setUserData] = useState('')
  const [dataContent, setDataContent] = useState([])
  const dispatch = useDispatch();
  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  }
  const token = localStorage.getItem('token')
  const [auctionTime, setAuctionTime] = useState([])
  const [isAuctionEnded, setIsAuctionEnded] = useState(false)
  const [falseUser, setFalseUser] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  useEffect(() => {
    const getData = async () => {

      if (token) {
        const { data } = await Axios.get(`/auth/getdata/${token}`)

        setUserData(data?.data)
        console.log(data?.data, "dataaa")
      }
      try {
        if (a?.searchData?.item) {
          setIsLoaded(false)
          handleSearchItem(a.searchData.item)
        }
        else if (finalResult?.categoryName) {
          setIsLoaded(false)
          const { data } = await Axios.get(`/product?category=${finalResult?.categoryName}&isVerified=true&isSold=false`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
          })
          // setDatas(data?.data)
        }
        else if (a.searchData?.item === '') {
          setIsLoaded(false)
          const { data } = await Axios.get(`/product?isVerified=true&isSold=false`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
          })
          // setDatas(data?.data)


        }

        else {
          setIsLoaded(false)
          const { data } = await Axios.get(`/product?isVerified=true&isSold=false`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
          })

          // setDatas(data?.data)
        }




      } catch (error) {
        alert(error.message);
        if (error.message === "Already processing eth_requestAccounts. Please wait." || error.code === -32002) {
          setFalseUser(true)
        }
      } finally {
        setIsLoaded(true)
      }

    }
    getData()



  }, [location.pathname])
  const handleReset = async () => {
    try {
      setIsLoaded(false)
      const { data } = await Axios(`/product?isVerified=true&isSold=false`)
      // setDatas(data?.data)
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoaded(true)
    }
  }
  const handleSearchItem = async (item) => {
    try {
      setIsLoaded(false)
      const { data } = await Axios(`/product/data/?name=${item}&isVerified=true&isSold=false`)
      console.log('====================================');
      console.log(data);
      console.log('====================================');
      // setDatas(data?.data);
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoaded(true)
    }
  }
  const [isDark, setIsDark] = useState(false)
  useEffect(() => {
    if (localStorage.getItem('mode') === 'dark') {
      setIsDark(true)
    } else if (localStorage.getItem('mode') === 'light') {
      setIsDark(false)
    }

  }, [])
  const handleView = async (item) => {
    try {
      const sendView = await Axios.put('/product', { "_id": item._id, "view": userData?._id }, {
        'Content-type': 'Application/json'
      })

    } catch (error) {
      console.log(error);
    }
  }
  // const handleCategory = useCallback((event) => {

  //   handleCategoryApi(event)
  //   console.log(event);
  //   const { id } = event.target;
  //   dispatch(filterCategories({value: id, singleSelect: false}));
  // }, [dispatch]);


  var category = []
  const handleCategory = async (e, item) => {
    try {
      setIsLoaded(false)
      category.push(`category=${item?.categoryName}&`)
      const categoryData = category?.filter((item, i) => {
        if (item !== 'category=undefined&') {
          return item
        }
      })
      const { data } = await Axios.get(`/product/?${categoryData.join('')}isVerified=true&isSold=false`)
      // setDatas(data?.data);
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoaded(true)
    }
  }
  const handleSubCategory = async (item) => {
    try {
      setIsLoaded(false)
      const { data } = await Axios.get(`/product/?subCategory=${item?.target?.id}&isVerified=true&isSold=false`)
      // setDatas(data?.data);
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoaded(true)
    }

  }

  // const handleCategoryApi = async(event)=>{

  // try {
  //   if(event.target.checked){
  //     const {data} = await Axios.get(`/product/?category=${event?.target?.id}&isVerified=true&isSold=false`)
  //     setDatas(data?.data);
  //   }
  //   else {
  //     const {data} = await Axios.get(`/product`)
  //     setDatas(data?.data);
  //   }
  // } catch (error) {
  //   console.log(error);
  // }
  // }


  const handleStatus = useCallback((event) => {
    const { id } = event.target;
    handleStatusApi(event)
    // dispatch(filterStatus({value: id, singleSelect: false}));
  }, [dispatch]);



  const handleStatusApi = async (event) => {

    try {
      if (event.target.checked) {
        if (event?.target?.id === "buy_now") {
          setIsLoaded(false)
          const { data } = await Axios.get(`/product/?isAuction=${false}&isVerified=true&isSold=false`)
          console.log('====================================');
          console.log(data?.data);
          console.log('====================================');
          // setDatas(data?.data);
        }
        if (event?.target?.id === "on_auction") {
          setIsLoaded(false)
          const { data } = await Axios.get(`/product/?isAuction=${true}&isVerified=true&isSold=false`)
          // setDatas(data?.data);
        }
        if (event?.target?.id === "has_offers") {
          setIsLoaded(false)
          const { data } = await Axios.get(`/product/?isAuction=${false}&isVerified=true&isSold=false`)
          // setDatas(data?.data);
        }
        else {

        }

      }
      else {
        const { data } = await Axios.get(`/product?isVerified=true&isSold=false`)
        // setDatas(data?.data);
      }
    } catch (error) {
      console.log(error?.message);
    }
    finally {
      setIsLoaded(true)
    }
  }
  // useEffect(()=>{
  //   const obj =  async(item) => {
  //     const data = await Axios(`/product/data/?name=${item}&isVerified=true&isSold=false`)
  //     console.log(data);
  //     setDatas(data?.data?.data)
  //   } 
  //   if(a?.searchData?.item){
  //     obj(a?.searchData?.item)
  //   }  
  // },[])
  console.log(finalResult, "result")
  return (
    <>
      {/* <section className="jumbotron no-bg h-vh" style={{ background: 'linear-gradient(180deg, rgba(9,60,121,1) 25%, rgba(50,152,208,1) 100%)', height: '35vh' }}>
        <br /><br /><br /><br />
        <section className='container-fluid pb-0'>
          <div style={{ "border": "10px solid white", "width": "200px", "height": "200px", "border-radius": "10px" }}>
            <img src={finalResult?.data?.Image} alt='' width='100%' height='100%' style={{ objectFit: 'cover' }} />
          </div>
        </section>

      </section> */}
 <>
                <section id="profile_banner" className='jumbotron breadcumb no-bg myCollection_cover' style={{ background: 'linear-gradient(180deg, rgba(9,60,121,1) 25%, rgba(50,152,208,1) 100%)' }}>
                  <br /><br /><br /><br />
                  <section className='container-fluid pb-0' >
                    <div id="ssmallbanner">

                      <div className="sbanner-small" style={{ backgroundImage: `url(${finalResult?.data?.Image})` }}
                      >


                      </div>
                     
                    <ModeEditOutlineIcon />
                    </div>
                  </section>
                </section>
              </>
      <br /><br /><br></br>
      <GridContainer container spacing={0} >
        <LeftGrid item xs={12} sm={12} md={4} xl={4} lg={4}>
          <h2>{finalResult?.data?.Collection_Name}</h2>
          <h6>{finalResult?.data?.users?.name}</h6>
        </LeftGrid>
        <RightGrid item xs={12} sm={12} md={8} xl={8} lg={8}>
          {/* <StarOutlineRoundedIcon />
          <DriveFolderUploadIcon />
          <MoreHorizIcon /> */}
        </RightGrid>
      </GridContainer>
      <GridContainer container spacing={0}>
        <LeftGrids item xs={12} sm={12} md={8} xl={8} lg={8}>
          <p>Items<span>   {finalResult?.data?.products?.length}  </span></p>
          <p>Created<span>   {finalResult?.data?.created} </span></p>
          <p>Creator earnings <span>   5%  </span></p>

          <p>Chain<span>   Polygon  </span></p>
          <p>Category<span>   PFPS </span></p>

        </LeftGrids>
      </GridContainer>
      <GridContainer container spacing={0} >
        <LeftGrid item xs={12} sm={12} md={9} xl={9} lg={9}>
          <p>Pluto is a world of fun, interactive experiences that cut across art, gaming & fashion.</p>

        </LeftGrid>
      </GridContainer>
      <GridContainer container spacing={0} >
        <LeftGrid item xs={12} sm={12} md={12} xl={8} lg={8}>
          <div className='nft_flex_wrap'>
            <div>
              <h5>{finalResult?.data?.total_volumn.toFixed(8)} {finalResult?.data?.Token_Symbol}</h5>
              total volume

            </div>
            <div>
              <h5>{finalResult?.data?.florPrice} {finalResult?.data?.Token_Symbol}</h5>
              floor price

            </div>
            <div>
              <h5>0.1055 {finalResult?.data?.Token_Symbol}</h5>
              best offer

            </div>
            <div>
              <h5>3%</h5>
              listed

            </div>
            <div>
              <h5>1</h5>
              owners

            </div>
            {/* <div>
              <h5>23%</h5>
              unique owners

            </div> */}
          </div>



        </LeftGrid>
      </GridContainer>
      <GridContainer>
        <LeftGrid item xs={12} sm={12} md={12} xl={8} lg={8}>

          <GridContainer>
            <CenterGrid item xs={6} sm={2} md={2} xl={2} lg={2}>
              <Button>Items</Button>
            </CenterGrid>
            <CenterGrid item xs={6} sm={2} md={2} xl={2} lg={2}>
              <Button>Offers</Button>
            </CenterGrid>
            <CenterGrid item xs={6} sm={2} md={2} xl={2} lg={2}>
              <Button>Analytics</Button>
            </CenterGrid>
            <CenterGrid item xs={6} sm={2} md={2} xl={2} lg={2}>
              <Button>Activity</Button>
            </CenterGrid>
          </GridContainer>


        </LeftGrid>

      </GridContainer>
      <Box style={{ "border": "1px solid rgba(0,0,0,0.8)", "margin": "40px 2px" }}></Box>

      <GridContainer container spacing={0} style={{ "padding": "1px 20px" }}>
        <CenterGrids item xs={12} sm={6} md={6} xl={2} lg={2}>
          <Button onClick={()=>setShow(!show)}><MenuOpenRoundedIcon /></Button>
          <h6>Live</h6>
          {finalResult?.data?.products?.length} Results
        </CenterGrids>
        <CenterGrid item xs={12} sm={6} md={6} xl={5} lg={5}>
          <TextField
            placeholder="Search by Name"
            variant="outlined"
            style={{ "width": "95%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </CenterGrid>
        <CenterGrid item xs={12} sm={12} md={6} xl={2} lg={2}>
          <FormControl fullWidth>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              placeholder='Low to High'
              onChange={handleChange}
            >
              <MenuItem value="Low to High">Low to High</MenuItem>
              <MenuItem value="High to Low">High to Low</MenuItem>
            </Select>
          </FormControl>
        </CenterGrid>
        <CenterGrid item xs={12} sm={12} md={6} xl={3} lg={3} >
          <CustomTabs value={values} onChange={handleChanges} aria-label="basic tabs example" style={{ "width": "min-content" }}>
            <CustomTab icon={<ListOutlinedIcon />} {...a11yProps(0)} />
            <CustomTab icon={<GridOnRoundedIcon />} {...a11yProps(1)} />
            <CustomTab icon={<WindowOutlinedIcon />} {...a11yProps(2)} />
            <CustomTab icon={<AutoAwesomeMosaicOutlinedIcon />} {...a11yProps(3)} />

          </CustomTabs>
        </CenterGrid>
      </GridContainer>

      {
        isDark ?
          <div className="greyscheme" onClick={handleView}>
            <StyledHeader theme={theme} />

            <div className='container '>
              <div className='row'>
                <div className="spacer-double"></div>
                <div className='col-md-3'>
                  <CheckboxFilter handleCategory={handleCategory} handleStatus={handleStatus} />
                </div>
                <div className="col-md-9">
                  {/* <ColumnNewThreeColRedux/> */}
                  <div className='grid_explore_container'>
                    {
                      dataContent ?

                        dataContent?.map((item, i) => {
                          if (item?.isVerified === true && item?.isSold === false) {
                            return (


                              <NftCard nft={item} onImgLoad={onImgLoad} />
                            )
                          }
                          // else if(item?.isVerified === true && item?.isSold ===true){
                          //   return(


                          //     <h1 className='noDataFound'>No data found...</h1>
                          //         )
                          // }
                        }) :


                        datas?.map((item, i) => {
                          if (item?.isVerified === true && item?.isSold === false) {
                            return (


                              <NftCard nft={item} onImgLoad={onImgLoad} />
                            )
                          }
                          // else if(item?.isVerified === true && item?.isSold ===true){
                          //   return(


                          //     <h1 className='noDataFound'>No data found...</h1>
                          //         )
                          // }
                        })
                    }
                    {
                      datas?.length === 0 ?
                        <h1 className='noDataFound'>No data found...</h1>
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>



            <Footer />



            <Footer />
          </div>
          :

          <div className="profile-container-section">
            <GlobalStyles />
            <div className='container-fluid'>
              <div className='row'>
                <div className="spacer-double"></div>
               {!show ? <div className='col-lg-2 col-md-3'>
                  <CheckboxFilter handleReset={handleReset} handleCategory={handleCategory} handleSubCategory={handleSubCategory} handleStatus={handleStatus} />
                </div> :<></>}
                <div className={!show ? "col-lg-10 col-md-9" : "col-lg-12 col-md-12"}>
                  {/* <ColumnNewThreeColRedux/> */}
                  <CustomTabPanel value={values} index={0}> <Explorenfttable nfts={datas} /> </CustomTabPanel>
        <CustomTabPanel value={values} index={1}> 
        <div className='grid_explore_containers'>
                    {/* {
                      dataContent?.length===0?
                       datas?.map((item,i)=>{
                        if(item?.isVerified === true && item?.isSold ===false){
                          return(
                        
                           <div onClick={()=>handleView(item)}>  
                            <NftCard  nft={item} onImgLoad={onImgLoad} />
                            </div>
                                )
                        }
                        // else if(item?.isVerified === true && item?.isSold ===true){
                        //   return(
                        
                     
                        //     <h1 className='noDataFound'>No data found...</h1>
                        //         )
                        // }
                      }):null
                    } */}

{
                      isLoaded ?

                        datas?.length !== 0 ?
                          datas?.map((item, i) => {
                            return (
                              <div onClick={() => handleView(item)} key={i}>
                                {
                                  userData && !falseUser ?
                                    <Suspense fallback={<ExploreContentLoader />}>
                                       
                                      <Explorenftcard setNftAddress={setNftAddress} nft={item?.products} datas={datas} onImgLoad={onImgLoad} />
                                     
                                    </Suspense>
                                    :
                                    <Suspense fallback={<ExploreContentLoader />}>
                                      <Explorenftcard nft={item?.products} datas={datas} onImgLoad={onImgLoad} />
                                    </Suspense>
                                }
                              </div>
                            )
                          }) :
                          <h1 className='noDataFound' style={{ height: '100vh' }}>No data found...</h1>

                        :
                        Array(3).fill()?.map((item) => (
                          <ExploreContentLoader />
                        ))
                    }
                    {/* {
                        datas?.length===0? 
                        // <FraktionLoader/>
                        <div class="lds-hourglass" style={{marginTop:'50%'}}></div>
                        :null
                     } */}

                  </div>
                  </CustomTabPanel>
        <CustomTabPanel value={values} index={2}> 
        <div className='grid_explore_container'>
{
                      isLoaded ?

                        datas?.length !== 0 ?
                          datas?.map((item, i) => {
                            return (
                              <div onClick={() => handleView(item)} key={i}>
                                {
                                  userData && !falseUser ?
                                    <Suspense fallback={<ExploreContentLoader />}>
                                       
                                      <Explorenftcard setNftAddress={setNftAddress} nft={item?.products} datas={datas} onImgLoad={onImgLoad} />
                                     
                                    </Suspense>
                                    :
                                    <Suspense fallback={<ExploreContentLoader />}>
                                      <Explorenftcard nft={item?.products} datas={datas} onImgLoad={onImgLoad} />
                                    </Suspense>
                                }
                              </div>
                            )
                          }) :
                          <h1 className='noDataFound' style={{ height: '100vh' }}>No data found...</h1>

                        :
                        Array(3).fill()?.map((item) => (
                          <ExploreContentLoader />
                        ))
                    }
                 

                  </div>
        </CustomTabPanel>
        <CustomTabPanel value={values} index={3}> 
        <div className='image-griding'>

  
{
                      isLoaded ?

                        datas?.length !== 0 ?
                          datas?.map((item, i) => {
                            return (
                              <Link  to={`/itemdetails/${item?.products?.mintTransaction?.transactionHash}`} onClick={() => handleView(item)} key={i} className="image-items">
                                 <img src={item?.products?.ProductImage}  className="lazy img-fluid" alt="" />
                              </Link>
                            )
                          }) :
                          <h1 className='noDataFound' style={{ height: '100vh' }}>No data found...</h1>

                        :
                        Array(3).fill()?.map((item) => (
                          <ExploreContentLoader />
                        ))
                    }
                 

                  </div>
        </CustomTabPanel>

                  
                </div>
              </div>
            </div>

            <div style={{ position: 'sticky', bottom: '0px' }}>
              <Footer />
            </div>
          </div>


      }
    </>
  )
}
export default Explore;