import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ColumnNewRedux from '../components/ColumnNewRedux';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import * as selectors from '../../store/selectors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAuthorList } from "../../store/actions/thunks";
import api from "../../core/api";
import { StyledHeader } from '../Styles';
import Axios from '../../api-instance'
import NftCard from "../components/NftCard";
import NftCreatedCard from "../components/NftCreatedCard";
import NftPurchasedCard from "../components/NftPurchasedCard";
import NftSale from "../components/NftSale";
import NftSoldCard from "../components/NFTSoldedCard";
import Avatar from 'react-avatar';
import constants from '../../constants'
import { useLocation } from "@reach/router";
import FormData from "form-data";


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;


//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREYLOGIN'; //LIGHT, GREY, RETRO


const Colection = ({ authorId }) => {

  const location = useLocation();
  const finalResult = location.state;




  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = useState(false)
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    setOpenMenu2(false);
    setOpenMenu3(false);
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    document.getElementById("Mainbtn3").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
    setOpenMenu2(false);
    setOpenMenu(false);
    setOpenMenu3(false);
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
    document.getElementById("Mainbtn3").classList.remove("active");
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu2);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu3(false);
    document.getElementById("Mainbtn2").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn3").classList.remove("active");
  };

  const handleBtnClick3 = () => {
    setOpenMenu3(!openMenu3);
    setOpenMenu(false);
    setOpenMenu1(false);
    setOpenMenu2(false);
    document.getElementById("Mainbtn3").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn2").classList.remove("active");
  };

  const dispatch = useDispatch();
  const authorsState = useSelector(selectors.authorsState);
  const author = authorsState.data ? authorsState.data[0] : {};

  useEffect(() => {
    dispatch(fetchAuthorList(authorId));
  }, [dispatch, authorId]);


  const [isDark, setIsDark] = useState(false)
  useEffect(() => {
    if (localStorage.getItem('mode') === 'dark') {
      setIsDark(true)
    } else if (localStorage.getItem('mode') === 'light') {
      setIsDark(false)
    }
  }, [])

  const [userProfile, setUserProfile] = useState([])
  const [userId, setUserId] = useState(null)
  const [mintedData, setMintedData] = useState([])
  const [userContent, setUserContent] = useState(null);
  const [walletAddress, setWalletAddress] = useState('')
  const [isCopied, setIsCopied] = useState(false);
  const [currentMetamaskAddress, setCurrentMetamaskAddress] = useState(false)
  var imageCollectionData = new FormData();
  useEffect(() => {
    const getData = async () => {
      const account = await window.ethereum.request({
        method: "eth_requestAccounts"
      });
      setCurrentMetamaskAddress(account[0])
      try {
        const token = window.localStorage.getItem('token')

        const { data } = await Axios.get(`/product`)
        setMintedData(data?.data);
        const userData = await Axios.get(`/auth/data/?_id=${window.location.pathname.split('/').pop()}`)


        if (window.location.pathname.split('/').pop()) {
          if (userData?.data?.data[0]?._id !== window.location.pathname.split('/').pop()) {
            window.location.href = `${constants?.MainURL}/404`
          }
        }


        setUserId(userData?.data?.data[0]?._id);
        setUserContent(userData?.data?.data)


      } catch (error) {
        console.log(error);
      }

      if (account) {
        setWalletAddress(account[0])
      }
    }
    getData();


  }, [])


  const [height, setHeight] = useState(0);

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  }


  async function copyTextToClipboard(text) {

    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }
  const handleCopyClick = () => {

    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(walletAddress)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const onChangePicture = async (e) => {

      imageCollectionData.append("Image", e.target.file);
    imageCollectionData.append("imageType", "cover");
    console.log(imageCollectionData)
    const data = await Axios.post('/product/createCollection', imageCollectionData, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    }).then(response => {
      // Axios automatically parses the JSON response
      const data = response.data;
      console.log(data,"data....")
      // if (data.msg === "success") {
      //   toast.success("Collection Created Sucessfully", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   })
        
      // }
      // else {
      //   toast.error("Error", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // }
    })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      });
    console.log(data, "api");



  };


  return (
    <>
          <ToastContainer style={{ top: "5em", zIndex: "999" }} />
      {
        isDark ?
          <div className="greyscheme">
            <StyledHeader theme={theme} />
            {author.banner &&
              <section id='profile_banner' className='jumbotron breadcumb no-bg myCollection_cover' style={{ backgroundImage: `url(${finalResult?.coverPicture ? finalResult?.coverPicture : api.baseUrl + author.banner.url})` }}>
                <div className='mainbreadcumb'>
                </div>
              </section>
            }
            <section className='container no-bottom'>
              <div className='row'>
                <div className='spacer-double'></div>
                <div className="col-md-12">
                  <div className="d_profile de-flex">
                    <div className="de-flex-col">
                      <div className="profile_avatar">
                        <div className="profile_image_container">
                          {
                            finalResult?.profilePicture ?
                              <img src={finalResult?.profilePicture} alt={finalResult?.name} style={{ border: '2px solid grey', borderRadius: '50%', boxShadow: '2px 2px 10px grey' }} />
                              :
                              <Avatar name={finalResult?.name} size="100" round="50px" />
                          }

                          <i className="fa fa-check tickMark" ></i>
                        </div>
                        <div className="profile_name">
                          <h4>
                            {finalResult?.name}
                            <span className="profile_username">{finalResult?.username}</span>
                            <span id="wallet" className="profile_wallet">{walletAddress}</span>
                            <button id="btn_copy" title="Copy Text" onClick={handleCopyClick}>{isCopied ? 'Copied!' : 'Copy'}</button>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="profile_follow de-flex">
                      <div className="de-flex-col">
                        <div className="profile_follower">{author.followers} followers</div>
                      </div>
                      <div className="de-flex-col">
                        <span className="btn-main">Follow</span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>

            <section className='container no-top'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className="items_filter">
                    <ul className="de_nav text-left">
                      <li id='Mainbtn' className="active"><span onClick={handleBtnClick}>On Sale</span></li>
                      <li id='Mainbtn1' className=""><span onClick={handleBtnClick1}>Created</span></li>
                      <li id='Mainbtn2' className=""><span onClick={handleBtnClick2}>Purchased Items</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              {openMenu && (
                <div id='zero1' className='onStep fadeIn'>
                  <div className='grid_explore_container'>
                    {
                      mintedData?.map((item, i) => {
                        if (item?.userId?._id === userId && item?.isVerified) {
                          return (
                            // <div key={i} className='explore_container'>
                            // <img width='50%'   src={item.ProductImage} alt={item.name}/>
                            // <h4>{item?.name}</h4>
                            //  <p>Price:{item?.price}</p>
                            // </div>
                            <NftSale nft={item} onImgLoad={onImgLoad} />
                          )
                        }
                      })
                    }
                  </div>
                  {
                    mintedData?.length <= 0 ?
                      <h1 style={{ textAlign: 'center' }}>No Product Minted/Created....</h1>
                      : null
                  }
                </div>
              )}
              {openMenu1 && (
                <div id='zero2' className='onStep fadeIn'>
                  <div className='grid_explore_container'>
                    {
                      mintedData?.map((item, i) => {
                        if (item.userId?._id === userId) {
                          item?.mintTransaction &&

                            <NftCreatedCard isApproved={item?.isVerified} nft={item} onImgLoad={onImgLoad} />

                        }

                      })
                    }
                  </div>
                  {
                    mintedData?.length <= 0 ?
                      <h1 style={{ textAlign: 'center' }}>No Product Minted/Created....</h1>
                      : null
                  }
                </div>
              )}
              {openMenu2 && (
                <div id='zero3' className='onStep fadeIn'>
                  <div className='grid_explore_container'>
                    {
                      mintedData?.map((item, i) => {

                        return item?.owners?.map(data => {
                          if (data === userId) {
                            return (
                              <NftPurchasedCard nft={item} onImgLoad={onImgLoad} />
                            )
                          }
                        })

                        //  if(item.userId?._id===userId){
                        //       return (

                        //         <NftCard nft={item} onImgLoad={onImgLoad} />
                        //       )
                        //  }

                      })
                    }
                  </div>
                  {
                    mintedData?.length <= 0 ?
                      <h1 style={{ textAlign: 'center' }}>No Product Purchased....</h1>
                      : null
                  }
                </div>
              )}

              {openMenu3 && (
                <div id='zero3' className='onStep fadeIn'>
                  <div className='grid_explore_container'>
                    {
                      mintedData?.map((item, i) => {

                        if (item?.userId === userId) {
                          if (item?.isSold) {
                            return (
                              <NftSoldCard nft={item} onImgLoad={onImgLoad} />
                            )
                          }
                        } else {
                          <h1>No data found...</h1>
                        }



                        //  if(item.userId?._id===userId){
                        //       return (

                        //         <NftCard nft={item} onImgLoad={onImgLoad} />
                        //       )
                        //  }

                      })
                    }
                  </div>
                  {
                    mintedData?.length <= 0 ?
                      <h1 style={{ textAlign: 'center' }}>No Product Purchased....</h1>
                      : null
                  }
                </div>
              )}
            </section>


            <Footer />
          </div>
          :
          <div >

            {author.banner &&
              <section id='profile_banner' className='jumbotron breadcumb no-bg ' style={{ backgroundImage: `url(${finalResult?.coverPicture ? finalResult?.coverPicture : api.baseUrl + author.banner.url})` }}>
                <div className='mainbreadcumb'>
                </div>
              </section>
            }
            <section className='container no-bottom'>
              <div className='row'>
                <div className='spacer-double'></div>
                <div className="col-md-12">
                  <div className="d_profile de-flex">
                    <div className="de-flex-col">
                      <div className="profile_avatar">
                        <div className="profile_image_container">
                          {
                            finalResult?.profilePicture ?
                              <img src={finalResult?.profilePicture} alt={finalResult?.name} style={{ border: '2px solid grey', borderRadius: '50%', boxShadow: '2px 2px 10px grey' }} />
                              :
                              <Avatar name={finalResult?.name} size="100" round="50px" />
                          }
 <input
                          onChange={onChangePicture}
                          id="upload_file"
                          type="file"
                          multiple
                        />
                          <i className="fa fa-check tickMark" ></i>
                        </div>
                        <div className="profile_name">
                          <h4>
                            {finalResult?.name}s
                            <span className="profile_username">{finalResult?.username}s</span>
                            {/* <span id="wallet" className="profile_wallet">{walletAddress}</span> */}
                            {/* <button id="btn_copy" title="Copy Text" onClick={handleCopyClick}>{isCopied ? 'Copied!' : 'Copy'}</button> */}
                          </h4>
                        </div>
                      </div>
                    </div>
                    {/* <div className="profile_follow de-flex">
                  <div className="de-flex-col">
                    <div className="profile_follower">{author.followers} followers</div>
                  </div>
                  <div className="de-flex-col">
                      <span className="btn-main">Follow</span>
                  </div>
              </div> */}

                  </div>
                </div>
              </div>
            </section>

            <section className='container no-top'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className="items_filter">
                    <ul className="de_nav text-left">
                      {/* <li id='Mainbtn' className="active"><span onClick={handleBtnClick}>Items</span></li> */}
                      {/* <li id='Mainbtn1' className=""><span onClick={handleBtnClick1}>Created</span></li> */}
                      {/* <li id='Mainbtn2' className=""><span onClick={handleBtnClick2}>Purchased Items</span></li>
                    <li id='Mainbtn3' className=""><span onClick={handleBtnClick3}>Sold Item</span></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {openMenu && (
                <div id='zero1' className='onStep fadeIn'>
                  {/* <div style={{display:'flex',gap:'20px',alignContent:'center',padding:'20px'}}>
               <button    className="active" >Buy Now</button>
               <button    className="btn-main  ">On Auction</button>
             </div> */}
                  <div className='grid_explore_container'>

                    {
                      mintedData?.map((item, i) => {
                        if (item?.userId?._id === userId && item?.isVerified) {
                          if (!item?.buyTransaction) {
                            return (
                              <NftSale nft={item} onImgLoad={onImgLoad} />
                            )
                          }
                        }
                      })
                    }
                  </div>
                  {
                    mintedData?.length <= 0 ?
                      <h1 style={{ textAlign: 'center' }}>No Product Minted/Created....</h1>
                      : null
                  }
                </div>
              )}
              {/* {openMenu1 && ( 
        <div id='zero2' className='onStep fadeIn'>
           <div  className='grid_explore_container'>
         {
           mintedData?.map((item,i)=>{
               if(item.userId?._id===userId){
                return   item?.mintTransaction&&                   
                  <NftCreatedCard isApproved={item?.isVerified} nft={item} onImgLoad={onImgLoad} />                    
               }
             
           })
         }
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Minted/Created....</h1>
           :null
         }
        </div>
      )}  */}
              {openMenu2 && (
                <div id='zero3' className='onStep fadeIn'>
                  <div className='grid_explore_container'>
                    {
                      mintedData?.map((item, i) => {


                        if (userId !== item?.userId) {
                          if (item?.buyTransaction?.from === currentMetamaskAddress) {
                            return (
                              <NftPurchasedCard nft={item} onImgLoad={onImgLoad} />
                            )
                          }
                        }
                      })

                      //  if(item.userId?._id===userId){
                      //       return (

                      //         <NftCard nft={item} onImgLoad={onImgLoad} />
                      //       )
                      //  }

                    }

                  </div>
                  {
                    mintedData?.length <= 0 ?
                      <h1 style={{ textAlign: 'center' }}>No Product Purchased....</h1>
                      : null
                  }
                </div>
              )}

              {openMenu3 && (
                <div id='zero3' className='onStep fadeIn'>
                  <div className='grid_explore_container'>
                    {
                      mintedData?.map((item, i) => {
                        if (item?.userId?._id === userId) {
                          if (item?.isSold) {
                            return (
                              <NftSoldCard nft={item} onImgLoad={onImgLoad} />
                            )
                          }
                        } else {
                          <h1>No data found...</h1>
                        }



                        //  if(item.userId?._id===userId){
                        //       return (

                        //         <NftCard nft={item} onImgLoad={onImgLoad} />
                        //       )
                        //  }

                      })
                    }
                  </div>
                  {
                    mintedData?.length <= 0 ?
                      <h1 style={{ textAlign: 'center' }}>No Product Purchased....</h1>
                      : null
                  }
                </div>
              )}
            </section>


            <Footer />
          </div>
      }
    </>
  );
}
export default memo(Colection);