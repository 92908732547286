import React, { memo, useEffect, useState } from 'react';
import styled from "styled-components";
import Clock from "./Clock";
import { navigate } from '@reach/router';
import api from '../../core/api';
import { Link } from '@reach/router';
import Avatar from 'react-avatar';
// import { Axios } from '../../core/axios';
import Axios from '../../api-instance'
import ethereumImage from '../../assets/ethereum.webp'
import polygonImage from '../../assets/polygon.webp'
import binanceImage from '../../assets/binance.webp'
import VerifiedIcon from '@mui/icons-material/Verified';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({ setNftAddress, nftTime, handleView, userData, isApproved, nft,datas,className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad }) => {




    // if(nft?.favourite){
    const [likedOrNot, setLikedOrNot] = useState(false)
    const a = nft?.favourite?.filter((item, i) => {
        if (item === userData?._id) {
            setLikedOrNot(true)
        }
    })
    // console.log(a);
    // }

    const [checkTimeOrNot, setCheckTimeOrNot] = useState('')
    const [auctionTime, setAuctionTime] = useState(false)
    const [dollar, setDollar] = useState('')

    useEffect(() => {




        const getData = async () => {

            try {
                const { data } = await Axios.get(`/admin`)
                nft.admin = data?.data[0]
                setDollar(data?.data[0])



            } catch (error) {
                console.log(error);
            }
        }

        getData()
    }, [])


    const handleAddress = () => {
        setNftAddress(nft?.mintTransaction?.transactionHash)
        
    }


    console.log(datas, 'name');
    return (
        <div  >
            <Link
  to={`/itemdetails/${nft.mintTransaction?.transactionHash}`}
  onClick={() => {
   
    setNftAddress(nft?.mintTransaction?.transactionHash);
    
   
    navigate(`/itemdetails/${nft.mintTransaction?.transactionHash}`, {
      state: { data: nft },
    });
  }}
>
            {/* <Link onClick={handleAddress}  state= {{ data: nft }} to={`/itemdetails/${nft.mintTransaction?.transactionHash}`}> */}
                <div className="nft_pic">
                    <div className="nft_pic_wrap" style={{ "height": "250px" }}>
                        <img src={nft?.ProductImage} className="lazy img-fluid" alt="" />

                    </div>
                    <div className='nft_text_wrap'>
                        <div className='textCell'>
                            <h4 className="black-color">{nft?.name}</h4>
                        </div>
                        <div className='nft_noflex_wrap'>
                            <div>
                                {nft?.status === 'has_offers' ? (
                                    <div className="has_offers">
                                        <span className='through black-color'>{nft?.price}</span> {nft?.price} {nft?.blockChain === "MATIC" ? "MATIC" : nft?.blockChain === "BNB" ? "BNB" : "ETH"} (${(nft?.price * parseInt(dollar?.amount)).toFixed(5)})
                                    </div>
                                ) : (
                                    <div className="nft__item_price black-color">
                                        {console.log(nft?.price, "price")}
                                        {nft?.price} {nft?.blockChain === "MATIC" ? "MATIC" : nft?.blockChain === "BNB" ? "BNB" : "ETH"} (${(nft?.price * parseInt(dollar?.amount)).toFixed(5)})
                                        {nft?.status === 'on_auction' &&
                                            <span className="black-color">{nft?.bid}/{nft?.max_bid}</span>
                                        }
                                    </div>
                                )
                                }
                                <div className="nft__item_action">
                                    <span className="black-color">{nft?.isAuction ? 'Place a bid' : 'Buy Now'}</span>
                                </div>
                                <div className="nft__item_like">
                                    {
                                        likedOrNot ?
                                            <i className="fa fa-heart" style={{ color: 'red' }}></i>
                                            :
                                            <i className="fa fa-heart"></i>
                                    }
                                    <span className="black-color">{nft?.favourite?.length}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default memo(NftCard);