import React, { memo, useEffect,useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import ColumnNewRedux from '../components/ColumnNewRedux';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import * as selectors from '../../store/selectors';
import { fetchAuthorList } from "../../store/actions/thunks";
import api from "../../core/api";
import { StyledHeader } from '../Styles';
import Axios from '../../api-instance'
import NftCard from "../components/NftCard";
import NftCreatedCard from "../components/NftCreatedCard";
import NftPurchasedCard from "../components/NftPurchasedCard";
import NftSale from "../components/NftSale";
import NftSoldCard from "../components/NFTSoldedCard";
import Avatar from 'react-avatar';
import constants from '../../constants'


const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;


//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREYLOGIN'; //LIGHT, GREY, RETRO


const Colection = ({ authorId }) => {
const [openMenu, setOpenMenu] = React.useState(true);
const [openMenu1, setOpenMenu1] = React.useState(false);
const [openMenu2, setOpenMenu2] = React.useState(false);
const [openMenu3, setOpenMenu3] = useState(false)
const handleBtnClick = () => {
  setOpenMenu(!openMenu);
  setOpenMenu1(false);
  setOpenMenu2(false);
  setOpenMenu3(false);
  document.getElementById("Mainbtn").classList.add("active");
  document.getElementById("Mainbtn1").classList.remove("active");
  document.getElementById("Mainbtn2").classList.remove("active");
  document.getElementById("Mainbtn3").classList.remove("active");
};
const handleBtnClick1 = () => {
  setOpenMenu1(!openMenu1);
  setOpenMenu2(false);
  setOpenMenu(false);
  setOpenMenu3(false);
  document.getElementById("Mainbtn1").classList.add("active");
  document.getElementById("Mainbtn").classList.remove("active");
  document.getElementById("Mainbtn2").classList.remove("active");
  document.getElementById("Mainbtn3").classList.remove("active");
};
const handleBtnClick2 = () => {
  setOpenMenu2(!openMenu2);
  setOpenMenu(false);
  setOpenMenu1(false);
  setOpenMenu3(false);
  document.getElementById("Mainbtn2").classList.add("active");
  document.getElementById("Mainbtn").classList.remove("active");
  document.getElementById("Mainbtn1").classList.remove("active");
  document.getElementById("Mainbtn3").classList.remove("active");
};

const handleBtnClick3 = () => {
  setOpenMenu3(!openMenu3);
  setOpenMenu(false);
  setOpenMenu1(false);
  setOpenMenu2(false);
  document.getElementById("Mainbtn3").classList.add("active");
  document.getElementById("Mainbtn").classList.remove("active");
  document.getElementById("Mainbtn1").classList.remove("active");
  document.getElementById("Mainbtn2").classList.remove("active");
};

const dispatch = useDispatch();
const authorsState = useSelector(selectors.authorsState);
const author = authorsState.data ? authorsState.data[0] : {};

useEffect(() => {
  dispatch(fetchAuthorList(authorId));
}, [dispatch, authorId]);


const [isDark, setIsDark] = useState(false)
useEffect(()=>{
  if(localStorage.getItem('mode')==='dark'){
     setIsDark(true)
  }else if(localStorage.getItem('mode')==='light'){
    setIsDark(false)
  }
},[])


const [userProfile, setUserProfile] = useState([])
const [userId, setUserId] = useState(null)
const [mintedData, setMintedData] = useState([])
const [userContent, setUserContent] = useState(null);
const [walletAddress, setWalletAddress] = useState('')
const [isCopied, setIsCopied] = useState(false);
const [currentMetamaskAddress, setCurrentMetamaskAddress] = useState(false)
const [userBuyData, setUserBuyData] = useState('')
useEffect(()=>{
  const getData = async()=>{
    if(window?.ethereum){
    const account = await window.ethereum.request({ 
      method: "eth_requestAccounts"
    });
    setCurrentMetamaskAddress(account[0])
    try {
      const token = window.localStorage.getItem('token') 

        const {data} = await Axios.get(`/product`)
        const auctionBuyData = await Axios.get('/auction')

        const arr = []
      
     const a = data?.data?.map((item,i)=>{
          if(item?.isSold&&item?.isAuction){
            return  auctionBuyData?.data?.data?.map((data,index)=>{
            
                 if(data?.productId?._id===item?._id){
                   const a = {
                     item,data
                   }
                   arr.push(a);
                   return item
                 }
              })
          }
        })
        

        setUserBuyData(a.shift())


          setMintedData(data?.data);
        const userData = await Axios.get(`/auth/getdata/${token}`)
      

         if(window.location.pathname.split('/').pop()){
          if(userData?.data?.data?._id!== window.location.pathname.split('/').pop()){
               window.location.href=`${constants?.MainURL}/404`
          }
        }

        setUserId(userData?.data?.data?._id);
        setUserContent(userData?.data?.data)

          
    } catch (error) {
      console.log(error);
    }
    if(account){
      setWalletAddress(account[0])
      
    }
  }
  }
  getData();


},[])

const [height, setHeight] = useState(0);

const onImgLoad = ({target:img}) => {
    let currentHeight = height;
    if(currentHeight < img.offsetHeight) {
        setHeight(img.offsetHeight);
    }
}


async function copyTextToClipboard(text) {
  
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

const handleCopyClick = () => {
  
  // Asynchronously call copyTextToClipboard
  copyTextToClipboard(walletAddress)
    .then(() => {
      // If successful, update the isCopied state value
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    })
    .catch((err) => {
      console.log(err);
    });
}



return (
  <> 
{
  isDark?
<div className="greyscheme">
<StyledHeader theme={theme} />
{ author.banner && 
    <section id='profile_banner' className='jumbotron breadcumb no-bg myCollection_cover' style={{backgroundImage: `url(${userContent?.coverPicture?userContent?.coverPicture:api.baseUrl + author.banner.url})`}}>
      <div className='mainbreadcumb'>
      </div>
    </section>
  }
  <section className='container no-bottom'>
    <div className='row'>
      <div className='spacer-double'></div>
      <div className="col-md-12">
         <div className="d_profile de-flex">
              <div className="de-flex-col">
                  <div className="profile_avatar">
                    <div className="profile_image_container">
                      {
                        userContent?.profilePicture?
                                              <img src={userContent?.profilePicture} alt={userContent?.name} style={{border:'2px solid grey',borderRadius:'50%',boxShadow:'2px 2px 10px grey'}}/>
           :
                         <Avatar name={userContent?.name} size="100" round="50px"/>
                      }
                    
                      <i className="fa fa-check tickMark" ></i>
                      </div>
                      <div className="profile_name">
                          <h4>
                            {userContent?.name}                                          
                              <span className="profile_username">{userContent?.username}</span>
                              <span id="wallet" className="profile_wallet">{walletAddress}</span>
                              <button id="btn_copy" title="Copy Text" onClick={handleCopyClick}>{isCopied ? 'Copied!' : 'Copy'}</button>
                          </h4>
                      </div>
                  </div>
              </div>
              <div className="profile_follow de-flex">
                  <div className="de-flex-col">
                    <div className="profile_follower">{author.followers} followers</div>
                  </div>
                  <div className="de-flex-col">
                      <span className="btn-main">Follow</span>
                  </div>
              </div>

          </div>
      </div>
    </div>
  </section>

  <section className='container no-top'>
        <div className='row'>
          <div className='col-lg-12'>
              <div className="items_filter">
                <ul className="de_nav text-left">
                    <li id='Mainbtn' className="active"><span onClick={handleBtnClick}>On Sale</span></li>
                    <li id='Mainbtn1' className=""><span onClick={handleBtnClick1}>Created</span></li>
                    <li id='Mainbtn2' className=""><span onClick={handleBtnClick2}>Purchased Items</span></li>
                </ul>
            </div>
          </div>
        </div>
      {openMenu && (  
        <div id='zero1' className='onStep fadeIn'>
           <div  className='grid_explore_container'>
         {
           mintedData?.map((item,i)=>{
               if(item?.userId?._id===userId && item?.isVerified){
                    return (
                      // <div key={i} className='explore_container'>
                      // <img width='50%'   src={item.ProductImage} alt={item.name}/>
                      // <h4>{item?.name}</h4>
                      //  <p>Price:{item?.price}</p>
                      // </div>
                      <NftSale nft={item} onImgLoad={onImgLoad} />
                    )
               }
           })
         }
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Minted/Created....</h1>
           :null
         }
        </div>
      )}
      {openMenu1 && ( 
        <div id='zero2' className='onStep fadeIn'>
           <div  className='grid_explore_container'>
         {
           mintedData?.map((item,i)=>{
               if(item.userId?._id===userId){
                item?.mintTransaction&&
                    
                      <NftCreatedCard isApproved={item?.isVerified} nft={item} onImgLoad={onImgLoad} />
                    
               }
             
           })
         }
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Minted/Created....</h1>
           :null
         }
        </div>
      )}
      {openMenu2 && ( 
        <div id='zero3' className='onStep fadeIn'>
            <div  className='grid_explore_container'>
         {
           mintedData?.map((item,i)=>{
                    
                 return  item?.owners?.map(data=>{
                       console.log(data,userId);
                         if(data===userId){
                           return(
                            <NftPurchasedCard nft={item} onImgLoad={onImgLoad} />
                           )
                         }
                   })

              //  if(item.userId?._id===userId){
              //       return (
                        
              //         <NftCard nft={item} onImgLoad={onImgLoad} />
              //       )
              //  }
             
           })
         }
         
         {/* {
          userBuyData&&
         
          userBuyData?.data?.transaction.pop()._id===userId&&
            
              <div>
               
                            <NftPurchasedCard nft={userBuyData?.item} onImgLoad={onImgLoad} />
                           
              </div>
            
          
            
         } */}

{
           mintedData?.map((item,i)=>{
                    
                 return  item?.owners?.map(data=>{
                       
                         if(data===userId){
                           return(
                            <NftPurchasedCard nft={item} onImgLoad={onImgLoad} />
                           )
                         }
                   })

              //  if(item.userId?._id===userId){
              //       return (
                        
              //         <NftCard nft={item} onImgLoad={onImgLoad} />
              //       )
              //  }
             
           })
         }
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Purchased....</h1>
           :null
         }
        </div>
      )}

{openMenu3 && ( 
        <div id='zero3' className='onStep fadeIn'>
            <div  className='grid_explore_container'>
         {
           mintedData?.map((item,i)=>{

            if(item?.userId===userId){
              if(item?.isSold){
              return(
                <NftSoldCard nft={item} onImgLoad={onImgLoad} />
              )
              }
            }else{
              <h1>No data found...</h1>
            }
                    
                

              //  if(item.userId?._id===userId){
              //       return (
                        
              //         <NftCard nft={item} onImgLoad={onImgLoad} />
              //       )
              //  }
             
           })
         }
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Purchased....</h1>
           :null
         }
        </div>
      )}
      </section>


  <Footer />
</div>
  :
  <div className="profile-container-section">

{ author.banner && 
    <section id='profile_banner' className='jumbotron breadcumb no-bg ' style={{backgroundImage: `url(${userContent?.coverPicture?userContent?.coverPicture:api.baseUrl + author.banner.url})`, backgroundSize:'cover',
    backgroundPosition:'center'}}>
      <div className='mainbreadcumb'>
      </div>
    </section>
  }
  <section className='container no-bottom '>
    <div className='row'>
      <div className='spacer-double'></div>
      <div className="col-md-12">
         <div className="d_profile de-flex">
              <div className="de-flex-col">
                  <div className="profile_avatar">
                    <div className="profile_image_container">
                      {
                        userContent?.profilePicture?
                                              <img src={userContent?.profilePicture} alt={userContent?.name} style={{border:'2px solid grey',borderRadius:'50%',boxShadow:'2px 2px 10px grey'}}/>
           :
                         <Avatar name={userContent?.name} size="100" round="50px"/>
                      }
                    
                      <i className="fa fa-check tickMark" ></i>
                      </div>
                      <div className="profile_name">
                          <h4 className="black-color">
                            {userContent?.name}                                          
                              <span className="profile_username black-color">{userContent?.username}</span>
                              <span id="wallet" className="profile_wallet black-color">{walletAddress}</span>
                              <button id="btn_copy" title="Copy Text" onClick={handleCopyClick}>{isCopied ? 'Copied!' : 'Copy'}</button>
                          </h4>
                      </div>
                  </div>
              </div>
              {/* <div className="profile_follow de-flex">
                  <div className="de-flex-col">
                    <div className="profile_follower">{author.followers} followers</div>
                  </div>
                  <div className="de-flex-col">
                      <span className="btn-main">Follow</span>
                  </div>
              </div> */}

          </div>
      </div>
    </div>
  </section>

  <section className='container no-top'>
        <div className='row'>
          <div className='col-lg-12'>
              <div className="items_filter">
                <ul className="de_nav text-left">
                    <li id='Mainbtn' className="active"><span className="btn-main" onClick={handleBtnClick}>On Sale</span></li>
                    <li id='Mainbtn1' className=""><span className="btn-main" onClick={handleBtnClick1}>Created</span></li>
                    <li id='Mainbtn2' className=""><span className="btn-main" onClick={handleBtnClick2}>Purchased Items</span></li>
                    <li id='Mainbtn3' className=""><span className="btn-main" onClick={handleBtnClick3}>Sold Item</span></li>
                </ul>
            </div>
          </div>
        </div>
      {openMenu && (  
        <div id='zero1' className='onStep fadeIn'>
           <div  className='grid_explore_container'>
         {
           mintedData?.map((item,i)=>{
               if(item?.userId?._id===userId && item?.isVerified){
                 if(!item?.buyTransaction){
                  return(
                    <NftSale nft={item} onImgLoad={onImgLoad} />
              )
                 }
               }
           })
         }
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Minted/Created....</h1>
           :null
         }
        </div>
      )}
      {openMenu1 && ( 
        <div id='zero2' className='onStep fadeIn'>
           <div  className='grid_explore_container'>
         {
           mintedData?.map((item,i)=>{
               if(item.userId?._id===userId){
                return   item?.mintTransaction&&                   
                  <NftCreatedCard isApproved={item?.isVerified} nft={item} onImgLoad={onImgLoad} />                    
               }
             
           })
         }
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Minted/Created....</h1>
           :null
         }
        </div>
      )} 
      {openMenu2 && ( 
        <div id='zero3' className='onStep fadeIn'>
            <div  className='grid_explore_container'>
         {/* {
           mintedData?.map((item,i)=>{
                    
                 
                          if(userId!==item?.userId){
                         if(item?.buyTransaction?.from===currentMetamaskAddress){
                           return(
                            <NftPurchasedCard nft={item} onImgLoad={onImgLoad} />
                           )
                         }
                        }
                   })

              //  if(item.userId?._id===userId){
              //       return (
                        
              //         <NftCard nft={item} onImgLoad={onImgLoad} />
              //       )
              //  }
             
           } */}



{
           mintedData?.map((item,i)=>{
                    
                 return  item?.owners?.map(data=>{
                       console.log(data,userId);
                         if(data===userId){
                           return(
                            <NftPurchasedCard nft={item} onImgLoad={onImgLoad} />
                           )
                         }
                   })

              //  if(item.userId?._id===userId){
              //       return (
                        
              //         <NftCard nft={item} onImgLoad={onImgLoad} />
              //       )
              //  }
             
           })
         }
         
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Purchased....</h1>
           :null
         }
        </div>
      )} 

{openMenu3 && ( 
        <div id='zero3' className='onStep fadeIn'>
            <div  className='grid_explore_container'>
         {
           mintedData?.map((item,i)=>{
            if(item?.userId?._id===userId){
              if(item?.isSold){
              return(
                <NftSoldCard nft={item} onImgLoad={onImgLoad} />
              )
              }
            }else{
              <h1>No data found...</h1>
            }
                    
                

              //  if(item.userId?._id===userId){
              //       return (
                        
              //         <NftCard nft={item} onImgLoad={onImgLoad} />
              //       )
              //  }
             
           })
         }
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Purchased....</h1>
           :null
         }
        </div>
      )}
      </section>


  <Footer />
</div>
}
</>
);
}
export default memo(Colection);