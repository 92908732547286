import React, { Component, useState, useEffect, useCallback } from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormControl from '@mui/material/FormControl';
import Clock from "../components/Clock";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import "../pages/style.css";
import contractABI from "../../web3/metamaskConnection/ABI/contractABI.json";
import contractAddress from "../../web3/metamaskConnection/contractAddress/contractAddress";
import Select from "react-select";
import FormData from "form-data";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from "axios";
import './create2.css';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { pinJSONToIPFS } from "../../web3/metamaskConnection/pinata/pinata";
import constant from "../../constants";
import Axios from "../../api-instance";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../assets/loader/giphy.gif";
import NormalLoader from "../../assets/loader/load.gif";
import TopFilterBar from "../components/TopFilterBar";
import TopStatus from "../components/TopStatus";
import { useDispatch } from "react-redux";
import {
  filterCategories,
  filterStatus,
  filterItemsType,
  filterNftTitle,
} from "../../store/actions";
import Typography from '@mui/material/Typography';
import newContractAddress from "../../web3/metamaskConnection/contractAddress/binance_auction_contract_address";
import newContractABI from "../../web3/metamaskConnection/ABI/newAuctionABI.json";
import polygonContractAddress from "../../web3/metamaskConnection/contractAddress/polygonContractAddress";
import polygonContractABI from "../../web3/metamaskConnection/ABI/polygonContractABI.json";
import polygonBuyContractAddress from "../../web3/metamaskConnection/contractAddress/polygonBuyContract";
import polygonBuyContractABI from "../../web3/metamaskConnection/ABI/polygonBuyABI.json";
import binanceContractAddress from '../../web3/metamaskConnection/contractAddress/binance_contract_address'
import binanceBuyContractAddress from "../../web3/metamaskConnection/contractAddress/binance_buy_contract_address";
import binanceContractABI from '../../web3/metamaskConnection/ABI/binance_contract.json'
import binanceBuyContractABI from '../../web3/metamaskConnection/ABI/binance_buy_contract.json'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';

import { useSelector } from "react-redux";
import cancel from "../../assets/loader/cancel.webp";
// import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import buyContractAddress from "../../web3/metamaskConnection/contractAddress/buyContractAddress";
import buyContractABI from "../../web3/metamaskConnection/ABI/buyABI.json";
import TreeItem from "@mui/lab/TreeItem";
import tick from "../../assets/loader/tick.webp";
import {
  networks,
  categories,
} from "../../../src/components/components/constants/filters";
import Web3 from "web3";
// import { ethers} from "ethers";
// var ethers = require("ethers");

import * as IPFS from "ipfs-core";

import nftLoader from '../../assets/output-onlinegiftools.gif'
import nftLoader1 from '../../assets/output-onlinegiftools (1).gif'
import { useNavigate } from "@reach/router";
import { ethers } from "ethers";
import { Collections } from "@mui/icons-material";
const Buttons = styled(Button)(({ theme }) => ({
  width: "80px",
  height: "80px",
  backgroundColor: "transparent",
  boxShadow: "none",
  border: "1px dotted rgba(0, 0, 0, 0.23)",
  '& svg': {
    fill: "black"
  }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    border: "none",
    '& button': {
      width: "100px",
      height: "100px",
      backgroundColor: "transparent",
      border: "1px dotted rgba(0, 0, 0, 0.23)"
    }
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    '& button': {
      width: "100%",
      color: "white",
      backgroundColor: "#1976d2",
      borderRadius: "10px",
      transition: "none"
    }
  },
  '& .MuiDialog-paper': {
    width: "500px",
    minWidth: "300px",
    margin: "auto",
    height: "550px",
    overflow: "auto",
    borderRadius: "10px",
    padding: "10px",
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: "rgba(0, 0, 0, 0.23)"
    },
    '& ::-webkit-scrollbar': {
      width: "5px !important",
      borderRadius: "5px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "300px",
    },

  },
  '& .MuiDialogTitle-root': {
    '& h6': {
      fontWeight: "600"
    },
    '& span': {
      fontSize: "12px !important",
      fontWeight: "300 !important",
      lineHeight: "2px !important",
      color: "rgba(0,0,0,0.30) !important",
    },
    '& h2': {
      fontSize: "12px !important",
      fontWeight: "300 !important",
      lineHeight: "2px !important",
      color: "rgba(0,0,0,0.30) !important",
    }
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// alchemyUrl for polygon
// const alchemyKey ="https://polygon-mumbai.g.alchemy.com/v2/qlt3u9DeNuef36KsQWwzALRg1x23aBeE";

// const alchemyKey =
// "https://eth-mainnet.g.alchemy.com/v2/E11IswOPCxAsDPDlRkg7KV-RPwkjCafv";

// const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
// const web3 = createAlchemyWeb3(alchemyKey);

// const web3 = new Web3('https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161')
// const polygonWeb3 = new Web3("https://rpc-mumbai.maticvigil.com")

const web3 = new Web3(window.ethereum);
const polygonWeb3 = new Web3(window.ethereum);

// console.log(web3.eth);

const GlobalStyles = createGlobalStyle` 
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const GlobalStylesDark = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid #ff343f;
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: #ff343f;
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 #ff343f;
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: rgba(33, 36, 40, .9);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{
    color: #fff !important;
    background: #ff343f;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{ 
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: #ff343f;
  }
  @media only screen and (max-width: 1199px) { 
    .navbar {
      background: #212428;
    }
  }
`;

const Create = () => {
  var bodyFormData = new FormData();
  var bodyCollectionData = new FormData();

  const [isAuctionContract, setIsAuctionContract] = useState(false);
  const [Cname, setIsCname] = useState(null)
  const [Network, setIsNetwork] = useState();
  const [Token_Symbol, setIsToken_Symbol] = useState(null);
  const [openBidValues, setOpenBidValues] = useState(1000);
  const [isOpenBid, setIsOpenBid] = useState(false);
  const [removePrice, setRemovePrice] = useState(false);

  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState('');
  const [textFieldValue, setTextFieldValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [url, setUrl] = useState(`https://mumbai.polygonscan.com/tx/`)

  const handleFileChange = (event) => {
    let file = event.target.files[0]


    if (file) {
      setSelectedFile(event.target.files[0]);

      // Create a URL for the selected file and set it as the image preview
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
      setfileWarning(false)
    } else {
      setSelectedFile(null);
      setImagePreview(null);
    }
  };

  // const handleFileChange = (event) => {
  //   const files = event.target.files;

  //   if (files.length > 0) {
  //     const selected = Array.from(files);
  //     setSelectedFile(selected);

  //     // Create an array of data URLs for the selected images
  //     const previews = selected.map((file) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       return new Promise((resolve) => {
  //         reader.onload = () => {
  //           resolve(reader.result);
  //         };
  //       });
  //     });

  //     Promise.all(previews).then((imagePreview) => {
  //       setImagePreview(imagePreview);
  //     });
  //   } else {
  //     setSelectedFile([]);
  //     setImagePreview([]);
  //   }
  // };

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setSelectedFiles([...selectedFiles, ...files]);
  // };

  const removeFile = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);
  };
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleTextFieldChange = (event) => {
    setIsCname(event.target.value);
    setCnameWarning(false)
  };
  const handleTextFieldChangenetwork = (Network) => {
    setIsNetwork(Network);
    setNetworksWarning(false)
  };
  const handleTextFieldChangesymbol = (event) => {
    setIsToken_Symbol(event.target.value);
    setToken_SymbolWarning(false)
  };

  const handleShow = () => {
    document.getElementById("tab_opt_1").classList.add("show");
    document.getElementById("tab_opt_1").classList.remove("hide");
    document.getElementById("tab_opt_2").classList.remove("show");
    document.getElementById("btn1").classList.add("active");
    document.getElementById("btn2").classList.remove("active");
    document.getElementById("btn3").classList.remove("active");
    setIsAuctionContract(false);
    setIsOpenBid(false);
    setRemovePrice(false);
  };
  const handleShow1 = () => {
    document.getElementById("tab_opt_1").classList.add("hide");
    document.getElementById("tab_opt_1").classList.remove("show");
    document.getElementById("tab_opt_2").classList.add("show");
    document.getElementById("btn1").classList.remove("active");
    document.getElementById("btn2").classList.add("active");
    document.getElementById("btn3").classList.remove("active");
    setIsAuctionContract(true);
    setIsOpenBid(false);
    setRemovePrice(true);
  };
  const handleShow2 = () => {
    document.getElementById("tab_opt_1").classList.add("hide");
    document.getElementById("tab_opt_1").classList.remove("show");
    document.getElementById("tab_opt_2").classList.add("show");
    document.getElementById("btn1").classList.remove("active");
    document.getElementById("btn3").classList.add("active");
    document.getElementById("btn2").classList.remove("active");
    setIsAuctionContract(true);
    setOpenBidValues(1);
    setIsOpenBid(true);
    setRemovePrice(true);
  };

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [sendImage, setSendImage] = useState(null);
  //warning for undefined field in minting
  const [imageWarning, setImageWarning] = useState(false);
  const [nameWarning, setNameWarning] = useState(false);
  const [descriptionWarning, setDescriptionWarning] = useState(false);
  const [priceWarning, setPriceWarning] = useState(false);
  const [categoryWarning, setCategoryWarning] = useState(false);
  const [collectionWarning, setCollectionWarning] = useState(false);
  const [minBidValueWarning, setMinBidValueWarning] = useState(false);
  const [auctionFromDateWarning, setAuctionFromDateWarning] = useState(false);
  const [auctionToDateWarning, setAuctionToDateWarning] = useState(false);
  const [ipfsUrl, setIpfsUrl] = useState("");
  const [ipfsHash, setIpfsHash] = useState("");
  const [modelUrl, setModelUrl] = useState("");
  const [open, setOpen] = React.useState(false);
  const [collection_Name, setCollection_name] = useState("")
  const [collectionoptions, setCollectionoptions] = useState([])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //switching chain network using chain id
  // const collectionoptions = [
  //   { value: 'trees', label: 'Trees' },
  //   { value: 'birds art', label: 'Birds Art' },
  //   { value: 'cat man collection', label: 'Cat Man Collection' },
  //   { value: 'astronaut', label: 'Astronaut' },

  // ]
  const userValue = useSelector(
    (state) => state?.userReducer?.user?.user?.data
  );

  // console.log(userValue,'uservalue');

  const onChangePicture = async (e) => {
    try {
      setImageWarning(false);
      if (e.target.files[0]) {
        setPicture(e.target.files[0]);
        setSendImage(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        if (assetTypeData === "3D") {
          //       const ipfs =  await window.IpfsCore.create()
          //       let added = await ipfs.add(e.target.files[0]);

          //  let a =  await ipfs.pin.add(added.path)
          //  console.log(Object.fromEntries(a._baseCache).z);
          const ipfs = await IPFS.create();
          // await ipfs.files.mkdir('/Batchimages')
          // const cid = await ipfs.add(e.t;arget.files[0]);
          let added = await ipfs.add(e.target.files[0]);
          let a = await ipfs.pin.add(added.path);
          setIpfsHash(Object.fromEntries(a._baseCache).z);
          // console.log();
          setModelUrl(URL.createObjectURL(e.target.files[0]));
          return setIpfsUrl(
            `https://cloudflare-ipfs.com/ipfs/${Object.fromEntries(a._baseCache).z
            }`
          );
        }
        reader.readAsDataURL(e.target.files[0]);
        const ipfs = await IPFS.create();

        // await ipfs.files.mkdir('/Batchimages')
        // const cid = await ipfs.add(e.t;arget.files[0]);
        let added = await ipfs.add(e.target.files[0]);
        let a = await ipfs.pin.add(added.path);
        // setIpfsHash(Object.fromEntries(a._baseCache).z)
        // setIpfsUrl(`https://ipfs.io/ipfs/${Object.fromEntries(a._baseCache).z}`);
      }

      //   const ipfs = await IPFS.create();

      //   const ab = await ipfs.add(
      //     {
      //       path: `${e.target.files[0].name}`,
      //       content: e.target.files[0],
      //     },
      //     { wrapWithDirectory: true }
      //   );

      //  console.log(ab);
      //   const url = `https://gateway.pinata.cloud/ipfs/${ab.cid._baseCache.get(
      //     "z"
      //   )}/${e.target.files[0].name}`;
      //   setPicture(url)
    } catch (error) {
      console.log(error);
    }
  };

  const [formContent, setFormContent] = useState({
    item_image: picture,
    item_title: null,
    item_desc: null,
    item_price: null,
    bid_starting_date: null,
    bid_expiration_date: null,
    item_price_bid: null,
    auctionTimeline: null,

  });

  const [userData, setUserData] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const handleTextFieldChangecollection = (collection_Name) => {
    setCollection_name(collection_Name);
    setCollectionWarning(false)
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        // const account = await window.ethereum.request({
        //   method: "eth_requestAccounts",
        //   chainId: "0x1",
        // });
        // const category = await Axios.get(`/category?isDeleted=${false}`);
        // setCategoryData(category?.data?.data);
        const token = window.localStorage.getItem("token");
        if (token) {
          const { data } = await Axios.get(`/auth/getdata/${token}`);
          setUserData(data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, []);


  const [mintingLoader, setMintingLoader] = React.useState(false);
  const [mintingApproved, setMintingApproved] = useState(false);
  const [mintedHash, setMintedHash] = useState(null);

  const [transactionHash, setTransactionHash] = useState("");
  const [transactionFrom, setTransactionFrom] = useState("");
  const [transactionTo, setTransactionTo] = useState("");
  const [transactionBlockNumber, setTransactionBlockNumber] = useState("");
  const [transactionTokenID, setTransactionTokenID] = useState("");
  const [transactionGasUsed, setTransactionGasUsed] = useState("");
  const [ethPriceValue, setEthPriceValue] = useState("");
  const [usdPriceValue, setUsdPriceValue] = useState("");
  const [isEthValue, setIsEthValue] = useState(true);
  const [ethPrice, setEthPrice] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const [equalPrice, setEqualPrice] = useState(false);
  const [auctionTimelineWarning, setAuctionTimelineWarning] = useState(false);
  const [
    auctionTimelineProperWarning,
    setAuctionTimelineProperWarning,
  ] = useState(false);
  const [mintingApproveBox, setMintingApproveBox] = useState(false);
  const [mintingStart, setMintingStart] = useState(false);
  const [approveStart, setApproveStart] = useState(false);
  const [makeMarketStart, setMakeMarketStart] = useState(false);
  const [errorHandling, setErrorHandling] = useState(false);
  const [rejectWarning, setRejectWarning] = useState(null);
  const [isCategory, setIsCategory] = useState(false);
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [mainCategoryNewValue, setMainCategoryNewValue] = useState("");
  const [subCategoryNewValue, setSubCategoryNewValue] = useState("");
  const [assetTypeData, setAssetTypeData] = useState(null);
  const [isAssetSelected, setIsAssetSelected] = useState(false);

  const dispatch = useDispatch();

  const handleCategory = useCallback(
    (option) => {
      setIsCategory(false);
      setMainCategoryNewValue(option?.value);
      if (option?.subCategory?.length > 0) {
        const options = option?.subCategory?.map((item, i) => {
          return {
            value: item,
            label: item,
          };
        });
        setSubCategoryData(options);
        setIsCategory(true);
      }
      setCategory(option);
      const { value } = option;
      dispatch(filterCategories({ value, singleSelect: true }));
    },
    [dispatch]
  );

  const handleCategoryNew = async (e) => {
    setMainCategoryNewValue(e.value);
  };

  const handleNetworks = async (e) => {
    setNetworkWarning(false);
    setNetworkValue(e.value);
    getCollections(e.value)
    if (e.value === "polygon") {
      // const account = await window.ethereum.request({
      //   method: "signTypedData_v1"
      // });
      const account = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: web3.utils.toHex(80001) }],
      });
      setUrl(`https://mumbai.polygonscan.com/tx/`)
    }
    if (e.value === "ethereum") {
      const account = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }],
      });
      setUrl(`https://etherscan.io/tx/`)
    }
    if (e.value === "binance") {
      const account = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }],
      });
      setUrl(`https://bscscan.com/tx/`)
    }
  };

  const handleSubCategory = useCallback(
    (option) => {
      setCategory(option);
      setSubCategoryNewValue(option?.value);
      const { value } = option;
      dispatch(filterCategories({ value, singleSelect: true }));
    },
    [dispatch]
  );

  const handleStatus = useCallback(
    (option) => {
      const { value } = option;
      setStatus(option);
      dispatch(filterStatus({ value, singleSelect: true }));
    },
    [dispatch]
  );

  const handleAssetType = async (data) => {
    setIsAssetSelected(true);
    setAssetTypeData(data?.value);
  };

  const [categoryValue, setCategoryValue] = useState("");

  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [categoryMain, setCategoryMain] = useState("");
  const [mainCategoryValue, setMainCategoryValue] = useState("");
  const [networkWarning, setNetworkWarning] = useState(false);
  const [networkValue, setNetworkValue] = useState("");
  const [fileWarning, setfileWarning] = useState(false);
  const [CnameWarning, setCnameWarning] = useState(false);
  const [NetworksWarning, setNetworksWarning] = useState(false);
  const [Token_SymbolWarning, setToken_SymbolWarning] = useState(false);

  const customStyles = {
    option: (base, state) => ({
      ...base,
      background: "white !important",
      color: "black !important",
      borderRadius: state.isFocused ? "0" : 0,
      "&:hover": {
        background: "#eee",
      },
      cursor: "pointer",
      border: "1px solid grey ",
      borderBottom: "none",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      marginTop: 0,
      cursor: "pointer",
      // border:"1px solid grey"
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      cursor: "pointer",
      border: "1px solid grey",
    }),

    control: (base, state) => ({
      ...base,
      background: "transparent",
      // match with the menu
      // borderRadius: state.isFocused ? "3px 3px 2px 2px" : 3,
      // Overwrittes the different states of border
      border: "0.5px solid grey",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "#cccccc" : "#cccccc"
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "black",
    }),
  };

  const handleContractPolygon = async () => {
    console.log("polygon");
    let mainValue;
    let subValue;

    if (mainCategoryNewValue) {
      mainValue = mainCategoryNewValue;
      subValue = subCategoryNewValue;
    } else if (!mainCategoryNewValue) {
      mainValue = categoryValue;
    }

    if (subCategoryNewValue === undefined) {
      subValue = mainCategoryNewValue;
    }
    if (sendImage === null) {
      return setImageWarning(true);
    }
    if (formContent.item_title === null) {
      return setNameWarning(true);
    }
    if (formContent.item_desc === null) {
      return setDescriptionWarning(true);
    }

    if (networkValue === "") {
      return networkWarning(true);
    }

    if (mainValue === "") {
      return setCategoryWarning(true);
    }

    if (!removePrice) {
      if (formContent.item_price === null) {
        return setPriceWarning(true);
      }
    }

    if (isAuctionContract) {
      //  if(formContent.bid_expiration_date===null){
      //   return setAuctionToDateWarning(true)
      //  }
      //  if(formContent.bid_starting_date===null){
      //   return setAuctionFromDateWarning(true)
      //  }
      //  if(formContent.item_price_bid===null){
      //   return setMinBidValueWarning(true)
      //  }
      //  if(formContent?.item_price !==formContent?.item_price_bid){
      //   return setEqualPrice(true)
      // }

      if (isAuctionContract) {
        if (!isOpenBid) {
          if (formContent?.auctionTimeline === null) {
            return setAuctionTimelineWarning(true);
          }

          if (formContent?.auctionTimeline <= 0) {
            return setAuctionTimelineProperWarning(true);
          }
        }
      }
    }

    setMintingLoader(true);
    document.body.style.overflow = "hidden";

    // const accounts = await window.ethereum.request({
    //   method: "wallet_switchEthereumChain",
    //   params:[{ chainId: '0x4' }]
    // });

    const account = await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [{ chainId: "0x80001" }],
    });

    bodyFormData.append("name", formContent.item_title);
    bodyFormData.append("description", formContent.item_desc);
    // bodyFormData.append("ipfsHash",ipfsHash)
    bodyFormData.append("blockChain", "MATIC");

    if (removePrice) {
      bodyFormData.append("price", formContent.item_price_bid);
    } else {
      bodyFormData.append("price", formContent.item_price);
    }
    bodyFormData.append("ProductImage", sendImage);
    bodyFormData.append("userId", userValue?._id);
    bodyFormData.append("creator", userValue?._id);
    bodyFormData.append("category", mainValue);
    bodyFormData.append("collection_name", collection_Name.value);
    bodyFormData.append("subCategory", "-");
    //  bodyFormData?.append('status',status?.value)
    bodyFormData?.append("status", "buy_now");
    // bodyFormData?.append("network",networkValue)

    try {
      const contractInstance = new polygonWeb3.eth.Contract(
        polygonContractABI,
        polygonContractAddress
      );
      const buyContractInstance = new polygonWeb3.eth.Contract(
        polygonBuyContractABI,
        polygonBuyContractAddress
      );

      if (isAuctionContract) {
        setMintingLoader(true);
        // bodyFormData.append('auctionFromDate',formContent.bid_starting_date)
        // bodyFormData.append('auctionToDate',formContent.bid_expiration_date)
        // bodyFormData.append('timeDuration',formContent.auctionTimeline)
        bodyFormData.append("auctionMinBid", formContent.item_price_bid);
        bodyFormData.append("isAuction", true);

        const data = await Axios.post(`/product`, bodyFormData, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        });

        const formToJson = {
          name: formContent.item_title,
          description: formContent.item_desc,
          price: formContent.item_price,
          image: data?.data?.data?.ProductImage,
          fromAuction: formContent.bid_starting_date,
          toAuction: formContent.bid_expiration_date,
          minBidValueWarning: formContent.item_price_bid,
          // isVerified:
        };
        const pinataResponse = await pinJSONToIPFS(formToJson);

        const transactionGetID = await contractInstance.methods
          .mintToken(pinataResponse.pinataUrl)
          .send({
            from: account[0],
            chainId: "0x80001",
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gas: await contractInstance.methods
              .mintToken(pinataResponse.pinataUrl)
              .estimateGas({ from: account[0] }),
          });

        // 0x80001
        const auctionContractInstance = new polygonWeb3.eth.Contract(
          newContractABI,
          newContractAddress
        );
        // const auctionStarting = await auctionContractInstance.methods.startauction(contractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId,ethersethers.utils.parseEther(`${formContent?.item_price}`)._hex).send({from:account[0],chainId:'0x4'})
        // const approveToken = await contractInstance.methods.approve(newContractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId).send({from:account[0],chainId:'0x4'})

        // if(approveToken){
        //   const startAuc= await auctionContractInstance.methods.start().call()
        //   console.log(startAuc);
        // }

        if (transactionGetID) {
          const singleMint = await Axios.put(
            "/product",
            {
              _id: data?.data?.data?._id,
              mintTransaction: transactionGetID,
              openBid: false,
            },
            { "Content-type": "application/json" }
          );

          setTransactionHash(transactionGetID?.transactionHash);
          setTransactionFrom(transactionGetID?.from);
          setTransactionTo(transactionGetID?.to);
          setTransactionBlockNumber(transactionGetID?.blockNumber);
          setTransactionGasUsed(transactionGetID?.gasUsed);
          setTransactionTokenID(
            transactionGetID?.events?.Transfer?.returnValues?.tokenId
          );

          if (isAuctionContract) {
            if (!isOpenBid) {
              const singleMint = await Axios.put(
                "/product",
                {
                  _id: data?.data?.data?._id,
                  mintTransaction: transactionGetID,
                  openBid: false,
                },
                { "Content-type": "application/json" }
              );

              const datas = await Axios.post(
                `/auction`,
                {
                  mintId:
                    transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                  productId: singleMint?.data?.data?._id,
                  userId: singleMint?.data?.data?.userId,
                  timeDuration: formContent?.auctionTimeline,
                  openBid: false,
                  timeBid: true,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                  },
                }
              );
            }

            const sendMail = await Axios.post(
              "/product/sendMailFirst?title=mint",
              {
                fromID: userValue?._id,
                assetID: data?.data?.data?._id,
              }
            );
            setMintingLoader(false);
          }

          if (isAuctionContract) {
            if (isOpenBid) {
              const singleMint = await Axios.put(
                "/product",
                {
                  _id: data?.data?.data?._id,
                  mintTransaction: transactionGetID,
                  openBid: true,
                },
                { "Content-type": "application/json" }
              );

              const datas = await Axios.post(
                `/auction`,
                {
                  mintId:
                    transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                  productId: singleMint?.data?.data?._id,
                  userId: singleMint?.data?.data?.userId,
                  timeDuration: openBidValues,
                  openBid: true,
                  timeBid: false,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                  },
                }
              );
            }
            setMintingLoader(false);
          }
        }

        //       const approveToken = await contractInstance.methods.approve(newContractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId).send({from:account[0],chainId:'0x4'})

        //       const auctionStarting = await auctionContractInstance.methods.startauction(contractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId,ethersethers.utils.parseEther(`${formContent?.item_price}`)._hex).send({from:account[0],chainId:'0x4'})
        // if(auctionStarting){
        //   const startAuctionContract = await auctionContractInstance.methods.start().send({from:account[0]});
        // }
        setMintingApproveBox(false);
        setMintingApproved(true);
        setMintingLoader(false);
        toast.success(`Your Assert- ${formContent?.item_title} Minted Successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          navigate('/Explore')
        }, 9000);
        window.document.body.style.overflow = "hidden";
      } else if (!isAuctionContract) {
        const data = await Axios.post(`/product`, bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        });
        const formToJson = {
          name: formContent.item_title,
          description: formContent.item_desc,
          price: formContent.item_price,
          image: data?.data?.data?.ProductImage,
        };
        const pinataResponse = await pinJSONToIPFS(formToJson);
        setMintingLoader(false);
        setMintingApproveBox(true);

        const transactionGetID = await contractInstance.methods
          .mintToken(pinataResponse.pinataUrl)
          .send({
            from: account[0],
            chainId: "0x80001",
            gasPrice: `${await polygonWeb3.eth.getGasPrice()}`,
            gas: await contractInstance.methods
              .mintToken(pinataResponse.pinataUrl)
              .estimateGas({ from: account[0] }),
          });

        if (transactionGetID) {
          setMintingStart(true);
          const approveTokenTransactionParameter = {
            to: buyContractAddress,
            from: account[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gas: await contractInstance.methods
              .approve(
                buyContractAddress,
                transactionGetID?.events?.Transfer?.returnValues?.tokenId
              )
              .estimateGas({ from: account[0] }),
          };

          const approveToken = await contractInstance.methods
            .approve(
              buyContractAddress,
              transactionGetID?.events?.Transfer?.returnValues?.tokenId
            )
            .send(approveTokenTransactionParameter);

          if (approveToken) {
            setApproveStart(true);
            const nonce = await web3.eth.getTransactionCount(
              account[0],
              "latest"
            ); // nonce starts counting from 0
            const transactionParameters = {
              to: buyContractAddress, // Required except during contract publications.
              from: account[0], // must match user's active address.
              // nonce: `${nonce}`, // ignored by MetaMask
              gasPrice: `${await web3.eth.getGasPrice()}`,
              gasLimit: await buyContractInstance.methods
                .makeMarketItem(
                  polygonContractAddress,
                  transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                  web3.utils.toWei(`${formContent?.item_price}`, "ether")
                )
                .estimateGas({ from: account[0] }),
              // customizable by user during MetaMask confirmation.
              value: web3.utils.toWei(`${formContent?.item_price}`, "ether"),
              // value:'0xa',
            };

            const makeMarket = await buyContractInstance.methods
              .makeMarketItem(
                polygonContractAddress,
                transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                web3.utils.toWei(`${formContent?.item_price}`, "ether")
              )
              .send(transactionParameters);
            if (makeMarket) {
              setMakeMarketStart(true);
              const singleMint = await Axios.put(
                "/product",
                {
                  _id: data?.data?.data?._id,
                  mintTransaction: transactionGetID,
                  makeMarketTransaction: makeMarket,
                },
                { "Content-type": "application/json" }
              );

              const sendMail = await Axios.post(
                "/product/sendMailFirst?title=mint",
                {
                  fromID: userValue?._id,
                  assetID: data?.data?.data?._id,
                }
              );
              setMintingLoader(false);
              setMintingApproveBox(false);
              setMintingApproved(true);

              setTransactionHash(transactionGetID?.transactionHash);
              setTransactionFrom(transactionGetID?.from);
              setTransactionTo(transactionGetID?.to);
              setTransactionBlockNumber(transactionGetID?.blockNumber);
              setTransactionGasUsed(transactionGetID?.gasUsed);
              setTransactionTokenID(
                transactionGetID?.events?.Transfer?.returnValues?.tokenId
              );
              toast.success(`Your Assert- ${formContent?.item_title} Minted Successfully`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                navigate('/Explore')
              }, 9000);

              window.document.body.style.overflow = "hidden";
            }
          }
        }

        // 0x80001
      }
    } catch (error) {
      console.log(error);
      if (
        error?.code === 4001 &&
        error.message ===
        "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          // window.location.reload();
        }, 5000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      if (error.code === -32603) {
        setTimeout(() => {
          setErrorHandling(false);
          // window.location.reload();
        }, 5000);
        setErrorHandling(true);
        setRejectWarning("Transaction failed.....");
      }
      setTimeout(() => {
        setErrorHandling(false);
        // window.location.reload();
      }, 5000);
      setErrorHandling(true);
      setRejectWarning(error?.message);
      // return err
      // console.error(err);
      // console.log('====================================');
      //   if(err.message === 'Unrecognized chain ID "0x80001". Try adding the chain using wallet_addEthereumChain first.'){
      //     alert('Please update polygon chain in your browser')
      //     setMintingLoader(false)
      //   }
    }
  };

  const handleContractBinance = async () => {
    console.log("binance");
    console.log(isAuctionContract, isOpenBid, "opens")
    let mainValue;
    let subValue;

    if (mainCategoryNewValue) {
      mainValue = mainCategoryNewValue;
      subValue = subCategoryNewValue;
    } else if (!mainCategoryNewValue) {
      mainValue = categoryValue;
    }

    if (subCategoryNewValue === undefined) {
      subValue = mainCategoryNewValue;
    }
    if (sendImage === null) {
      return setImageWarning(true);
    }
    if (formContent.item_title === null) {
      return setNameWarning(true);
    }
    if (formContent.item_desc === null) {
      return setDescriptionWarning(true);
    }

    if (networkValue === "") {
      return networkWarning(true);
    }

    if (mainValue === "") {
      return setCategoryWarning(true);
    }

    if (!removePrice) {
      if (formContent.item_price === null) {
        return setPriceWarning(true);
      }
    }

    if (isAuctionContract) {
      //  if(formContent.bid_expiration_date===null){
      //   return setAuctionToDateWarning(true)
      //  }
      //  if(formContent.bid_starting_date===null){
      //   return setAuctionFromDateWarning(true)
      //  }
      //  if(formContent.item_price_bid===null){
      //   return setMinBidValueWarning(true)
      //  }
      //  if(formContent?.item_price !==formContent?.item_price_bid){
      //   return setEqualPrice(true)
      // }

      if (isAuctionContract) {
        if (!isOpenBid) {
          if (formContent?.auctionTimeline === null) {
            return setAuctionTimelineWarning(true);
          }

          if (formContent?.auctionTimeline <= 0) {
            return setAuctionTimelineProperWarning(true);
          }
        }
      }
    }

    setMintingLoader(true);
    document.body.style.overflow = "hidden";

    // const accounts = await window.ethereum.request({
    //   method: "wallet_switchEthereumChain",
    //   params:[{ chainId: '0x4' }]
    // });

    const account = await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [{ chainId: "0x38" }],
    });
    bodyFormData.append("name", formContent.item_title);
    bodyFormData.append("description", formContent.item_desc);
    // bodyFormData.append("ipfsHash",ipfsHash)
    bodyFormData.append("blockChain", "BNB");
    if (removePrice) {
      bodyFormData.append("price", formContent.item_price_bid);
    } else {
      bodyFormData.append("price", formContent.item_price);
    }
    bodyFormData.append("ProductImage", sendImage);
    bodyFormData.append("userId", userValue?._id);
    bodyFormData.append("creator", userValue?._id);
    bodyFormData.append("category", mainValue);
    bodyFormData.append("collection_name", collection_Name.value);
    bodyFormData.append("subCategory", "-");
    //  bodyFormData?.append('status',status?.value)
    bodyFormData?.append("status", "buy_now");
    // bodyFormData?.append("network",networkValue)

    try {
      const contractInstance = new polygonWeb3.eth.Contract(
        binanceContractABI,
        binanceContractAddress
      );
      const buyContractInstance = new polygonWeb3.eth.Contract(
        binanceBuyContractABI,
        binanceBuyContractAddress
      );
      if (isAuctionContract) {
        setMintingLoader(true);
        // bodyFormData.append('auctionFromDate',formContent.bid_starting_date)
        // bodyFormData.append('auctionToDate',formContent.bid_expiration_date)
        // bodyFormData.append('timeDuration',formContent.auctionTimeline)
        bodyFormData.append("auctionMinBid", formContent.item_price_bid);
        bodyFormData.append("isAuction", true);
        const data = await Axios.post(`/product`, bodyFormData, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        });
        console.log(data, "datasd")
        const formToJson = {
          name: formContent.item_title,
          description: formContent.item_desc,
          price: formContent.item_price,
          image: data?.data?.data?.ProductImage,
          fromAuction: formContent.bid_starting_date,
          toAuction: formContent.bid_expiration_date,
          minBidValueWarning: formContent.item_price_bid,
        };
        const pinataResponse = await pinJSONToIPFS(formToJson);

        const transactionGetID = await contractInstance.methods
          .mintToken(pinataResponse.pinataUrl)
          .send({
            from: account[0],
            chainId: "0x38",
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gas: await contractInstance.methods
              .mintToken(pinataResponse.pinataUrl)
              .estimateGas({ from: account[0] }),
          });
        console.log("🚀 ~ handleContractBinance ~ transactionGetID:", transactionGetID)

        // 0x80001
        const auctionContractInstance = new polygonWeb3.eth.Contract(
          newContractABI,
          newContractAddress
        );



        // const auctionStarting = await auctionContractInstance.methods.startauction(contractAddress, transactionGetID?.events?.Transfer?.returnValues?.tokenId, ethers.utils.parseEther(`${formContent?.item_price}`)._hex).send({ from: account[0], chainId: '0x61' })
        // const approveToken = await contractInstance.methods.approve(newContractAddress, transactionGetID?.events?.Transfer?.returnValues?.tokenId).send({ from: account[0], chainId: '0x61' })

        // if (approveToken) {
        //   const startAuc = await auctionContractInstance.methods.start().call()
        //   console.log(startAuc);
        // }

        if (transactionGetID) {
          const singleMint = await Axios.put(
            "/product",
            {
              openBid: false,
              _id: data?.data?.data?._id,
              mintTransaction: transactionGetID,

            },
            { "Content-type": "application/json" }
          );
          console.log("🚀 ~ handleContractBinance ~ singleMint:", singleMint)

          setTransactionHash(transactionGetID?.transactionHash);
          setTransactionFrom(transactionGetID?.from);
          setTransactionTo(transactionGetID?.to);
          setTransactionBlockNumber(transactionGetID?.blockNumber);
          setTransactionGasUsed(transactionGetID?.gasUsed);
          setTransactionTokenID(
            transactionGetID?.events?.Transfer?.returnValues?.tokenId
          );
          if (isAuctionContract) {
            if (!isOpenBid) {
              console.log('ifsd')
              const singleMint = await Axios.put(
                "/product",
                {
                  openBid: 'false',
                  _id: data?.data?.data?._id,
                  mintTransaction: transactionGetID,

                },
                { "Content-type": "application/json" }
              );

              const datas = await Axios.post(
                `/auction`,
                {
                  mintId:
                    transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                  productId: singleMint?.data?.data?._id,
                  userId: singleMint?.data?.data?.userId,
                  timeDuration: formContent?.auctionTimeline,
                  openBid: false,
                  timeBid: true,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                  },
                }
              );
            }

            const sendMail = await Axios.post(
              "/product/sendMailFirst?title=mint",
              {
                fromID: userValue?._id,
                assetID: data?.data?.data?._id,
              }
            );
            setMintingLoader(false);
          }

          if (isAuctionContract) {
            console.log("🚀 ~ handleContractBinance ~ isAuctionContract:", isAuctionContract)

            console.log("🚀 ~ handleContractBinance ~ isOpenBid:", isOpenBid)
            if (isOpenBid) {
              console.log("ifsdf")
              const singleMint = await Axios.put(
                "/product",
                {
                  _id: data?.data?.data?._id,
                  mintTransaction: transactionGetID,
                  openBid: true,
                },
                { "Content-type": "application/json" }
              );

              const datas = await Axios.post(
                `/auction`,
                {
                  mintId:
                    transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                  productId: singleMint?.data?.data?._id,
                  userId: singleMint?.data?.data?.userId,
                  timeDuration: openBidValues,
                  openBid: true,
                  timeBid: false,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                  },
                }
              );
            }
            setMintingLoader(false);
          }
        }


        //       const approveToken = await contractInstance.methods.approve(newContractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId).send({from:account[0],chainId:'0x4'})

        //       const auctionStarting = await auctionContractInstance.methods.startauction(contractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId,ethersethers.utils.parseEther(`${formContent?.item_price}`)._hex).send({from:account[0],chainId:'0x4'})
        // if(auctionStarting){
        //   const startAuctionContract = await auctionContractInstance.methods.start().send({from:account[0]});
        // }
        setMintingApproveBox(false);
        setMintingApproved(true);
        setMintingLoader(false);
        window.document.body.style.overflow = "hidden";
      } else if (!isAuctionContract) {
        const data = await Axios.post(`/product`, bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        });
        const formToJson = {
          name: formContent.item_title,
          description: formContent.item_desc,
          price: formContent.item_price,
          image: data?.data?.data?.ProductImage,
        };
        const pinataResponse = await pinJSONToIPFS(formToJson);

        setMintingApproveBox(true);

        const transactionGetID = await contractInstance.methods
          .mintToken(pinataResponse.pinataUrl)
          .send({
            from: account[0],
            chainId: "0x38",
            gasPrice: `${await polygonWeb3.eth.getGasPrice()}`,
            gas: await contractInstance.methods
              .mintToken(pinataResponse.pinataUrl)
              .estimateGas({ from: account[0] }),
          });

        if (transactionGetID) {
          setMintingStart(true);
          const approveTokenTransactionParameter = {
            to: binanceBuyContractAddress,
            from: account[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gas: await contractInstance.methods
              .approve(
                binanceBuyContractAddress,
                transactionGetID?.events?.Transfer?.returnValues?.tokenId
              )
              .estimateGas({ from: account[0] }),
          };

          const approveToken = await contractInstance.methods
            .approve(
              binanceBuyContractAddress,
              transactionGetID?.events?.Transfer?.returnValues?.tokenId
            )
            .send(approveTokenTransactionParameter);

          if (approveToken) {
            setApproveStart(true);
            const nonce = await web3.eth.getTransactionCount(
              account[0],
              "latest"
            ); // nonce starts counting from 0




            const transactionParameters = {
              to: binanceBuyContractAddress, // Required except during contract publications.
              from: account[0], // must match user's active address.
              // nonce: `${nonce}`, // ignored by MetaMask
              gasPrice: `${await web3.eth.getGasPrice()}`,
              gasLimit: await buyContractInstance.methods
                .makeMarketItem(
                  binanceContractAddress,
                  transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                  web3.utils.toWei(`${formContent?.item_price}`, "ether")
                )
                .estimateGas({ from: account[0] }),
              // customizable by user during MetaMask confirmation.
              value: web3.utils.toWei(`${formContent?.item_price}`, "ether"),
              // value:'0xa',
            };
            const makeMarket = await buyContractInstance.methods
              .makeMarketItem(
                binanceContractAddress,
                transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                web3.utils.toWei(`${formContent?.item_price}`, "ether")
              )
              .send({ from: account[0] });

            if (makeMarket) {
              setMakeMarketStart(true);
              const singleMint = await Axios.put(
                "/product",
                {
                  _id: data?.data?.data?._id,
                  mintTransaction: transactionGetID,
                  makeMarketTransaction: makeMarket,
                },
                { "Content-type": "application/json" }
              );

              const sendMail = await Axios.post(
                "/product/sendMailFirst?title=mint",
                {
                  fromID: userValue?._id,
                  assetID: data?.data?.data?._id,
                }
              );
              setMintingLoader(false);
              setMintingApproveBox(false);
              setMintingApproved(true);
              setMintingLoader(false);

              setTransactionHash(transactionGetID?.transactionHash);
              setTransactionFrom(transactionGetID?.from);
              setTransactionTo(transactionGetID?.to);
              setTransactionBlockNumber(transactionGetID?.blockNumber);
              setTransactionGasUsed(transactionGetID?.gasUsed);
              setTransactionTokenID(
                transactionGetID?.events?.Transfer?.returnValues?.tokenId
              );
              toast.success('NFT Minted Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                navigate('/Explore')
              }, 9000);

              window.document.body.style.overflow = "hidden";
            }
          }
        }

        // 0x80001
      }
    } catch (error) {
      console.log(error);
      if (
        error?.code === 4001 &&
        error.message ===
        "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          // window.location.reload();
        }, 5000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      if (error.code === -32603) {
        setTimeout(() => {
          setErrorHandling(false);
          // window.location.reload();
        }, 5000);
        setErrorHandling(true);
        setRejectWarning("Transaction failed.....");
      }
      setTimeout(() => {
        setErrorHandling(false);
        // window.location.reload();
      }, 5000);
      setErrorHandling(true);
      setRejectWarning(error?.message);
      // return err
      // console.error(err);
      // console.log('====================================');
      //   if(err.message === 'Unrecognized chain ID "0x80001". Try adding the chain using wallet_addEthereumChain first.'){
      //     alert('Please update polygon chain in your browser')
      //     setMintingLoader(false)
      //   }
    }
  };

  const handleContract = async () => {
    // const gasPrice = await axios.get(
    //   "https://ethgasstation.info/api/ethgasAPI.json"
    // );
    // console.log(gasPrice?.data?.fast);

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [{ chainId: "0x1" }],
    });

    let mainValue;
    let subValue;
    if (mainCategoryNewValue) {
      mainValue = mainCategoryNewValue;
      subValue = subCategoryNewValue;
    } else if (!mainCategoryNewValue) {
      mainValue = categoryValue;
    }

    if (subCategoryNewValue === undefined) {
      subValue = mainCategoryNewValue;
    }

    if (assetTypeData === null) {
      return setImageWarning(true);
    }
    if (sendImage === null) {
      return setImageWarning(true);
    }

    if (formContent.item_title === null) {
      return setNameWarning(true);
    }
    if (formContent.item_desc === null) {
      return setDescriptionWarning(true);
    }

    // if(networkValue===""){
    //  return networkWarning(true)
    // }

    if (mainValue === "") {
      return setCategoryWarning(true);
    }

    if (!removePrice) {
      if (formContent.item_price === null) {
        return setPriceWarning(true);
      }
    }

    if (isAuctionContract) {
      //  if(formContent.bid_expiration_date===null){
      //   return setAuctionToDateWarning(true)
      //  }
      //  if(formContent.bid_starting_date===null){
      //   return setAuctionFromDateWarning(true)
      //  }
      //  if(formContent.item_price_bid===null){
      //   return setMinBidValueWarning(true)
      //  }
      //  if(formContent?.item_price !==formContent?.item_price_bid){
      //   return setEqualPrice(true)
      // }

      if (isAuctionContract) {
        if (!isOpenBid) {
          if (formContent?.auctionTimeline === null) {
            return setAuctionTimelineWarning(true);
          }

          if (formContent?.auctionTimeline <= 0) {
            return setAuctionTimelineProperWarning(true);
          }
        }
      }
    }

    setMintingLoader(true);
    document.body.style.overflow = "hidden";

    // const accounts = await window.ethereum.request({
    //   method: "wallet_switchEthereumChain",
    //   params:[{ chainId: '0x4' }]
    // });

    const account = await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [{ chainId: "0x1" }],
    });

    bodyFormData.append("name", formContent.item_title);
    bodyFormData.append("description", formContent.item_desc);
    bodyFormData.append("blockChain", "ETH");
    // bodyFormData.append("ipfsHash", ipfsHash);
    // bodyFormData.append("ipfsUrl", ipfsUrl);
    // bodyFormData.append("imageType", assetTypeData);
    if (removePrice) {
      bodyFormData.append("price", formContent.item_price_bid);
    } else {
      bodyFormData.append("price", formContent.item_price);
    }
    bodyFormData.append("ProductImage", sendImage);
    bodyFormData.append("userId", userValue?._id);
    bodyFormData.append("creator", userValue?._id);
    bodyFormData.append("category", mainValue);
    bodyFormData.append("collection_name", collection_Name.value);
    bodyFormData.append("subCategory", "-");
    //  bodyFormData?.append('status',status?.value)
    bodyFormData?.append("status", "buy_now");
    // bodyFormData?.append("network",networkValue)

    try {
      const contractInstance = new web3.eth.Contract(
        contractABI,
        contractAddress
      );
      const buyContractInstance = new web3.eth.Contract(
        buyContractABI,
        buyContractAddress
      );

      if (isAuctionContract) {
        setMintingLoader(true);
        // bodyFormData.append('auctionFromDate',formContent.bid_starting_date)
        // bodyFormData.append('auctionToDate',formContent.bid_expiration_date)
        // bodyFormData.append('timeDuration',formContent.auctionTimeline)
        bodyFormData.append("auctionMinBid", formContent.item_price_bid);
        bodyFormData.append("isAuction", true);

        const data = await Axios.post(`/product`, bodyFormData, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        });

        const formToJson = {
          name: formContent.item_title,
          description: formContent.item_desc,
          price: formContent.item_price,
          image: data?.data?.data?.ProductImage,
          fromAuction: formContent.bid_starting_date,
          toAuction: formContent.bid_expiration_date,
          minBidValueWarning: formContent.item_price_bid,
        };
        const pinataResponse = await pinJSONToIPFS(formToJson);

        const transactionGetID = await contractInstance.methods
          .mintToken(pinataResponse.pinataUrl)
          .send({
            from: account[0],
            chainId: "0x1",
            gasPrice: `${await web3.eth.getGasPrice()}`,
            // gasPrice:'36000000000',
            // gasPrice: gasPrice?.data?.fast + "00000000",
            gas: await web3?.eth?.estimateGas({
              from: account[0],
            }),
            gasLimit: "80000",
          });

        // 0x13881
        const auctionContractInstance = new web3.eth.Contract(
          newContractABI,
          newContractAddress
        );
        // const auctionStarting = await auctionContractInstance.methods.startauction(contractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId,ethersethers.utils.parseEther(`${formContent?.item_price}`)._hex).send({from:account[0],chainId:'0x4'})
        // const approveToken = await contractInstance.methods.approve(newContractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId).send({from:account[0],chainId:'0x4'})

        // if(approveToken){
        //   const startAuc= await auctionContractInstance.methods.start().call()
        //   console.log(startAuc);
        // }

        if (transactionGetID) {
          const singleMint = await Axios.put(
            "/product",
            {
              _id: data?.data?.data?._id,
              mintTransaction: transactionGetID,
              openBid: false,
            },
            { "Content-type": "application/json" }
          );

          setTransactionHash(transactionGetID?.transactionHash);
          setTransactionFrom(transactionGetID?.from);
          setTransactionTo(transactionGetID?.to);
          setTransactionBlockNumber(transactionGetID?.blockNumber);
          setTransactionGasUsed(transactionGetID?.gasUsed);
          setTransactionTokenID(
            transactionGetID?.events?.Transfer?.returnValues?.tokenId
          );

          if (isAuctionContract) {
            if (!isOpenBid) {
              const singleMint = await Axios.put(
                "/product",
                {
                  _id: data?.data?.data?._id,
                  mintTransaction: transactionGetID,
                  openBid: false,
                },
                { "Content-type": "application/json" }
              );

              const datas = await Axios.post(
                `/auction`,
                {
                  mintId:
                    transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                  productId: singleMint?.data?.data?._id,
                  userId: singleMint?.data?.data?.userId,
                  timeDuration: formContent?.auctionTimeline,
                  openBid: false,
                  timeBid: true,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                  },
                }
              );
            }

            const sendMail = await Axios.post(
              "/product/sendMailFirst?title=mint",
              {
                fromID: userValue?._id,
                assetID: data?.data?.data?._id,
              }
            );
            setMintingLoader(false);
          }

          if (isAuctionContract) {
            if (isOpenBid) {
              const singleMint = await Axios.put(
                "/product",
                {
                  _id: data?.data?.data?._id,
                  mintTransaction: transactionGetID,
                  openBid: true,
                },
                { "Content-type": "application/json" }
              );

              const datas = await Axios.post(
                `/auction`,
                {
                  mintId:
                    transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                  productId: singleMint?.data?.data?._id,
                  userId: singleMint?.data?.data?.userId,
                  timeDuration: openBidValues,
                  openBid: true,
                  timeBid: false,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Credentials": "true",
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                  },
                }
              );
            }
            setMintingLoader(false);
          }
        }

        //       const approveToken = await contractInstance.methods.approve(newContractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId).send({from:account[0],chainId:'0x4'})

        //       const auctionStarting = await auctionContractInstance.methods.startauction(contractAddress,transactionGetID?.events?.Transfer?.returnValues?.tokenId,ethersethers.utils.parseEther(`${formContent?.item_price}`)._hex).send({from:account[0],chainId:'0x4'})
        // if(auctionStarting){
        //   const startAuctionContract = await auctionContractInstance.methods.start().send({from:account[0]});
        // }
        setMintingApproveBox(false);
        setMintingApproved(true);
        setMintingLoader(false);
        window.document.body.style.overflow = "hidden";
      } else if (!isAuctionContract) {
        const data = await Axios.post(`/product`, bodyFormData, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        });
        const formToJson = {
          name: formContent.item_title,
          description: formContent.item_desc,
          price: formContent.item_price,
          image: data?.data?.data?.ProductImage,
        };
        const pinataResponse = await pinJSONToIPFS(formToJson);
        setMintingLoader(false);
        setMintingApproveBox(true);

        // console.log({
        //   gasPrice:gasPrice?.data?.fast+"00000000",
        //   gas:await web3?.eth?.estimateGas({
        //     from:account[0],
        //     data:contractInstance.methods
        //     .mintToken(pinataResponse.pinataUrl).encodeABI()
        //   })
        // });

        const transactionGetID = await contractInstance.methods
          .mintToken(pinataResponse.pinataUrl)
          .send({
            from: account[0],
            chainId: "0x1",
            // gasPrice: gasPrice?.data?.fast + "00000000",
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gas: await contractInstance.methods
              .mintToken(pinataResponse.pinataUrl)
              .estimateGas({
                from: account[0],
              }),
          });

        if (transactionGetID) {
          setMintingStart(true);
          const approveTokenTransactionParameter = {
            to: buyContractAddress,
            from: account[0],
            // gasPrice: gasPrice?.data?.fast + "00000000",
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gas: await contractInstance.methods
              .approve(
                buyContractAddress,
                transactionGetID?.events?.Transfer?.returnValues?.tokenId
              )
              ?.estimateGas({
                from: account[0],
              }),
          };

          const approveToken = await contractInstance.methods
            .approve(
              buyContractAddress,
              transactionGetID?.events?.Transfer?.returnValues?.tokenId
            )
            .send(approveTokenTransactionParameter);

          if (approveToken) {
            setApproveStart(true);
            const nonce = await web3.eth.getTransactionCount(
              account[0],
              "latest"
            ); // nonce starts counting from 0
            const transactionParameters = {
              to: buyContractAddress, // Required except during contract publications.
              from: account[0], // must match user's active address.
              // nonce: `${nonce}`, // ignored by MetaMask
              gasPrice: `${await web3.eth.getGasPrice()}`,
              // gasLimit: "800009",
              // gasPrice: gasPrice?.data?.fast + "00000000",
              gas: await buyContractInstance.methods
                .makeMarketItem(
                  contractAddress,
                  transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                  web3.utils.toWei(`${formContent?.item_price}`, "ether")
                )
                ?.estimateGas({
                  from: account[0],
                }),
              // customizable by user during MetaMask confirmation.
              value: web3.utils.toWei(`${formContent?.item_price}`, "ether"),
              // value:'0xa',
            };

            const makeMarket = await buyContractInstance.methods
              .makeMarketItem(
                contractAddress,
                transactionGetID?.events?.Transfer?.returnValues?.tokenId,
                web3.utils.toWei(`${formContent?.item_price}`, "ether")
              )
              .send({ from: account[0] });
            if (makeMarket) {
              setMakeMarketStart(true);
              const singleMint = await Axios.put(
                "/product",
                {
                  _id: data?.data?.data?._id,
                  mintTransaction: transactionGetID,
                  makeMarketTransaction: makeMarket,
                },
                { "Content-type": "application/json" }
              );

              const sendMail = await Axios.post(
                "/product/sendMailFirst?title=mint",
                {
                  fromID: userValue?._id,
                  assetID: data?.data?.data?._id,
                }
              );
              setMintingLoader(false);
              setMintingApproveBox(false);
              setMintingApproved(true);

              setTransactionHash(transactionGetID?.transactionHash);
              setTransactionFrom(transactionGetID?.from);
              setTransactionTo(transactionGetID?.to);
              setTransactionBlockNumber(transactionGetID?.blockNumber);
              setTransactionGasUsed(transactionGetID?.gasUsed);
              setTransactionTokenID(
                transactionGetID?.events?.Transfer?.returnValues?.tokenId
              );
              toast.success('NFT Minted Successfully', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                navigate('/Explore')
              }, 9000);
              window.document.body.style.overflow = "hidden";
            }
          }
        }

        // 0x13881
      }
    } catch (error) {
      console.log(error,"error")
      // if (
      //   error?.code === 4001 &&
      //   error.message ===
      //   "MetaMask Tx Signature: User denied transaction signature."
      // ) {
      //   setTimeout(() => {
      //     setErrorHandling(false);
      //     window.location.reload();
      //   }, 3000);
      //   setErrorHandling(true);
      //   setRejectWarning("Metamask transaction user denied signature....");
      // }
      // if (error.code === -32603) {
      //   setTimeout(() => {
      //     setErrorHandling(false);
      //     window.location.reload();
      //   }, 3000);
      //   setErrorHandling(true);
      //   setRejectWarning("Transaction failed.....");
      // }
      // setTimeout(() => {
      //   setErrorHandling(false);
      //   window.location.reload();
      // }, 3000);
      // setErrorHandling(true);
      // setRejectWarning(error?.message);
     
    }
  };

  const Createcollection = async () => {

    if (selectedFile === null) {
      return setfileWarning(true);
    }
    else if (Cname === null) {
      return setCnameWarning(true);
    }
    // else if (Token_Symbol === null) {
    //   return setToken_SymbolWarning(true);
    // }
    else if (Network === undefined) {
      return setNetworksWarning(true);
    }
    if (Network.value === 'ethereum') {
      bodyCollectionData.append("Token_Symbol", 'ETH');
    } else if (Network.value === 'binance') {
      bodyCollectionData.append("Token_Symbol", 'BNB');
    } else if (Network.value === 'polygon') {
      bodyCollectionData.append("Token_Symbol", 'MATIC');
    }
    bodyCollectionData.append("Collection_Name", Cname);
    bodyCollectionData.append("Network", Network.value);

    bodyCollectionData.append("Image", selectedFile);
    bodyCollectionData.append("user_id", userValue?._id);
    const data = await Axios.post('/product/createCollection', bodyCollectionData, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    }).then(response => {
      // Axios automatically parses the JSON response
      const data = response.data;
      if (data.msg === "success") {
        toast.success("Collection Created Sucessfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setSelectedFile(null)
        setIsCname(null)
        setIsToken_Symbol(null)
        setIsNetwork(null)
        handleClose()
      }
      else {
        toast.error("Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
      .catch(error => {
        console.error('Error:', error);
        toast.error("Error", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      });
  };
  const networkoptions = [
    { value: 'polygon', label: 'Polygon' },
    { value: 'ethereum', label: 'Ethereum' },
    { value: 'binance', label: 'Binance' },
  ];

  const getCollections = async (value) => {
    const datas = {
      userId: userValue?._id,
      network: value
    }
    const { data } = await Axios.post('/product/getUserCollections', datas, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    })
    setCollectionoptions([])
    const dt = []
    for (let i = 0; i < data?.data?.length; i++) {
      const element = data?.data[i]
      dt.push({ value: element?._id, label: element?.Collection_Name })
    }
    setCollectionoptions(dt)
  }

  const [isDark, setIsDark] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("mode") === "dark") {
      setIsDark(true);
    } else if (localStorage.getItem("mode") === "light") {
      setIsDark(false);
    }
  }, []);

  const handleEthSwitch = (e) => {
    if (e.target.value === "USD") {
      setIsEthValue(false);
    } else {
      setIsEthValue(true);
    }
  };

  const handleChange = (e) => {
    setNameWarning(false);
    setDescriptionWarning(false);
    setCategoryWarning(false);
    setPriceWarning(false);
    setAuctionFromDateWarning(false);
    setAuctionToDateWarning(false);
    setMinBidValueWarning(false);
    setEqualPrice(false);
    setAuctionTimelineProperWarning(false);
    setAuctionTimelineWarning(false);

    const { name, value } = e.target;
    setFormContent((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const arr = [];
  // const option=categoryData.map((item,i)=>{
  // return item.subCategory.map((e,index)=>{
  //     arr.push({
  //     value:e,
  //     label:e
  //   })
  //   })
  // })

  const options = categoryData.map((item, i) => {
    return {
      value: item?.categoryName,
      label: item?.categoryName,
      subCategory: item?.subCategory,
    };
  });

  const assetOptions = [
    {
      value: "Image",
      label: "Image",
    },
    {
      value: "3D",
      label: "3D",
    },
  ];

  const maxLength = 12;

  return (
    <>
      <ToastContainer style={{ top: "5em", zIndex: "999" }} />
      {mintingApproved && (
        <div className="checkout" style={{ zIndex: "99" }}>
          <div className="maincheckout modalColor">
            <button
              className="btn-close"
              onClick={() => setMintingApproved(false)}
            >
              x
            </button>
            <div className="heading">
              <p
                style={{
                  color: "black",
                  fontSize: "20px",
                  fontWeight: "700",
                  margin: "0px auto",
                }}
              >
                {" "}
                Your Asset has been Minted Successfully.....
              </p>
              <br />
            </div>
            <p style={{ color: "black" }}>
              Transaction Hash:{" "}
              {transactionHash.length > maxLength
                ? `${transactionHash.slice(
                  0,
                  maxLength - 3
                )}...${transactionHash.slice(-3)}`
                : transactionHash}
            </p>
            <p style={{ color: "black" }}>
              Block Number: {transactionBlockNumber}
            </p>
            <p style={{ color: "black" }}>From Address: {transactionFrom}</p>
            <p style={{ color: "black" }}>Token ID : {transactionTokenID}</p>
            <p style={{ color: "black" }}>Gas Used: {transactionGasUsed}</p>
            <div>
              <button className="btn-main ">
                {" "}
                <a
                  target="_blank"
                  rel="noreferrer"

                  href={`${url}${transactionHash}`}
                >
                  {" "}
                  Click to see transaction details
                </a>
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                style={{ width: "100%" }}
                className="btn-main1 "
                onClick={() => {
                  window.location.href = `${constant.MainURL}/myprofile/${userValue?._id}`;
                }}
              >
                View in Explorer
              </button>
            </div>
          </div>
        </div>
      )}
      {mintingApproved && (
        <div className="checkout" style={{ zIndex: "99" }}>
          <div className="maincheckout modalColor">
            <button
              className="btn-close"
              onClick={() => setMintingApproved(false)}
            >
              x
            </button>
            <div className="heading">
              <p
                style={{
                  color: "black",
                  fontSize: "20px",
                  fontWeight: "700",
                  margin: "0px auto",
                }}
              >
                {" "}
                Your Asset has been Minted Successfully.....
              </p>
              <br />
            </div>
            <p style={{ color: "black" }}>
              Transaction Hash:{" "}
              {transactionHash.length > maxLength
                ? `${transactionHash.slice(
                  0,
                  maxLength - 3
                )}...${transactionHash.slice(-3)}`
                : transactionHash}
            </p>
            <p style={{ color: "black" }}>
              Block Number: {transactionBlockNumber}
            </p>
            <p style={{ color: "black" }}>From Address: {transactionFrom}</p>
            <p style={{ color: "black" }}>Token ID : {transactionTokenID}</p>
            <p style={{ color: "black" }}>Gas Used: {transactionGasUsed}</p>
            <div>
              <button className="btn-main ">
                {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${url}${transactionHash}`}
                >
                  {" "}
                  Click to see transaction details
                </a>
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                style={{ width: "100%" }}
                className="btn-main1 "
                onClick={() => {
                  window.location.href = `${constant.MainURL}/myprofile/${userValue?._id}`;
                }}
              >
                View in Explorer
              </button>
            </div>
          </div>
        </div>
      )}
      {mintingApproveBox && mintingApproveBox ? (
        <div className="checkout">
          <div className="maincheckout modalColor">
            <button className="btn-close" onClick={()=>{setMintingApproveBox(false); toast.error("Minting Cancelled");setTimeout(()=>{ window.location.reload()},2000)}}>x</button>
            <div className="heading">
              <p
                style={{
                  color: "black",
                  fontSize: "20px",
                  fontWeight: "700",
                  margin: "0px auto",
                }}
              >
                Minting In Progress…
              </p>
            </div>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {mintingStart ? (
                <img width="50px" src={tick} alt="startAuction" />
              ) : (
                <img width="50px" src={NormalLoader} alt="startAuction" />
              )}
              <h5
                className="blackColor"
                style={{ textAlign: "left", color: "black" }}
              >
                <span className="blackColor" style={{ color: "black" }}>
                  1. Waiting for Minting...
                </span>
              </h5>
            </div>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {approveStart ? (
                <img width="50px" src={tick} alt="startAuction" />
              ) : (
                <img width="50px" src={NormalLoader} alt="startAuction" />
              )}
              <h5
                className="blackColor"
                style={{ textAlign: "left", color: "black" }}
              >
                <span className="blackColor" style={{ color: "black" }}>
                  2. Waiting for Approval...
                </span>
              </h5>
            </div>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {makeMarketStart ? (
                <img width="50px" src={tick} alt="startAuction" />
              ) : (
                <img width="50px" src={NormalLoader} alt="startAuction" />
              )}
              <h5
                className="blackColor"
                style={{ textAlign: "left", color: "black" }}
              >
                <span className="blackColor" style={{ color: "black" }}>
                  3. Waiting for Market Listing...
                </span>
              </h5>
            </div>
          </div>
        </div>
      ) : null}
      {errorHandling && (
        <div className="checkout">
          <div className="maincheckout modalColor">
            <button className="btn-close">x</button>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <h1
                className="blackColor"
                style={{ color: "red", textAlign: "center" }}
              >
                Error
              </h1>
            </div>
            <p className="blackColor" style={{ textAlign: "center" }}>
              {rejectWarning}
            </p>
          </div>
        </div>
      )}

      {/* {mintingLoader && (
        <div className="loaderContainer">
          <div className="loaderContainerInside">
            <img
              src={Loader}
              alt="loader"
              style={{
                width: "30vw",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            />
          </div>
        </div>
      )} */}
      {mintingLoader && (
        <div className="loaderContainer">
          <div className="loaderContainerInside">
            <img
              src={nftLoader1}
              alt="loader"
              style={{
                width: "30vw",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            />
          </div>
        </div>
      )}

      {isDark ? (
        <div className="greyscheme">
          <GlobalStylesDark />

          <section
            className="jumbotron breadcumb no-bg"
          // style={{
          //   backgroundImage: `url(${"./img/background/subheader.webp"})`,
          // }}
          >
            <div className="mainbreadcumb">
              <div className="container">
                <div className="row m-10-hor">
                  <div className="col-12">
                    <h1 className="text-center">Create</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container">
            <div className="row">
              <div className="col-lg-7 offset-lg-1 mb-5">
                <form id="form-create-item" className="form-border" action="#">
                  <div className="field-set">
                    <div>
                      <h5 className="black-color">Select Asset Type</h5>
                      <Select
                        styles={customStyles}
                        menuContainerStyle={{ zIndex: 999 }}
                        options={[...options]}
                        onChange={handleCategory}
                        className="black-color"
                      />
                    </div>

                    <h5 className="black-color">Upload file</h5>

                    <div className="d-create-file">
                      <p id="file_name">PNG, JPG, GIF. Max 100mb.</p>

                      <div className="browse">
                        <input
                          type="button"
                          id="get_file"
                          className="btn-main"
                          value="Browse"
                        />
                        <input
                          onChange={onChangePicture}
                          id="upload_file"
                          type="file"
                          multiple
                        />
                      </div>
                    </div>

                    <div className="spacer-single"></div>

                    <h5>Select method</h5>
                    <div className="de_tab tab_methods">
                      <ul className="de_nav">
                        <li id="btn1" className="active" onClick={handleShow}>
                          <span>
                            <i className="fa fa-tag"></i>Fixed price
                          </span>
                        </li>
                        <li id="btn2" onClick={handleShow1}>
                          <span>
                            <i className="fa fa-hourglass-1"></i>Timed auction
                          </span>
                        </li>
                        <li id="btn3" onClick={handleShow2}>
                          <span>
                            <i className="fa fa-users"></i>Make Offers
                          </span>
                        </li>
                      </ul>

                      <div className="de_tab_content pt-3">
                        <div id="tab_opt_1">
                          {/* <h5>Price</h5>
                    <input
                      type="text"
                      name="item_price"
                      id="item_price"
                      className="form-control"
                      placeholder="enter price for one item (ETH)"
                    /> */}
                        </div>

                        <div id="tab_opt_2" className="hide">
                          <h5>Minimum bid</h5>
                          <input
                            type="number"
                            name="item_price_bid"
                            id="item_price_bid"
                            className="form-control"
                            placeholder="enter minimum bid"
                          />

                          <div className="spacer-20"></div>

                          {/* <div className="row">
                            <div className="col-md-6">
                              <h5>Starting date</h5>
                              <input
                                type="date"
                                name="bid_starting_date"
                                id="bid_starting_date"
                                className="form-control"
                                min="1997-01-01"
                              />
                            </div>
                            <div className="col-md-6">
                              <h5>Expiration date</h5>
                              <input
                                type="date"
                                name="bid_expiration_date"
                                id="bid_expiration_date"
                                className="form-control"
                              />
                            </div>
                          </div> */}
                        </div>

                        <div id="tab_opt_3"></div>
                      </div>
                    </div>

                    <div className="spacer-20"></div>

                    <div></div>
                    <h5>Title</h5>
                    <input
                      type="text"
                      name="item_title"
                      id="item_title"
                      className="form-control"
                      placeholder="e.g. 'Crypto Funk"
                      onChange={handleChange}
                      value={formContent.item_title}
                    />

                    <div className="spacer-10"></div>

                    <h5>Description</h5>
                    <textarea
                      data-autoresize
                      name="item_desc"
                      id="item_desc"
                      className="form-control"
                      placeholder="e.g. 'This is very limited item'"
                      onChange={handleChange}
                      value={formContent.item_desc}
                    ></textarea>

                    <div className="spacer-10"></div>

                    <h5>Price</h5>
                    <input
                      type="number"
                      name="item_price"
                      id="item_price"
                      className="form-control"
                      placeholder={`enter price for one item ${networkValue === "polygon"
                        ? "(MATIC)"
                        : networkValue === "ethereum"
                          ? "(ETH)"
                          : "(BNB)"
                        }`}
                      onChange={handleChange}
                      value={formContent.item_price}
                    />

                    <div className="spacer-10"></div>

                    {/* <h5>Royalties</h5>
              <input
                type="text"
                name="item_royalties"
                id="item_royalties"
                className="form-control"
                placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%"
              /> */}

                    <div className="spacer-10"></div>

                    <input
                      type="button"
                      id="submit"
                      className="btn-main"
                      value="Create Item"
                      onClick={
                        networkValue === "polygon"
                          ? handleContractPolygon
                          : handleContract
                      }
                    />
                  </div>
                </form>
              </div>

              <div className="col-lg-3 col-sm-6 col-xs-12">
                <h5>Preview item</h5>
                <div className="nft__item m-0 glassCards">
                  {/* <div className="de_countdown">
                    <Clock deadline="December, 30, 2021" />
                  </div> */}
                  <div className="author_list_pp">
                    <span>
                      <img
                        className="lazy"
                        src={imgData ? imgData : "./img/author/author-1.jpg"}
                        alt=""
                      />
                      <i className="fa fa-check"></i>
                    </span>
                  </div>
                  <div className="nft__item_wrap">
                    <span>
                      <img
                        src={imgData ? imgData : "./img/author/author-1.jpg"}
                        id="get_file_2"
                        className="lazy nft__item_preview"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="nft__item_info">
                    <span>
                      <h4>{formContent?.item_title}</h4>
                    </span>
                    <div className="nft__item_price">
                      {formContent?.item_price}
                    </div>
                    {/* <div className="nft__item_action">
                      <span>Place a bid</span>
                    </div> */}
                    {/* <div className="nft__item_like">
                      <i className="fa fa-heart"></i>
                      <span>50</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      ) : (
        <div>
          <GlobalStyles />

          <section
            className="jumbotron breadcumb no-bg "
          // style={{background:'linear-gradient(90deg, rgba(9,60,121,1) 0%, rgba(50,152,208,1) 100%)'}}
          >
            <div className="mainbreadcumb">
              <div className="container">
                <div className="row m-10-hor">
                  <div className="col-12">
                    <h1 className="text-center black-color">Create</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container">
            <div className="row">
              <div className="col-lg-7 offset-lg-1 mb-5">
                <form id="form-create-item" className="form-border" action="#">
                  <div className="field-set">
                    <div>
                      <h5 className="black-color">Select Asset Type</h5>
                      <Select
                        styles={customStyles}
                        menuContainerStyle={{ zIndex: 999 }}
                        options={[...assetOptions]}
                        onChange={handleAssetType}
                        className="black-color"
                      />
                      {imageWarning ? (
                        <p style={{ color: "red", textAlign: "center" }}>
                          *Please Upload the image
                        </p>
                      ) : null}
                    </div>
                    <div className="spacer-single"></div>
                    {isAssetSelected && (
                      <>
                        <h5 className="black-color">Upload file</h5>
                        {assetTypeData === "Image" ? (
                          <div className="d-create-file">
                            <p id="file_name" className="black-color">
                              PNG, JPG, GIF. Max 100mb.
                            </p>

                            <div className="browse">
                              <input
                                type="button"
                                id="get_file"
                                className="btn-main"
                                value="Browse"
                              />
                              <input
                                onChange={onChangePicture}
                                id="upload_file"
                                type="file"
                                multiple
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="d-create-file">
                            <p id="file_name" className="black-color">
                              GLB Objects.
                            </p>

                            <div className="browse">
                              <input
                                type="button"
                                id="get_file"
                                className="btn-main"
                                value="Browse"
                              />
                              <input
                                onChange={onChangePicture}
                                id="upload_file"
                                type="file"
                                multiple
                              />
                            </div>
                          </div>
                        )}

                        {imageWarning ? (
                          <p style={{ color: "red", textAlign: "center" }}>
                            *Please Upload the image
                          </p>
                        ) : null}
                      </>
                    )}

                    <div className="spacer-single"></div>

                    <h5 className="black-color">Select method</h5>
                    <div className="de_tab tab_methods">
                      <ul className="de_nav">
                        <li id="btn1" className="active" onClick={handleShow}>
                          <span className="black-color">
                            <i className="fa fa-tag black-color"></i>Fixed price
                          </span>
                        </li>
                        <li id="btn2" onClick={handleShow1}>
                          <span className="black-color">
                            <i className="fa fa-hourglass-1 black-color"></i>
                            Timed auction
                          </span>
                        </li>
                        <li id="btn3" onClick={handleShow2}>
                          <span className="black-color">
                            <i className="fa fa-users black-color"></i>Make
                            Offers
                          </span>
                        </li>
                      </ul>

                      <div className="de_tab_content pt-3">
                        <div id="tab_opt_1">
                          {/* <h5>Price</h5>
                    <input
                      type="text"
                      name="item_price"
                      id="item_price"
                      className="form-control"
                      placeholder="enter price for one item (ETH)"
                    /> */}
                        </div>

                        <div id="tab_opt_2" className="hide">
                          <h5 className="black-color">Minimum bid</h5>
                          <input
                            type="number"
                            name="item_price_bid"
                            id="item_price_bid"
                            className="form-control"
                            placeholder="enter minimum bid"
                            onChange={handleChange}
                          />
                          {minBidValueWarning ? (
                            <p style={{ color: "red", textAlign: "center" }}>
                              *Please enter this field
                            </p>
                          ) : null}
                          <div className="spacer-20"></div>

                          {isAuctionContract ? (
                            isOpenBid ? (
                              <div>
                                <h5 className="black-color">
                                  Auction Timeline
                                </h5>
                                {/* <input
                                  type="number"
                                  name="auctionTimeline"
                                  id="auctionTimeline"
                                  className="form-control"
                                  placeholder="Enter the timeline for Auction"
                                  value={openBidValues}
                                /> */}
                                <span
                                  className="black-color"
                                  style={{ color: "grey" }}
                                >
                                  The Open bid Auction ends, When the owner
                                  Accepts the highest Bid...
                                </span>
                              </div>
                            ) : (
                              <div>
                                <h5 className="black-color">
                                  Auction Timeline
                                </h5>
                                <input
                                  type="number"
                                  name="auctionTimeline"
                                  id="auctionTimeline"
                                  className="form-control"
                                  placeholder="Enter the timeline for Auction"
                                  onChange={handleChange}
                                  value={formContent.auctionTimeline}
                                />
                                <span style={{ color: "grey" }}>
                                  After Admin approval, The auction will be
                                  started.
                                </span>
                              </div>
                            )
                          ) : null}
                          {auctionTimelineWarning ? (
                            <p style={{ color: "red", textAlign: "center" }}>
                              *Please enter this field.
                            </p>
                          ) : null}
                          {auctionTimelineProperWarning ? (
                            <p style={{ color: "red", textAlign: "center" }}>
                              The Timeline values must be greater than 0.
                            </p>
                          ) : null}
                          {/* <div className="col-md-6">
                              <h5>Starting date</h5>
                              <input
                                type="date"
                                name="bid_starting_date"
                                id="bid_starting_date"
                                className="form-control"
                                min="1997-01-01"
                                onChange={handleChange}
                              />
                              {
                        auctionFromDateWarning?
                       <p style={{color:'red',textAlign:'center'}}>*Please enter this field</p>:null
                     }
                            </div>
                            
                            <div className="col-md-6">
                              <h5>Expiration date</h5>
                              <input
                                type="date"
                                name="bid_expiration_date"
                                id="bid_expiration_date"
                                className="form-control"
                                onChange={handleChange}
                              />
                               {
                        auctionToDateWarning?
                       <p style={{color:'red',textAlign:'center'}}>*Please enter this field</p>:null
                     }
                            </div> */}
                        </div>

                        <div id="tab_opt_3"></div>
                      </div>
                    </div>

                    <div className="spacer-20"></div>

                    <h5 className="black-color">Title</h5>
                    <input
                      type="text"
                      name="item_title"
                      id="item_title"
                      className="form-control"
                      placeholder="e.g. 'Crypto Funk"
                      onChange={handleChange}
                      value={formContent.item_title}
                    />
                    {nameWarning ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Please enter this field
                      </p>
                    ) : null}
                    <div className="spacer-10"></div>

                    <h5 className="black-color">NFT URL:</h5>
                    <input
                      type="text"
                      name="item_title"
                      id="item_title"
                      className="form-control"
                      placeholder="https://ipfs.io/ipfs/QmUDTQwA2Aqr2mwz3JNJbtHgPpVabMVFPMm4pAAYL4SfYA"
                      value={ipfsUrl}
                    />

                    <h5 className="black-color">Description</h5>
                    <textarea
                      data-autoresize
                      name="item_desc"
                      id="item_desc"
                      className="form-control"
                      placeholder="e.g. 'This is very limited item'"
                      onChange={handleChange}
                      value={formContent.item_desc}
                    ></textarea>
                    {descriptionWarning ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Please enter this field
                      </p>
                    ) : null}
                    <div className="spacer-10"></div>

                    {/* <h5>Status</h5> */}
                    {/* <input
                      type="text"
                      name="item_title"
                      id="item_title"
                      className="form-control"
                      placeholder="e.g. 'Crypto Funk"
                      onChange={handleChange}
                      value={formContent.item_title}
                    /> */}

                    {/* <TopStatus handleStatus={handleStatus}></TopStatus>
              
                    <div className="spacer-10"></div> */}

                    {/* <div className="form-control">
                   {
                     isEthValue?
                     <input
                     type="number"
                     name="item_price"
                     id="item_price"
                     style={{width:'40%'}}
                     placeholder="enter price for one item (ETH)"
                     onChange={handleChange}
                     value={formContent.item_price}
                   />:
                     <input
                      type="number"
                      name="item_price"
                      id="item_price"
                      style={{width:'40%'}}
                      placeholder="enter price for one item (USD)"
                      onChange={handleChange}
                      value={formContent.item_price}
                    /> 
                   }
                   {
                     isEthValue?
                     <input
                     type="text"
                     name="item_price"
                     id="item_price"
                     style={{width:'40%'}}
                     value={`${ethPriceValue} USD`}
                     
                   />:
                     <input
                      type="text"
                      name="item_price"
                      id="item_price"
                      style={{width:'40%'}}
                      value={`${usdPriceValue} ETH`}
                       
                    /> 
                   }
                   <select onChange={handleEthSwitch}>  
<option value = "ETH" > ETH </option>  
<option value = "USD" > USD  </option>  
</select>  
                    
                    </div>  */}

                    <h5 className="black-color">Select Network</h5>
                    <Select
                      styles={customStyles}
                      menuContainerStyle={{ zIndex: 999 }}
                      options={[...networks]}
                      onChange={handleNetworks}
                    />

                    {networkWarning ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Please enter this field
                      </p>
                    ) : null}

                    <div className="spacer-40"></div>

                    <h5 className="black-color">Category</h5>
                    <Select
                      styles={customStyles}
                      menuContainerStyle={{ zIndex: 999 }}
                      options={[...categories]}
                      onChange={handleCategoryNew}
                    />
                    {categoryWarning ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Please enter this field
                      </p>
                    ) : null}
                    {isCategory && (
                      <div>
                        <div className="spacer-40"></div>
                        <h5 className="black-color">Sub Category</h5>
                        <Select
                          styles={customStyles}
                          menuContainerStyle={{ zIndex: 999 }}
                          options={[...subCategoryData]}
                          onChange={handleSubCategory}
                        />
                      </div>
                    )}

                    <div className="spacer-40"></div>

                    <h5 className="black-color">Collection <span style={{ "cursor": "pointer" }} onClick={() => setOpen(true)}><InfoOutlinedIcon /></span></h5>

                    <Select

                      name="collection_name"

                      placeholder='Collection Name'
                      onChange={handleTextFieldChangecollection}
                      options={collectionoptions}
                      value={collection_Name}
                    />
                    {collectionWarning ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Please enter this field
                      </p>
                    ) : null}

                    {removePrice ? null : (
                      <>
                        <h5 className="black-color" style={{ "marginTop": "30px" }}>Price</h5>
                        <input
                          type="number"
                          name="item_price"
                          id="item_price"
                          className="form-control"
                          placeholder={`enter price for one item ${networkValue === "BNB" ? "(BNB)" :
                            networkValue === "polygon" ? "(BNB)" : "(BNB)"
                            } 
                          `}
                          onChange={handleChange}
                          value={formContent.item_price}
                        />
                      </>
                    )}

                    {priceWarning ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Please enter this field
                      </p>
                    ) : null}
                    {equalPrice ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Price must be equal to minimum bid price
                      </p>
                    ) : null}
                    <div className="spacer-10"></div>

                    {/* <h5>Royalties</h5>
              <input
                type="text"
                name="item_royalties"
                id="item_royalties"
                className="form-control"
                placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%"
              /> */}

                    <input
                      type="button"
                      id="submit"
                      className="btn-main"
                      value="Create Item"
                      onClick={() => {
                        networkValue === "polygon" && handleContractPolygon();
                        networkValue === "ethereum" && handleContract();
                        networkValue === "binance" && handleContractBinance();
                      }}
                    />
                  </div>
                </form>
              </div>

              <div className="col-lg-3 col-sm-6 col-xs-12">
                <h5 className="black-color">Preview item</h5>
                <div className="nft__item m-0 glassCards">
                  {/* <div className="de_countdown">
                    <Clock deadline="December, 30, 2022" />
                  </div> */}
                  <div className="author_list_pp">
                    <span>
                      <img
                        className="lazy"
                        src={
                          userValue?.profilePicture
                            ? userValue?.profilePicture
                            : "./img/author/author-1.jpg"
                        }
                        alt=""
                      />
                      <i className="fa fa-check"></i>
                    </span>
                  </div>
                  <div className="nft__item_wrap">
                    <span>
                      {assetTypeData === "3D" ? (
                        <model-viewer
                          alt="3d models"
                          src={`${modelUrl}`}
                          ar
                          ar-modes="webxr scene-viewer quick-look"
                          shadow-intensity="1"
                          camera-controls
                          touch-action="pan-y"
                          style={{ width: "100%", height: "200px" }}
                        ></model-viewer>
                      ) : (
                        <img
                          src={imgData ? imgData : "./img/author/author-1.jpg"}
                          id="get_file_2"
                          className="lazy nft__item_preview"
                          alt=""
                        />
                      )}
                    </span>
                  </div>
                  <div className="nft__item_info">
                    <span>
                      <h6 className="black-color">{formContent?.item_title}</h6>
                    </span>
                    <div className="nft__item_price">
                      <span className="black-color">
                        {networkValue === "polygon"
                          ? formContent?.item_price
                            ? "Price : " + formContent?.item_price + " MATIC"
                            : null
                          : formContent?.item_price
                            ? "Price : " + formContent?.item_price + " ETH"
                            : null}
                      </span>
                    </div>
                    <div className="nft__item_action">
                      <span>Place a bid</span>
                    </div>
                    <div className="nft__item_like">
                      <i className="fa fa-heart"></i>
                      <span className="black-color">50</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
              <h5 className="black-color">Create Collection</h5>
              <p className="title_span">Deploying your own contract requires uploading your metadata outside of OpenSea.</p>

            </BootstrapDialogTitle>
            <DialogContent>
              <div className="input_div">
                <p className="black-color">Logo Image</p>
                <div className="file_div">
                  {selectedFile ?

                    <img src={imagePreview} onClick={() => setSelectedFile(null)} alt="Selected" style={{ "width": "150px", "height": "150px", "objectFit": "contain" }} />

                    : <>
                      <input
                        type="file"
                        id="fileInput"
                        multiple
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />

                      <label htmlFor="fileInput">
                        <Buttons
                          variant="contained"
                          component="span"

                        >
                          <AddIcon />
                        </Buttons>
                      </label>
                    </>}

                </div>
                {fileWarning ? (
                  <p style={{ color: "red", textAlign: "center" }}>
                    *Please Upload the image
                  </p>
                ) : null}
              </div>
              <div className="input_div">
                <p className="black-color">Name   </p>
                <TextField fullWidth
                  label="Name"
                  size="small"
                  value={Cname}
                  onChange={handleTextFieldChange}
                />
                {CnameWarning ? (
                  <p style={{ color: "red", textAlign: "center" }}>
                    *Please fill this field
                  </p>
                ) : null}

              </div>

              <div className="input_div">
                <p className="black-color">Blockchain</p>
                <div style={{ width: "100%" }}>
                  <Select
                    label="Blockchain"
                    options={networkoptions}
                    value={Network}
                    onChange={handleTextFieldChangenetwork}
                  />
                </div>
                {NetworksWarning ? (
                  <p style={{ color: "red", textAlign: "center" }}>
                    *Please fill this field
                  </p>
                ) : null}

              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={Createcollection}>
                Create Collection
              </Button>
            </DialogActions>
          </BootstrapDialog>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Create;
