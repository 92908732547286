import React from 'react'
import './Loader.css'
import fraktionLogo from '../../assets/logo/logoSmall.webp'

const Loader = ({dayMode}) => {
    return (
        <div>
            <div className='eze_loader' style={{position:'relative',display:'flex',alignItems:'center',justifyContent:'center',width:'50vw',height:'50vh',overflow:'hidden'}}>
                <img src={fraktionLogo} alt="eze-dark" />
            </div>
        </div>
    )
}

export default Loader
