import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from "../../menu/header";
import Footer from "../../components/footer";
import './HelpCenter.css'
import Axios from "../../../api-instance"
// import togglebtn from '../../img/togglebtn.png'
// import Menuclose from '../../img/Menuclose.png'
import { Link } from "@reach/router";
// import customer from '../../img/customer.svg'
// import maptrace from '../../img/maptrace.svg'
// import guarantee from '../../img/guarantee.svg'
// import bestseller from '../../img/bestseller.svg'
// import bill from '../../img/bill.svg'
// import product from '../../img/product.svg'
// import supplychain from '../../img/supplychain.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
  helpcommoninner: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    textAlign: 'left !important'
  }
});

const DetailPage = () => {

  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)
  const [faqData3, setFaqData3] = useState([])
  const [selectedCategory3, setSelectedCategory3] = useState('');
  const [selectedQuestion3, setSelectedQuestion3] = useState('')
  const [selectedAnswer3, setSelectedAnswer3] = useState('')
  const [selectedFAQ, setSelectedFAQ] = useState(0); // 


  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  const handleCategoryClick3 = (questionId) => {
    const selectedFaq = faqData3.find((faq) => faq._id === questionId);
    console.log(selectedFaq, "faqqq")
    if (selectedFaq) {
      // setSelectedQuestion(selectedFaq.question);
      setSelectedAnswer3(selectedFaq.answer);
    }
    setSelectedFAQ(questionId)
  };

  const faqCreatingList = async () => {
    try {
      const payload = {
        name: 'Creating'

      }
      console.log(payload, "payyy")
      const response = await Axios.post('/admin/getquestByName', payload)
      console.log(response, "checkdata")
      if (response && response.status === 200) {
        const deta = response?.data?.data
        setFaqData3(deta)
        setSelectedAnswer3(deta[0].answer)
      } else {
        console.log("No Data Found")
      }



    } catch (error) {
      console.log(error, "error")

    }
  }

  useEffect(() => {
    faqCreatingList();
  }, []);

  const [faq, setFaq] = useState([
    { name: "How much does it cost to create an NFT?" },
    { name: "What is Lazy Minting?" },
    { name: "Can I edit my collection?" },
    { name: "How to create/mint a new NFT?" },
  ])


  const [val, setVal] = useState(0)

  console.log(val, 'val');
  const [selected, setSelected] = useState(false)
  const handleCloseSelect = () => {
    setSelected(false)
  }

  const handleVal = (ind) => {
    setVal(ind)
    setSelected(true)
    setSelectedFAQ(ind)
  }



  console.log(selectedAnswer3, 'selectedAnswer3');

  return (
    <div className="dashboard-page help-center-main">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="outer-bgcolor-container"
          >
            <Item className={classes.bodyclass}>
              <div className="togglebtn" onClick={openMenuHandler}></div>
              <div
                className="categories-menu"
                id={!menuOpen ? "openmenucls" : ""}
              >
                <div
                  className="togglebtn-close"
                  onClick={closeMenuHandler}
                ></div>
                <div className="categories-menu-inner">
                  {/* <Link><div className='iconic'><img src={customer} alt={customer} /></div>Customer</Link>
    <Link><div className='iconic'><img src={maptrace} alt={maptrace} /></div>Traceability</Link>
    <Link><div className='iconic'><img src={guarantee} alt={guarantee} /></div>Products</Link>
    <Link><div className='iconic'><img src={bestseller} alt={bestseller} /></div>Blockchain</Link>
    <Link><div className='iconic'><img src={bill} alt={bill} /></div>Post-sale</Link>
    <Link><div className='iconic'><img src={product} alt={product} /></div>Billing</Link>
    <Link><div className='iconic'><img src={supplychain} alt={supplychain} /></div>Sellers Authorization </Link> */}
                </div>
              </div>

              <div className="detail-pages-inner-outer">
                <Box sx={{ flexGrow: 1 }}>
                  <div className="breadcrum-helpcenter">
                    <Link to="/helpcenter-new">Help Center</Link> / Creating
                  </div>

                  <Grid
                    container
                    spacing={0}
                    className={classes.helpcenterbodycontain}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="link-left-side-detail-id">
                      {selected ?
                        <>
                          <div style={{ textAlign: 'left', cursor: 'pointer', marginBottom: "30px" }} onClick={handleCloseSelect}>
                            <ArrowBackIcon />
                          </div>

                          <Item className={classes.helpcommoninner}>
                            {/* {selectedQuestion && <h2>{selectedQuestion}</h2>} */}
                            {/* {selectedAnswer1 && <div dangerouslySetInnerHTML={{ __html: selectedAnswer1 }}></div>} */}

                            {val === 0 && <>
                              <p>Our clients often ask, "can I create NFT?" and the simple answer is 'Yes,’ anyone can create an NFT.</p><h3><strong>How Much does it Cost to Create an NFT?</strong></h3><p>Creating your own NFT is exciting as well as profitable in the long run. On average, NFT costs can be as low as $0.01 but go into thousands of dollars.</p><p>For example, you can create NFTs on an Ethereum blockchain, the most expensive of all blockchains costing an average of $70. However, there are many examples where making your own NFT that can cost you more than $500 at its peak time.</p><p>In contrast, Solana is one of the cheapest blockchains where the cost of NFT is approximately $0.01 per token. However, these NFT costs do not include the fee you will pay to the marketplace. Therefore, when you ask how much do NFTs cost to make? Remember to add the marketplace fee of between 2.5% and 5%.</p><p>We advise our customers to ask the following questions before diving into the NFT-verse.</p>
                            </>}
                            {val === 1 && <>
                              <h2><strong>What is Lazy Minting?</strong></h2><p>You can <strong>mint</strong> your own NFT into your crypto wallet using wallexNFTStudio tools. You don’t need to have any coding knowledge to use these tools. To get started, you’ll just need some cryptocurrency for gas fees, and the artwork you’d like to mint into an NFT.</p><p>This step-by-step guide covers how to create an NFT collection and mint directly to your wallet. You can watch a video of this process on our site. If you want the public or your community to be able to mint your NFTs directly into their wallets, learn more in our Wallex NFT.&nbsp;</p>
                            </>}
                            {val === 2 && <>
                              <h2><strong>Can I edit my collection?</strong></h2><p>From the wallex NFT homepage, navigate to your <strong>Profile icon</strong> and select <strong>Studio</strong>.&nbsp;</p><p>You’ll see a list of all of your collections associated with your wallet. Simply click on the collection you’d like to edit, or select <strong>Edit details</strong>, to navigate to your Edit collection page and get started!&nbsp;</p>
                            </>}
                            {val === 3 && <>
                              <h2><strong>How to create/mint a new NFT?</strong></h2><p>Sign in using username and password, then click connect wallet button from top right corner and connect your Metamask wallet with mainnet.</p><p>Click the CREATE button at the top right corner of the page.</p><p>Upload your image, video, music file, GIF or 3D model.</p><p>Select any one method from the following:</p><p>Fixed Price - NFT will have a fixed price which buyers can view and directly buy it for that price. Kindly check this article below to know more about how to buy a fixed price NFT.</p><p>Timed Auctions - NFT can be bought through Auction where buyers can quote their best price within the timeline set by the NFT owner.</p><p>In make offers there will be no timeline and Auction will continue until the owner accepts the highest bid.</p><p>Specify name, description and royalties.</p><p>Select preferred network (ETH, Polygon or BNB chain) in which NFT should be minted.</p><p>In fixed price NFTs, price must be set.</p><p>Now click “Create Item” to mint and deploy NFT.</p><p>&nbsp;Once the NFT collection is deployed, your wallet will ask you to sign and pay for gas fees. After paying, NFT will be minted and will enter your metamask wallet.</p>
                            </>}





                          </Item>
                        </> :

                        <>
                          <h4>Article in this section</h4>
                          {faq.map((faq, index) => (
                            <div className={index === selectedFAQ ? "display-2 redirect-link-specific active" : "display-2 redirect-link-specific"} onClick={() => handleVal(index)} >
                              <div
                                key={faq.id}
                                // to="#"
                                style={{ cursor: 'pointer' }}


                              >
                                <p>{faq.name}</p>
                              </div>
                              <div>
                                <NavigateNextIcon />
                              </div>
                            </div>

                          ))}
                        </>}
                    </Grid>

                    {/* <Grid item xs={12} lg={2} xl={2} id="link-left-side-id">
                      <Item className="helpcommoninner">
                        <h4>Article in this section</h4>
                        {faq.map((faq, index) => (
                          <div
                            key={faq._id}
                            // to="#"
                            style={{ cursor: 'pointer' }}
                            className={ index === selectedFAQ  ? "redirect-link-specific active" : "redirect-link-specific"}
                            onClick={() => handleVal(index)}
                          >
                            <p>{faq.name}</p>
                          </div>
                        ))}
                      </Item>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10} id="link-left-side-detail-id">
                      <Item className={classes.helpcommoninner}>
                        {/* {selectedQuestion && <h2>{selectedQuestion}</h2>} */}
                        {/* {selectedAnswer3 && <div dangerouslySetInnerHTML={{ __html: selectedAnswer3 }}></div>} */}
                        {/* {val === 0 && <>
  <p>Our clients often ask, "can I create NFT?" and the simple answer is 'Yes,’ anyone can create an NFT.</p><h3><strong>How Much does it Cost to Create an NFT?</strong></h3><p>Creating your own NFT is exciting as well as profitable in the long run. On average, NFT costs can be as low as $0.01 but go into thousands of dollars.</p><p>For example, you can create NFTs on an Ethereum blockchain, the most expensive of all blockchains costing an average of $70. However, there are many examples where making your own NFT that can cost you more than $500 at its peak time.</p><p>In contrast, Solana is one of the cheapest blockchains where the cost of NFT is approximately $0.01 per token. However, these NFT costs do not include the fee you will pay to the marketplace. Therefore, when you ask how much do NFTs cost to make? Remember to add the marketplace fee of between 2.5% and 5%.</p><p>We advise our customers to ask the following questions before diving into the NFT-verse.</p> 
</>}
{val === 1 && <>
  <h2><strong>What is Lazy Minting?</strong></h2><p>You can <strong>mint</strong> your own NFT into your crypto wallet using wallexNFTStudio tools. You don’t need to have any coding knowledge to use these tools. To get started, you’ll just need some cryptocurrency for gas fees, and the artwork you’d like to mint into an NFT.</p><p>This step-by-step guide covers how to create an NFT collection and mint directly to your wallet. You can watch a video of this process on our site. If you want the public or your community to be able to mint your NFTs directly into their wallets, learn more in our Wallex NFT.&nbsp;</p> 
</>}
{val === 2 && <>
  <h2><strong>Can I edit my collection?</strong></h2><p>From the wallex NFT homepage, navigate to your <strong>Profile icon</strong> and select <strong>Studio</strong>.&nbsp;</p><p>You’ll see a list of all of your collections associated with your wallet. Simply click on the collection you’d like to edit, or select <strong>Edit details</strong>, to navigate to your Edit collection page and get started!&nbsp;</p> 
</>}
{val === 3 && <>
  <h2><strong>How to create/mint a new NFT?</strong></h2><p>Sign in using username and password, then click connect wallet button from top right corner and connect your Metamask wallet with mainnet.</p><p>Click the CREATE button at the top right corner of the page.</p><p>Upload your image, video, music file, GIF or 3D model.</p><p>Select any one method from the following:</p><p>Fixed Price - NFT will have a fixed price which buyers can view and directly buy it for that price. Kindly check this article below to know more about how to buy a fixed price NFT.</p><p>Timed Auctions - NFT can be bought through Auction where buyers can quote their best price within the timeline set by the NFT owner.</p><p>In make offers there will be no timeline and Auction will continue until the owner accepts the highest bid.</p><p>Specify name, description and royalties.</p><p>Select preferred network (ETH, Polygon or BNB chain) in which NFT should be minted.</p><p>In fixed price NFTs, price must be set.</p><p>Now click “Create Item” to mint and deploy NFT.</p><p>&nbsp;Once the NFT collection is deployed, your wallet will ask you to sign and pay for gas fees. After paying, NFT will be minted and will enter your metamask wallet.</p> 
</>}
                   */}







                      </Item>
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={2}
                      xl={2}
                      id="link-left-side-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h4>Article in this section</h4>

                        <Link
                          to="/"
                          className="redirect-link-specific"
                        >
                          How to create/mint a new NFT?
                        </Link>
                        <Link
                          to="/"
                          className="redirect-link-specific"
                        >
                          How much does it cost to create an NFT?
                        </Link>
                        <Link
                          to="/"
                          className="redirect-link-specific"
                        >
                          What is Lazy Minting?
                        </Link>
                        <Link
                          to="/"
                          className="redirect-link-specific"
                        >
                          Can I edit my collection?
                        </Link>
                      </Item>
                    </Grid> */}

                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={10}
                      xl={10}
                      id="link-left-side-detail-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h2>How to create/mint a new NFT?</h2>

                        <ol>
                          <li>
                             Sign in using username and password, then click
                            connect wallet button from top right corner and
                            connect your Metamask wallet with mainnet.
                          </li>
                          <li>
                             Click the CREATE button at the top right corner
                            of the page.
                          </li>
                          <li>
                            Upload your image, video, music file, GIF or 3D
                            model.
                          </li>
                          <li>
                             Select any one method from the following:
                            <li>
                              Fixed Price - NFT will have a fixed price which
                              buyers can view and directly buy it for that
                              price. Kindly check this article below to know
                              more about how to buy a fixed price NFT.
                            </li>
                            <li>
                              Timed Auctions - NFT can be bought through Auction
                              where buyers can quote their best price within the
                              timeline set by the NFT owner.
                            </li>
                            <li>
                              In make offers there will be no timeline and
                              Auction will continue until the owner accepts the
                              highest bid.
                            </li>
                          </li>
                          <li>Specify name, description and royalties.</li>
                          <li>
                         Select preferred network (ETH, Polygon or BNB
                            chain) in which NFT should be minted.
                          </li>
                          <li>In fixed price NFTs, price must be set.</li>
                          <li>
                         Now click “Create Item” to mint and deploy NFT.
                          </li>
                          <li>
                            Once the NFT collection is deployed, your wallet
                            will ask you to sign and pay for gas fees. After
                            paying, NFT will be minted and will enter your
                            metamask wallet.
                          </li>
                        </ol>

                        <h4>How much does it cost to create an NFT?</h4>

                        <h4>What is Lazy Minting?</h4>

                        <h4>Can I edit my collection?</h4>
                      </Item>
                    </Grid> */}
                  </Grid>
                </Box>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <Footer />

          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default DetailPage;
