import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Header from "../../menu/header";
import Footer from "../../components/footer";
import "./HelpCenter.css";
// import togglebtn from '../../img/togglebtn.png'
// import Menuclose from '../../img/Menuclose.png'
import { Link } from "@reach/router";
// import customer from '../../img/customer.svg'
// import maptrace from '../../img/maptrace.svg'
// import guarantee from '../../img/guarantee.svg'
// import bestseller from '../../img/bestseller.svg'
// import bill from '../../img/bill.svg'
// import product from '../../img/product.svg'
// import supplychain from '../../img/supplychain.svg'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: "0px",
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    padding: "90px 0 !important",
    "@media (max-width: 991.98px)": {
      padding: "30px 0 !important",
    },
  },
  headercls: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
  },

  bodyclass: {
    background: "transparent !important",
    borderRadius: "0px !important",
    boxShadow: "none !important",
    position: "relative",
  },
});

const GetStarted = () => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true);

  const openMenuHandler = () => {
    setMenuOpen(false);
  };

  const closeMenuHandler = () => {
    setMenuOpen(true);
  };

  return (
    <div className="dashboard-page help-center-main">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="outer-bgcolor-container"
          >
            <Item className={classes.bodyclass}>
              <div className="togglebtn" onClick={openMenuHandler}>
                {/* <img src={togglebtn} alt="togglebtn" /> */}
              </div>
              {/* <div
                className="categories-menu"
                id={!menuOpen ? "openmenucls" : ""}
              >
                <div className="togglebtn-close" onClick={closeMenuHandler}>
                  <img src={Menuclose} alt="Menuclose" />
                </div>
                <div className="categories-menu-inner">
                  <Link>
                    <div className="iconic">
                      <img src={customer} alt={customer} />
                    </div>
                    Customer
                  </Link>
                  <Link>
                    <div className="iconic">
                      <img src={maptrace} alt={maptrace} />
                    </div>
                    Traceability
                  </Link>
                  <Link>
                    <div className="iconic">
                      <img src={guarantee} alt={guarantee} />
                    </div>
                    Products
                  </Link>
                  <Link>
                    <div className="iconic">
                      <img src={bestseller} alt={bestseller} />
                    </div>
                    Blockchain
                  </Link>
                  <Link>
                    <div className="iconic">
                      <img src={bill} alt={bill} />
                    </div>
                    Post-sale
                  </Link>
                  <Link>
                    <div className="iconic">
                      <img src={product} alt={product} />
                    </div>
                    Billing
                  </Link>
                  <Link>
                    <div className="iconic">
                      <img src={supplychain} alt={supplychain} />
                    </div>
                    Sellers Authorization{" "}
                  </Link>
                </div>
              </div> */}

              <div className="detail-pages-inner-outer">
                <Box sx={{ flexGrow: 1 }}>
                  <div className="breadcrum-helpcenter">
                    <Link to="/helpcenter-new">Help Center</Link> / Selling
                  </div>
                  <div className="help-center-main-box">
                    <h2>Selling</h2>
                    {/* <p className="detail-pages-inner-outer-para">
                    Learn how to create an account, set up your wallet, and what
                    you can do on OpenSea
                  </p> */}
                    <Grid
                      container
                      spacing={0}
                      className={classes.helpcenterbodycontain}
                    >
                      <Link
                        to="/selling-page"
                        className="redirect-link-specific"
                      >
                        How to sell an NFT at fixed-price?
                      </Link>
                      <Link
                        to="/selling-page"
                        className="redirect-link-specific"
                      >
                        How to list my NFT for sale and what are the fees
                        involved?
                      </Link>
                      <Link
                        to="/selling-page"
                        className="redirect-link-specific"
                      >
                        How to sell my NFT at an auction?
                      </Link>
                      <Link
                        to="/selling-page"
                        className="redirect-link-specific"
                      >
                        How to manage my offers?
                      </Link>
                      <Link
                        to="/selling-page"
                        className="redirect-link-specific"
                      >
                        Where can I see my Sell history?
                      </Link>
                    </Grid>
                  </div>
                </Box>
              </div>
            </Item>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.footercls}>
              <Footer />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default GetStarted;
