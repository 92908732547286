import React,{useState,useEffect} from 'react';
import SliderMainZero from '../components/SliderMainZero1';
import FeatureBox from '../components/FeatureBox';
import ColumnNewsound from '../components/ColumnNewsound';
import AuthorListReduxNew from '../components/AuthorListReduxNew';
import CatgorNew from '../components/CatgorNew';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Logo from '../../assets/logo/logoSmall.webp'
import Community from '../components/Community'
import CountingCard from '../components/CountingCard'
import '../components/index.scss'
import Catgor from '../components/Catgor';
import { Link } from '@reach/router';


const GlobalStyles = createGlobalStyle`

@media only screen and (max-width: 409px) {
  .footer-light .subfooter .social-icons .copy{
    width:220px
    
  }
}
@media (max-width: 700px) { 
  #myHeader .container .row .logo .navbar-item img {   
    width:150px;
}
}
@media (max-width: 500px) { 
  #myHeader .container .row .logo .navbar-item img {   
    width:100px;
}
}





  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 30px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }

  .footer_card .feature_card .featurecards .feature-box{
    height:340px;
  }
  @media only screen and (max-width: 1397px) { 
    .footer_card .feature_card .featurecards .feature-box{
      height:390px;
    }
  }
  @media only screen and (max-width: 1220px) { 
    .footer_card .feature_card .featurecards .feature-box{
      height:450px;
    }
  }
  @media only screen and (max-width: 992px) { 
    .footer_card .feature_card .featurecards .feature-box{
      height:340px;
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 768px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
    .container #cat_section{
      margin-top:-300px;
    } 
    
    }

    @media (max-width: 581px)  { 
      #ban .fraktion-show{
        margin-top:-350px;
      } 
    }
    @media only screen and (max-width: 768px) { 
      #ban .fraktion-show{
        margin-top:-350px;
      } 
      
    }

    @media (max-width: 576px)  { 
      #ban .fraktion-show  {
        margin-top:-250px;
        
      } 
      #ban .fraktion-show .row #count {
        text-align:center;
        
      } 
      .container #cat_section{
        margin-top:-150px;
      } 
    }


    @media only screen and (max-width: 990px){ 
      #ban .fraktion-show{
        padding-top:100px;
      }
    }
  @media (min-width: 350px) and (max-width: 477px) { 
    .col-white {   
      margin-top:10px; 
  }
  h1{
    font-size: 30px;
    color:red
   
  }
  .de_count{    
    h3{
      font-size: 20px;
      margin-bottom: 0;
      span {
        font-size: 15px;
      }
    }
  }

  .tot-num-warp {
    flex-wrap: initial;
    width: 38%;
    text-align: center;
  }
}

@media (min-width: 300px) and (max-width: 349px) { 
  .col-white {   
    margin-top:10px; 
}
h1{
  font-size: 30px;
  color:red
 
}
.de_count{    
  h3{
    font-size: 22px;
    margin-bottom: 0;
    span {
      font-size: 15px;
    }
  }
}

.tot-num-warp {
  flex-wrap: initial;
  width: 38%;
  text-align: center;
}
}

 
`;

const Hometwo= () => {

  const today = [
    {
      "id":"1",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f1.png",
       "isred":true
    },
    {
      "id":"2",
      "title":"Doodles",
      "floor":"14.4",
      "volume":"197.4",
       "value":"20",
       "percentage":"-50.09",
       "image":"./f2.png"
    },
    {
      "id":"3",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f3.png",
       "isred":true
    },
    {
      "id":"4",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f4.png"
    },
    {
      "id":"5",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f5.png",
       "isred":true
    },
    {
      "id":"6",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f6.png"
    },
    {
      "id":"7",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f7.png",
       "isred":true
    },
    {
      "id":"8",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f8.png"
    }, {
      "id":"9",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f9.png",
       "isred":true
    }
    , {
      "id":"10",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f10.png",
       "isred":true
    }
    , {
      "id":"11",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f11.png",
       "isred":true
    }
    , {
      "id":"12",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f12.png"
    }
    ,
    {
      "id":"13",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f13.png",
       "isred":true
    },
    {
      "id":"14",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f14.png"
    }
  ]

  const lastSever = [
    {
      "id":"1",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f7.png",
       "isred":true
    },
    {
      "id":"2",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f8.png"
    }, {
      "id":"3",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f9.png",
       "isred":true
    }
    , {
      "id":"4",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f10.png",
       "isred":true
    }
    , {
      "id":"5",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f11.png",
       "isred":true
    }
    , {
      "id":"6",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f12.png"
    }
    ,
    {
      "id":"7",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f13.png",
       "isred":true
    },
    {
      "id":"8",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f14.png"
    },
    {
      "id":"9",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f1.png",
       "isred":true
    },
    {
      "id":"10",
      "title":"Doodles",
      "floor":"14.4",
      "volume":"197.4",
       "value":"20",
       "percentage":"-50.09",
       "image":"./f2.png"
    },
    {
      "id":"11",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f3.png",
       "isred":true
    },
    {
      "id":"12",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f4.png"
    },
    {
      "id":"13",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f5.png",
       "isred":true
    },
    {
      "id":"14",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f6.png"
    },
    
  ]

  const lastThirty = [
    {
      "id":"1",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f3.png",
       "isred":true
    },
    {
      "id":"2",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f4.png"
    },
    {
      "id":"3",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f5.png",
       "isred":true
    },
    {
      "id":"4",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f6.png"
    },
    {
      "id":"5",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f7.png",
       "isred":true
    },
    {
      "id":"6",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f8.png"
    }, 
    {
      "id":"7",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f1.png",
       "isred":true
    },
    {
      "id":"8",
      "title":"Doodles",
      "floor":"14.4",
      "volume":"197.4",
       "value":"20",
       "percentage":"-50.09",
       "image":"./f2.png"
    },
   {
      "id":"9",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f9.png",
       "isred":true
    }
    , {
      "id":"10",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f10.png",
       "isred":true
    }
    , {
      "id":"11",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f11.png",
       "isred":true
    }
    , {
      "id":"12",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f12.png"
    }
    ,
    {
      "id":"13",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f13.png",
       "isred":true
    },
    {
      "id":"14",
      "title":"Otherdeed for Others...",
      "floor":"1.4",
      "volume":"270.4",
       "value":"-0.40",
       "percentage":"-10.09",
       "image":"./f14.png"
    }
  ]

  const topSeller = [
    {
      "id":"1",
      "title":"Drunk Robots",
      "description":"@drunk_robots",
       "value":"2.2M",
       "image":"./f1.png"
    },
    {
      "id":"2",
      "title":"Equinox",
      "description":"@equinox",
       "value":"2.0M",
       "image":"./f2.png"
    },
    {
      "id":"3",
      "title":"GamesPad",
      "description":"@GamesPad",
       "value":"540.7K",
       "image":"./f3.png"
    },
    {
      "id":"4",
      "title":"Ftribe Fighters",
      "description":"@f2nft",
       "value":"297.6K",
       "image":"./f4.png"
    },
    {
      "id":"5",
      "title":"LQT Launchpad",
      "description":"@launchpad",
       "value":"224.8K",
       "image":"./f5.png"
    },
    {
      "id":"6",
      "title":"Monster Battle",
      "description":"@MonsterBattle",
       "value":"176.3K",
       "image":"./f6.png"
    },
    {
      "id":"7",
      "title":"SugarKingdom",
      "description":"@sugarkingdom",
       "value":"164.9M",
       "image":"./f7.png"
    },
    {
      "id":"8",
      "title":"Cyber City",
      "description":"@cybercity",
       "value":"2.2M",
       "image":"./f8.png"
    },
    {
      "id":"9",
      "title":"Liquidity Collection",
      "description":"@liquidity",
       "value":"117.9K",
       "image":"./f9.png"
    },
    {
      "id":"10",
      "title":"Skill Labs",
      "description":"@skilllabs",
       "value":"2.2M",
       "image":"./f10.png"
    },
    {
      "id":"11",
      "title":"DoRac Official",
      "description":"@drunk_robots",
       "value":"77K",
       "image":"./f11.png"
    },
    {
      "id":"12",
      "title":"The Next Wild",
      "description":"@nextwild",
       "value":"110K",
       "image":"./f12.png"
    }
  ]

  const topBuyers = [
    {
      "id":"1",
      "title":"Monster Battle",
      "description":"@MonsterBattle",
       "value":"176.3K",
       "image":"./f6.png"
    },
    {
      "id":"2",
      "title":"SugarKingdom",
      "description":"@sugarkingdom",
       "value":"164.9M",
       "image":"./f7.png"
    },
    {
      "id":"3",
      "title":"Drunk Robots",
      "description":"@drunk_robots",
       "value":"2.2M",
       "image":"./f1.png"
    },
    {
      "id":"4",
      "title":"Equinox",
      "description":"@equinox",
       "value":"2.0M",
       "image":"./f2.png"
    },
    {
      "id":"5",
      "title":"GamesPad",
      "description":"@GamesPad",
       "value":"540.7K",
       "image":"./f3.png"
    },
    {
      "id":"6",
      "title":"Ftribe Fighters",
      "description":"@f2nft",
       "value":"297.6K",
       "image":"./f4.png"
    },
    {
      "id":"7",
      "title":"LQT Launchpad",
      "description":"@launchpad",
       "value":"224.8K",
       "image":"./f5.png"
    },
    
    {
      "id":"8",
      "title":"Cyber City",
      "description":"@cybercity",
       "value":"2.2M",
       "image":"./f8.png"
    },
    {
      "id":"9",
      "title":"DoRac Official",
      "description":"@drunk_robots",
       "value":"77K",
       "image":"./f11.png"
    },
    {
      "id":"10",
      "title":"The Next Wild",
      "description":"@nextwild",
       "value":"110K",
       "image":"./f12.png"
    },
    {
      "id":"11",
      "title":"Liquidity Collection",
      "description":"@liquidity",
       "value":"117.9K",
       "image":"./f9.png"
    },
    {
      "id":"12",
      "title":"Skill Labs",
      "description":"@skilllabs",
       "value":"2.2M",
       "image":"./f10.png"
    }
    
  ]

  const topCreators = [
    {
      "id":"1",
      "title":"Cyber City",
      "description":"@cybercity",
       "value":"2.2M",
       "image":"./f8.png"
    },
    {
      "id":"2",
      "title":"Liquidity Collection",
      "description":"@liquidity",
       "value":"117.9K",
       "image":"./f9.png"
    },
    {
      "id":"3",
      "title":"Skill Labs",
      "description":"@skilllabs",
       "value":"2.2M",
       "image":"./f10.png"
    },
    {
      "id":"4",
      "title":"DoRac Official",
      "description":"@drunk_robots",
       "value":"77K",
       "image":"./f11.png"
    },
    {
      "id":"5",
      "title":"The Next Wild",
      "description":"@nextwild",
       "value":"110K",
       "image":"./f12.png"
    },
    {
      "id":"6",
      "title":"Drunk Robots",
      "description":"@drunk_robots",
       "value":"2.2M",
       "image":"./f1.png"
    },
    {
      "id":"7",
      "title":"Equinox",
      "description":"@equinox",
       "value":"2.0M",
       "image":"./f2.png"
    },
    {
      "id":"8",
      "title":"GamesPad",
      "description":"@GamesPad",
       "value":"540.7K",
       "image":"./f3.png"
    },
    {
      "id":"9",
      "title":"Ftribe Fighters",
      "description":"@f2nft",
       "value":"297.6K",
       "image":"./f4.png"
    },
    {
      "id":"10",
      "title":"LQT Launchpad",
      "description":"@launchpad",
       "value":"224.8K",
       "image":"./f5.png"
    },
    {
      "id":"11",
      "title":"Monster Battle",
      "description":"@MonsterBattle",
       "value":"176.3K",
       "image":"./f6.png"
    },
    {
      "id":"12",
      "title":"SugarKingdom",
      "description":"@sugarkingdom",
       "value":"164.9M",
       "image":"./f7.png"
    },
    
  ]

  const [topSellerHeading, setTopSellerHeading] = useState("topSeller")
  const [topCollectionHeading, setTopCollectionHeading] = useState("today")

return(
  <div>
    <GlobalStyles />
    {/* <div className="scroll-container"> */}
    <section className="jumbotron no-bg h-vh backgroundImage section-first" >
         {/* <br/><br/><br/><br/> */}
        <div style={{height:'100vw'}}>
         <SliderMainZero/>
         </div>
      </section>

      <section  style={{background:'white'}}>

  
        
        {/* <div  > */}
          <FeatureBox/>
          {/* <CountingCard/> */}
        {/* </div> */}
      </section>
    {/* </div> */}
      

      {/* <section className='container-fluid pb-0 newGradient'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2 className='textShadow new_big_font'>New Items</h2>
              <div className="small-border"></div>
            </div>
          </div>
        </div>
       <ColumnNewsound/>
      </div>
      </section> */}

      {/* <section className='container pt-5'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2 className='textShadow new_big_font'>Browse By Category</h2>
              <div className="small-border"></div>
            </div>
          </div>
        </div>
        <CatgorNew/>
      </section> */}

      <section className='third-section-container'>
        <h1 className="get-started-title">The Wallex NFT marketplace experience</h1>
        <div className="third-section-card-container">
          <div className="third-section-card" data-aos="fade-right">
          <center>
          <img src="./third-security.png" alt="security" width='100' />
          </center>
            <p className='third-card-content'>Protect your NFTs with <br />industry-leading security.</p>
          </div>
          <div className="third-section-card" data-aos="fade-down">
          <center>
          <img src="./third-rocket.png" alt="security" width='100' />
          </center>
            <p className='third-card-content'>Enjoy zero gas fees for <br />trades on Wallex NFT*</p>
          </div>
          <div className="third-section-card" data-aos="fade-left">
          <center>
          <img src="./third-ranking.png" alt="security" width='100' />
          </center>
            <p className='third-card-content'>Track the rarity rankings <br />of your NFTs</p>
          </div>
          <div className="third-section-card" data-aos="fade-right">
          <center>
          <img src="./third-dollar.png" alt="security" width='100' />
          </center>
            <p className='third-card-content'>Pay with cash or 185+ <br />cryptocurrencies</p>
          </div>
          <div className="third-section-card" data-aos="fade-up">
          <center>
          <img src="./third-blockchain.png" alt="security" width='100' />
          </center>
            <p className='third-card-content'>Collect NFTs across <br />multiple blockchains</p>
          </div>
          <div className="third-section-card" data-aos="fade-left">
          <center>
          <img src="./third-calender.png" alt="security" width='100' />
          </center>
            <p className='third-card-content'>24/7 customer support, <br />365 days a year</p>
          </div>
        </div>
      </section>

      <section className="fourth-section-container">
      <h1 className="fourth-get-started-title">Top Collections</h1>
      <div className="fourth-inner-container">
      <div className="fourth-sort-container">
         <button onClick={()=>setTopCollectionHeading("today")} className={`fourth-sort-btn ${topCollectionHeading==="today" && "fourth-sort-btn-active"} ` }>Today</button>
         <button onClick={()=>setTopCollectionHeading("lastsevendays")} className={`fourth-sort-btn ${topCollectionHeading==="lastsevendays" && "fourth-sort-btn-active"} ` }>Last 7 Days</button>
         <button onClick={()=>setTopCollectionHeading("lastthirtydays")} className={`fourth-sort-btn ${topCollectionHeading==="lastthirtydays" && "fourth-sort-btn-active"} ` }>Last 30 Days</button>
      </div>
      <div className="top-collection-main-map-container">
        {
          topCollectionHeading ==="today"&&
          today?.map((item,i)=>(
            <div className='top-collection-map-container' data-aos="flip-up">
            <div className="top-collection-id" style={{color:'black'}}>
              {item.id}
            </div>
            <div className="top-collection-image-container">
              <img src={item.image} alt={item.title} className="top-collection-image" />
            </div>
            <div>
              <h5 style={{color: "#040C1B" }} className="top-collection-title">{item.title}</h5>
              <div className="top-collection-floor-flex">
              <h6 className="top-collection-floor-content" style={{fontWeight:'500',fontSize:'14px',color:"#040C1B"}}>Floor: </h6>
              <h6 className="top-collection-floor-content" style={{fontWeight:'500',fontSize:'14px',color:"#040C1B"}}>${item.floor}K</h6>
              <button className="top-collection-floor-btn" style={{fontWeight:'500',fontSize:'14px',background:item.isred?'#ff00005d':'#00800060'}} >{item.value}%</button>
              </div>
              <div className="top-collection-volume-flex">
                <p style={{fontWeight:'500',fontSize:'14px'}}>Volume: </p>
                <p style={{fontWeight:'500',fontSize:'14px'}}>${item.volume}K</p>
                <p style={{fontWeight:'500',fontSize:'14px'}}>{item.percentage}%</p>
              </div>
              
            </div>
            </div>
          ))

          
        }
           {
          topCollectionHeading ==="lastsevendays"&&
          lastSever?.map((item,i)=>(
            <div className='top-collection-map-container' data-aos="flip-up">
            <div className="top-collection-id" style={{color:'black'}}>
              {item.id}
            </div>
            <div className="top-collection-image-container">
              <img src={item.image} alt={item.title} className="top-collection-image" />
            </div>
            <div>
              <h5 style={{color: "#040C1B" }} className="top-collection-title">{item.title}</h5>
              <div className="top-collection-floor-flex">
              <h6 className="top-collection-floor-content" style={{fontWeight:'500',fontSize:'14px',color:"#040C1B"}}>Floor: </h6>
              <h6 className="top-collection-floor-content" style={{fontWeight:'500',fontSize:'14px',color:"#040C1B"}}>${item.floor}K</h6>
              <button className="top-collection-floor-btn" style={{fontWeight:'500',fontSize:'14px',background:item.isred?'#ff00005d':'#00800060'}} >{item.value}%</button>
              </div>
              <div className="top-collection-volume-flex">
                <p style={{fontWeight:'500',fontSize:'14px'}}>Volume: </p>
                <p style={{fontWeight:'500',fontSize:'14px'}}>${item.volume}K</p>
                <p style={{fontWeight:'500',fontSize:'14px'}}>{item.percentage}%</p>
              </div>
              
            </div>
            </div>
          ))

          
        }
           {
          topCollectionHeading ==="lastthirtydays"&&
          lastThirty?.map((item,i)=>(
            <div className='top-collection-map-container' data-aos="flip-up">
            <div className="top-collection-id" style={{color:'black'}}>
              {item.id}
            </div>
            <div className="top-collection-image-container">
              <img src={item.image} alt={item.title} className="top-collection-image" />
            </div>
            <div>
              <h5 style={{color: "#040C1B" }} className="top-collection-title">{item.title}</h5>
              <div className="top-collection-floor-flex">
              <h6 className="top-collection-floor-content" style={{fontWeight:'500',fontSize:'14px',color:"#040C1B"}}>Floor: </h6>
              <h6 className="top-collection-floor-content" style={{fontWeight:'500',fontSize:'14px',color:"#040C1B"}}>${item.floor}K</h6>
              <button className="top-collection-floor-btn" style={{fontWeight:'500',fontSize:'14px',background:item.isred?'#ff00005d':'#00800060'}} >{item.value}%</button>
              </div>
              <div className="top-collection-volume-flex">
                <p style={{fontWeight:'500',fontSize:'14px'}}>Volume: </p>
                <p style={{fontWeight:'500',fontSize:'14px'}}>${item.volume}K</p>
                <p style={{fontWeight:'500',fontSize:'14px'}}>{item.percentage}%</p>
              </div>
              
            </div>
            </div>
          ))

          
        }
      </div>
      </div>
      
      </section>

      <section className="fifth-section-container">
      <h1 className="get-started-title">Browse by Category</h1>
      <section className='container'>
        <div className='row'>
          {/* <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Browse by category</h2>
              <div className="small-border"></div>
            </div>
          </div> */}
        </div>
        <CatgorNew/>
      </section>
      </section>

      <section className="sixth-section-container">
      <div className='sixth-section-inner-container'>
      <div className='sixth-section-content'>
        <h5 onClick={()=>setTopSellerHeading("topSeller")} className={topSellerHeading==="topSeller"&&"active-top-seller"} >TOP SELLERS</h5>
        <h5 onClick={()=>setTopSellerHeading("topCreaters")} className={topSellerHeading==="topCreaters"&&"active-top-seller"}>TOP CREATORS</h5>
        <h5 onClick={()=>setTopSellerHeading("topBuyers")} className={topSellerHeading==="topBuyers"&&"active-top-seller"}>TOP BUYERS</h5>
      </div>

<div className='sixth-section-map-container'>
{
  topSellerHeading==='topSeller' &&
        topSeller?.map((item,i)=>(
          <div data-aos="zoom-out-down" className='sixth-section-map-inner-container'>
          <div className='sixth-section-map-first-flex'>
          <p>{item.id}</p>
           <img src={item?.image} alt={item?.title} width="50" style={{borderRadius:'50%',marginTop:'-10px'}}/>
            <div>
              <h6>{item?.title}</h6>
              <p>{item?.description}</p>
            </div>
          </div>
           <div>
            <p>${item?.value}</p>
           </div>

          </div>
        ))
       }

{
  topSellerHeading==='topCreaters' &&
        topCreators?.map((item,i)=>(
          <div data-aos="zoom-out-down" className='sixth-section-map-inner-container'>
          <div className='sixth-section-map-first-flex'>
          <p>{item.id}</p>
           <img src={item?.image} alt={item?.title} width="50" style={{borderRadius:'50%',marginTop:'-10px'}}/>
            <div>
              <h6>{item?.title}</h6>
              <p>{item?.description}</p>
            </div>
          </div>
           <div>
            <p>${item?.value}</p>
           </div>

          </div>
        ))
       }


{
  topSellerHeading==='topBuyers' &&
        topBuyers?.map((item,i)=>(
          <div data-aos="zoom-out-down" className='sixth-section-map-inner-container'>
          <div className='sixth-section-map-first-flex'>
          <p>{item.id}</p>
           <img src={item?.image} alt={item?.title} width="50" style={{borderRadius:'50%',marginTop:'-10px'}}/>
            <div>
              <h6>{item?.title}</h6>
              <p>{item?.description}</p>
            </div>
          </div>
           <div>
            <p>${item?.value}</p>
           </div>

          </div>
        ))
       }
</div>
      </div>
       
      </section>

      <section className='seven-section-container'>
        <div className='sixth-section-first-container'>
           <h2 className="black-color">Ready to Get Started?</h2>
           <p className="black-color">Take your business global with our NFTs</p>
           <div style={{display:'flex',alignItems:'center'}}>
           <Link to="/register">
           <button className='get-started-btn btn-main whiteColor' style={{backgroundColor:'#147fbf'}}>Get Started Now</button>
           </Link>
          
             <button className='contact-sales-btn btn-main'>Contact Sales</button>
           </div>
        </div>
        <div className='sixth-section-second-container'>
          <img src="./figma2.png" alt="dog" width='250' style={{borderRadius:'10px'}} />
        </div>
      </section>

      {/* <section className='container pb-0 newGradient'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2 className='textShadow new_big_font'>Top Sellers</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <AuthorListReduxNew/>
          </div>
        </div>
      </section>

      <section className='container pt-5'>
        <div className='row'>
      
        </div>
        <Community/>
      </section> */}

    {/* <section className='container pt-0'>
    <div className="row">
      <div className='col-lg-12'>
            <div className='text-center'>
              <h2 className='textShadow new_big_font'>Latest News</h2>
              <div className="small-border"></div>
            </div>
          </div>

      <div className="col-lg-4 col-md-6 mb30">
        <div className="bloglist item">
            <div className="post-content">
                <div className="post-image">
                    <img alt="" src="./img/news/news-1.jpg" className="lazy"/>
                </div>
                <div className="post-text">
                    <span className="p-tagline">Tips &amp; Tricks</span>
                    <span className="p-date">October 28, 2020</span>
                    <h4><span>The next big trend in crypto<span></span></span></h4>
                    <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                    <span className="btn-main">Read more</span>
                </div>
            </div>
        </div>
      </div>
      
      <div className="col-lg-4 col-md-6 mb30">
        <div className="bloglist item">
            <div className="post-content">
                <div className="post-image">
                    <img alt="" src="./img/news/news-2.jpg" className="lazy"/>
                </div>
                <div className="post-text">
                    <span className="p-tagline">Tips &amp; Tricks</span>
                    <span className="p-date">October 28, 2020</span>
                    <h4><span>The next big trend in crypto<span></span></span></h4>
                    <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                    <span className="btn-main">Read more</span>
                </div>
            </div>
        </div>
      </div>
      
      <div className="col-lg-4 col-md-6 mb30">
        <div className="bloglist item">
            <div className="post-content">
                <div className="post-image">
                    <img alt="" src="./img/news/news-3.jpg" className="lazy"/>
                </div>
                <div className="post-text">
                    <span className="p-tagline">Tips &amp; Tricks</span>
                    <span className="p-date">October 28, 2020</span>
                    <h4><span>The next big trend in crypto<span></span></span></h4>
                    <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                    <span className="btn-main">Read more</span>
                </div>
            </div>
        </div>
      </div>
        
    </div>
  </section> */}


    <Footer />

  </div>
)};
export default Hometwo;