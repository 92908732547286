import React, { useState, useEffect } from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Axios from '../../api-instance'
import constant from '../../constants'
import { Link, useLocation, useNavigate } from '@reach/router';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom'
import validator from 'validator';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Google } from '@mui/icons-material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getUserData } from '../../store/actions/userData';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #fff;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const GlobalStylesGrey = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid #ff343f;
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: #ff343f;
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 #ff343f;
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: rgba(33, 36, 40, .9);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{ 
    color: #fff !important;
    background: #ff343f;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: #ff343f;
  }
  @media only screen and (max-width: 1199px) { 
    .navbar {
      background: #212428;
    }
  }
`;

// const StyledToastContainer = styled(ToastContainer)`
//   .Toastify__toast-container {
//   }
//   .Toastify__toast-container--top-right {
//     top: 5em !important;
//   }
// `;

const Logintwo = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [isDark, setIsDark] = useState(false)

  const checkVerify = () => {
    const data = location?.state?.status
    console.log(data, "email");
    if (data === "Verified") {
      toast.success(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    // if (location?.state?.page === "email") {
    //   setverifystatus(data)
    //   setTimeout(() => {
    //     setverifystatus("empty")
    //   }, 4000);
    // }
  }

  useEffect(() => {
    checkVerify()
  }, [])



  useEffect(() => {
    if (localStorage.getItem('mode') === 'dark') {
      setIsDark(true)
    } else if (localStorage.getItem('mode') === 'light') {
      setIsDark(false)
    }
  }, [])


  const [dataSet, setDataSet] = useState({
    email: null,
    password: null,
    ipAddress: null,
    userLocation: null
  })


  const handleChange = (e) => {
    setEmailWarning(false)
    setPasswordWarning(false)
    setData(false)
    const { name, value } = e.target;


    setDataSet((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const [redirectPage, setRedirectPage] = useState(null)
  const [emailWarning, setEmailWarning] = useState(null)
  const [emailValidator, setEmailValidator] = useState(null)
  const [passwordWarning, setPasswordWarning] = useState(null)
  const [data, setData] = React.useState(false)
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");


  const handleSubmit = async (event) => {

    event.preventDefault()
    if (dataSet.email.trim() === "") {
      return setEmailWarning(true)
    }
    if (dataSet.password.trim() === "") {
      return setPasswordWarning(true)
    }
    if (!validator.isEmail(dataSet.email)) {
      return setEmailValidator(true)
    }
    try {

      // const getUserIp = await axios.get(`https://checkip.amazonaws.com`)

      const getUserIp = await axios.get(`https://api.ipify.org/`)
      dataSet.ipAddress = getUserIp.data
      const data = await Axios.post(`/auth/login`, dataSet, {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        }
      })
      console.log(data, "123")
      dispatch(getUserData(data?.data))
      setData(data?.data)
      if (data?.data?.status === true) {

        window.localStorage.setItem('token', data?.data?.data?.token)
        window.localStorage.setItem('isAuth', true)
        toast.success('Logged in Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate(`${constant.MainURL}/`)
        }, 1000);
        // window.location.href=`${constant.MainURL}/`
      } else {
        console.log("hai");
        toast.error(data?.data?.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log(error.message);
      // alert(error)
      // window.location.reload()
    }
  }

  // const toastId = React.useRef(null);
  //   if(! toast.isActive(toastId.current)) {
  //     toastId.current = toast('Logged in Successfully', {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       });
  //   }

  const [hidePassword, setHidePassword] = useState(true)

  const passwordToggle = () => {
    if (hidePassword) {
      setHidePassword(false)
    } else {
      setHidePassword(true)
    }
  }

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setDataSet((prev) => ({ ...prev, email }));
    setEmailError(email === "" ? "" : validateEmail(email) ? "" : "Invalid email address");
  };

  const validateEmail = (email) => {
    return validator.isEmail(email);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setDataSet((prev) => ({ ...prev, password: newPassword }));
    setPasswordError(
      newPassword === "" ? "" : validatePassword(newPassword)
        ? ""
        : "Password must be at least 8 characters long."
    );
  }
  const validatePassword = (password) => {
    return (
      password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /\d/.test(password) &&
      /[!@#$%^&*]/.test(password)
    );
  };




  // navigator.geolocation.getCurrentPosition(showPosition)

  // function showPosition(position) {
  //  console.log(position.coords.latitude);
  // }

  const watchId = navigator.geolocation.getCurrentPosition(async (position) => {
    const { latitude, longitude } = position.coords;
    const { data } = await axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
    // console.log(getUserLocation.data);
    // const getUserLocation = data
    dataSet.userLocation = data
  });

  return (
    <>
      <ToastContainer style={{ top: "5em" }} />

      {

        redirectPage ?
          <div>
            {/* <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/> */}
            <Navigate to='/'></Navigate>
          </div>
          :
          isDark ?
            <div className="greyscheme" style={{ maxHeight: '100vh', }}>
              <GlobalStylesGrey />




              <section className='container'>
                <div className="row">
                  <div className='spacer-double'></div>
                  <div className="col-md-6 offset-md-3">

                    <form name="contactForm" id='contact_form' className="form-border" action=''>
                      <h3>Login to your account</h3>
                      <div className="field-set">
                        <label>Email</label>
                        <input type='email' name='email' id='email' className="form-control" placeholder="" value={dataSet.email} onChange={handleEmailChange} required />
                        {
                          emailWarning ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *Please Enter this field
                            </p> : null
                        }
                        {
                          emailError ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *Please Enter this valid email address
                            </p> : null
                        }
                        {
                          data.msg === "User not verified" ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              User mail not verified
                            </p> : null
                        }
                      </div>


                      <div className="field-set">
                        <label>Password</label>
                        <div className="form-control" style={{ display: 'flex', alignItems: 'center' }}>
                          <input type={hidePassword ? 'password' : 'text'} name='password' id='password' className='passwordField' placeholder="" value={dataSet.password} onChange={handlePasswordChange} style={{ width: '100%' }} required />
                          {
                            hidePassword ?
                              <AiFillEye onClick={passwordToggle} /> :
                              <AiFillEyeInvisible onClick={passwordToggle} />
                          }
                        </div>
                        {
                          passwordWarning ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *Please Enter this field
                            </p> : null
                        }
                        {
                          passwordError ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *{passwordError}
                            </p> : <>
                            </>
                        }
                        {
                          data.error === "Wrong Password" ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              Wrong Password
                            </p> : null
                        }
                      </div>

                      <div id='submit'>
                        <input onClick={handleSubmit} type='submit' id='send_message' value='Login' className="btn btn-main color-2" />
                        <div className="clearfix"></div>

                        <div className="spacer-single"></div>
                        <h4>Don't have any account? <Link to='/register' style={{ color: 'blue' }}>Sign Up</Link> </h4>
                        {/* <Link to='register'>Sign Up</Link> */}
                        <ul className="list s3">
                          <li>Or login with:</li>
                          <li><span>Facebook</span></li>
                          <li><span>Google</span></li>
                          <li><span>Instagram</span></li>
                        </ul>
                      </div>
                    </form>

                  </div>
                </div>
              </section>

              <Footer />
            </div>
            :

            <div>
              <GlobalStyles />

              <section className='jumbotron breadcumb no-bg '
              //  style={{background:'linear-gradient(90deg, rgba(9,60,121,1) 0%, rgba(50,152,208,1) 100%)'}}

              >
                <div className='mainbreadcumb'>
                  <div className='container'>
                    <div className='row'>
                      <div className="col-md-12 text-center">
                        <h1 className='text-center black-color'>User Login</h1>
                        <span className="black-color">Good to see you again ! Login to explore more NFT's</span><br />
                        {/* <span>Anim pariatur cliche </span> */}



                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className='container'>
                <div className="row">
                  <div className="col-md-6 offset-md-3">


                    <form name="contactForm" id='contact_form' className="form-border" action=''>
                      <h3 className="black-color">Login to your account</h3>

                      <div className="field-set">
                        <label className="black-color">Email Id</label>
                        <input type='email' name='email' id='email' className="form-control" placeholder="" value={dataSet.email} onChange={handleEmailChange} required />
                        {
                          emailWarning ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *Please Enter this field
                            </p> : null
                        }
                        {
                          emailError ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *Please Enter this valid email address
                            </p> : null
                        }
                        {
                          data.msg === "User not verified" ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              User mail not verified
                            </p> : null
                        }
                        {
                          data.msg === "User not found" ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              User Not Found
                            </p> : null
                        }
                      </div>


                      <div className="field-set">
                        <label className="black-color">Password</label>
                        <div className="form-control" style={{ display: 'flex', alignItems: 'center' }}>
                          <input type={hidePassword ? 'password' : 'text'} name='password' id='password' className='passwordField' placeholder="" value={dataSet.password} onChange={handlePasswordChange} style={{ width: '100%' }} required />
                          {
                            hidePassword ?
                              <AiFillEye onClick={passwordToggle} /> :
                              <AiFillEyeInvisible onClick={passwordToggle} />
                          }
                        </div>
                        {
                          passwordWarning ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *Please Enter this field
                            </p> : null
                        }
                        {
                          passwordError ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              *{passwordError}
                            </p> : <>
                            </>
                        }
                        {
                          data.error === "Wrong Password" ?
                            <p style={{ color: 'red', textAlign: 'center' }}>
                              Wrong Password
                            </p> : null
                        }
                      </div>
                      <Link to='/forgotPassword' style={{ color: '#4285F4', textDecoration: 'underline' }}>Forgot Password?</Link>
                      <div className="spacer-single"></div>
                      <div id='submit'>

                        <input onClick={handleSubmit} type='submit' id='send_message' value='Login' className="btn btn-main color-2" />
                        <div className="clearfix"></div>

                        <div className="spacer-single"></div>
                        <h4 className="black-color">Don't have any account? <Link to='/register' style={{ color: 'blue' }}>Sign Up</Link> </h4>
                        {/* <Link to='register'>Sign Up</Link> */}
                        {/* <ul className="list s3">
                               <li>Or login with:</li>
                               <li><span>Facebook</span></li>
                               <li><span>Google</span></li>
                               <li><span>Instagram</span></li>
                           </ul> */}
                      </div>
                    </form>

                  </div>
                </div>
              </section>

              <Footer />
            </div>

      }
    </>
  )
}
export default Logintwo;