import React, { memo, useState, useEffect } from 'react';
import Clock from "./Clock";
import './NFtToptable.css'
import { navigate } from '@reach/router';
import { styled } from '@mui/material/styles';
import api from '../../core/api';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';

const StyledTable = styled(Table)(({ theme }) => ({
    border: "none !important",
    fontFamily: "inherit !important",
    '& thead th': {
        backgroundColor: "white !important",
        fontSize: "14px !important",
        fontFamily: "inherit !important",
        fontWeight: 400,
        color: "#545454 !important",
        border: "none !important",
        textAlign: "left !important",
        padding: "16px !important",
        borderBottom: "1px solid rgba(0,0,0,.1) !important"
    },
}));

// Styled TableCell component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "10px",
    fontFamily: "inherit !important",
    fontWeight: 600,
    fontSize: "16px",
    border: "none !important",

}));

const GridContainer = styled(Grid)(({ theme }) => ({

    display: "flex",


}));
//react functional component
const NftToptable = ({ nfts }) => {
    const navigateTo = (link) => {
        navigate(link);
    }
    const midIndex = Math.ceil(nfts?.length / 2);
    const nftsFirstHalf = nfts?.slice(0, midIndex);
    const nftsSecondHalf = nfts?.slice(midIndex);
    // const useAudio = (url) => {
    //     const [audio] = useState(new Audio(url));
    //     const [playing, setPlaying] = useState(false);

    //     const toggle = () => setPlaying(!playing);

    //     useEffect(() => {
    //         playing ? audio.play() : audio.pause();
    //       },
    //       [playing]
    //     );

    //     useEffect(() => {
    //       audio.addEventListener('ended', () => setPlaying(false));
    //       return () => {
    //         audio.removeEventListener('ended', () => setPlaying(false));
    //         audio.pause();
    //       };
    //     }, [audio]);

    //     return [playing, toggle];
    // };

    // const [playing, toggle] = useAudio(audioUrl);

    return (
        <>
            <GridContainer container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                    <TableContainer >
                        <StyledTable aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>Rank</TableCell>
                                    <TableCell align='left'>Collection</TableCell>
                                    <TableCell align='left'>Floor</TableCell>
                                    <TableCell align='left'>Volume</TableCell>
                                </TableRow>
                            </TableHead>
                           { nftsFirstHalf?.length > 0 ? 
                            <TableBody>

                                {nftsFirstHalf?.map((nft, index) => (

                                    < TableRow key={index} onClick={() => { navigate('/Items', { state: { data: nft } }) }} style={{ cursor: "pointer" }}>
                                        <StyledTableCell>{index + 1}</StyledTableCell>
                                        <StyledTableCell >
                                            <div className='imgtextCell' >
                                                <div className='imgCell'>
                                                    <img src={nft?.Image} alt="" />
                                                </div>
                                                <div className='textCell'>
                                                    <p>{nft?.Collection_Name}</p>
                                                </div>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>  <div className='textCell'>
                                            <p>{nft?.florPrice} {nft?.Token_Symbol}</p>
                                        </div></StyledTableCell>
                                        <StyledTableCell><div className='textCell'>
                                            <p>{nft?.total_volumn.toFixed(8)} {nft?.Token_Symbol}</p>
                                        </div></StyledTableCell>

                                    </TableRow>

                                ))}
                            </TableBody> : 
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <div className='nodata-txt'>
                                        NO DATA FOUND !!!
                                        </div>
                                        </TableCell>
                                </TableRow>
                                {/* <span>!</span> */}
                                
                            </TableBody>
                            }
                        </StyledTable>
                    </TableContainer>

                </Grid>
               { <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>

                    <TableContainer>
                        <StyledTable aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align='left'>Rank</TableCell>
                                    <TableCell align='left'>Collection</TableCell>
                                    <TableCell align='left'>Floor</TableCell>
                                    <TableCell align='left'>Volume</TableCell>
                                </TableRow>
                            </TableHead>
                          {nftsSecondHalf?.length >0 ? 
                           <TableBody>


                                {nftsSecondHalf?.map((nft, index) => (

                                    <TableRow key={index} onClick={() => { navigate('/Items', { state: { data: nft } }) }} style={{ cursor: "pointer" }} >

                                        <StyledTableCell >{nftsFirstHalf?.length + index +1}</StyledTableCell>
                                        <StyledTableCell >
                                            <div className='imgtextCell ' >
                                                <div className='imgCell'>
                                                    <img src={nft?.Image} alt="" />
                                                </div>
                                                <div className='textCell'>
                                                    <p>{nft?.Collection_Name}</p>

                                                </div>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell>  <div className='textCell'>
                                            <p>{nft?.florPrice} {nft?.Token_Symbol}</p>
                                        </div></StyledTableCell>
                                        <StyledTableCell><div className='textCell'>
                                            <p>{nft?.total_volumn.toFixed(8)} {nft?.Token_Symbol}</p>
                                        </div></StyledTableCell>

                                    </TableRow>
                                ))}
                            </TableBody> :
                            // <span>NO DATA FOUND!!!</span>
                            <TableBody>
                                 <TableRow>
                                    <TableCell colSpan={4}>
                                        <div className='nodata-txt'>
                                        NO DATA FOUND !!!
                                        </div>
                                        </TableCell>
                                </TableRow>
                            </TableBody>
                            }
                        </StyledTable>
                    </TableContainer>

                </Grid>}
            </GridContainer >
        </>

    );
};

export default memo(NftToptable);