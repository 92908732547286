import React from 'react';
import { Link } from '@reach/router';

const catgor= () => (
  <div className='row'>
    <div className="col-md-2 col-sm-4 col-6 mb-3 category-container" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
        <Link className="icon-box style-2 rounded" to="">
            {/* <i className="fa fa-image"></i> */}
            <img src="./sports.png" alt="sports" style={{width:'100%'}} />
            <br/>
            <p style={{color:'black'}}>Sports</p>
            
        </Link>
    </div>
    <div className="col-md-2 col-sm-4 col-6 mb-3" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
        <Link className="icon-box style-2 rounded" to="">
        <img src="./collectibles.webp" alt="collectibles" style={{width:'100%'}} />
        <br/>
            <p style={{color:'black'}}>Collectibles</p>
        </Link>
    </div>
    <div className="col-md-2 col-sm-4 col-6 mb-3" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
        <Link className="icon-box style-2 rounded" to="">
        <img src="./domain-names.png" alt="domain names" style={{width:'100%'}} />
        <br/>
            <p style={{color:'black'}}>Domain Names</p>
        </Link>
    </div>
    <div className="col-md-2 col-sm-4 col-6 mb-3" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
        <Link className="icon-box style-2 rounded" to="">
        <img src="./utility.png" alt="utility" style={{width:'100%'}} />
        <br/>
            <p style={{color:'black'}}>Utility</p>
        </Link>
    </div>
    <div className="col-md-2 col-sm-4 col-6 mb-3" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
        <Link className="icon-box style-2 rounded" to="">
        <img src="./virtual-worlds.png" alt="virtual world" style={{width:'100%'}} />
        <br/>
            <p style={{color:'black'}}>Virtual World</p>
        </Link>
    </div>
    <div className="col-md-2 col-sm-4 col-6 mb-3" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom">
        <Link className="icon-box style-2 rounded" to="">
        <img src="./trading-cards.png" alt="trading cards" style={{width:'100%'}} />
        <br/>
            <p style={{color:'black'}}>Trading Cards</p>
        </Link>
    </div>
  </div>
);
export default catgor;