import React from 'react';
import Particle from '../components/Particle';
import SliderMainParticle from '../components/SliderMainParticle';
import SliderMainZero from '../components/SliderMainZero1';
import FeatureBox from '../components/FeatureBox';
import ColumnNewsound from '../components/ColumnNewsound';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import NftTabs from '../components/nftTabs';
import AuthorListReduxNew from '../components/AuthorListReduxNew';
import CatgorNew from '../components/CatgorNew';
import Footer from '../components/footer';
import './Styles/Viewallpage.css'
import { createGlobalStyle } from 'styled-components';
import ViewAllTabs from '../components/ViewAllTabs';
import one from '../../assets/one.svg'

// import Button from '@mui/material/Button';

const ButtonGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    margin: "20px",
    justifyContent: "center",
    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        margin: "0px 15px",
        width: "90%",
        height: "45px",
        textAlign: "center",
        color: "#030303",
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        },
        [theme.breakpoints.down('sm')]: {

            margin: "5px",
            padding: "0px 20px",
            fontSize: "13px",
        },
    },

}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;


const Viewallpage = () => {
    const [value, setValue] = React.useState(0);
    const [values, setValues] = React.useState(0);

   
   
    return (
        <div>
            <GlobalStyles />
            <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: '40px 20px 20px 20px' }}>
                <br /><br /><br /><br />
                <div className='text-left'>
                    <h2>Collection stats</h2>
                </div>
                <div>
                    <Box>
                        {/* sx={{ borderBottom: 1, borderColor: 'divider' }} */}
                        <Grid container spacing={3}>
                            <ButtonGrid item xs={12} sm={12} md={5} xl={5} lg={5}>
                                <Button>Trending</Button>
                                <Button>Top</Button>
                                <Button>WatchList</Button>
                            </ButtonGrid>
                        </Grid>

                    </Box>

                </div>
            </Box>

            <section className='container-fluid pb-0'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <ViewAllTabs />
                    </div>
                </div>
            </section>
            <Footer />
         
        </div>
    )

}
export default Viewallpage;