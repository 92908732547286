import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import logoDark from "../../assets/logo/Fraktionlogo_white.webp";
import logoWhite from "../../assets/logo/logo-color.webp";
import logoSmall from "../../assets/logo/wallex_white.svg";
import wallexLogo from '../../assets/icons/Logo-Wallex.png'
import facebook from '../../assets/facebook.svg'
import twitter from '../../assets/twitter.svg'
import linkedin from '../../assets/linkedin.svg'
import instagram from '../../assets/instagram.svg'
import medium from '../../assets/medium.svg'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { Button } from "@mui/material";
import fb from '../../assets/facebook.png'
import soc from '../../assets/social.png'
import med from '../../assets/medium.png'
import inst from '../../assets/instagram.png'
import twet from '../../assets/tweet.png'
import { useNavigate } from "@reach/router";
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius:'10px',
  p: 4,
};
const Footer = () => {
  const [isDark, setIsDark] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("mode") === "dark") {
      setIsDark(true);
    } else if (localStorage.getItem("mode") === "light") {
      setIsDark(false);
    }
  }, []);

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const [openModel, setOpenModel] = useState(false);
  const handleOpenModel = () => setOpenModel(true);
  const handleCloseModel = () => setOpenModel(false);

  const [openModel2, setOpenModel2] = useState(false);
  const handleOpenModel2 = () => setOpenModel2(true);
  const handleCloseModel2 = () => setOpenModel2(false);

const navigate= useNavigate()
  return (
    <footer
      className="headerColor gradient-border-footer"
      style={{ paddingTop: "50px" }}
    >
      <div className="container ">
        <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-12">
        <div className="logo-wal">
              <img src={wallexLogo} alt="asd" />
            </div>
          </div>
          <div className="col-md-5 col-sm-6 col-xs-12 m-10">
          <div>
              <h5 className="whiteColor2">Join Now
              </h5>
              <div className="displays-1">
                <div className="jn-btn">
                  <Button onClick={handleOpenModel}>Sign Up Individuals</Button>
                </div>
                <div className="jn-btn">
                  <Button onClick={handleOpenModel2}>Sign Up Business</Button>
                </div>
              </div>

            </div>
          </div>
          {/* <div className="col-md-4 col-sm-6 col-xs-12 m-10">
          <div>
              <h5 className="whiteColor2">Mobile App
              </h5>
              <div className="displays-1">
              <div className="mb-asset">
              <img width="30" height="30" src="https://img.icons8.com/ios-filled/250/mac-os.png" alt="mac-os"/>
                </div>
                <div className="mb-asset">
                <img width="30" height="30" src="https://img.icons8.com/ios-filled/30/google-play.png" alt="google-play"/>
                </div>
            
              </div>
            </div>
          </div> */}
          <div className="col-md-4 col-sm-6 col-xs-12 m-10">
          <div >
              <h5 className="whiteColor2">Follow us</h5>
              <div className="displays-1 smx">


                <a rel="noreferrer" href="https://www.facebook.com/profile.php?id=100083728852015&mibextid=LQQJ4d" target="_blank">
                  <span className="icon-span">
                    {/* <i class="fa-brands fa-facebook-f"></i> */}
                    <img src={fb} alt="alt" />
                  </span>



                </a>

                <a rel="noreferrer" href="https://twitter.com/wallex_official" target="_blank">
                  <span className="icon-span twet">
                    {/* <i class="fa-brands fa-linkedin-in"></i> */}
                    
                    <img src={twet} alt="alt" />
             
                  </span>

                </a>

                <a rel="noreferrer" href="https://www.linkedin.com/company/wallextrustcustody/" target="_blank">
                  <span className="icon-span">
                {/* <i class="fa-brands fa-square-x-twitter"></i> */}
                <img src={soc} alt="alt" />
                </span>
                  {/* <FontAwesomeIcon icon="fa-brands fa-x-twitter" /> */}
                  
                </a>

                <a rel="noreferrer" href="https://www.instagram.com/wallex_global/" target="_blank">
                  <span className="icon-span">
                    {/* <i class="fa-brands fa-instagram"></i> */}
                    <img src={inst} alt="alt" />
                  </span>

                </a>

                <a rel="noreferrer" href="https://medium.com/@wallexcustody/" target="_blank">
                  <span className="icon-span twet">
                    {/* <i class="fa-brands fa-medium"></i> */}
                    <img src={med} alt="alt" />
                  </span>

                </a>

              </div>
            </div>
          </div>
          <div className="footer_social_container">
                     
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1 m-10">
            <div className="widget ">
              <h5 className="whiteColor2">Solutions
              </h5>
              <ul className="whiteColor">
                <li>
                  <a rel="noreferrer" href="https://wallex.global/neobanking" target="_blank" className="whiteColor">
                    Neobanking
                  </a>

                </li>
                <li>
                  <a href="https://wallex.global/custody-pro" target="_blank" className="whiteColor">
                    Custody Pro
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/smart-custody" target="_blank" className="whiteColor">
                    Smart Custody
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/self-managed-custody" target="_blank" className="whiteColor">
                    Self Custody
                  </a>
                </li>
                <li>
                  <a href="https://nft.wallex.global/" target="_blank" className="whiteColor">
                    NFT
                  </a>
                </li>
                <li>
                  <a href="https://www.wallexlab.com/" target="_blank" className="whiteColor">
                    Wallex Lab
                  </a>
                </li>
                <li>


                  <ListItemButton onClick={handleClick} className="list-footer">

                    <ListItemText primary="Prime" className="whiteColor"/>
                    {open ? <KeyboardArrowRightIcon /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <li>
                      <a href="https://wallex.global/corporate" target="_blank" className="whiteColor">
                        Corporate
                      </a>
                    </li>
                    <li>

                      <a href="https://wallex.global/personal" target="_blank" className="whiteColor">
                        Personal
                      </a>
                    </li>

                  </Collapse>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1 m-10">
            <div className="widget">
              <h5 className="whiteColor2">Help</h5>
              <ul className="whiteColor">
                <li>
                  <a href="/helpcenter-new" target="_blank" className="whiteColor">
                    Help Center
                  </a>
                </li>
                <li>
                  <a href="https://experience.wallex.global/wallex/system-status" target="_blank" className="whiteColor">
                    System Status
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/wallexpedia" target="_blank" className="whiteColor">
                    Wallexpedia
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/contact-us" target="_blank" className="whiteColor">
                    Contacts
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/referral" target="_blank" className="whiteColor">
                    Invite a Friend
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/wallex-assets/" target="_blank" className="whiteColor">
                    Assets
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/fees" target="_blank" className="whiteColor">
                    Fees
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1 m-10">
            <div className="widget">
              <h5 className="whiteColor2">Company</h5>
              <ul className="whiteColor">
                <li>
                  <a href="https://wallex.global/about" target="_blank" className="whiteColor">
                    About
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/security" target="_blank" className="whiteColor">
                    Security
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/careers" target="_blank" className="whiteColor">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/foundation/" target="_blank" className="whiteColor">
                    Foundation
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/press-kit" target="_blank" className="whiteColor">
                    Press Kit
                  </a>
                </li>

              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1 m-10">
            <div className="widget ">
              <h5 className="whiteColor2">Policies</h5>
              <ul className="whiteColor">
                <li>
                  <a href="https://wallex.global/policies" target="_blank" className="whiteColor">
                    Policies
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/terms-and-conditions" target="_blank" className="whiteColor">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/savings-terms-and-conditions" target="_blank" className="whiteColor">
                    Savings Terms
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/wx-credit-terms" target="_blank" className="whiteColor">
                    WX-Credit Terms
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/refer-a-friend-terms" target="_blank" className="whiteColor">
                    Refer a friend Terms
                  </a>
                </li>
                <li>
                  <a href="https://wallex.global/crypto-assets-terms" target="_blank" className="whiteColor">
                    Crypto Assets Terms
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                  <span onClick={() => window.open("", "_self")}>
                    {/* <hr/> */}

                    <div className="footer_line"></div>

                    <div>
                      <p className="copy-p">Copyright<strong>©2024Wallex</strong></p>
                      <p className="copy-p2t">
                        Wallex GLOBAL Services are not available in United States , UK and other prohibited jurisdictions. No legal, tax, investment, or other advice is provided by any Wallex entity. Please consult your legal/tax/investment professional for questions about your specific circumstances. Digital asset holdings involve a high degree of risk, and can fluctuate greatly on any given day. Accordingly, your digital asset holdings may be subject to large swings in value and may even become worthless.
                      </p>
                    </div>

                    {/* <img className="f-logo d-1"   alt="#"
                    width='50%'
                    height='50%'src={logoSmall} style={{marginTop:'-10px'}} /> */}

                    {/* <div className="logo-nft">
                <img
                   src={logoSmall}
                    alt="#"
                    width='50%'
                    height='50%'
                    style={{marginTop:'-10px'}}
                  />
                  <div className="vertical-line"></div>
                  <h3 className="mildWhite" style={{marginTop:'20px'}}>
                    NFT
                  </h3>
                  </div> */}
                    {/* <img alt="" className="f-logo d-3" src={logoSmall} />
                                              <img alt="" className="f-logo d-4" src={logoSmall}/> */}
                    {/* {
                                                  isDark?
                                                  <img alt='' src={logoDark} width='150px'/>:
                                                  <img alt='' src={logoWhite} width='150px'/>
                                              } */}

                    {/* <h3 className='whiteColor' style={{marginTop:'15px'}}>Wallex</h3> */}
                  </span>
                </div>
                {/* <div className="de-flex-col">
                                          <div className="social-icons">
                                          <span className="copy whiteColor" >Copyright © 2022 Wallex. All rights reserved.</span> */}
                {/* <span onClick={()=> window.open("", "_self")}><i className="fa fa-facebook fa-lg"></i></span>
                                              <span onClick={()=> window.open("", "_self")}><i className="fa fa-twitter fa-lg"></i></span>
                                              <span onClick={()=> window.open("", "_self")}><i className="fa fa-linkedin fa-lg"></i></span>
                                              <span onClick={()=> window.open("", "_self")}><i className="fa fa-pinterest fa-lg"></i></span>
                                              <span onClick={()=> window.open("", "_self")}><i className="fa fa-rss fa-lg"></i></span> */}
                {/* </div>
                                      </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
  open={openModel}
  // onClose={handleCloseModel}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <div className="displays-2">
  <div className="logo-wal">
              <img src={wallexLogo} alt="asd" style={{width:"40px", height:"40px"}} />
            </div>
           <CloseIcon className="cursor" onClick={handleCloseModel}/>
  </div>

  <div className="pop-head">
  Open an Individual Account:

  </div>
  <div className="pop-btn">
    <Button variant="contained" onClick={()=>navigate('https://app.wallex.global/auth/sign-up')}>NEO BANKING <ArrowRightAltIcon /></Button>
  </div>
  <div className="pop-btn">
    <Button variant="contained"  onClick={()=>navigate('https://app.pro.wallexcustody.com/auth/sign-up')}>CUSTODY PRO <ArrowRightAltIcon /></Button>
  </div>
  </Box>
</Modal>

<Modal
  open={openModel2}
  // onClose={handleCloseModel2}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <div className="displays-2">
  <div className="logo-wal">
              <img src={wallexLogo} alt="asd" style={{width:"40px", height:"40px"}} />
            </div>
           <CloseIcon className="cursor" onClick={handleCloseModel2} />
  </div>

  <div className="pop-head">
  Open a Business Account:

  </div>
  <div className="pop-btn">
    <Button variant="contained" onClick={()=>navigate('https://app.wallex.global/auth/sign-up')}>NEO BANKING <ArrowRightAltIcon /></Button>
  </div>
  <div className="pop-btn">
    <Button variant="contained" onClick={()=>navigate('https://app.pro.wallexcustody.com/auth/sign-up')}>CUSTODY PRO <ArrowRightAltIcon /></Button>
  </div>
  </Box>
</Modal>
    </footer>
  );
};

export default Footer;
