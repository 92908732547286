import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from "../../menu/header";
import Footer from "../../components/footer";
import './HelpCenter.css'
import Axios from "../../../api-instance"
// import togglebtn from '../../img/togglebtn.png'
// import Menuclose from '../../img/Menuclose.png'
import { Link } from "@reach/router";
// import customer from '../../img/customer.svg'
// import maptrace from '../../img/maptrace.svg'
// import guarantee from '../../img/guarantee.svg'
// import bestseller from '../../img/bestseller.svg'
// import bill from '../../img/bill.svg'
// import product from '../../img/product.svg'
// import supplychain from '../../img/supplychain.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
  helpcommoninner: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    textAlign: 'left !important'
  }
});

const DetailPage = () => {

  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)
  const [faqData1, setFaqData1] = useState([])
  const [selectedCategory1, setSelectedCategory1] = useState('');
  const [selectedQuestion1, setSelectedQuestion1] = useState('')
  const [selectedAnswer1, setSelectedAnswer1] = useState('')

  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  const [selectedFAQ, setSelectedFAQ] = useState(0); // 

  const handleCategoryClick2 = (questionId) => {
    const selectedFaq = faqData1.find((faq) => faq._id === questionId);
    console.log(selectedFaq, "faqqq")
    if (selectedFaq) {
      // setSelectedQuestion(selectedFaq.question);
      setSelectedAnswer1(selectedFaq.answer);
    }

    setSelectedFAQ(questionId)
  };

  const faqBuyingList = async () => {
    try {
      const payload = {
        name: 'Buying'

      }
      console.log(payload, "payyy")
      const response = await Axios.post('/admin/getquestByName', payload)
      console.log(response, "checkdata")
      if (response && response.status === 200) {
        const deta = response?.data?.data
        setFaqData1(deta)
        setSelectedAnswer1(deta[0].answer)
      } else {
        console.log("No Data Found")
      }



    } catch (error) {
      console.log(error, "error")

    }
  }

  useEffect(() => {
    faqBuyingList();
  }, []);

  console.log(selectedAnswer1, 'selectedAnswer1');

  const [faq, setFaq] = useState([
    { name: "How to manage my bid offers?" },
    { name: "How do I participate in a Primary Drop?" },
    { name: "Why did my transaction fail or take time for confirmation?" },
    { name: "How do I place a bid on an NFT?" },
    { name: "Where can I see my Buy history?" },

  ])

  const [val, setVal] = useState(0)

  console.log(val, 'val');


  const handleVal = (ind) => {
    setVal(ind)
    setSelectedFAQ(ind)
    setSelected(true)
  }
  const [selected, setSelected] = useState(false)
  const handleCloseSelect = () => {
    setSelected(false)
  }

  return (
    <div className="dashboard-page help-center-main">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="outer-bgcolor-container"
          >
            <Item className={classes.bodyclass}>
              <div className="togglebtn" onClick={openMenuHandler}></div>
              <div
                className="categories-menu"
                id={!menuOpen ? "openmenucls" : ""}
              >
                <div
                  className="togglebtn-close"
                  onClick={closeMenuHandler}
                ></div>
                <div className="categories-menu-inner">
                  {/* <Link><div className='iconic'><img src={customer} alt={customer} /></div>Customer</Link>
    <Link><div className='iconic'><img src={maptrace} alt={maptrace} /></div>Traceability</Link>
    <Link><div className='iconic'><img src={guarantee} alt={guarantee} /></div>Products</Link>
    <Link><div className='iconic'><img src={bestseller} alt={bestseller} /></div>Blockchain</Link>
    <Link><div className='iconic'><img src={bill} alt={bill} /></div>Post-sale</Link>
    <Link><div className='iconic'><img src={product} alt={product} /></div>Billing</Link>
    <Link><div className='iconic'><img src={supplychain} alt={supplychain} /></div>Sellers Authorization </Link> */}
                </div>
              </div>

              <div className="detail-pages-inner-outer">
                <Box sx={{ flexGrow: 1 }}>
                  <div className="breadcrum-helpcenter">
                    <Link to="/helpcenter-new">Help Center</Link> / Buying
                  </div>

                  <Grid
                    container
                    spacing={0}
                    className={classes.helpcenterbodycontain}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="link-left-side-detail-id">
                      {selected ?
                        <>
                          <div style={{ textAlign: 'left', cursor: 'pointer', marginBottom: "30px" }} onClick={handleCloseSelect}>
                            <ArrowBackIcon />
                          </div>

                          <Item className={classes.helpcommoninner}>
                            {/* {selectedQuestion && <h2>{selectedQuestion}</h2>} */}
                            {/* {selectedAnswer1 && <div dangerouslySetInnerHTML={{ __html: selectedAnswer1 }}></div>} */}

                            {val === 0 && <>
                              <h2><strong>How to manage my bid offers?</strong></h2>
                              <p>When selling an NFT using Wallex NFT, you might receive offers on your item that you feel are too low for you to seriously consider. To prevent your inbox from getting flooded with these low offers, here are three tools you can use to control and customize your selling experience on Wallex NFT.</p><p>&nbsp;</p>
                              <h2><strong>Set minimum offers per collection</strong></h2>
                              <p>If you don’t want to receive an email for every offer on your item, you can set a minimum offer amount for each collection in your offer settings.&nbsp;</p>
                              <p>When you set a minimum offer amount, it will override the Minimum Bid Threshold on the notification page&nbsp;and apply to all offers, including collection offers. This also prevents offers from being visible in the "Offers" section of your item, and in your "Offers received" tab in your Wallex NFT account.&nbsp;</p>
                              <p>If you own more than one NFT in a collection, make sure your minimum offer takes into account the lowest offer you would consider for any NFT from that collection</p>
                            </>}
                            {val === 1 && <>
                              <h2><strong>How do I participate in a Primary Drop?</strong></h2>
                              <p>To participate in a Primary Drop on WallexNFT, you'll need a crypto wallet to store your NFT, digital currency to purchase an NFT, and an Wallex account.&nbsp;</p>
                              <p>We recommend completing these steps well in advance of the start time so you’re ready to go when the drop starts and the NFTs are available to mint. Let’s dive in!</p>
                              <h2><strong>1. Install a crypto wallet</strong></h2>
                              <p>A crypto wallet, such as metamask, stores your NFTs and digital currency and processes transactions on the Ethereum (ETH) blockchain. Each wallet has a unique address, which you'll use to complete transactions.</p>
                              <p>You can see the available types of wallets in our help center guide, what crypto wallet can i use with<a href="https://support.opensea.io/hc/articles/1500007978402"> </a>WallexNFT</p>
                              <h2><strong>2. Purchase digital currency</strong></h2>
                              <p>Once you have a crypto wallet installed, you can buy ETH, the digital currency that fuels transactions on the Ethereum blockchain. You can purchase ETH from a digital currency exchange like coinbase. You'll need ETH to mint and purchase an NFT, and for the gas fees needed to complete your transaction.</p><p>&nbsp;</p>
                            </>}


                            {val === 2 && <>
                              <h2><strong>Why did my transaction fail or take time for confirmation?</strong></h2>
                              <p>There are four common errors you might encounter with failed transactions. You can view your transaction on Etherscan and check the <strong>Status</strong> row to see why the transaction failed.
                              </p><ul><li><strong>Reverted:</strong> this is the most common error when purchasing an NFT. The failure refers to the loss of the transaction (gas) fee, not the value of the item you attempted to purchase. In this situation, it’s usually the person who paid the most gas who will get to buy the item. This can happen if multiple users are attempting to purchase the same item at the same time.</li><li><strong>Dropped and Replaced: </strong>this means the transaction was dropped and replaced by a new one. For more information, you can refer to this Etherscan .</li><li><strong>Out of gas: </strong>this occurs when the gas limit of your transaction was set too low. Default gas limit settings for transactions are automatically calibrated on crypto wallets like MetaMask. <strong>Changing these gas limit settings may increase the chances of a failed transaction</strong>.</li><li><strong>Pending: </strong>sometimes Ethereum transactions can get stuck in a "pending" state when the transaction has been submitted with a low gas price. While fixing this error will vary by wallet provider, here's metamask guide.</li><li>For bulk purchase, you can experience one of these errors on one or more of the NFTs you’re attempting to buy, resulting in a failed or partially successful purchase.</li></ul>
                            </>}

                            {val === 3 && <>
                              <h2><strong>How do I place a bid on an NFT?</strong></h2>
                              <p>You can bid on specific NFTs or on entire collections.</p>
                              <p>Placing a “floor bid” on a collection will let any owner of any NFT in the selected collection accept it. If you bid on an individual item, only that NFT's owner can accept it.</p>
                              <p>By placing a floor bid, you might get any NFT from the collection, but the chance is greater that your bid will be accepted. By bidding on an individual item, only one person can accept your bid, but you'll only get the item you chose.</p>
                            </>}

                            {val === 4 && <>
                              <h2><strong>Where can I see my Buy history?</strong></h2><p>Etherscan is a useful tool for exploring the Ethereum blockchain. If you've sold an item on Ethereum, you can search your wallet address to see your token balance or enter a transaction hash to verify transaction history. It's an easy way to verify whether a transaction was completed.</p><p>If you're using the MetaMask wallet, click on your Ethereum balance. Then select the three dots at the top right of the extension, and select <strong>View Asset in explorer</strong>&nbsp;to view your token balance and transaction history on Etherscan.&nbsp;</p><p>Transactions such as NFT sales can be viewed under the "<strong>Internal Txns</strong>" tab on an Etherscan/Polygonscan account page.</p><p>Keep in mind the transaction records will show the fee received by Wallex NFT and, if any, creator earnings received by a project’s creator. Crypto wallets like MetaMask will not display internal transactions.</p>
                            </>}





                          </Item>
                        </> :

                        <>
                          <h4>Article in this section</h4>
                          {faq.map((faq, index) => (
                            <div className={index === selectedFAQ ? "display-2 redirect-link-specific active" : "display-2 redirect-link-specific"} onClick={() => handleVal(index)} >
                              <div
                                key={faq.id}
                                // to="#"
                                style={{ cursor: 'pointer' }}


                              >
                                <p>{faq.name}</p>
                              </div>
                              <div>
                                <NavigateNextIcon />
                              </div>
                            </div>

                          ))}
                        </>}
                    </Grid>
                    {/* <Grid item xs={12} lg={2} xl={2} id="link-left-side-id">
                      <Item className="helpcommoninner">
                        <h4>Article in this section</h4>
                        {faq.map((faq, index) => (
                          <div
                            key={faq.id}
                            // to="#"
                            style={{ cursor: 'pointer' }}
                            className={index === selectedFAQ ? "redirect-link-specific active" : "redirect-link-specific"}
                            onClick={() => handleVal(index)}
                          >
                            <p>{faq.name}</p>
                          </div>
                        ))}
                      </Item>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={12} lg={10} xl={10} id="link-left-side-detail-id">
                      <Item className={classes.helpcommoninner}>
                        {selectedQuestion && <h2>{selectedQuestion}</h2>}
                        {selectedAnswer1 && <div dangerouslySetInnerHTML={{ __html: selectedAnswer1 }}></div>}
                        
                        {val === 0 && <>
                          <h2><strong>How to manage my bid offers?</strong></h2>
                        <p>When selling an NFT using Wallex NFT, you might receive offers on your item that you feel are too low for you to seriously consider. To prevent your inbox from getting flooded with these low offers, here are three tools you can use to control and customize your selling experience on Wallex NFT.</p><p>&nbsp;</p>
                        <h2><strong>Set minimum offers per collection</strong></h2>
                        <p>If you don’t want to receive an email for every offer on your item, you can set a minimum offer amount for each collection in your offer settings.&nbsp;</p>
                        <p>When you set a minimum offer amount, it will override the Minimum Bid Threshold on the notification page&nbsp;and apply to all offers, including collection offers. This also prevents offers from being visible in the "Offers" section of your item, and in your "Offers received" tab in your Wallex NFT account.&nbsp;</p>
                        <p>If you own more than one NFT in a collection, make sure your minimum offer takes into account the lowest offer you would consider for any NFT from that collection</p>
                        </>}
                    {val === 1 && <>
                      <h2><strong>How do I participate in a Primary Drop?</strong></h2>
                        <p>To participate in a Primary Drop on WallexNFT, you'll need a crypto wallet to store your NFT, digital currency to purchase an NFT, and an Wallex account.&nbsp;</p>
                        <p>We recommend completing these steps well in advance of the start time so you’re ready to go when the drop starts and the NFTs are available to mint. Let’s dive in!</p>
                        <h2><strong>1. Install a crypto wallet</strong></h2>
                        <p>A crypto wallet, such as metamask, stores your NFTs and digital currency and processes transactions on the Ethereum (ETH) blockchain. Each wallet has a unique address, which you'll use to complete transactions.</p>
                        <p>You can see the available types of wallets in our help center guide, what crypto wallet can i use with<a href="https://support.opensea.io/hc/articles/1500007978402"> </a>WallexNFT</p>
                        <h2><strong>2. Purchase digital currency</strong></h2>
                        <p>Once you have a crypto wallet installed, you can buy ETH, the digital currency that fuels transactions on the Ethereum blockchain. You can purchase ETH from a digital currency exchange like coinbase. You'll need ETH to mint and purchase an NFT, and for the gas fees needed to complete your transaction.</p><p>&nbsp;</p> 
                    </>}

                      
{val === 2 && <>
  <h2><strong>Why did my transaction fail or take time for confirmation?</strong></h2><p>There are four common errors you might encounter with failed transactions. You can view your transaction on Etherscan and check the <strong>Status</strong> row to see why the transaction failed.</p><ul><li><strong>Reverted:</strong> this is the most common error when purchasing an NFT. The failure refers to the loss of the transaction (gas) fee, not the value of the item you attempted to purchase. In this situation, it’s usually the person who paid the most gas who will get to buy the item. This can happen if multiple users are attempting to purchase the same item at the same time.</li><li><strong>Dropped and Replaced: </strong>this means the transaction was dropped and replaced by a new one. For more information, you can refer to this Etherscan .</li><li><strong>Out of gas: </strong>this occurs when the gas limit of your transaction was set too low. Default gas limit settings for transactions are automatically calibrated on crypto wallets like MetaMask. <strong>Changing these gas limit settings may increase the chances of a failed transaction</strong>.</li><li><strong>Pending: </strong>sometimes Ethereum transactions can get stuck in a "pending" state when the transaction has been submitted with a low gas price. While fixing this error will vary by wallet provider, here's metamask guide.</li><li>For bulk purchase, you can experience one of these errors on one or more of the NFTs you’re attempting to buy, resulting in a failed or partially successful purchase.</li></ul>
</>}

               {val === 3 && <>
                <h2><strong>How do I place a bid on an NFT?</strong></h2>
                        <p>You can bid on specific NFTs or on entire collections.</p>
                        <p>Placing a “floor bid” on a collection will let any owner of any NFT in the selected collection accept it. If you bid on an individual item, only that NFT's owner can accept it.</p>
                        <p>By placing a floor bid, you might get any NFT from the collection, but the chance is greater that your bid will be accepted. By bidding on an individual item, only one person can accept your bid, but you'll only get the item you chose.</p> 
               </>}         

               {val === 4 && <>
                <h2><strong>Where can I see my Buy history?</strong></h2><p>Etherscan is a useful tool for exploring the Ethereum blockchain. If you've sold an item on Ethereum, you can search your wallet address to see your token balance or enter a transaction hash to verify transaction history. It's an easy way to verify whether a transaction was completed.</p><p>If you're using the MetaMask wallet, click on your Ethereum balance. Then select the three dots at the top right of the extension, and select <strong>View Asset in explorer</strong>&nbsp;to view your token balance and transaction history on Etherscan.&nbsp;</p><p>Transactions such as NFT sales can be viewed under the "<strong>Internal Txns</strong>" tab on an Etherscan/Polygonscan account page.</p><p>Keep in mind the transaction records will show the fee received by Wallex NFT and, if any, creator earnings received by a project’s creator. Crypto wallets like MetaMask will not display internal transactions.</p> 
               </>}

                       

                       

                      </Item>
                    </Grid> */}
                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={2}
                      xl={2}
                      id="link-left-side-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h4>Article in this section</h4>

                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How to buy fixed-price NFTs?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How do I place a bid on an NFT?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How to manage my bid offers?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          Why did my transaction fail or take time for
                          confirmation?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          Where can I see my Buy history?
                        </Link>
                      </Item>
                    </Grid> */}

                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={10}
                      xl={10}
                      id="link-left-side-detail-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h2>How to buy fixed-price NFTs?</h2>
                        <h4>How do I place a bid on an NFT?</h4>
                        <h4>How to manage my bid offers?</h4>
                        <h4>
                          Why did my transaction fail or take time for
                          confirmation?
                        </h4>
                        <h4>Where can I see my Buy history?</h4>
                      </Item>
                    </Grid> */}
                  </Grid>
                </Box>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <Footer />

          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default DetailPage;
