// require('dotenv').config();
import axios from 'axios'
const key = '80fe0b8cb52fe4955782';
const secret = 'a81b6b8456aae38c5cc35aa2773ea5126c5fc5ca50b3973db52bb554a9476658';

// const axios = require('axios');
export const pinJSONToIPFS = async(JSONBody) => {   
    
     const url = "https://api.pinata.cloud/pinning/pinJSONToIPFS";    
     //making axios POST request to Pinata ⬇️    
     return await axios.post(url, JSONBody, {            
         headers: {               
              pinata_api_key: key,                
              pinata_secret_api_key: secret,            
            }        
        })        
        .then(function (response) {      
           
            return {               
                success: true,               
                pinataUrl: "https://gateway.pinata.cloud/ipfs/" + response.data.IpfsHash           
            };        })        
            .catch(function (error) {            
                console.log(error)            
                return {                
                    success: false,                
                    message: error.message,            
                }    
            });
        };