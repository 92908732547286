import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Table, Grid } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ExploreContentLoader from '../components/exploreContentLoader'
import { useState } from 'react';
import { useEffect } from 'react';


const StyledTable = styled(Table)(({ theme }) => ({
  border: "none !important",
  fontFamily: "inherit !important",
  '& thead th': {
    backgroundColor: "white !important",
    fontSize: "14px !important",
    fontFamily: "inherit !important",
    fontWeight: 400,
    color: "#545454 !important",
    border: "none !important",
    textAlign: "left !important",
    padding: "16px !important",
    borderBottom: "1px solid rgba(0,0,0,.1) !important"
  },
}));

// Styled TableCell component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "10px",
  fontFamily: "inherit !important",
  fontWeight: 600,
  fontSize: "16px",
  border: "none !important",

}));

const GridContainer = styled(Grid)(({ theme }) => ({

  display: "flex",


}));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({

  // border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  borderRadius: '10px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(f, f, f, 1)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    marginTop: "20px",
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiTypography-root': {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "600"
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  height: "350px",
  overflow: "auto !important",
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions({ his, blockchain }) {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [nfts, setNfts] = useState([])
  useEffect(() => {
    console.log(his, "value")
    let arr = []
    if (his?.length > 0) {
      for (let i = 0; i < his.length; i++) {
        const element = his[i];
        let dt = {
          price: `${element?.auctionPriceValue} ${blockchain}`,
          USDT_Price: 0,
          Quantity: 1,
          floor_Difference: 0,
          From: element?.userData?.username
        }
        arr.push(dt)
      }
      setNfts(arr)
    }
  }, [his])
  // const nfts = [
  //   {
  //     price: "0.0103 WETH",
  //     USDT_Price: "$16.90",
  //     Quantity: "1",
  //     floor_Difference: "36% below",
  //     Expire: "6 days",
  //     From: "solutiondown"
  //   }
  // ]

  return (
    <div style={{ "margin": "20px" }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography> <ListRoundedIcon />Offers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer >
            <StyledTable aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Price</TableCell>
                  <TableCell align='left'>USD Price</TableCell>
                  <TableCell align='left'>Quantity</TableCell>

                  <TableCell align='left'>Floor Difference</TableCell>
                  {/* <TableCell align='left'>Expiration</TableCell> */}
                  <TableCell align='left'>From</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log(nfts.length)}

                {nfts.map((nft, index) => (

                  <TableRow key={index}>

                    <StyledTableCell>


                      <div className='textCell'>
                        <p>{nft?.price}</p>

                      </div>

                    </StyledTableCell>
                    <StyledTableCell>  <div className='textCell'>
                      <p>{nft?.USDT_Price}</p>
                    </div></StyledTableCell>
                    <StyledTableCell><div className='textCell'>
                      <p>{nft?.Quantity}</p>
                    </div></StyledTableCell>
                    <StyledTableCell>  <div className='textCell'>
                      <p>{nft?.floor_Difference}</p>
                    </div></StyledTableCell>
                    {/* <StyledTableCell><div className='textCell'>
                      <p>{nft?.Expire}</p>
                    </div></StyledTableCell> */}
                    <StyledTableCell><div className='textCell'>
                      <p style={{ "color": "#2081e2" }}>{nft?.From}</p>
                    </div></StyledTableCell>

                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

    </div>
  );
}
