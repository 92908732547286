import React,{useState,useEffect} from 'react';
import Wallet from '../components/wallet';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';




//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from '../Styles';
//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREYLOGIN'; //LIGHT, GREY, RETRO

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const Wallets= () =>{ 

  const [walletName, setWalletName] = useState(null)

  

  
 

  const [isDark, setIsDark] = useState(false)
  useEffect(()=>{
    if(localStorage.getItem('mode')==='dark'){
       setIsDark(true)
    }else if(localStorage.getItem('mode')==='light'){
      setIsDark(false)
    }
  },[])
  return(
    <>
    {
      isDark?
<div className="greyscheme">
<StyledHeader theme={theme} />
  <section className='jumbotron breadcumb no-bg' >
    <div className='mainbreadcumb'>
      <div className='container'>
        <div className='row m-10-hor'>
          <div className='col-12'>
            <h1 className='text-center'>Wallet</h1>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className='container'>
    <Wallet/>
  </section>

  <Footer />
</div>
      :
      
        <div>
        <GlobalStyles/>
        
          <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.webp'})`}}>
            <div className='mainbreadcumb'>
              <div className='container'>
                <div className='row m-10-hor'>
                  <div className='col-12'>
                    <h1 className='text-center'>Wallet</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
        
          <section className='container'>
            <Wallet  />
          </section>
        
          <Footer />
        </div>
        
        
    }
    </>
  )
}
export default Wallets;