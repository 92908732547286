import React, { memo, useEffect, useState } from "react";
import useCallback from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ColumnNewRedux from '../components/ColumnNewRedux';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import * as selectors from '../../store/selectors';
import { fetchAuthorList } from "../../store/actions/thunks";
import Myprofileimage from "./Myprofileimage"
import api from "../../core/api";
import { StyledHeader } from '../Styles';
import CheckboxFilter from '../components/CheckboxFilter';
import Axios from '../../api-instance'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import SearchIcon from '@mui/icons-material/Search';
import TabPanel from '@mui/lab/TabPanel';
//IMPORT DYNAMIC STYLED COMPONENT
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Select from '@mui/material/Select';
import Eth from '../../assets/eth.svg'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import "./myProfile.css"
import Typography from '@mui/material/Typography';
import NftCard from "../components/NftCard";
import CollectionCard from "./myprofilecard"
import Nfttable from "./Nfttable";
import Myprofiletable from "./Myprofiletable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NftCreatedCard from "../components/NftCreatedCard";
import NftPurchasedCard from "../components/NftPurchasedCard";
import NftSale from "../components/NftSale";
import NftSoldCard from "../components/NFTSoldedCard";
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import { Link, useNavigate } from '@reach/router';
import ExploreContentLoader from '../components/LoaderNew'
import constants from '../../constants'
import { useLocation, useParams } from "@reach/router";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Grid, Button, Box } from '@mui/material';
import constant from '../../constants'
import { styled } from '@mui/material/styles';
import { classNames } from "react-select/dist/index-ea9e225d.cjs.prod";
import { TabContext } from "@mui/lab";
import { Gallery } from "react-grid-gallery";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;
const RightGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 30px",
    [theme.breakpoints.down('sm')]: {
        display: "flex",
        justifyContent: "center",
    },
}));
const RightGrids = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "flex-start",
    margin: "10px 30px",
    [theme.breakpoints.down('md')]: {
        display: "flex",
        justifyContent: "center",
    },
}));
const GridContainer = styled(Grid)(({ theme }) => ({

    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",

    [theme.breakpoints.down('md')]: {
        flexWrap: "wrap",
    },

    '& .MuiSvgIcon-root': {
        margin: "1px 10px",
        fontSize: "30px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px"
        },
    }
}));
const GridContainers = styled(Grid)(({ theme }) => ({

    display: "flex",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
        flexWrap: "wrap",
    },
    '& .MuiSvgIcon-root': {
        margin: "1px 10px",
        fontSize: "30px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px"
        },
    }
}));
const GridContainered = styled(Grid)(({ theme }) => ({

    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down('md')]: {
        flexWrap: "wrap",
        justifyContent: "center",
    },
    '& .MuiSvgIcon-root': {
        margin: "1px 10px",
        fontSize: "30px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px"
        },
    }
}));
const LeftGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',

    margin: "10px 30px",
    '& img': {
        width: "20px"
    },
    '& h2': {
        marginBottom: "-1px !important"
    },
    '& p': {
        fontSize: '20px',
        marginBottom: "1px"
    },
    [theme.breakpoints.down('sm')]: {
        margin: "10px",
        textTlign: "center",
    },

    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        width: "90%",
        color: "#030303",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        },
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        }
    },

}));
const ButtonGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    margin: "20px",
    justifyContent: "flex-start",
    [theme.breakpoints.down('sm')]: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },

    '& .MuiButton-root': {
        fontSize: '12px',
        fontWeight: 600,
        borderRadius: "10px",
        width: "80%",
        background: "#fff",
        boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.2)",
        margin: "0px 10px",

        height: "45px",
        textAlign: "center",
        color: "#030303",
        "&:hover,&:focus": {
            background: "#f6f6f6",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",

        },
        [theme.breakpoints.down('sm')]: {

            margin: "5px",
            padding: "0px 20px",
            fontSize: "13px",
        },
    },

}));
const CenterGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "center",
    '& .MuiOutlinedInput-root': {
        height: "50px",
        width: "100%",
        //border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: "10px"
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: "1px solid rgba(0,0,0,0.3)"
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: "1px solid rgba(0,0,0,0.3)"
    },
    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        width: "90%",
        color: "#030303",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        },
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        }
    },

}));
const CenterGrids = styled(Grid)(({ theme }) => ({
    fontSize: '18px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        color: "#030303",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        },
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        }
    },

}));
const CustomTabs = styled(Tabs)(({ theme }) => ({


    borderRadius: "10px",
    background: "#f6f6f6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "48px",
    color: "#030303",
    '& .MuiTabs-flexContainer': {
        display: "flex",
        justifyContent: "center"
    },

    '& .MuiTab-root': {
        fontSize: '15px',
        fontWeight: 600,
        minHeight: "30px",
        minWidth: "50px",
        padding: "10px",
        margin: "4px 4px",
        transition: "all 0.3s ease 0s",
        color: "#545454 ",

    },
    '& .Mui-selected': {
        background: "#fff",
        borderRadius: "10px",
        color: "#030303 !important",
        transition: "all 0.3s ease 0s",
        boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    }
    ,
    '& .MuiTabs-indicator': {
        display: "none",
        backgroundColor: 'red',
    },
}));
const CustomTab = styled(Tab)(({ theme }) => ({
    fontSize: '15px',
    '& .MuiSvgIcon-root': {
        margin: "1px 2px",
        fontSize: "20px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px"
        },
    }
    // fontWeight: 500,
    // transition: "all 0.3s ease 0s"
}));
const Selects = styled(Select)(({ theme }) => ({
    "& .MuiOutlinedInput-notchedOutline": { border: "1px solid rgba(0,0,0,0.3)" },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
        border: "1px solid rgba(0,0,0,0.3)"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: "1px solid rgba(0,0,0,0.3)"
    }
}))
//SWITCH VARIABLE FOR PAGE STYLE
const theme = 'GREYLOGIN'; //LIGHT, GREY, RETRO
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    console.log("index", index)
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Collected = ({ authorId, setNftAddress }) => {

    const location = useLocation();
    const finalResult = location.state;
    const params = useParams()



    const [openMenu, setOpenMenu] = React.useState(true);
    const [openMenu1, setOpenMenu1] = React.useState(false);
    const [openMenu2, setOpenMenu2] = React.useState(false);
    const [openMenu3, setOpenMenu3] = useState(false)
    const handleBtnClick = () => {
        setOpenMenu(!openMenu);
        setOpenMenu1(false);
        setOpenMenu2(false);
        setOpenMenu3(false);
        document.getElementById("Mainbtn").classList.add("active");
        document.getElementById("Mainbtn1").classList.remove("active");
        document.getElementById("Mainbtn2").classList.remove("active");
        document.getElementById("Mainbtn3").classList.remove("active");
    };
    const handleBtnClick1 = () => {
        setOpenMenu1(!openMenu1);
        setOpenMenu2(false);
        setOpenMenu(false);
        setOpenMenu3(false);
        document.getElementById("Mainbtn1").classList.add("active");
        document.getElementById("Mainbtn").classList.remove("active");
        document.getElementById("Mainbtn2").classList.remove("active");
        document.getElementById("Mainbtn3").classList.remove("active");
    };
    const handleBtnClick2 = () => {
        setOpenMenu2(!openMenu2);
        setOpenMenu(false);
        setOpenMenu1(false);
        setOpenMenu3(false);
        document.getElementById("Mainbtn2").classList.add("active");
        document.getElementById("Mainbtn").classList.remove("active");
        document.getElementById("Mainbtn1").classList.remove("active");
        document.getElementById("Mainbtn3").classList.remove("active");
    };

    const handleBtnClick3 = () => {
        setOpenMenu3(!openMenu3);
        setOpenMenu(false);
        setOpenMenu1(false);
        setOpenMenu2(false);
        document.getElementById("Mainbtn3").classList.add("active");
        document.getElementById("Mainbtn").classList.remove("active");
        document.getElementById("Mainbtn1").classList.remove("active");
        document.getElementById("Mainbtn2").classList.remove("active");
    };

    const dispatch = useDispatch();
    const authorsState = useSelector(selectors.authorsState);
    const author = authorsState.data ? authorsState.data[0] : {};

    useEffect(() => {
        dispatch(fetchAuthorList(authorId));
    }, [dispatch, authorId]);


    const [isDark, setIsDark] = useState(false)
    useEffect(() => {
        if (localStorage.getItem('mode') === 'dark') {
            setIsDark(true)
        } else if (localStorage.getItem('mode') === 'light') {
            setIsDark(false)
        }
    }, [])

    const [userProfile, setUserProfile] = useState([])
    const [userId, setUserId] = useState(null)
    const [mintedData, setMintedData] = useState([])
    const [userContent, setUserContent] = useState({});
    const [walletAddress, setWalletAddress] = useState('')
    const [isCopied, setIsCopied] = useState(false);
    const [currentMetamaskAddress, setCurrentMetamaskAddress] = useState(false)
    const [isHovered, setIsHovered] = useState(false);
    const [values, setValues] = React.useState(0);
    const [age, setAge] = React.useState('all');
    const [show, setShow] = React.useState(false)
    const [status, setStatus] = React.useState('all');
    const [filters, setFilters] = React.useState('all')
    const [nfts, setNfts] = useState([])
    const [datas, setDatas] = useState([])
    const [nftdata, setNftdata] = useState([])    
    const [isLoaded, setIsLoaded] = useState(true)
    const navigate = useNavigate()
    var imageCollectionData = new FormData();
    const handleChange = (event) => {
        getCollection(event.target.value, age, filters)
        setStatus(event.target.value);
    };
    const handleChangetab = (event, newValue) => {

        setValues(newValue);
    };

    const handleChanges = (event, newValue) => {
        setAge(event.target.value);
        getCollection(status, event.target.value, filters)
    };
    const handleChange1 = (event, newValue) => {
        setFilters(event.target.value);
        getCollection(status, age, event.target.value)
    };
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const months = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];
    useEffect(() => {
        console.log("params", params)

nftdatas()
        getData();
    }, [])
    const getData = async () => {
        const account = await window.ethereum.request({
            method: "eth_requestAccounts"
        });
        setCurrentMetamaskAddress(account[0])
        try {
            const token = window.localStorage.getItem('token')
            const { data } = await Axios.get(`/auth/getdata/${token}`);
            setUserId(data?.data?._id);
            setUserContent(data?.data)
            // const { data } = await Axios.get(`/product`)
            // setMintedData(data?.data);
            // const userData = await Axios.get(`/auth/data/?_id=${window.location.pathname.split('/').pop()}`)


            // if (window.location.pathname.split('/').pop()) {
            //   if (userData?.data?.data[0]?._id !== window.location.pathname.split('/').pop()) {
            //     window.location.href = `${constants?.MainURL}/404`
            //   }
            // }



            // setUserContent(userData?.data?.data[0])
            // console.log(userData?.data?.data)

        } catch (error) {
            console.log(error);
        }

        if (account) {
            setWalletAddress(account[0])
        }
    }
    const nftdatas = async () => {
        try {
          const { data } = await Axios.get(`/product/getfavourite/${params.authorId}`);
          console.log("nftdata",data.data)
    setNftdata(data.data)
        } catch (error) {
          console.log(error);
        }
      }
    const getCollection = async (datas, dt, filters) => {
        const data = await Axios.post(`/product/ownCollection`, {
            userid: userId,
            filter: datas,
            chain: dt,
            type: filters
        })

        setNfts(data?.data?.data)
        console.log("NFT", data?.data?.data)
    }

    useEffect(() => {
        getCollection("all", "all")
    }, [userId])

    const [height, setHeight] = useState(0);

    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    }



    async function copyTextToClipboard(text) {

        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleCopyClick = () => {

        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(walletAddress)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    // const handleStatus = useCallback((event) => {
    //   const { id } = event.target;
    //   handleStatusApi(event)
    //   // dispatch(filterStatus({value: id, singleSelect: false}));
    // }, [dispatch]);



    const handleStatusApi = async (event) => {

        try {
            if (event.target.checked) {
                if (event?.target?.id === "buy_now") {
                    setIsLoaded(false)
                    const { data } = await Axios.get(`/product/?isAuction=${false}&isVerified=true&isSold=false`)
                    console.log('====================================');
                    console.log(data?.data);
                    console.log('====================================');
                    setDatas(data?.data);
                }
                if (event?.target?.id === "on_auction") {
                    setIsLoaded(false)
                    const { data } = await Axios.get(`/product/?isAuction=${true}&isVerified=true&isSold=false`)
                    setDatas(data?.data);
                }
                if (event?.target?.id === "has_offers") {
                    setIsLoaded(false)
                    const { data } = await Axios.get(`/product/?isAuction=${false}&isVerified=true&isSold=false`)
                    setDatas(data?.data);
                }
                else {

                }

            }
            else {
                const { data } = await Axios.get(`/product?isVerified=true&isSold=false`)
                setDatas(data?.data);
            }
        } catch (error) {
            console.log(error?.message);
        }
        finally {
            setIsLoaded(true)
        }
    }
    var category = []
    const handleCategory = async (e, item) => {
        try {
            setIsLoaded(false)
            category.push(`category=${item?.categoryName}&`)
            const categoryData = category?.filter((item, i) => {
                if (item !== 'category=undefined&') {
                    return item
                }
            })
            const { data } = await Axios.get(`/product/?${categoryData.join('')}isVerified=true&isSold=false`)


            setDatas(data?.data);

        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoaded(true)
        }

    }

    const handleSubCategory = async (item) => {
        try {
            setIsLoaded(false)
            const { data } = await Axios.get(`/product/?subCategory=${item?.target?.id}&isVerified=true&isSold=false`)
            setDatas(data?.data);
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoaded(true)
        }

    }
    const onChangePicture = async (e) => {
        const profile = "Profile"

        imageCollectionData.append("Image", e.target.files[0]);
        imageCollectionData.append("imageType", profile);
        console.log(imageCollectionData, "Profile")
        const data = await Axios.post(`/auth/ediprofile/${params.authorId}`, imageCollectionData)
            .then(response => {

                console.log(response.data.msg, "data....")
                if (response.data.msg === "success") {
                    toast.success("Image Changed Sucessfully", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                }
                else {
                    toast.error("Error", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                getData()
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error("Error", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            });




    };

    const onChangePictures = async (e) => {

        const profile = "Cover"
        imageCollectionData.append("Image", e.target.files[0]);
        imageCollectionData.append("imageType", profile);
        console.log(imageCollectionData, "asf")
        const data = await Axios.post(`/auth/ediprofile/${params.authorId}`, imageCollectionData)
            .then(response => {

                console.log(response.data.msg, "data....")
                if (response.data.msg === "success") {
                    toast.success("Image Changed Sucessfully", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                }
                else {
                    toast.error("Error", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                getData()
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error("Error", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            });

    };

    const handleReset = async () => {
        try {
            setIsLoaded(false)
            const { data } = await Axios(`/product?isVerified=true&isSold=false`)
            setDatas(data?.data)
        } catch (error) {
            console.log(error);
        }
        finally {
            setIsLoaded(true)
        }
    }
    const handleAddress = (item) => {
        console.log("item", item)
        navigate(`/itemdetails/${item?.mintTransaction?.transactionHash}`, { state: { data: item } })
    }

    return (
        <>
            <div >
                <GridContainered container spacing={0}>
                    <CenterGrids item xs={1} sm={1} md={1} xl={1} lg={1}>
                        <Button onClick={() => setShow(!show)}><MenuOpenRoundedIcon /></Button>
                    </CenterGrids>
                    <CenterGrid item xs={2} sm={2} md={2} xl={1} lg={1}>

                        <FormControl style={{ "width": "90%", "marginRight": "15px" }}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Selects
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Status"
                                value={status}
                                placeholder='Low to High'
                                onChange={handleChange}

                            >
                                <MenuItem value="Listed">Listed</MenuItem>
                                <MenuItem value="Auction">Auction</MenuItem>
                                <MenuItem value="all">All</MenuItem>
                            </Selects>
                        </FormControl>
                    </CenterGrid>
                    <CenterGrid item xs={2} sm={2} md={2} xl={1} lg={1}>
                        <FormControl style={{ "width": "90%", "marginRight": "15px" }}>
                            <InputLabel id="demo-simple-select-label">Chain</InputLabel>
                            <Selects
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Chain"
                                value={age}
                                placeholder='Low to High'
                                onChange={handleChanges}
                            >
                                <MenuItem value="BNB">BNB</MenuItem>
                                <MenuItem value="MATIC">POLYGON</MenuItem>
                                <MenuItem value="ETH">ETH</MenuItem>
                                <MenuItem value="all">All</MenuItem>
                            </Selects>
                        </FormControl>
                    </CenterGrid>
                    <CenterGrid item xs={6} sm={6} md={6} xl={5} lg={5}>
                        <TextField
                            id="outlined-basic"
                            placeholder="Search by Name"
                            variant="outlined"

                            style={{ "width": "90%", "border": "1px solid rgba(0,0,0,0.3)", borderRadius: "10px" }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                color: "red",
                                "& .MuiOutlinedInput-notchedOutline": { border: 0 },
                                "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                    border: 0,
                                }
                            }}
                        />
                    </CenterGrid>
                    <CenterGrid item xs={5} sm={5} md={5} xl={2} lg={2}>
                        <FormControl style={{ "width": "90%", "marginRight": "15px" }}>
                            <InputLabel id="demo-simple-select-label">Recently Received</InputLabel>
                            <Selects
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Recently Viewed"
                                value={filters}
                                placeholder='Low to High'
                                onChange={handleChange1}
                            >
                                <MenuItem value="Recently">Recently Received</MenuItem>
                                <MenuItem value="High to Low"> Price High to Low</MenuItem>
                                <MenuItem value="Low to High">Price Low to High</MenuItem>
                            </Selects>
                        </FormControl>
                    </CenterGrid>
                    <CenterGrid item xs={5} sm={5} md={5} xl={2} lg={2} >
                        <CustomTabs value={values} onChange={handleChangetab} aria-label="basic tabs example" style={{ "width": "min-content" }}>
                            <CustomTab icon={<ListOutlinedIcon />} {...a11yProps(0)} />
                            <CustomTab icon={<GridOnRoundedIcon />} {...a11yProps(1)} />
                            <CustomTab icon={<WindowOutlinedIcon />} {...a11yProps(2)} />
                            <CustomTab icon={<AutoAwesomeMosaicOutlinedIcon />} {...a11yProps(3)} />
                        </CustomTabs>

                    </CenterGrid>
                </GridContainered>


                <section className='container-fluid no-bottom'>
                    <GridContainers container spacing={0} >
                        {!show ? <LeftGrid item xs={12} sm={12} md={2} xl={2} lg={2}>
                            <CheckboxFilter handleReset={handleReset} handleCategory={handleCategory} handleSubCategory={handleSubCategory} />
                        </LeftGrid> : <></>}

                        <RightGrids item xs={12} sm={12} md={!show ? 10 : 12} xl={!show ? 10 : 12} lg={!show ? 10 : 12}>
                            <CustomTabPanel value={values} index={0}>
                                {nftdata ? <Myprofiletable nfts={nftdata} setNftAddress={setNftAddress} onImgLoad={onImgLoad} /> :  <h1 className='noDataFound' style={{ height: '100vh' }}>No data found...</h1>}
                                
                            </CustomTabPanel>
                            <CustomTabPanel value={values} index={1}>
                                <div className='grid_explore_containers'>
                                    {
                                        nftdata?.length !== 0 ?
                                            nftdata?.map((item, i) => {
                                                return (
                                                    <div style={{ "marginTop": "20px" }}>
                                                        <CollectionCard nft={item} setNftAddress={setNftAddress} onImgLoad={onImgLoad} />

                                                    </div>
                                                )
                                            })
                                            :
                                            <h1 className='noDataFound' style={{ height: '100vh' }}>No data found...</h1>

                                    }
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={values} index={2}>
                                <div className='grid_explore_container'>
                                    {
                                        nftdata?.length !== 0 ?
                                            nftdata?.map((item, i) => {
                                                return (
                                                    <div style={{ "marginTop": "20px" }}>
                                                        <CollectionCard nft={item} setNftAddress={setNftAddress} onImgLoad={onImgLoad} />

                                                    </div>
                                                )
                                            })
                                            :
                                            <h1 className='noDataFound' style={{ height: '100vh' }}>No data found...</h1>

                                    }
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={values} index={3}>
                                <div>
                                    <div className='image-griding'>
                                        {
                                            isLoaded ?
                                                nftdata?.length !== 0 ?
                                                    nftdata?.map((item, i) => {
                                                        return (
                                                            <div onClick={() => handleAddress(item)} key={i} className="image-items">
                                                                <img src={item?.ProductImage} className="lazy img-fluid" alt="" />
                                                            </div>
                                                        )
                                                    }) :
                                                    <h1 className='noDataFound' style={{ height: '100vh' }}>No data found...</h1>
                                                :
                                                Array(3).fill()?.map((item) => (
                                                    <ExploreContentLoader />
                                                ))
                                        }
                                    </div>
                                </div>
                            </CustomTabPanel>
                        </RightGrids>
                    </GridContainers>
                </section>

                <section className='container no-top'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className="items_filter">
                                <ul className="de_nav text-left">
                                    {/* <li id='Mainbtn' className="active"><span onClick={handleBtnClick}>Items</span></li> */}
                                    {/* <li id='Mainbtn1' className=""><span onClick={handleBtnClick1}>Created</span></li> */}
                                    {/* <li id='Mainbtn2' className=""><span onClick={handleBtnClick2}>Purchased Items</span></li>
                    <li id='Mainbtn3' className=""><span onClick={handleBtnClick3}>Sold Item</span></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {openMenu && (
                        <div id='zero1' className='onStep fadeIn'>
                            {/* <div style={{display:'flex',gap:'20px',alignContent:'center',padding:'20px'}}>
               <button    className="active" >Buy Now</button>
               <button    className="btn-main  ">On Auction</button>
             </div> */}
                            <div className='grid_explore_container'>

                                {
                                    mintedData?.map((item, i) => {
                                        if (item?.userId?._id === userId && item?.isVerified) {
                                            if (!item?.buyTransaction) {
                                                return (
                                                    <NftSale nft={item} onImgLoad={onImgLoad} />
                                                )
                                            }
                                        }
                                    })
                                }
                            </div>
                            {/* {
                    mintedData?.length <= 0 ?
                      <h1 style={{ textAlign: 'center' }}>No Product Minted/Created....</h1>
                      : null
                  } */}
                        </div>
                    )}
                    {/* {openMenu1 && ( 
        <div id='zero2' className='onStep fadeIn'>
           <div  className='grid_explore_container'>
         {
           mintedData?.map((item,i)=>{
               if(item.userId?._id===userId){
                return   item?.mintTransaction&&                   
                  <NftCreatedCard isApproved={item?.isVerified} nft={item} onImgLoad={onImgLoad} />                    
               }
             
           })
         }
         </div>
         {
           mintedData?.length<=0?
           <h1 style={{textAlign:'center'}}>No Product Minted/Created....</h1>
           :null
         }
        </div>
      )}  */}
                    {openMenu2 && (
                        <div id='zero3' className='onStep fadeIn'>
                            <div className='grid_explore_container'>
                                {
                                    mintedData?.map((item, i) => {


                                        if (userId !== item?.userId) {
                                            if (item?.buyTransaction?.from === currentMetamaskAddress) {
                                                return (
                                                    <NftPurchasedCard nft={item} onImgLoad={onImgLoad} />
                                                )
                                            }
                                        }
                                    })

                                    //  if(item.userId?._id===userId){
                                    //       return (

                                    //         <NftCard nft={item} onImgLoad={onImgLoad} />
                                    //       )
                                    //  }

                                }

                            </div>
                            {
                                mintedData?.length <= 0 ?
                                    <h1 style={{ textAlign: 'center' }}>No Product Purchased....</h1>
                                    : null
                            }
                        </div>
                    )}

                    {openMenu3 && (
                        <div id='zero3' className='onStep fadeIn'>
                            <div className='grid_explore_container'>
                                {
                                    mintedData?.map((item, i) => {
                                        if (item?.userId?._id === userId) {
                                            if (item?.isSold) {
                                                return (
                                                    <NftSoldCard nft={item} onImgLoad={onImgLoad} />
                                                )
                                            }
                                        } else {
                                            <h1>No data found...</h1>
                                        }



                                        //  if(item.userId?._id===userId){
                                        //       return (

                                        //         <NftCard nft={item} onImgLoad={onImgLoad} />
                                        //       )
                                        //  }

                                    })
                                }
                            </div>
                            {/* {
                    mintedData?.length <= 0 ?
                      <h1 style={{ textAlign: 'center' }}>No Product Purchased....</h1>
                      : null
                  } */}
                        </div>
                    )}
                </section>

            </div>
        </>
    );
}
export default memo(Collected);