import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from "../../menu/header";
import Footer from "../../components/footer";
import './HelpCenter.css'
import Axios from "../../../api-instance"
// import togglebtn from '../../img/togglebtn.png'
// import Menuclose from '../../img/Menuclose.png'
import { Link } from "@reach/router";
// import customer from '../../img/customer.svg'
// import maptrace from '../../img/maptrace.svg'
// import guarantee from '../../img/guarantee.svg'
// import bestseller from '../../img/bestseller.svg'
// import bill from '../../img/bill.svg'
// import product from '../../img/product.svg'
// import supplychain from '../../img/supplychain.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
  helpcommoninner: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    textAlign: 'left !important'
  }
});

const DetailPage = () => {

  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)
  const [faqData5, setFaqData5] = useState([])
  const [selectedCategory5, setSelectedCategory5] = useState('');
  const [selectedQuestion5, setSelectedQuestion5] = useState('')
  const [selectedAnswer5, setSelectedAnswer5] = useState('')
  const [selectedFAQ, setSelectedFAQ] = useState(0); //
  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  const handleCategoryClick5 = (questionId) => {
    const selectedFaq = faqData5.find((faq) => faq._id === questionId);
    console.log(selectedFaq, "faqqq")
    if (selectedFaq) {
      // setSelectedQuestion(selectedFaq.question);
      setSelectedAnswer5(selectedFaq.answer);
    }
    setSelectedFAQ(questionId)
  };

  const faqList = async () => {
    try {
      const payload = {
        name: 'Faq'

      }
      console.log(payload, "payyy")
      const response = await Axios.post('/admin/getquestByName', payload)
      console.log(response, "checkdata")
      if (response && response.status === 200) {
        const deta = response?.data?.data
        setFaqData5(deta)
        setSelectedAnswer5(deta[0].answer)
      } else {
        console.log("No Data Found")
      }



    } catch (error) {
      console.log(error, "error")

    }
  }

  useEffect(() => {
    faqList();
  }, []);

  const [faq, setFaq] = useState([
    { name: "How do I delete an NFT?" },
    { name: "What is the future of NFTs?" },
    { name: "I am experiencing issues in Wallex NFT, who should I contact to resolve?" },
    { name: "What is the difference between a Fungible and Non Fungible token?"},
    { name: "Is it worth creating (or) buying NFTs?" },
 
  ])


  const [val, setVal] = useState(0)

  console.log(val, 'val');

  const [selected, setSelected] = useState(false)
  const handleCloseSelect = () => {
    setSelected(false)
  }
  const handleVal = (ind) => {
    setVal(ind)
    setSelectedFAQ(ind)
    setSelected(true)
  }

  console.log(selectedAnswer5,'selectedAnswer5');

  return (
    <div className="dashboard-page help-center-main">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="outer-bgcolor-container"
          >
            <Item className={classes.bodyclass}>
              <div className="togglebtn" onClick={openMenuHandler}></div>
              <div
                className="categories-menu"
                id={!menuOpen ? "openmenucls" : ""}
              >
                <div
                  className="togglebtn-close"
                  onClick={closeMenuHandler}
                ></div>
                <div className="categories-menu-inner">
                  {/* <Link><div className='iconic'><img src={customer} alt={customer} /></div>Customer</Link>
    <Link><div className='iconic'><img src={maptrace} alt={maptrace} /></div>Traceability</Link>
    <Link><div className='iconic'><img src={guarantee} alt={guarantee} /></div>Products</Link>
    <Link><div className='iconic'><img src={bestseller} alt={bestseller} /></div>Blockchain</Link>
    <Link><div className='iconic'><img src={bill} alt={bill} /></div>Post-sale</Link>
    <Link><div className='iconic'><img src={product} alt={product} /></div>Billing</Link>
    <Link><div className='iconic'><img src={supplychain} alt={supplychain} /></div>Sellers Authorization </Link> */}
                </div>
              </div>

              <div className="detail-pages-inner-outer">
                <Box sx={{ flexGrow: 1 }}>
                  <div className="breadcrum-helpcenter">
                    <Link to="/helpcenter-new">Help Center</Link> / FAQ
                  </div>

                  <Grid
                    container
                    spacing={0}
                    className={classes.helpcenterbodycontain}
                  >
                       <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="link-left-side-detail-id">
                      {selected ?
                        <>
                          <div style={{ textAlign: 'left', cursor: 'pointer', marginBottom: "30px" }} onClick={handleCloseSelect}>
                            <ArrowBackIcon />
                          </div>

                          <Item className={classes.helpcommoninner}>
                          {val === 0 && <>
                          <h2><strong>How do I delete an NFT?</strong></h2><p>An item minted on the blockchain can’t be “deleted” the same way you would delete a file or a folder from your personal computer, but you do have some options for handling NFTs you didn’t purchase or no longer want to show in your account.</p><p>This article outlines 3 such options:</p><ul><li><strong>Hiding an item</strong> on OpenSea will move it from the <strong>Collected</strong> tab to the <strong>Hidden</strong> tab on your profile, though the item will still be publicly visible on OpenSea more broadly</li><li><strong>Hiding a collection page</strong> will hide the collection and its items from being visible on OpenSea</li><li><strong>Burning</strong> an item will remove it from your wallet entirely</li></ul> 
                        </>}
                        {val === 1 && <>
                          <h2><strong>What is the future of NFTs?</strong></h2><p>Non Fungible tokens (NFTs) have a promising future, not only because digital art is a highly underutilized asset class ready to overtake the market, but also because NFTs are offering a gateway to digital economies, which will affect everybody in the future.The future of NFTs is unpredictable as it is influenced by a number of variables, such as technological advancements, shifts in consumer demand, and legal changes. While others are more pessimistic about NFTs’ long-term prospects, some people think they could transform the way we see ownership and value in the digital age.</p><p>The following events could have an impact on the future of NFTs:</p><p><strong>Wider Adoption:</strong> As more people learn about NFTs and the technology becomes simpler to use, it’s possible that it will become more widely used, accepted, and ingrained in daily life.</p><p><strong>Increased Regulation: </strong>As the scale and significance of the NFT market increase, it’s possible that authorities may start to more strictly control the purchase and ownership of NFTs.
                        </p><p><strong>Technological Developments:</strong> The blockchain technology that supports NFTs is still rather young but is advancing quickly. New technological advances could make NFTs more useful, safe, and easy to use, which could speed up their adoption.</p><p>As an evolving technology, NFTs have the potential to grow in the following areas:</p><p><strong>Metaverse:</strong> One potential future application for NFTs is the creation of the metaverse, a virtual world in which individuals can interact with each other as well as with digital items and experiences. Token technology has allowed artists to sell digital originals without the use of middlemen. If the artwork is resold, they may be paid royalties. However, this is just the start of what non-fungible tokens may achieve for the Web3.0 world, which is growing in size and utility. NFTs could be utilized to symbolize and exchange virtual real estate, in-game objects, and other virtual assets in the metaverse.</p><p><strong>Non-Bankable Assets </strong>like collectibles, antiques, and fine art could likewise be represented and traded as NFTs. This has the potential to create new markets and make it easier for consumers to buy and sell these types of assets.&nbsp;</p><p><strong>Digital Wallets </strong>will almost certainly play an important role in the purchase, sale, and storage of NFTs. To promote the widespread adoption of NFTs, these wallets, which are essentially software programmes that allow users to store and manage their digital assets, will need to be safe and user-friendly.</p>

                        </>}

                        {val === 2 && <>
                        
                          <h2><strong>I am experiencing issues in Wallex NFT, who should I contact to resolve?</strong></h2><p>If you can’t create, buy or sell NFTs on&nbsp;<strong> Wallex NFT</strong>, there may be some issues that prevent you from interacting with the&nbsp;<strong> Wallex NFT</strong> correctly. Here are a few ways to fix issues when connecting with&nbsp;<strong> Wallex NFT</strong>:&nbsp;</p><h2><strong>Reconnect Your Wallet</strong></h2><p>One reason you can't create, buy or sell NFTs could be a wallet connection issue.&nbsp; This could be due to a few reasons:&nbsp;</p><ol><li>You're connected with the wrong wallet</li><li>You disconnected your wallet whilst using&nbsp;<strong> Wallex NFT</strong></li><li>You're using multiple, conflicting wallet plugins</li></ol><p>In any of these cases, you can fix the problem by disconnecting your wallet and connecting it again (make sure it's the correct one). You can do this either on&nbsp;<strong> Wallex NFT </strong>itself or within your wallet.&nbsp;</p><p>On&nbsp;<strong> Wallex NFT</strong>, go onto your profile and then click on the "off" sign to disconnect:&nbsp;</p><p>After disconnecting your wallet, go back to the&nbsp;<strong> Wallex NFT home page</strong> and click "connect wallet" in the top right-hand corner to reconnect. Make sure you connect with the wallet you'd like to purchase, mint or sell from.&nbsp;</p><p>For best results, disable all wallet extensions except for your preferred one.&nbsp;
                        </p><h2><strong>Your Wallet is Empty</strong></h2><p>Every transaction on a blockchain (especially on Ethereum) costs a gas fee. To place bids, purchase NFTs or mint anything, you need to have some tokens in your wallet (an exception is lazy minting).&nbsp;</p><p>You can't bid in money you don't have or pay a gas fee when you have no ETH. We suggest topping up your wallet if you run into this issue. If you would like to find out more about how you can add more funds in your wallet, follow this article</p><h2><strong>Clearing Your Cache</strong></h2><p>If you haven't interacted with&nbsp;<strong> Wallex NFT</strong> in a long time, it's possible that your computer or browser is still trying to interact with an old version of&nbsp;<strong> Wallex NFT</strong> or outdated scripts. Because we constantly update our software, it's possible that this could break your interactions with&nbsp;<strong> Wallex NFT</strong>.&nbsp;</p><p>Clearing your cache in your browser should fix this issue. When you do so, your browser requests the newest version of&nbsp;<strong> Wallex NFT</strong> and lets you interact with it.</p><p>After clearing your cache, you should be able to use all of&nbsp;<strong> Wallex NFT</strong> features again with no issues.</p> 
                        </>}

                        {val === 3 && <>
                          <h2><strong>What is the difference between a Fungible and Non Fungible token?</strong></h2><figure class="table"><table><tbody><tr><td><strong>Criteria</strong></td><td><strong>Fungible Tokens</strong></td><td><strong>Non-fungible Tokens</strong></td></tr><tr><td><strong>Interchangeability</strong></td><td>Fungible tokens are simply interchangeable despite there being no extra value linked with interchanging fungible tokens.</td><td>Non-fungible tokens are not interchangeable as every token represents unique assets.</td></tr><tr><td><strong>Value Transfer</strong></td><td>Value transfer relies on the tokens numbers in the ownership of a person.</td><td>The value of the unique asset depicted by NFT is beneficial in their value transfer.</td></tr><tr><td><strong>Real world purpose</strong></td><td>Payment system</td><td>Intellectual property</td></tr><tr><td><strong>Token standards</strong></td><td>Fungible tokens rely on the ERC-20 standard.</td><td>Non-fungible tokens leverage the ERC-721 standard.</td></tr></tbody></table></figure> 
                        </>}

                        {val === 4 && <>
                        
                          <h2><strong>Is it worth creating (or) buying NFTs?</strong></h2><p>While there are several benefits to owning NFTs, there are just as many downsides. NFTs that represent ownership of digital-only items, in general, should be purchased only because you want them or there is an established market for the one you want—otherwise, it's very likely that there will be no price appreciation following your purchase.</p><p>If the NFT represents ownership of a tangible, real-world asset, be sure you apply investing fundamentals and ascertain whether there is actual value and growth potential.</p> 
                        </>}






                          </Item>
                        </> :

                        <>
                          <h4>Article in this section</h4>
                          {faq.map((faq, index) => (
                            <div className={index === selectedFAQ ? "display-2 redirect-link-specific active" : "display-2 redirect-link-specific"} onClick={() => handleVal(index)} >
                              <div
                                key={faq.id}
                                // to="#"
                                style={{ cursor: 'pointer' }}


                              >
                                <p>{faq.name}</p>
                              </div>
                              <div>
                                <NavigateNextIcon />
                              </div>
                            </div>

                          ))}
                        </>}
                    </Grid>

                    {/* <Grid item xs={12} lg={2} xl={2} id="link-left-side-id">
                      <Item className="helpcommoninner">
                        <h4>Article in this section</h4>
                        {faq.map((faq, index) => (
                          <div
                            key={faq.id}
                            // to="#"
                            style={{ cursor: 'pointer' }}
                            className={ index === selectedFAQ  ? "redirect-link-specific active" : "redirect-link-specific"}
                            onClick={() => handleVal(index)}
                          >
                            <p>{faq.name}</p>
                          </div>
                        ))}
                      </Item>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10} id="link-left-side-detail-id">
                      <Item className={classes.helpcommoninner}>
                        {/* {selectedQuestion && <h2>{selectedQuestion}</h2>} */}
                        {/* {selectedAnswer5 && <div dangerouslySetInnerHTML={{ __html: selectedAnswer5 }}></div>} */}


                        {/* {val === 0 && <>
                          <h2><strong>How do I delete an NFT?</strong></h2><p>An item minted on the blockchain can’t be “deleted” the same way you would delete a file or a folder from your personal computer, but you do have some options for handling NFTs you didn’t purchase or no longer want to show in your account.</p><p>This article outlines 3 such options:</p><ul><li><strong>Hiding an item</strong> on OpenSea will move it from the <strong>Collected</strong> tab to the <strong>Hidden</strong> tab on your profile, though the item will still be publicly visible on OpenSea more broadly</li><li><strong>Hiding a collection page</strong> will hide the collection and its items from being visible on OpenSea</li><li><strong>Burning</strong> an item will remove it from your wallet entirely</li></ul> 
                        </>}
                        {val === 1 && <>
                          <h2><strong>What is the future of NFTs?</strong></h2><p>Non Fungible tokens (NFTs) have a promising future, not only because digital art is a highly underutilized asset class ready to overtake the market, but also because NFTs are offering a gateway to digital economies, which will affect everybody in the future.The future of NFTs is unpredictable as it is influenced by a number of variables, such as technological advancements, shifts in consumer demand, and legal changes. While others are more pessimistic about NFTs’ long-term prospects, some people think they could transform the way we see ownership and value in the digital age.</p><p>The following events could have an impact on the future of NFTs:</p><p><strong>Wider Adoption:</strong> As more people learn about NFTs and the technology becomes simpler to use, it’s possible that it will become more widely used, accepted, and ingrained in daily life.</p><p><strong>Increased Regulation: </strong>As the scale and significance of the NFT market increase, it’s possible that authorities may start to more strictly control the purchase and ownership of NFTs.
                        </p><p><strong>Technological Developments:</strong> The blockchain technology that supports NFTs is still rather young but is advancing quickly. New technological advances could make NFTs more useful, safe, and easy to use, which could speed up their adoption.</p><p>As an evolving technology, NFTs have the potential to grow in the following areas:</p><p><strong>Metaverse:</strong> One potential future application for NFTs is the creation of the metaverse, a virtual world in which individuals can interact with each other as well as with digital items and experiences. Token technology has allowed artists to sell digital originals without the use of middlemen. If the artwork is resold, they may be paid royalties. However, this is just the start of what non-fungible tokens may achieve for the Web3.0 world, which is growing in size and utility. NFTs could be utilized to symbolize and exchange virtual real estate, in-game objects, and other virtual assets in the metaverse.</p><p><strong>Non-Bankable Assets </strong>like collectibles, antiques, and fine art could likewise be represented and traded as NFTs. This has the potential to create new markets and make it easier for consumers to buy and sell these types of assets.&nbsp;</p><p><strong>Digital Wallets </strong>will almost certainly play an important role in the purchase, sale, and storage of NFTs. To promote the widespread adoption of NFTs, these wallets, which are essentially software programmes that allow users to store and manage their digital assets, will need to be safe and user-friendly.</p>

                        </>}

                        {val === 2 && <>
                        
                          <h2><strong>I am experiencing issues in Wallex NFT, who should I contact to resolve?</strong></h2><p>If you can’t create, buy or sell NFTs on&nbsp;<strong> Wallex NFT</strong>, there may be some issues that prevent you from interacting with the&nbsp;<strong> Wallex NFT</strong> correctly. Here are a few ways to fix issues when connecting with&nbsp;<strong> Wallex NFT</strong>:&nbsp;</p><h2><strong>Reconnect Your Wallet</strong></h2><p>One reason you can't create, buy or sell NFTs could be a wallet connection issue.&nbsp; This could be due to a few reasons:&nbsp;</p><ol><li>You're connected with the wrong wallet</li><li>You disconnected your wallet whilst using&nbsp;<strong> Wallex NFT</strong></li><li>You're using multiple, conflicting wallet plugins</li></ol><p>In any of these cases, you can fix the problem by disconnecting your wallet and connecting it again (make sure it's the correct one). You can do this either on&nbsp;<strong> Wallex NFT </strong>itself or within your wallet.&nbsp;</p><p>On&nbsp;<strong> Wallex NFT</strong>, go onto your profile and then click on the "off" sign to disconnect:&nbsp;</p><p>After disconnecting your wallet, go back to the&nbsp;<strong> Wallex NFT home page</strong> and click "connect wallet" in the top right-hand corner to reconnect. Make sure you connect with the wallet you'd like to purchase, mint or sell from.&nbsp;</p><p>For best results, disable all wallet extensions except for your preferred one.&nbsp;
                        </p><h2><strong>Your Wallet is Empty</strong></h2><p>Every transaction on a blockchain (especially on Ethereum) costs a gas fee. To place bids, purchase NFTs or mint anything, you need to have some tokens in your wallet (an exception is lazy minting).&nbsp;</p><p>You can't bid in money you don't have or pay a gas fee when you have no ETH. We suggest topping up your wallet if you run into this issue. If you would like to find out more about how you can add more funds in your wallet, follow this article</p><h2><strong>Clearing Your Cache</strong></h2><p>If you haven't interacted with&nbsp;<strong> Wallex NFT</strong> in a long time, it's possible that your computer or browser is still trying to interact with an old version of&nbsp;<strong> Wallex NFT</strong> or outdated scripts. Because we constantly update our software, it's possible that this could break your interactions with&nbsp;<strong> Wallex NFT</strong>.&nbsp;</p><p>Clearing your cache in your browser should fix this issue. When you do so, your browser requests the newest version of&nbsp;<strong> Wallex NFT</strong> and lets you interact with it.</p><p>After clearing your cache, you should be able to use all of&nbsp;<strong> Wallex NFT</strong> features again with no issues.</p> 
                        </>}

                        {val === 3 && <>
                          <h2><strong>What is the difference between a Fungible and Non Fungible token?</strong></h2><figure class="table"><table><tbody><tr><td><strong>Criteria</strong></td><td><strong>Fungible Tokens</strong></td><td><strong>Non-fungible Tokens</strong></td></tr><tr><td><strong>Interchangeability</strong></td><td>Fungible tokens are simply interchangeable despite there being no extra value linked with interchanging fungible tokens.</td><td>Non-fungible tokens are not interchangeable as every token represents unique assets.</td></tr><tr><td><strong>Value Transfer</strong></td><td>Value transfer relies on the tokens numbers in the ownership of a person.</td><td>The value of the unique asset depicted by NFT is beneficial in their value transfer.</td></tr><tr><td><strong>Real world purpose</strong></td><td>Payment system</td><td>Intellectual property</td></tr><tr><td><strong>Token standards</strong></td><td>Fungible tokens rely on the ERC-20 standard.</td><td>Non-fungible tokens leverage the ERC-721 standard.</td></tr></tbody></table></figure> 
                        </>}

                        {val === 4 && <>
                        
                          <h2><strong>Is it worth creating (or) buying NFTs?</strong></h2><p>While there are several benefits to owning NFTs, there are just as many downsides. NFTs that represent ownership of digital-only items, in general, should be purchased only because you want them or there is an established market for the one you want—otherwise, it's very likely that there will be no price appreciation following your purchase.</p><p>If the NFT represents ownership of a tangible, real-world asset, be sure you apply investing fundamentals and ascertain whether there is actual value and growth potential.</p> 
                        </>} */}


                        


                 


                       




                      </Item>
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={2}
                      xl={2}
                      id="link-left-side-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h4>Article in this section</h4>

                        <Link to="/FaqPage" className="redirect-link-specific">
                          How do I delete an NFT?
                        </Link>
                        <Link to="/FaqPage" className="redirect-link-specific">
                          What is the difference between a Fungible and Non
                          Fungible token?
                        </Link>
                        <Link to="/FaqPage" className="redirect-link-specific">
                          I am experiencing issues in Wallex NFT, who should I
                          contact to resolve?
                        </Link>
                        <Link to="/FaqPage" className="redirect-link-specific">
                          What is the future of NFTs?
                        </Link>
                        <Link to="/FaqPage" className="redirect-link-specific">
                          Is it worth creating (or) buying NFTs?
                        </Link>
                      </Item>
                    </Grid> */}

                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={10}
                      xl={10}
                      id="link-left-side-detail-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h2>How do I delete an NFT?</h2>
                        <h4>
                          What is the difference between a Fungible and Non
                          Fungible token?
                        </h4>
                        <h4>
                          I am experiencing issues in Wallex NFT, who should I
                          contact to resolve?
                        </h4>
                        <h4>What is the future of NFTs?</h4>
                        <h4>Is it worth creating (or) buying NFTs?</h4>
                      </Item>
                    </Grid> */}
                  </Grid>
                </Box>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Footer />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default DetailPage;
