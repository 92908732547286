import React, { 
  memo, 
  useState,
  useEffect 
} from 'react';
import { useDispatch } from 'react-redux';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { postContactForm } from '../../store/actions/thunks';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;


const GlobalStylesGrey = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid #ff343f;
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: #ff343f;
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 #ff343f;
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: rgba(33, 36, 40, .9);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{
    color: #fff !important;
    background: #ff343f;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: #ff343f;
  }
  @media only screen and (max-width: 1199px) { 
    .navbar {
      background: #212428;
    }
  }
`;

const Contact = () => {
  const useThunkDispatch = () => useDispatch();
  const dispatch = useThunkDispatch();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleChangeName = (event) => {
    const { value } = event.target;
    setName(value);
  }
  const handleChangeEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
  }
  const handleChangePhone = (event) => {
    const { value } = event.target;
    setPhone(value);
  }
  const handleChangeMessage = (event) => {
    const { value } = event.target;
    setMessage(value);
  }

  const sendEmail = async (e) => {

    const success = document.getElementById("success");
    const button = document.getElementById("buttonsent");
    const failed = document.getElementById("failed");
    e.preventDefault();
    try {
      let form = {
        name,
        email,
        phone,
        message
      };

      await dispatch(postContactForm(form));
      
      success.classList.add('show');
      button.classList.add('show');
      failed.classList.remove('show');
    } catch (error) {
      console.log(error);
      failed.classList.add('show');
    }
  }

  const [isDark, setIsDark] = useState(false)
useEffect(()=>{
  if(localStorage.getItem('mode')==='dark'){
     setIsDark(true)
  }else if(localStorage.getItem('mode')==='light'){
    setIsDark(false)
  }
},[])
  return (
  <>
  {
    isDark?
    <div className="greyscheme">
    <GlobalStylesGrey />
  
    <section className='jumbotron breadcumb no-bg'>
      <div className='mainbreadcumb'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-12 text-center">
                <h1>Contact Us</h1>
                <p>Anim pariatur cliche reprehenderit</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  
        <section className='container'>
          <div className='row'>
            <div className='col-lg-8 mb-3'>
            <h3>Do you have any question?</h3>
              <div className="form-side">
                <form className="formcontact" onSubmit={sendEmail}>
                  <input type="text" className="form-control" name="user_name" placeholder="Your Name" required />
                  <input type="email" className="form-control" name="user_email" placeholder="Your Email" required />
                  <input type="text" className="form-control" name="user_phone" placeholder="Your Phone" required />
                  <textarea name="message" className="form-control" placeholder="Your Message" required />
                  <div id='success' className='hide'>Your message has been sent...</div>
                  <div id='failed' className='hide'>Message failed...</div>
                  <input type='submit' id='buttonsent' value='Submit Now' className="btn btn-main color-2" />
                </form>
              </div>
            </div>
  
            <div className='col-md-4'>
             
             <div className="padding40 box-rounded mb30">
                <h3>US Office</h3>
                <address className="s1">
                  <span><i className="id-color fa fa-map-marker fa-lg"></i>08 W 36th St, New York, NY 10001</span>
                  <span><i className="id-color fa fa-phone fa-lg"></i>+1 333 9296</span>
                  <span><i className="id-color fa fa-envelope-o fa-lg"></i><span className='btn'>contact@example.com</span></span>
                  <span><i className="id-color fa fa-file-pdf-o fa-lg"></i><span className='btn'>Download Brochure</span></span>
                </address>
              </div>
  
              <div className="padding40 box-rounded mb30 text-light">
                <h3>AU Office</h3>
                <address className="s1">
                  <span><i className="fa fa-map-marker fa-lg"></i>100 Mainstreet Center, Sydney</span>
                  <span><i className="fa fa-phone fa-lg"></i>+61 333 9296</span>
                  <span><i className="fa fa-envelope-o fa-lg"></i><span className='btn'>contact@example.com</span></span>
                  <span><i className="fa fa-file-pdf-o fa-lg"></i><span className='btn'>Download Brochure</span></span>
                </address>
              </div>
  
            </div>
  
          </div>
        </section>
        <Footer />
      </div>
    :
    <div>
  <GlobalStyles />

  <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.webp'})`}}>
    <div className='mainbreadcumb'>
      <div className='container'>
        <div className='row'>
          <div className="col-md-12 text-center">
              <h1>Contact Us</h1>
              <p>Anim pariatur cliche reprehenderit</p>
          </div>
        </div>
      </div>
    </div>
  </section>

      <section className='container'>
        <div className='row'>
          
          <div className='col-lg-8 mb-3'>
          <h3>Do you have any question?</h3>
            <div className="form-side">
              <form className="formcontact" onSubmit={sendEmail}>
                <input onChange={handleChangeName} value={name} type="text" className="form-control" name="user_name" placeholder="Your Name" required />
                <input onChange={handleChangeEmail} value={email} type="email" className="form-control" name="user_email" placeholder="Your Email" required />
                <input onChange={handleChangePhone} value={phone} type="text" className="form-control" name="user_phone" placeholder="Your Phone" required />
                <textarea onChange={handleChangeMessage} value={message} name="message" className="form-control" placeholder="Your Message" required />
                <div id='success' className='hide'>Your message has been sent...</div>
                <div id='failed' className='hide'>Message failed...</div>
                <input type='submit' id='buttonsent' value='Submit Now' className="btn btn-main color-2" />
              </form>
            </div>
          </div>

          <div className='col-md-4'>
           
           <div className="padding40 box-rounded mb30">
              <h3>US Office</h3>
              <address className="s1">
                <span><i className="id-color fa fa-map-marker fa-lg"></i>08 W 36th St, New York, NY 10001</span>
                <span><i className="id-color fa fa-phone fa-lg"></i>+1 333 9296</span>
                <span><i className="id-color fa fa-envelope-o fa-lg"></i><span className='btn'>contact@example.com</span></span>
                <span><i className="id-color fa fa-file-pdf-o fa-lg"></i><span className='btn'>Download Brochure</span></span>
              </address>
            </div>

            <div className="padding40 box-rounded mb30 text-light">
              <h3>AU Office</h3>
              <address className="s1">
                <span><i className="fa fa-map-marker fa-lg"></i>100 Mainstreet Center, Sydney</span>
                <span><i className="fa fa-phone fa-lg"></i>+61 333 9296</span>
                <span><i className="fa fa-envelope-o fa-lg"></i><span className='btn'>contact@example.com</span></span>
                <span><i className="fa fa-file-pdf-o fa-lg"></i><span className='btn'>Download Brochure</span></span>
              </address>
            </div>

          </div>

        </div>
      </section>
      <Footer />
    </div>
  }
  </>
  );
}
export default memo(Contact);