import React, { memo } from 'react';
import styled from "styled-components";
import Clock from "./Clock";
import { navigate } from '@reach/router';
import api from '../../core/api';
import { Link } from '@reach/router';
import Avatar from 'react-avatar';
import sellContractABI from '../../web3/metamaskConnection/ABI/sellABI.json'
import sellContractAddress from '../../web3/metamaskConnection/contractAddress/sellContract'


const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({ isApproved,nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad }) => {

    const handleReSell = async(item)=>{
        try {
            console.log(item);
        } catch (error) {
            console.log();
        }
    }

    console.log(nft);


    return (
        <div >
            {/* <Link state={nft} to='/ItemDetail/1'> */}
            <div className="nft__item m-0 blue_card">
            { nft?.item_type === 'single_items' ? (
             <div className='icontype'><i className="fa fa-bookmark"></i></div>   
             ) : (  
             <div className='icontype'><i className="fa fa-shopping-basket"></i></div>
                )
            }
             {/* { nft?.created && clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft?.created} />
                    </div>
                } */}
                
                <div className="author_list_pp">
                    <span >
                    {/* onClick={()=> navigateTo(nft?.Link)} */}
                    {
nft?.userId?.profilePicture?
<img className="lazy" src={nft?.userId?.profilePicture} alt=""/>
:
<Avatar name={nft?.userId?.name} size="40" round="50px"/>

                    }
                        <i className="fa fa-check"></i>
                    </span>
                </div>
                <div  className="nft__item_wrap imageBoxContainer" >
                <Outer>
                    <span>
                        <img   src={nft?.ProductImage} className="lazy nft__item_preview imageBox" alt="imagess" />
                    </span>
                </Outer>
                </div>
                { nft?.created && !clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft?.created} />
                    </div>
                }
                <div className="nft__item_info">
                    <span >
              
                        <h4>{nft?.name}</h4>
                    </span>
                    { nft?.status === 'has_offers' ? (
                            <div className="has_offers">
                                <span className='through'>{nft?.price}</span> {nft?.price} ETH
                            </div> 
                        ) : (
                            <div className="nft__item_price">
                                {nft?.price} ETH
                                { nft?.status === 'on_auction' && 
                                    <span>{nft?.bid}/{nft?.max_bid}</span>
                                }
                            </div>
                        )
                    }
                    {/* <div className="nft__item_action">
                        <span>{ nft?.status === 'on_auction' ? 'Place a bid' : 'Buy Now' }</span>
                    </div> */}
                    <div className="nft__item_like">
                        <i className="fa fa-heart"></i><span>{nft?.likes}</span>
                    </div>  
                    {
                        nft?.isSold&&
                        <div>
                        <Link to='/create-resell' state={nft}>
                    <button
                              className="btn-main lead mb-5 mr15"
                            >
                              Sell
                            </button>
                            </Link>
                        </div>  
                    }        
                                    
                </div> 
            </div>
            
            {/* </Link> */}
        </div>             
    );
};

export default memo(NftCard);