import { Grid } from '@mui/material'
import React, { useState } from 'react'
import '../assets/style.scss'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Axios  from '../api-instance';
import axios from 'axios';
import constants from '../constants';
import { useLocation, useParams } from '@reach/router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPswd() {


  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [showconfPassword, setShowconfPassword] = React.useState(false);

  const handleClickconfShowPassword = () => setShowconfPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const [password,setPassword] = useState("")
  const [confPassword,setConfPassword] = useState("")

  const [passwordErr,setPasswordErr] = useState("")
  const [confPasswordErr, setConfPasswordErr] = useState("")

  const {id} = useParams()
console.log(id,"id")

  const handleChangePass = async()=>{
    try {
      if(password == ""){
        setPasswordErr("Please enter Password")
      }else if (confPassword== ""){
        setConfPasswordErr("Please Enter conf Password")
      }
      else if(password != confPassword){
        setConfPasswordErr("Password doesnot match")
      } else{
        const {data} = await Axios.post(`${constants.port}/auth/resetsPsws`,{
          password: password,
          token: id
        })
        console.log(data,"datadata")
        if(data?.status == true){
          toast.success(data?.data)
          setTimeout(()=>{
            window.location.replace("/login")
          },1000)
        }
      }
    } catch (error) {
      console.log(error,"error")
    }
  }

  return (
    <div className='resetpaswd' style={{ margin: "100px 0px" }}>
        <ToastContainer style={{ top: "5em" }} />
      <Grid container justifyContent="center">
        <Grid xs={12} sm={10} md={7} lg={6} xl={4}>
          <div className='reser-main'>
            <div className='rset-title'>
              Reset Password
            </div>

            <FormControl sx={{ my: 3, width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                onChange={(e)=>{setPassword(e.target.value); setPasswordErr("")}}
                // placeholder='New Password'
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
              {passwordErr && <div style={{textAlign:"center", color:"red"}} >{passwordErr}</div>}
            </FormControl>
            <FormControl sx={{ my: 3, width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showconfPassword ? 'text' : 'password'}
                placeholder='Confirm Password'
                onChange={(e)=>{setConfPassword(e.target.value); setConfPasswordErr("")}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickconfShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {!showconfPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
              {confPasswordErr && <div style={{textAlign:"center", color:"red"}} >{confPasswordErr}</div>}
            </FormControl>

            <div id='submit' >
              <input type='submit' id='send_message' value='Reset password' className="btn btn-main color-2" style={{margin:'10px auto'}} onClick={()=>{handleChangePass()}} />
              <div className="clearfix"></div>
            </div>

            <h6 className="footer_last_title2">Copyright © 2024 <a href='https://yoda.hypeople.studio/ '> Wallex.</a> All rights reserved </h6>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ResetPswd