import React,{useState,useEffect} from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Axios from '../../api-instance'
import constant from '../../constants'
import { Link } from '@reach/router';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Navigate} from 'react-router-dom'
import validator from 'validator';
import {AiFillEye,AiFillEyeInvisible} from 'react-icons/ai'
const  passwordValidator = require('password-validator');
var schema = new passwordValidator();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const GlobalStylesGrey = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid #ff343f;
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: #ff343f;
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 #ff343f;
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: rgba(33, 36, 40, .9);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{ 
    color: #fff !important;
    background: #ff343f;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: #ff343f;
  }
  @media only screen and (max-width: 1199px) { 
    .navbar {
      background: #212428;
    }
  }
`;

  // Add properties to it
  schema
  .is().min(8)                                    // Minimum length 8
  .is().max(30)                                  // Maximum length 100
  .has().uppercase(1)                              // Must have uppercase letters
  .has().lowercase(1)                              // Must have lowercase letters
  .has().digits(1)                                // Must have at least 2 digits
  .has().not().spaces()                           // Should not have spaces
  .is().not().oneOf(['Passw0rd', 'Password123']); 

const Logintwo= () => {

  const [isDark, setIsDark] = useState(false)
  useEffect(()=>{
    if(localStorage.getItem('mode')==='dark'){
       setIsDark(true)
    }else if(localStorage.getItem('mode')==='light'){
      setIsDark(false)
    }
  },[])


const [dataSet, setDataSet] = useState({
  email:null,
  password:null,
  rePassword:null
})




const handleChange =(e)=>{
  setEmailWarning(false)
  // setPasswordWarning(false)
  // setRePasswordWarning(false)
  // setSendPassword(false)
  setEmailValidator(false)
  // setPasswordValidator(false)
  setData(false)
   const {name,value} = e.target;
   setDataSet((prev)=>{
     return{
       ...prev,
       [name]:value
     }
   })
}

const [redirectPage, setRedirectPage] = useState(null)
const [emailWarning, setEmailWarning] = useState(null)
const [emailValidator, setEmailValidator] = useState(null)
const [passwordWarning, setPasswordWarning] = useState(null)
const [rePasswordWarning, setRePasswordWarning] = useState(null)
const [passwordValidator, setPasswordValidator] = useState(null)
const [data,setData] = React.useState(false)
const [registered, setRegistered] = useState(false)
const [userData, setUserData] = useState('')
const [emailError, setEmailError] = useState("");


const handleEmailChange = (event) => {
  const email = event.target.value;
  setDataSet((prev) => ({ ...prev, email }));
  setEmailError(email === "" ? "" : validateEmail(email) ? "" : "Invalid email address");
};

const validateEmail = (email) => {
  return validator.isEmail(email);
};



const handleSubmit = async(event)=>{
  
  event.preventDefault()
  if(dataSet.email===null){
   return setEmailWarning(true)
  }
  // if(dataSet.password.trim()===""){
  //  return  setPasswordWarning(true)
  // }

  // if(dataSet.rePassword===null){
  //   return  setRePasswordWarning(true)
  //  }
  if(!validator.isEmail(dataSet.email)){
    return setEmailValidator(true)
}
// if(!schema.validate(dataSet.password)){
//     return setPasswordValidator(true)
// }

// if(dataSet.password!==dataSet.rePassword){
//     return setSendPassword(true)
//    }
  try {
    // const data = await Axios.post(`/auth/login`,dataSet,{
    //             headers:{'Content-Type':'application/json',
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,PATCH,OPTIONS",
    //             }
    //         })
    //         setData(data?.data)
    //         if(data?.data?.status){
    //           window.localStorage.setItem('token',data?.data?.data?.token)
    //           window.localStorage.setItem('isAuth',true)
    //           window.location.href=`${constant.MainURL}/home4`
    //         }
   
    const datas = await Axios.post('/auth/reset',{"email":dataSet.email},{'Content-type':'Application/json'})
    
    setRegistered(datas?.data?.data==='mail sent');
    setTimeout(()=>{
      window.location.href=`${constant.MainURL}/login`
    },3000)
  } catch (error) {
    alert(error)
    window.location.reload()
  }
  
}



  const [hidePassword, setHidePassword] = useState(true)

  const passwordToggle = ()=>{
    if(hidePassword){
      setHidePassword(false)
    }else {
    setHidePassword(true)
    }
  }


  return(
    <>
    
  {
      
        registered?
    <div className="checkout">
      <div className="maincheckout">
        {/* <Link to='/login'>
      <button
                   className="btn-close"
                  
                 >
                   x
                 </button>
                 </Link> */}
           <h1 className='heading'>Mail Sended successfully!</h1>
           <p>The verification link sent to this email <span style={{color:'#4285F4'}}>{dataSet?.email}</span> </p>
           <p>Please, Verfiy your E-mail and reset your Account...</p>
      </div>
    </div>:
    isDark?
    <div className="greyscheme" style={{maxHeight:'100vh'}}>
    <GlobalStylesGrey/>
    
      <section className='container'>
        <div className="row">
        <div className='spacer-double'></div>
        <div className="col-md-6 offset-md-3">
    
          <form name="contactForm" id='contact_form' className="form-border" action=''>
              <h3>Login to your account</h3>
                      <div className="field-set">
                          <label>Email</label>
                          <input type='email' name='email' id='email' className="form-control" placeholder="" value={dataSet.email} onChange={handleChange} required/>
                          {
                            emailWarning?
                            <p style={{color:'red',textAlign:'center'}}>
                              *Please Enter this field
                            </p>:null
                          }
                          {
                            emailValidator?
                            <p style={{color:'red',textAlign:'center'}}>
                              *Please Enter the Valid Email Adress
                            </p>:null
                          }
                          {
                            data.msg ==="User not verified"?
                            <p style={{color:'red',textAlign:'center'}}>
                            User mail not verified
                          </p>:null
                          }
                      </div>
    
    
                      <div className="field-set">
                          <label>Password</label>
                          <div className="form-control" style={{display:'flex',alignItems:'center'}}>
                          <input type={hidePassword?'password':'text'} name='password' id='password' className='passwordField'  placeholder="" value={dataSet.password} onChange={handleChange} style={{width:'100%'}} required/>
                          {
                            hidePassword?
                            <AiFillEye onClick={passwordToggle}/>:
                            <AiFillEyeInvisible onClick={passwordToggle}/>
                          }
                          </div>
                          {
                            passwordWarning?
                            <p style={{color:'red',textAlign:'center'}}>
                              *Please Enter this field
                            </p>:null
                          }
                          {
                            data.error ==="Wrong Password"?
                            <p style={{color:'red',textAlign:'center'}}>
                            Wrong Password
                          </p>:null
                          }
                      </div>
    
              <div id='submit'>
                  <input onClick={handleSubmit} type='submit' id='send_message' value='Login' className="btn btn-main color-2"/>
                  <div className="clearfix"></div>
          
                  <div className="spacer-single"></div>
                   <h4>Don't have any account,<Link to='/register' style={{color:'blue'}}>Sign Up</Link> </h4>
                   {/* <Link to='register'>Sign Up</Link> */}
                  <ul className="list s3">
                      <li>Or login with:</li>
                      <li><span>Facebook</span></li>
                      <li><span>Google</span></li>
                      <li><span>Instagram</span></li>
                  </ul>
              </div>
          </form>
    
          </div>
        </div>
      </section>
    
      <Footer />
    </div>
          :
          
            <div style={{overflow:'scroll'}}>
            <GlobalStyles/>
            
              {/* <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.webp'})`}}>
                <div className='mainbreadcumb'>
                  <div className='container'>
                    <div className='row'>
                      <div className="col-md-12 text-center">
                          <h1>User Login</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}

<section className='jumbotron breadcumb no-bg '  
// style={{background:'linear-gradient(90deg, rgba(9,60,121,1) 0%, rgba(50,152,208,1) 100%)'}}
>
                 <div className='mainbreadcumb'>
                   <div className='container'>
                     <div className='row'>
                       <div className="col-md-12 text-center">
                           <h1 className='text-center black-color'>Forgot Password</h1>
                           <span></span><br/>
                           {/* <span>Anim pariatur cliche </span> */}
								        	
								       

                       </div>
                     </div>
                   </div>
                 </div>
               </section>
            
              <section className='container'>
                <div className="row">
                <div className="col-md-6 offset-md-3">
            
                  <form name="contactForm" id='contact_form' className="form-border" action=''>
                      <h3 className="black-color">Forgot Password</h3>
                              <div className="field-set">
                                  <label className="black-color">Email Id</label>
                                  <input type='email' name='email' id='email' className="form-control" placeholder="" value={dataSet.email} onChange={handleEmailChange} required/>
                                  {
                            emailWarning?
                            <p style={{color:'red',textAlign:'center'}}>
                              *Please Enter this field
                            </p>:null
                          }
                          {
                            emailError ?
                              <p style={{ color: 'red', textAlign: 'center' }}>
                                *Please Enter this valid email address
                              </p> : null
                          }
                          {
                            data.msg ==="User not verified"?
                            <p style={{color:'red',textAlign:'center'}}>
                            User mail not verified
                          </p>:null
                          }
                              </div>
            
            
                              
            
                      <div  onClick={handleSubmit} id='submit'>
                          <input onClick={handleSubmit}  type='submit' id='send_message' value='Click to Reset Password' className="btn btn-main color-2"/>
                          <div className="clearfix"></div>
            
                          
                      </div>

                  </form>

                  
                  </div> 
                </div>
              </section>
            
            <div style={{position:'relative',bottom:'0',width:'100%'}}>
              <Footer />
              </div>
            </div>
  }

        
    
    </>
  )
}
export default Logintwo;