import React,{useState,useEffect} from 'react';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { Link } from '@reach/router';

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const GlobalStylesDark = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid #ff343f;
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: #ff343f;
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 #ff343f;
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: rgba(33, 36, 40, .9);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{
    color: #fff !important;
    background: #ff343f;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: #ff343f;
  }
  @media only screen and (max-width: 1199px) { 
    .navbar {
      background: #212428;
    }
  }
`;

const Logintwo= () => {

  const [isDark, setIsDark] = useState(false)
useEffect(()=>{
  if(localStorage.getItem('mode')==='dark'){
     setIsDark(true)
  }else if(localStorage.getItem('mode')==='light'){
    setIsDark(false)
  }
},[])

  return(
    <>
    {
      isDark ?
      <div className="greyscheme">
      <GlobalStylesDark/>
      
        <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/6.jpg'})`}}>
          <div className='mainbreadcumb'>
            <div className='container'>
              <div className='row align-items-center px-0'>
                <div className="col-lg-4 offset-lg-4 m-auto px-0">
                  <div className="box-login">
                    <h3 className="mb10">Sign In</h3>
                    <p>Login using an existing account or create a new account <span>here</span>.</p>
                    <form name="contactForm" id='contact_form' className="form-border" action='#'>
      
                        <div className="field-set">
                            <input type='text' name='email' id='email' className="form-control" placeholder="username"/>
                        </div>
                      
                       <div className="field-set" >
                            <input type='password' name='password' id='password' className="form-control" placeholder="password"/>
                        </div>
                      
                      <div className="field-set">
                        <input type='submit' id='send_message' value='Submit' className="btn btn-main btn-fullwidth color-2"/>
                      </div>
                      
                      <div className="clearfix"></div>  
                        <ul className="list s3">
                            <li>Login with:</li>
                            <li><span >Facebook</span></li>
                            <li><span >Google</span></li>
                        </ul>
                        <div className="spacer-half"></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
        <Footer />
      </div>
      :<div style={{color:'black'}}>
      <GlobalStyles/>
      
        <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.webp'})`}}>
          <div className='mainbreadcumb'>
            <div className='container'>
              <div className='row align-items-center'>
                <div className="col-lg-5 text-light wow fadeInRight" data-wow-delay=".5s">
                    <div className="spacer-10"></div>
                    <h1>Create, sell or collect digital items.</h1>
                    <p className="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua ut enim.</p>
                </div>
                <div className="col-lg-4 offset-lg-2 wow fadeIn" data-wow-delay=".5s">
                  <div className="box-login">
                    <h3 className="mb10">Sign In</h3>
                    <p>Login using an existing account or create a new account <span>here</span>.</p>
                    <form name="contactForm" id='contact_form' className="form-border" action='#'>
      
                        <div className="field-set">
                            <input type='text' name='email' id='email' className="form-control" placeholder="username"/>
                        </div>
                      
                       <div className="field-set">
                            <input type='password' name='password' id='password' className="form-control" placeholder="password"/>
                        </div>
                      
                      <div className="field-set">
                        <input type='submit' id='send_message' value='Submit' className="btn btn-main btn-fullwidth color-2"/>
                      </div>
                      
                      <div className="clearfix"></div>
                      
                      <div className="spacer-single"></div>
                        <ul className="list s3">
                            <li>Login with:</li>
                            <li><span >Facebook</span></li>
                            <li><span >Google</span></li>
                        </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      
        <Footer />
      </div>
    }
    </>
    );
}
export default Logintwo;