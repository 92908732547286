import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader 
    speed={3}
    width={1200}
    height={460}
    viewBox="0 0 1200 460"
    backgroundColor="#cccccc"
    foregroundColor="#dbdbdb"
    {...props}
  >
    <rect x="189" y="423" rx="0" ry="0" width="1" height="0" /> 
    <rect x="2" y="49" rx="3" ry="3" width="700" height="500" /> 
    <rect x="795" y="57" rx="0" ry="0" width="91" height="20" /> 
    <rect x="890" y="57" rx="0" ry="0" width="91" height="20" /> 
    <rect x="900" y="57" rx="0" ry="0" width="91" height="20" /> 
    <rect x="795" y="95" rx="0" ry="0" width="53" height="12" /> 
    <circle cx="815" cy="168" r="27" /> 
    <rect x="870" y="158" rx="0" ry="0" width="71" height="23" /> 
    <rect x="795" y="218" rx="0" ry="0" width="81" height="15" /> 
    <rect x="795" y="249" rx="0" ry="0" width="211" height="15" /> 
    <rect x="795" y="295" rx="0" ry="0" width="110" height="36" /> 
    <rect x="795" y="372" rx="0" ry="0" width="85" height="10" /> 
    <rect x="795" y="393" rx="0" ry="0" width="112" height="10" /> 
    <rect x="795" y="414" rx="0" ry="0" width="135" height="10" />
  </ContentLoader>
)

export default MyLoader

