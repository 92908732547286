import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Clock from "../components/Clock";
import Footer from "../components/footer";
import { Grid, Button, Box } from '@mui/material';
import constant from '../../constants'
import { styled } from '@mui/material/styles';
import { createGlobalStyle } from "styled-components";
import * as selectors from "../../store/selectors";
import { fetchNftDetail } from "../../store/actions/thunks";
import CurrencyBitcoinRoundedIcon from '@mui/icons-material/CurrencyBitcoinRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import VerifiedIcon from '@mui/icons-material/Verified';
// import Accordions from "./accordions";
import PriceAccordion from "./accordionspricelist"
import ListAccordion from "./accordionslistings"
import OfferAccordion from "./accordionsofferes"
import ItemAccordion from "./accordionsitem"
import CardAccordion from "./accordioncards"
import DecsAccordion from "./accortiondescrition"
import AboutAccordion from "./accordionsabout"
import DetailAccordion from "./accordiondetails"
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
/*import Checkout from "../components/Checkout";
import Checkoutbid from "../components/Checkoutbid";*/
import api from "../../core/api";
import moment from "moment";
import "./Itemdetails.css"
import { useLocation, useNavigate } from "@reach/router";
import Loader from "../../assets/loader/giphy.gif";
import NormalLoader from '../../assets/loader/load.gif'
import Axios from "../../api-instance";
import constants from "../../constants";
import startAuctionLoader from "../../assets/loader/d.gif";
import tick from "../../assets/loader/tick.webp";
//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from "../Styles";
import buyContractABI from "../../web3/metamaskConnection/ABI/buyABI.json";
import buyContractAddress from "../../web3/metamaskConnection/contractAddress/buyContractAddress";
import buyPolygonABI from '../../web3/metamaskConnection/ABI/polygonBuyABI.json'
import buyPolygonBuyContract from '../../web3/metamaskConnection/contractAddress/polygonBuyContract'
// import auctionContractAddress from "../../web3/metamaskConnection/contractAddress/auctionContractAddress";
// import auctionContractABI from "../../web3/metamaskConnection/ABI/auctionABI.json";
import mintContractAddress from "../../web3/metamaskConnection/contractAddress/contractAddress";
import polygonMintContractAddress from '../../web3/metamaskConnection/contractAddress/polygonContractAddress'
import mintContractABI from "../../web3/metamaskConnection/ABI/contractABI.json";
import timeBidContractAddress from "../../web3/metamaskConnection/contractAddress/timeBidContractAddress";
import auctionPolygonContractAddress from '../../web3/metamaskConnection/contractAddress/polygonAuctionContractAddress'
import timeAuctionPolygonContractAddress from '../../web3/metamaskConnection/contractAddress/polygonTimeAuctionContract'

// import auctionBnBContractAddress from '../../web3/metamaskConnection/contractAddress/binance_auction_contract_address.js'

import timeBidABI from "../../web3/metamaskConnection/ABI/timeBidABI.json";
import newAuctionContractAddress from "../../web3/metamaskConnection/contractAddress/binance_auction_contract_address";
import newTimeAuctionContractAddress from '../../web3/metamaskConnection/contractAddress/binance_time_auction_contract_address'
import newTimeAuctionContractABI from '../../web3/metamaskConnection/ABI/newTimeAuctionAbi.json'
import newAuctionContractABI from "../../web3/metamaskConnection/ABI/newAuctionABI.json";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import binanceContractAddress from '../../web3/metamaskConnection/contractAddress/binance_contract_address.js'
import binanceContractAPI from '../../web3/metamaskConnection/ABI/binance_contract.json'
import binanceBuyContractAddress from "../../web3/metamaskConnection/contractAddress/binance_buy_contract_address";
import binanceBuyContractAPI from '../../web3/metamaskConnection/ABI/binance_buy_contract.json'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import truncateEthAddress from "truncate-eth-address";

import Avatar from "react-avatar";
import { Link } from '@reach/router'
import axios from 'axios'
import ItemDetailContentLoader from '../components/LoaderNew'
import Web3 from "web3";
import Modal from '@mui/material/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField';

// var CronJob = require('cron').CronJob;
// var job = new CronJob('* * * * * *', function() {
//   console.log('You will see this message every second');
// }, null, true, 'America/Los_Angeles');
// job.start();

// polygon url
// const alchemyKey ="https://polygon-mumbai.g.alchemy.com/v2/qlt3u9DeNuef36KsQWwzALRg1x23aBeE";
// https://eth-rinkeby.alchemyapi.io


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  borderRadius:'12px',
  p: 4,
};


const alchemyKey =
  "https://eth-mainnet.g.alchemy.com/v2/E11IswOPCxAsDPDlRkg7KV-RPwkjCafv";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
// const web3 = createAlchemyWeb3(alchemyKey);
const web3 = new Web3(window.ethereum);
var ethers = require("ethers");

const PRIVATE_KEY = "0xqlt3u9DeNuef36KsQWwzALRg1x23aBeE";

//SWITCH VARIABLE FOR PAGE STYLE
const theme = "GREY"; //LIGHT, GREY, RETRO
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
    border-bottom: solid 1px #dddddd;
  }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #8364E2 !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;
const GridContainers = styled(Grid)(({ theme }) => ({

  display: "flex",
  flexWrap: "nowrap",
  alignItems: "flex-start",
  margin: "0px 20px",
  '& .MuiSvgIcon-root': {
    margin: "10px",
    fontSize: "20px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "15px"
    },
  }
}));
const GridContainer = styled(Grid)(({ theme }) => ({

  display: "flex",

  alignItems: "flex-start",
  [theme.breakpoints.down('sm')]: {
    margin: "auto",
  },
  '& .MuiSvgIcon-root': {
    margin: "10px",
    fontSize: "20px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "15px"
    },
  }
}));
const RightGrid = styled(Grid)(({ theme }) => ({
  fontSize: '15px',
  display: "flex",
  justifyContent: "flex-end",
  margin: "10px 30px",
  [theme.breakpoints.down('sm')]: {
    display: "flex",
    justifyContent: "center",
  },
}));
const LeftGrid = styled(Grid)(({ theme }) => ({
  fontSize: '15px',
  margin: "10px 0px",
  '& .MuiSvgIcon-root': {
    margin: "10px",
    fill: "#2081e2",
    fontSize: "20px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "15px"
    },
  },
  '& span': {
    color: "#2081e2",
    fontWeight: 500,

  },
  '& p': {
    marginBottom: "1px"
  },
  [theme.breakpoints.down('sm')]: {
    margin: "10px",
    textTlign: "center",
  },

  '& .MuiButton-root': {
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: "10px",
    background: "#f6f6f6",
    width: "90%",
    color: "#030303",
    [theme.breakpoints.down('sm')]: {
      marginTop: "10px",
    },
    "&:hover,&:focus": {
      background: "#fff",
      borderRadius: "10px",
      color: "#030303 !important",
      transition: "all 0.3s ease 0s",
      boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    },

  },

}));
const IconGrid = styled(Grid)(({ theme }) => ({
  fontSize: '15px',
  display: "flex",
  margin: "0px 20px",
  '& span': {
    color: "#2081e2",
    fontWeight: 500,

  },
  '& p': {
    fontSize: "15px",
    marginBottom: "1px",
    textTransform: "Captalize"
  },
  '& div': {
    display: "flex",
    alignItems: "center",
    marginRight: "15px"
  },
  '& .MuiSvgIcon-root': {
    margin: "8px 4px 8px 0px",
    fontSize: "15px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "15px"
    },
  },
  [theme.breakpoints.down('sm')]: {
    margin: "10px",
    textTlign: "center",
  },

}));
const LeftGrids = styled(Grid)(({ theme }) => ({
  fontSize: '15px',
  display: "flex",
  alignContent: "center",
  margin: "1px 30px",
  '& p': {
    marginRight: "25px",
    marginBottom: "1px"
  }
  ,
  '& span': {
    color: "#2081e2",
    fontWeight: 500,

  },
  [theme.breakpoints.down('sm')]: {
    '& p': {
      marginRight: "15px",
    }
  },
  '& .MuiButton-root': {
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: "10px",
    background: "#f6f6f6",
    width: "90%",
    color: "#030303",
    [theme.breakpoints.down('sm')]: {
      marginTop: "10px",
    },
    "&:hover,&:focus": {
      background: "#fff",
      borderRadius: "10px",
      color: "#030303 !important",
      transition: "all 0.3s ease 0s",
      boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    }
  },

}));
const CenterGrid = styled(Grid)(({ theme }) => ({
  fontSize: '15px',

  justifyContent: "space-between",
  '& .MuiOutlinedInput-root': {
    height: "40px",
    width: "100%",
    border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: "10px"
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: "transparent"
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: "transparent"
  },
  '& .MuiButton-root': {
    fontSize: '15px',
    fontWeight: 600,
    borderRadius: "10px",
    background: "#f6f6f6",
    width: "90%",
    color: "#030303",
    [theme.breakpoints.down('sm')]: {
      marginTop: "10px",
    },
    "&:hover,&:focus": {
      background: "#fff",
      borderRadius: "10px",
      color: "#030303 !important",
      transition: "all 0.3s ease 0s",
      boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    }
  },

}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ItemDetailRedux = ({ nftId }) => {

  const navigate = useNavigate()
  const location = useLocation();

  const [finalResult, setFinalResult] = useState(location.state)
  const [openMenu0, setOpenMenu0] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [balance, setBalance] = useState("");
  const [service, setService] = useState("");
  const [pay, setPay] = useState("");
  const [insufficientFunds, setInsufficientFunds] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [transactionFrom, setTransactionFrom] = useState("");
  const [transactionTo, setTransactionTo] = useState("");
  const [isAuctionTable, setIsAuctionTable] = useState(false);
  const [transactionBidder, setTransactionBidder] = useState("");
  const [transactionAuctionID, setTransactionAuctionID] = useState("");
  const [transactionGasUsed, setTransactionGasUsed] = useState("");
  const [mintingApproved, setMintingApproved] = useState(false);
  const [heartFill, setHeartFill] = useState(false);

  const [likeCount, setLikeCount] = useState(finalResult?.favourite?.length);
  const [totalViews, setTotalViews] = useState(finalResult?.view?.length);

  const [recentAuction, setRecentAuction] = useState("");

  const [openCheckout, setOpenCheckout] = React.useState(false);
  const [openCheckoutbid, setOpenCheckoutbid] = React.useState(false);
  const [mintingLoader, setMintingLoader] = React.useState(false);
  const [viewCount, setViewCount] = useState("");
  const [auctionPriceValue, setAuctionPriceValue] = useState("");
  const [auctionWarning, setAuctionWarning] = useState(false);
  const [auctionPriceWarning, setAuctionPriceWarning] = useState(false);
  const [lastBid, setLastBid] = useState("");
  const [userData, setUserData] = useState(null);
  const [isAlreadyBided, setIsAlreadyBided] = useState("");
  const [bidedValue, setBidedValue] = useState("");
  const [endTransaction, setEndTransaction] = useState(false);
  const [isMintedUser, setIsMintedUser] = useState(false);
  const [mintedUserBid, setMintedUserBid] = useState(false);
  const [auctionTable, setAuctionTable] = useState([]);

  const [startAuction, setStartAuction] = useState(false);
  const [approveAuction, setApproveAuction] = useState(false);
  const [biddingApproved, setBiddingApproved] = useState(false);
  const [owner, setOwner] = useState(false);
  const [claimButton, setClaimButton] = useState(false);

  const [errorHandling, setErrorHandling] = useState(false);
  const [rejectWarning, setRejectWarning] = useState(null);
  const [isDark, setIsDark] = useState(false);
  const [auctionId, setAuctionId] = useState("");
  const [timeDuration, setTimeDuration] = useState(false)
  const [isNotMintedUser, setIsNotMintedUser] = useState(true);
  const [mintedUserData, setMintedUserData] = useState([]);
  const [timeBidOpen, setTimeBidOpen] = useState(false);
  const [userDataID, setUserDataID] = useState('')
  const [reListAuction, setReListAuction] = useState(false)
  const [collections, setcollections] = useState([])
  const [time, setTime] = useState()

  const [reListingBox, setReListingBox] = useState(false)
  const [cancelAuction, setCancelAuction] = useState(false)

  const [handleOpen, setHandleOpen] = useState(false)

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrorHandling(false)
  };

 
  const handleBtnClick0 = () => {
    setOpenMenu0(!openMenu0);
    setOpenMenu(false);
    setOpenMenu1(false);
    setIsAuctionTable(false);
    setRecentAuction(false);
    document.getElementById("Mainbtn0").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    // document.getElementById("Mainbtn1").classList.remove("active");
  };
  const handleBtnClick = async () => {
    try {

      // setOpenMenu(!openMenu);
      setOpenMenu1(false);
      setOpenMenu0(false);
      setIsAuctionTable(true);
      // setRecentAuction(true);
      document.getElementById("Mainbtn").classList.add("active");
      // document.getElementById("Mainbtn1").classList.remove("active");
      document.getElementById("Mainbtn0").classList.remove("active");
    } catch (error) {
      console.log(error);
    }
  };
  // const handleBtnClick1 = () => {
  //   setOpenMenu1(!openMenu1);
  //   setOpenMenu(false);
  //   setOpenMenu0(false);
  //   setIsAuctionTable(false);
  //   setRecentAuction(false);
  //   document.getElementById("Mainbtn1").classList.add("active");
  //   document.getElementById("Mainbtn").classList.remove("active");
  //   document.getElementById("Mainbtn0").classList.remove("active");
  // };

  const [initialLoader, setInitialLoader] = useState(false);

  useEffect(() => {

    setTimeout(() => {
      setInitialLoader(false);
    }, 5000);
    setInitialLoader(true);
  }, []);

  const dispatch = useDispatch();
  const nftDetailState = useSelector(selectors.nftDetailState);
  const nft = nftDetailState.data ? nftDetailState.data : [];

  useEffect(() => {
    dispatch(fetchNftDetail(nftId));
  }, [dispatch, nftId]);

  useEffect(() => {
    if (localStorage.getItem("mode") === "dark") {
      setIsDark(true);
    } else if (localStorage.getItem("mode") === "light") {
      setIsDark(false);
    }
  }, []);

  const [historyValue, setHistoryValue] = useState('')
  const [his, setHis] = useState([])
  const [datas, setDatas] = useState([])

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await Axios.get(`/auction?productId=${finalResult?._id}`);
        setTime(data?.data?.data[0]?.timeDuration)
        if (data?.data?.data[0]?.transaction?.length > 0) {
          setHis(data?.data?.data[0]?.transaction)
          const d = data?.data?.data[0]?.transaction?.length - 1
          setHistoryValue(data?.data?.data[0]?.transaction[d])
        } else {
          setBidedValue(data?.data?.data[0]?.transaction)
        }

        // if(window.location.pathname.split('/').pop()){
        //   if(finalResult?.mintTransaction?.transactionHash!== window.location.pathname.split('/').pop()){
        //        window.location.href=`${constants?.MainURL}/404`
        //   }
        // }

        const mintedData = await Axios.get(
          `/auction?productId=${finalResult?._id}`
        );

        const productDatas = await Axios.get(`/product?id=${finalResult?._id}`);
        // const creatorData = await Axios.get('/auth/user?_id')
        setMintedUserData(mintedData?.data?.data);

        let arr = mintedData?.data?.data[0]?.transaction;
        if (finalResult?._id === mintedData?.data?.data[0]?.productId?._id) {
          setAuctionId(mintedData?.data?.data[0]?.auctionId);
          // setAuctionId(mintedData?.data?.data[0]?.mintId);
          if (mintedData?.data?.data[0]?.isActive === true) {
            setLastBid(arr);
          }
        }




        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        if (productDatas?.data?.data[0]?.mintTransaction?.from === accounts[0]) {
          setEndTransaction(true);
        }
        const token = window.localStorage.getItem("token");
        if (token) {
          const { data } = await Axios.get(`/auth/getdata/${token}`);
          setUserDataID(data?.data)
          setUserData(data?.data);
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            chainId: '0x1'
          });

          //finding the minting user or not for not displaying buynow button
          if (data) {
            if (data?.data?._id === productDatas?.data?.data[0]?.userId?._id) {
              if (finalResult?.makeMarketTransaction?.from === accounts[0]) {
                setIsNotMintedUser(false);
              }
            }
          }

          if (mintedData?.data?.data[0]?.userId === data?.data?._id) {
            if (finalResult?.mintTransaction?.from === accounts[0]) {
              if (finalResult?.isAuction) {
                if (mintedData?.data?.data[0]?.isActive === false) {
                  setIsMintedUser(true);
                }
                if (mintedData?.data?.data[0]?.isActive === true) {
                  if (mintedData?.data?.data[0]?.productId?._id === finalResult?._id) {

                    if (mintedData?.data?.data[0].timeBid && mintedData?.data?.data[0]?.transaction?.length > 0 && moment().format("lll") < moment(mintedData?.data?.data[0]?.expireDate).format("lll")) {
                      if (
                        moment().format("lll") <
                        moment(mintedData?.data?.data[0]?.expireDate).format(
                          "lll"
                        )
                      ) {
                        setClaimButton(true);
                        setTimeDuration(true)
                      }
                      else {
                        setClaimButton(true);
                        setTimeDuration(false)
                      }
                    }
                    else {
                      setReListAuction(true)
                    }






                    if (mintedData?.data?.data[0].openBid) {
                      setTimeDuration(true)
                      setReListAuction(false)
                    }

                    if (!mintedData?.data?.data[0].timeBid) {

                      if (mintedData?.data?.data[0]?.transaction.length > 0) {

                        setClaimButton(true);
                        if (mintedData?.data?.data[0]?.isVerified) {
                          // const getData = async()=>{
                          //   await Axios.put("/product",{'_id':finalResult?._id,"isReceived":true},{"Content-type":"Application/json"});
                          // }
                          // getData()
                        }
                      }
                    }
                    if (mintedData?.data?.data[0]?.isVerified) {
                      setClaimButton(false);
                    }
                  }
                }
              }
            }
          }



          if (finalResult?.mintTransaction?.from !== accounts[0]) {
            if (finalResult?.isAuction) {
              if (mintedData?.data?.data[0]?.isActive === true) {
                if (mintedData?.data?.data[0].timeBid === true) {
                  if (
                    moment().format("lll") <
                    moment(mintedData?.data?.data[0]?.expireDate).format(
                      "lll"
                    )
                  ) {
                    setMintedUserBid(true);
                  }
                }

              }
              if (mintedData?.data?.data[0].openBid === true) {
                setMintedUserBid(true);
              }


              else if (mintedData?.data?.data[0]?.isActive === false) {
                setMintedUserBid(false);
              }
            }
          }

          //This condition will explain the minted user in itemDetails page



          // if (finalResult?.isAuction) {
          //   const auctionCon =  finalResult?.auctionTransaction?.filter(
          //      (item, i) => {
          //        if (item?.userData?._id===data?.data?._id) {

          //          return item
          //        }
          //      }
          //    );

          //  if(auctionCon?.length!==0){
          //    const alreadyAuctionPrice = auctionCon[0];
          //    setIsAlreadyBided(true)
          //    setBidedValue(alreadyAuctionPrice)
          //  }
          //  }
        }

        const account = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: "0x1"
        });

        const getData = async () => {
          const ethereumBalance = await axios.get(`https://api.etherscan.io/api?module=account&action=balance&address=${account[0]}&tag=latest&apikey=TPAV1WFF45QS5H7EAZ8AFWSRFMN972S5WR`)

          // console.log(ethereumBalance,'asasf');
          setBalance(web3.utils.fromWei(ethereumBalance?.data?.result.toString(), "ether"))

        }
        getData()



        if (account) {
          web3.eth.getBalance(account[0], function (error, wei) {
            if (!error) {
              var balance = web3.utils.fromWei(wei, "ether");
              if (balance < finalResult?.price) {
                setInsufficientFunds(true);
              }






              // setBalance(parseFloat(balance).toFixed(5) + " ETH");
              setService(parseFloat((finalResult?.admin?.adminFees / 100)).toFixed(7));
            }
          });
        }

      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, [finalResult]);

  useEffect(() => {
    const da = async () => {
      const txHash = window.location.pathname.split('/').pop();
      const { data } = await Axios.get(`/product?transactionHash=${txHash}`)
      setFinalResult(data?.data);
      const collectionData = await Axios.post(`/product/getproductbycollection`, { _id: data?.data._id })
      setcollections(collectionData?.data?.data[0])
    }
    da()

  }, [])


  // const getCollection = async () => {

  //   const data = await Axios.post(`/product/getproductbycollection`,{_id:finalResult?._id})
  //   console.log("===>",data?.data)
  //   setcollections(data?.data?.data)
  // }

  // useEffect(() => {
  //   getCollection()
  // }, [])
  // useEffect(() => {

  //       const data =  Axios(`/product/data/?name=${finalResult.name}`)
  //       console.log(data,"data");
  //       setDatas(data?.data?.data)

  //   }, [])


  // const handleSell = async () => {
  //   try {
  //     // const gasPrice = await axios.get('https://ethgasstation.info/api/ethgasAPI.json');
  //     // console.log( ethers.utils.parseEther(finalResult?.price)._hex);
  //     // try {

  //     if (finalResult?.blockChain === "MATIC") {

  //       console.log('polygonasdfasf')
  //       setMintingLoader(true);
  //       await window.ethereum.request({
  //         method: "wallet_switchEthereumChain",
  //         params: [{ chainId: web3.utils.toHex(80001) }],
  //       });
  //       const account = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //         chainId: web3.utils.toHex(80001),
  //       });
  //       console.log("🚀 ~ file: Itemdetails.js:727 ~ account:", account)

  //       const contractInstance = new web3.eth.Contract(
  //         buyPolygonABI,
  //         buyPolygonBuyContract
  //       );
  //       console.log("🚀 ~ file: Itemdetails.js:732 ~ contractInstance:", contractInstance)

  //       const mintContractInstace = new web3.eth.Contract(
  //         mintContractABI, mintContractAddress
  //       )

  //       const trans = {
  //         from: account[0],
  //         to: buyContractAddress,
  //         gas: await web3.eth.getGasPrice()

  //       }

  //       console.log(ethers.utils.parseEther(`${finalResult?.price}`)._hex, "price")
  //       console.log(finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId, "itmes")
  //       console.log(await web3.utils.toWei((finalResult?.price).toString(), "ether"), "price1")
  //       console.log(finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId, "itmes")
  //       // const nonce = await web3.eth.getTransactionCount(account[0], "latest");

  //       console.log(await contractInstance.methods.createMarketsale(
  //         polygonMintContractAddress,-
  //         finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
  //       ).estimateGas({ from: account[0] ,value: await web3.utils.toWei((finalResult?.price).toString(), "ether") }),"gasparive")
  //       const transactionParams = {
  //         to: buyPolygonBuyContract,
  //         from: account[0], // must match user's active address.
  //         // nonce: `${nonce}`, // ignored by MetaMask
  //         // gasPrice: gasPrice?.data?.fast + "00000000",

  //         // gas: await contractInstance.methods.createMarketsale(
  //         //   polygonMintContractAddress,
  //         //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
  //         // ).estimateGas({ from: account[0] })
  //         //  ,
  //         value: await web3.utils.toWei((finalResult?.price).toString(), "ether"),
  //         // await web3.utils.toWei((finalResult?.price).toString(), "ether")
  //         //  ethers.utils.parseEther(`${finalResult?.price}`)._hex,
  //         // data: await contractInstance.methods.createMarketsale(
  //         //   mintContractAddress,
  //         //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
  //         // ).encodeABI()
  //       };
  //       console.log("🚀 ~ file: Itemdetails.js:762 ~ transactionParams:", transactionParams)

  //       // const txHash = await window.ethereum.request({
  //       //   method: "eth_sendTransaction",
  //       //   params: [transactionParams],
  //       // });



  //       const txHash = await contractInstance.methods.createMarketsale(
  //         polygonMintContractAddress,
  //         finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
  //       ).send(transactionParams);
  //       console.log("🚀 ~ file: Itemdetails.js:775 ~ txHash:", txHash)


  //       if (txHash) {
  //         const transactionReceipt = {
  //           to: finalResult?.mintTransaction?.from,
  //           from: account[0],
  //           hash: txHash.transactionHash,
  //         };

  //         await Axios.post('/product/collectionBuyProduct', {
  //           owner: userData?._id,
  //           product: finalResult._id
  //         },
  //           { "Content-type": "application/json" })
  //         const singleMint = await Axios.put(
  //           "/product",
  //           {
  //             _id: finalResult._id,
  //             buyTransaction: txHash,
  //             owners: userData?._id,
  //             isSold: true,
  //             userId: userData?._id,
  //             collection_id: finalResult?.collection_id
  //           },
  //           { "Content-type": "application/json" }
  //         );


  //         const sendMailtoBuyer = await Axios.post('/product/sendMailFirst?title=buy', {
  //           "fromID": userData?._id,
  //           "assetID": finalResult?._id
  //         })
  //         const sendMailtoReceiver = await Axios.post('/product/sendMailSecond?title=sold', {
  //           "fromID": finalResult?.userId,
  //           "assetID": finalResult?._id,
  //           "toID": userData?._id
  //         })

  //         setMintingLoader(false);
  //         setOpenMenu0(false)
  //         setOpenMenu1(false)
  //         setOpenMenu(false)
  //         setOpenCheckout(false)
  //         setOpenCheckoutbid(false)
  //         setMintingApproved(true);
  //         setTransactionHash(txHash?.transactionHash);
  //         setTransactionFrom(txHash?.from);
  //         setTransactionGasUsed(txHash?.gasUsed);
  //         setTransactionHash(transactionReceipt?.hash);
  //         toast.success(`New NFT- ${finalResult?.name} Buy Successfully`, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });

  //         setTimeout(() => {
  //           navigate('/Explore')
  //         }, 9000);
  //         window.document.body.style.overflow = "hidden";
  //       }
  //     } else {
  //       setMintingLoader(true);
  //       const account = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //         chainId: "0x61",
  //       });

  //       const contractInstance = new web3.eth.Contract(
  //         buyContractABI,
  //         buyContractAddress
  //       );

  //       const mintContractInstace = new web3.eth.Contract(
  //         mintContractABI, mintContractAddress
  //       )

  //       const trans = {
  //         from: account[0],
  //         to: buyContractAddress,
  //         gas: await web3.eth.getGasPrice()

  //       }



  //       // const nonce = await web3.eth.getTransactionCount(account[0], "latest");
  //       const transactionParams = {
  //         to: buyContractAddress,
  //         from: account[0], // must match user's active address.
  //         // nonce: `${nonce}`, // ignored by MetaMask
  //         // gasPrice: gasPrice?.data?.fast + "00000000",

  //         gas: await contractInstance.methods.createMarketsale(
  //           mintContractAddress,
  //           finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
  //         ).estimateGas({ from: account[0] })
  //         ,
  //         value: ethers.utils.parseEther(`${finalResult?.price}`)._hex,
  //         // data: await contractInstance.methods.createMarketsale(
  //         //   mintContractAddress,
  //         //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
  //         // ).encodeABI()
  //       };

  //       // const txHash = await window.ethereum.request({
  //       //   method: "eth_sendTransaction",
  //       //   params: [transactionParams],
  //       // });



  //       const txHash = await contractInstance.methods.createMarketsale(
  //         mintContractAddress,
  //         finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
  //       ).send(transactionParams);


  //       if (txHash) {
  //         const transactionReceipt = {
  //           to: finalResult?.mintTransaction?.from,
  //           from: account[0],
  //           hash: txHash.transactionHash,
  //         };

  //         await Axios.post('/product/collectionBuyProduct', {
  //           owner: userData?._id,
  //           product: finalResult._id
  //         },
  //           { "Content-type": "application/json" })
  //         const singleMint = await Axios.put(
  //           "/product",
  //           {
  //             _id: finalResult._id,
  //             buyTransaction: txHash,
  //             owners: userData?._id,
  //             isSold: true,
  //             userId: userData?._id,
  //             collection_id: finalResult?.collection_id
  //           },
  //           { "Content-type": "application/json" }
  //         );


  //         const sendMailtoBuyer = await Axios.post('/product/sendMailFirst?title=buy', {
  //           "fromID": userData?._id,
  //           "assetID": finalResult?._id
  //         })
  //         const sendMailtoReceiver = await Axios.post('/product/sendMailSecond?title=sold', {
  //           "fromID": finalResult?.userId,
  //           "assetID": finalResult?._id,
  //           "toID": userData?._id
  //         })

  //         setMintingLoader(false);
  //         setOpenMenu0(false)
  //         setOpenMenu1(false)
  //         setOpenMenu(false)
  //         setOpenCheckout(false)
  //         setOpenCheckoutbid(false)
  //         setMintingApproved(true);
  //         setTransactionHash(txHash?.transactionHash);
  //         setTransactionFrom(txHash?.from);
  //         setTransactionGasUsed(txHash?.gasUsed);
  //         setTransactionHash(transactionReceipt?.hash);
  //         toast.success('Buy Successfully', {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });

  //         setTimeout(() => {
  //           navigate('/Explore')
  //         }, 9000);
  //         window.document.body.style.overflow = "hidden";
  //       }
  //     }

  //   } catch (error) {
  //     console.log(error, "error")
  //     setMintingLoader(false);
  //     setOpenMenu0(false)
  //     setOpenMenu1(false)
  //     setOpenMenu(false)
  //     setOpenCheckout(false)
  //     setOpenCheckoutbid(false)
  //     if (
  //       error?.code === 4001 &&
  //       error.message ===
  //       "MetaMask Tx Signature: User denied transaction signature."
  //     ) {
  //       setTimeout(() => {
  //         setErrorHandling(false);
  //         window.location.reload();
  //       }, 3000);
  //       setErrorHandling(true);
  //       setRejectWarning("Metamask transaction user denied signature....");
  //     }
  //     if (error.code === -32603) {
  //       setTimeout(() => {
  //         setErrorHandling(false);
  //         window.location.reload();
  //       }, 3000);
  //       setErrorHandling(true);
  //       setRejectWarning("Transaction failed.....");
  //     }
  //   }
  // };

  const handleSell = async () => {
    try {
      if(window.localStorage.getItem("token") === null){
        toast.error("Please Login To Continue", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else if(window.localStorage.getItem("useraddress") === null){
        toast.error("Please Connect Your Wallet to Continue", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else if((window.localStorage.getItem("token") !==null) && (window.localStorage.getItem("useraddress") !== null) ){
     // const gasPrice = await axios.get('https://ethgasstation.info/api/ethgasAPI.json');
      // console.log( ethers.utils.parseEther(finalResult?.price)._hex);
      // try {
      if (finalResult?.blockChain === "MATIC") {

        setMintingLoader(true);
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(80001) }],
        });
        const account = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: web3.utils.toHex(80001),
        });

        const contractInstance = new web3.eth.Contract(
          buyPolygonABI,
          buyPolygonBuyContract
        );

        const mintContractInstace = new web3.eth.Contract(
          mintContractABI, mintContractAddress
        )

        const trans = {
          from: account[0],
          to: buyContractAddress,
          gas: await web3.eth.getGasPrice()

        }
        // const nonce = await web3.eth.getTransactionCount(account[0], "latest");
        const transactionParams = {
          to: buyPolygonBuyContract,
          from: account[0], // must match user's active address.
          // nonce: `${nonce}`, // ignored by MetaMask
          // gasPrice: gasPrice?.data?.fast + "00000000",

          // gas: await contractInstance.methods.createMarketsale(
          //   polygonMintContractAddress,
          //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
          // ).estimateGas({ from: account[0] })
          // ,
          value: await web3.utils.toWei((finalResult?.price).toString(), "ether")
          // await web3.utils.toWei((finalResult?.price).toString(), "ether")
          //  ethers.utils.parseEther(`${finalResult?.price}`)._hex,
          // data: await contractInstance.methods.createMarketsale(
          //   mintContractAddress,
          //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
          // ).encodeABI()
        };

        // const txHash = await window.ethereum.request({
        //   method: "eth_sendTransaction",
        //   params: [transactionParams],
        // });



        const txHash = await contractInstance.methods.createMarketsale(
          polygonMintContractAddress,
          finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
        ).send(transactionParams);


        if (txHash) {
          const transactionReceipt = {
            to: finalResult?.mintTransaction?.from,
            from: account[0],
            hash: txHash.transactionHash,
          };

          await Axios.post('/product/collectionBuyProduct', {
            owner: userData?._id,
            product: finalResult._id
          },
            { "Content-type": "application/json" })
          const singleMint = await Axios.put(
            "/product",
            {
              _id: finalResult._id,
              buyTransaction: txHash,
              owners: userData?._id,
              isSold: true,
              userId: userData?._id,
              collection_id: finalResult?.collection_id
            },
            { "Content-type": "application/json" }
          );


          const sendMailtoBuyer = await Axios.post('/product/sendMailFirst?title=buy', {
            "fromID": userData?._id,
            "assetID": finalResult?._id
          })
          const sendMailtoReceiver = await Axios.post('/product/sendMailSecond?title=sold', {
            "fromID": finalResult?.userId,
            "assetID": finalResult?._id,
            "toID": userData?._id
          })

          setMintingLoader(false);
          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)
          setOpenCheckout(false)
          setOpenCheckoutbid(false)
          setMintingApproved(true);
          setTransactionHash(txHash?.transactionHash);
          setTransactionFrom(txHash?.from);
          setTransactionGasUsed(txHash?.gasUsed);
          setTransactionHash(transactionReceipt?.hash);
          toast.success(`New NFT- ${finalResult?.name} Buy Successfully`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate('/Explore')
          }, 9000);
          window.document.body.style.overflow = "hidden";
        }
      } else if (finalResult?.blockChain === "BNB") {
        setMintingLoader(true);
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(97) }],
        });
        const account = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: web3.utils.toHex(97),
        });

        const contractInstance = new web3.eth.Contract(
          binanceBuyContractAPI,
          binanceBuyContractAddress
        );

        const mintContractInstace = new web3.eth.Contract(
          mintContractABI, mintContractAddress
        )

        const trans = {
          from: account[0],
          to: binanceBuyContractAddress,
          gas: await web3.eth.getGasPrice()

        }
        // const nonce = await web3.eth.getTransactionCount(account[0], "latest");
        const transactionParams = {
          to: binanceBuyContractAddress,
          from: account[0], // must match user's active address.
          // nonce: `${nonce}`, // ignored by MetaMask
          // gasPrice: gasPrice?.data?.fast + "00000000",

          // gas: await contractInstance.methods.createMarketsale(
          //   polygonMintContractAddress,
          //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
          // ).estimateGas({ from: account[0] })
          // ,
          value: await web3.utils.toWei((finalResult?.price).toString(), "ether")
          // await web3.utils.toWei((finalResult?.price).toString(), "ether")
          //  ethers.utils.parseEther(`${finalResult?.price}`)._hex,
          // data: await contractInstance.methods.createMarketsale(
          //   mintContractAddress,
          //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
          // ).encodeABI()
        };

        // const txHash = await window.ethereum.request({
        //   method: "eth_sendTransaction",
        //   params: [transactionParams],
        // });



        const txHash = await contractInstance.methods.createMarketsale(
          binanceContractAddress,
          finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
        ).send(transactionParams);


        if (txHash) {
          const transactionReceipt = {
            to: finalResult?.mintTransaction?.from,
            from: account[0],
            hash: txHash.transactionHash,
          };


          await Axios.post('/product/collectionBuyProduct', {
            owner: userData?._id,
            product: finalResult._id
          },
            { "Content-type": "application/json" })
          const singleMint = await Axios.put(
            "/product",
            {
              _id: finalResult._id,
              buyTransaction: txHash,
              owners: userData?._id,
              isSold: true,
              userId: userData?._id,
              collection_id: finalResult?.collection_id
            },
            { "Content-type": "application/json" }
          );


          const sendMailtoBuyer = await Axios.post('/product/sendMailFirst?title=buy', {
            "fromID": userData?._id,
            "assetID": finalResult?._id
          })
          const sendMailtoReceiver = await Axios.post('/product/sendMailSecond?title=sold', {
            "fromID": finalResult?.userId,
            "assetID": finalResult?._id,
            "toID": userData?._id
          })

          setMintingLoader(false);
          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)
          setOpenCheckout(false)
          setOpenCheckoutbid(false)
          setMintingApproved(true);
          setTransactionHash(txHash?.transactionHash);
          setTransactionFrom(txHash?.from);
          setTransactionGasUsed(txHash?.gasUsed);
          setTransactionHash(transactionReceipt?.hash);
          toast.success(`New NFT- ${finalResult?.name} Buy Successfully`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate('/Explore')
          }, 9000);
          window.document.body.style.overflow = "hidden";
        }
      }
      else {
        setMintingLoader(true);
        const account = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: "0x38",
        });

        const contractInstance = new web3.eth.Contract(
          buyContractABI,
          buyContractAddress
        );

        const mintContractInstace = new web3.eth.Contract(
          mintContractABI, mintContractAddress
        )

        const trans = {
          from: account[0],
          to: buyContractAddress,
          gas: await web3.eth.getGasPrice()

        }



        // const nonce = await web3.eth.getTransactionCount(account[0], "latest");
        const transactionParams = {
          to: buyContractAddress,
          from: account[0], // must match user's active address.
          // nonce: `${nonce}`, // ignored by MetaMask
          // gasPrice: gasPrice?.data?.fast + "00000000",

          gas: await contractInstance.methods.createMarketsale(
            mintContractAddress,
            finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
          ).estimateGas({ from: account[0] })
          ,
          value: ethers.utils.parseEther(`${finalResult?.price}`)._hex,
          // data: await contractInstance.methods.createMarketsale(
          //   mintContractAddress,
          //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
          // ).encodeABI()
        };

        // const txHash = await window.ethereum.request({
        //   method: "eth_sendTransaction",
        //   params: [transactionParams],
        // });



        const txHash = await contractInstance.methods.createMarketsale(
          mintContractAddress,
          finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
        ).send(transactionParams);


        if (txHash) {
          const transactionReceipt = {
            to: finalResult?.mintTransaction?.from,
            from: account[0],
            hash: txHash.transactionHash,
          };

          await Axios.post('/product/collectionBuyProduct', {
            owner: userData?._id,
            product: finalResult._id
          },
            { "Content-type": "application/json" })
          const singleMint = await Axios.put(
            "/product",
            {
              _id: finalResult._id,
              buyTransaction: txHash,
              owners: userData?._id,
              isSold: true,
              userId: userData?._id,
              collection_id: finalResult?.collection_id
            },
            { "Content-type": "application/json" }
          );


          const sendMailtoBuyer = await Axios.post('/product/sendMailFirst?title=buy', {
            "fromID": userData?._id,
            "assetID": finalResult?._id
          })
          const sendMailtoReceiver = await Axios.post('/product/sendMailSecond?title=sold', {
            "fromID": finalResult?.userId,
            "assetID": finalResult?._id,
            "toID": userData?._id
          })

          setMintingLoader(false);
          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)
          setOpenCheckout(false)
          setOpenCheckoutbid(false)
          setMintingApproved(true);
          setTransactionHash(txHash?.transactionHash);
          setTransactionFrom(txHash?.from);
          setTransactionGasUsed(txHash?.gasUsed);
          setTransactionHash(transactionReceipt?.hash);
          toast.success('Buy Successfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          setTimeout(() => {
            navigate('/Explore')
          }, 9000);
          window.document.body.style.overflow = "hidden";
        }
      }
    }

    } catch (error) {
      console.log(error, "error")
      setMintingLoader(false);
      setOpenMenu0(false)
      setOpenMenu1(false)
      setOpenMenu(false)
      setOpenCheckout(false)
      setOpenCheckoutbid(false)
      if (
        error?.code === 4001 &&
        error.message ===
        "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      if (error.code === -32603) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Transaction failed.....");
      }
    }
  };

  const handleSellBinance = async () => {
    try {
      const gasPrice = await axios.get('https://ethgasstation.info/api/ethgasAPI.json');
      // console.log( ethers.utils.parseEther(finalResult?.price)._hex);
      // try {
      setMintingLoader(true);
      const account = await window.ethereum.request({
        method: "eth_requestAccounts",
        chainId: "0x38",
      });

      const contractInstance = new web3.eth.Contract(
        binanceBuyContractAPI,
        binanceBuyContractAddress
      );

      const mintContractInstace = new web3.eth.Contract(
        binanceContractAPI, binanceContractAddress
      )

      const trans = {
        from: account[0],
        to: binanceBuyContractAddress,
        gas: await web3.eth.getGasPrice()

      }



      // const nonce = await web3.eth.getTransactionCount(account[0], "latest");
      const transactionParams = {
        to: binanceBuyContractAddress,
        from: account[0], // must match user's active address.
        // nonce: `${nonce}`, // ignored by MetaMask
        gasPrice: gasPrice?.data?.fast + "00000000",

        gas: await contractInstance.methods.createMarketsale(
          binanceContractAddress,
          finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
        ).estimateGas({ from: account[0] })
        ,
        value: ethers.utils.parseEther(`${finalResult?.price}`)._hex,
        // data: await contractInstance.methods.createMarketsale(
        //   mintContractAddress,
        //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
        // ).encodeABI()
      };

      // const txHash = await window.ethereum.request({
      //   method: "eth_sendTransaction",
      //   params: [transactionParams],
      // });



      const txHash = await contractInstance.methods.createMarketsale(
        binanceContractAddress,
        finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
      ).send(transactionParams);


      if (txHash) {
        const transactionReceipt = {
          to: finalResult?.mintTransaction?.from,
          from: account[0],
          hash: txHash.transactionHash,
        };
        const singleMint = await Axios.put(
          "/product",
          {
            _id: finalResult._id,
            buyTransaction: txHash,
            owners: userData?._id,
            isSold: true,
            userId: userData?._id
          },
          { "Content-type": "application/json" }
        );


        const sendMailtoBuyer = await Axios.post('/product/sendMailFirst?title=buy', {
          "fromID": userData?._id,
          "assetID": finalResult?._id
        })
        const sendMailtoReceiver = await Axios.post('/product/sendMailSecond?title=sold', {
          "fromID": finalResult?.userId,
          "assetID": finalResult?._id,
          "toID": userData?._id
        })
        setMintingLoader(false);
        setOpenMenu0(false)
        setOpenMenu1(false)
        setOpenMenu(false)
        setOpenCheckout(false)
        setOpenCheckoutbid(false)
        setMintingApproved(true);
        setTransactionHash(txHash?.transactionHash);
        setTransactionFrom(txHash?.from);
        setTransactionGasUsed(txHash?.gasUsed);
        setTransactionHash(transactionReceipt?.hash);
        window.document.body.style.overflow = "hidden";
      }
    } catch (error) {
      setMintingLoader(false);
      setOpenMenu0(false)
      setOpenMenu1(false)
      setOpenMenu(false)
      setOpenCheckout(false)
      setOpenCheckoutbid(false)
      if (
        error?.code === 4001 &&
        error.message ===
        "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      if (error.code === -32603) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Transaction failed.....");
      }
    }
  };

  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  const handleAuctionSell = async () => {
    try {
      if (mintedUserData[0].openBid === true) {

        const date = getNumberOfDays(
          `${new Date(finalResult?.auctionFromDate).getMonth()}/${new Date(
            finalResult?.auctionFromDate
          ).getDate()}/${new Date(finalResult?.auctionFromDate).getFullYear()}`,
          `${new Date(finalResult?.auctionToDate).getMonth()}/${new Date(
            finalResult?.auctionToDate
          ).getDate()}/${new Date(finalResult?.auctionToDate).getFullYear()}`
        );

        const auctionData = await Axios.get("/auction");
        const dts = await auctionData?.data?.data?.map((item, i) => {

          if (finalResult?._id === item?.productId?._id) {
            if (item?.transaction?.length > 0) {

              if (
                Number(auctionPriceValue) <
                Number(item?.transaction[item?.transaction?.length - 1]
                  ?.auctionPriceValue)
              ) {
                setAuctionPriceWarning(true);
                return false;
              }
            }
          }
        });
        if (!dts.includes(false)) {
          setOpen(false)

          var auctionInstance;
          if (finalResult?.blockChain == "BNB") {
            auctionInstance = await new web3.eth.Contract(
              newAuctionContractABI,
              newAuctionContractAddress
            );
          } else {
            auctionInstance = await new web3.eth.Contract(
              newAuctionContractABI,
              auctionPolygonContractAddress
            );
          }

          setMintingLoader(true);
          if (finalResult?.blockChain == "BNB") {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: web3.utils.toHex(97) }],

            });
          } else {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: web3.utils.toHex(80001) }],

            });
          }
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            // chainId: web3.utils.toHex(80001),
          });

          const nonce = await web3.eth.getTransactionCount(accounts[0], "latest");

          if (finalResult?.blockChain == "BNB") {
            const transactionParameter = {
              to: newAuctionContractAddress,
              from: accounts[0],
              nonce: `${nonce}`,
              gasPrice: `${await web3.eth.getGasPrice()}`,
              gasLimit: "100000",
              // customizable by user during MetaMask confirmation.
              gas: `${await web3.eth.estimateGas({
                to: newAuctionContractAddress,
                from: accounts[0],
                value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
                data: await auctionInstance.methods.placeBid(auctionId).encodeABI(),
              })}`,
              value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
              data: await auctionInstance.methods.placeBid(auctionId).encodeABI(),
            };
            const txHash = await auctionInstance.methods
              .placeBid(auctionId)
              .send(transactionParameter);
            if (txHash) {
              const transactionParameters = {
                date: new Date(),
                transactionReceipt:
                  txHash?.events?.ReserveAuctionBidPlaced?.returnValues,
                transactionParameter,
                auctionPriceValue,
                userData,
              };

              const sendAuctionTransaction = await Axios.put(
                "/auction/transaction",
                {
                  productId: finalResult._id,
                  transaction: [transactionParameters],
                },
                {
                  "Content-type": "Application/Json",
                }
              );


              const sendMailData = await Axios.post('/product/sendMailFirst?title=placeBid', {
                "fromID": userData?._id,
                "assetID": finalResult?._id
              })

              const senMailToData = await Axios.post('/product/sendMailSecond?title=newBidPlaced', {
                "fromID": finalResult?.userData?._id,
                "assetID": finalResult?._id
              })




              setOpenMenu0(false)
              setOpenMenu1(false)
              setOpenMenu(false)
              setOpenCheckout(false)
              setOpenCheckoutbid(false)
              setMintingLoader(false);
              // setMintingApproved(true);
              setTransactionHash(txHash?.transactionHash);
              setTransactionFrom(txHash?.from);
              setTransactionBidder(
                txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.bidder
              );
              setTransactionAuctionID(
                txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.auctionId
              );
              setTransactionGasUsed(txHash?.gasUsed);
              window.document.body.style.overflow = "hidden";
              setMintingLoader(false);
              toast.success(`New NFT- ${finalResult?.name} Auction Submitted Successfully`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              window.location.reload(true)
              // setTimeout(() => {

              // }, 4000);
            }
          } else {
            const transactionParameter = {
              to: auctionPolygonContractAddress,
              from: accounts[0],
              nonce: `${nonce}`,
              gasPrice: `${await web3.eth.getGasPrice()}`,
              gasLimit: "100000",
              // customizable by user during MetaMask confirmation.
              gas: `${await web3.eth.estimateGas({
                to: auctionPolygonContractAddress,
                from: accounts[0],
                value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
                data: await auctionInstance.methods.placeBid(auctionId).encodeABI(),
              })}`,
              value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
              data: await auctionInstance.methods.placeBid(auctionId).encodeABI(),
            };
            const txHash = await auctionInstance.methods
              .placeBid(auctionId)
              .send(transactionParameter);
            if (txHash) {
              const transactionParameters = {
                date: new Date(),
                transactionReceipt:
                  txHash?.events?.ReserveAuctionBidPlaced?.returnValues,
                transactionParameter,
                auctionPriceValue,
                userData,
              };

              const sendAuctionTransaction = await Axios.put(
                "/auction/transaction",
                {
                  productId: finalResult._id,
                  transaction: [transactionParameters],
                },
                {
                  "Content-type": "Application/Json",
                }
              );


              const sendMailData = await Axios.post('/product/sendMailFirst?title=placeBid', {
                "fromID": userData?._id,
                "assetID": finalResult?._id
              })

              const senMailToData = await Axios.post('/product/sendMailSecond?title=newBidPlaced', {
                "fromID": finalResult?.userData?._id,
                "assetID": finalResult?._id
              })




              setOpenMenu0(false)
              setOpenMenu1(false)
              setOpenMenu(false)
              setOpenCheckout(false)
              setOpenCheckoutbid(false)
              setMintingLoader(false);
              // setMintingApproved(true);
              setTransactionHash(txHash?.transactionHash);
              setTransactionFrom(txHash?.from);
              setTransactionBidder(
                txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.bidder
              );
              setTransactionAuctionID(
                txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.auctionId
              );
              setTransactionGasUsed(txHash?.gasUsed);
              window.document.body.style.overflow = "hidden";
              setMintingLoader(false);
              toast.success(`New NFT- ${finalResult?.name} Auction Submitted Successfully`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              window.location.reload(true)
              // setTimeout(() => {

              // }, 4000);
            }
          }

        }
      } else if (mintedUserData[0].timeBid === true) {
        const date = getNumberOfDays(
          `${new Date(finalResult?.auctionFromDate).getMonth()}/${new Date(
            finalResult?.auctionFromDate
          ).getDate()}/${new Date(finalResult?.auctionFromDate).getFullYear()}`,
          `${new Date(finalResult?.auctionToDate).getMonth()}/${new Date(
            finalResult?.auctionToDate
          ).getDate()}/${new Date(finalResult?.auctionToDate).getFullYear()}`
        );

        const auctionData = await Axios.get("/auction");

        auctionData?.data?.data?.map((item, i) => {
          if (finalResult?._id === item?.productId?._id) {
            if (item?.transaction?.length > 0) {
              if (
                auctionPriceValue <
                item?.transaction[item?.transaction?.length - 1]
                  ?.auctionPriceValue
              ) {
                setAuctionPriceWarning(true);
                return false;
              }
            }
          }
        });

        var auctionInstance;
        if (finalResult?.blockChain == "BNB") {
          auctionInstance = await new web3.eth.Contract(
            timeBidABI,
            newTimeAuctionContractAddress
          );
        } else {
          auctionInstance = await new web3.eth.Contract(
            timeBidABI,
            timeBidContractAddress
          );
        }

        // const auctionInstance = await new web3.eth.Contract(
        //   timeBidABI,
        //   timeBidContractAddress
        // );

        setMintingLoader(true);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        if (finalResult?.blockChain == "BNB") {
          const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0

          const transactionParameter = {
            to: newTimeAuctionContractAddress,
            from: accounts[0],
            nonce: `${nonce}`,

            gasPrice: `${await web3.eth.getGasPrice()}`,
            gasLimit: "100000",
            // customizable by user during MetaMask confirmation.
            gas: `${await web3.eth.estimateGas({
              to: newTimeAuctionContractAddress,
              from: accounts[0],
              value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
              data: await auctionInstance.methods
                .placeBid(parseInt(auctionId))
                .encodeABI(),
            })}`,
            value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
            data: await auctionInstance.methods
              .placeBid(parseInt(auctionId))
              .encodeABI(),
          };

          const txHash = await auctionInstance.methods
            .placeBid(parseInt(auctionId))
            .send(transactionParameter);

          if (txHash) {
            const transactionParameters = {
              date: new Date(),
              transactionReceipt:
                txHash?.events?.ReserveAuctionBidPlaced?.returnValues,
              transactionParameter,
              auctionPriceValue,
              userData,
            };

            const sendAuctionTransaction = await Axios.put(
              "/auction/transaction",
              {
                productId: finalResult._id,
                transaction: [transactionParameters],
              },
              {
                "Content-type": "Application/Json",
              }
            );



            const sendMailData = await Axios.post('/product/sendMailFirst?title=placeBid', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })

            const sendTopBider = await Axios.post('/product/sendMailFirst?title=topBiderTimeAuction', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })

            const senMailToData = await Axios.post('/product/sendMailSecond?title=newBidPlaced', {
              "fromID": finalResult?.userData?._id,
              "assetID": finalResult?._id
            })





            setMintingLoader(false);
            setOpenMenu0(false)
            setOpenMenu1(false)
            setOpenMenu(false)
            setMintingApproved(true);
            setTransactionHash(txHash?.transactionHash);
            setTransactionFrom(txHash?.from);
            setTransactionBidder(
              txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.bidder
            );
            setTransactionAuctionID(
              txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.auctionId
            );
            setTransactionGasUsed(txHash?.gasUsed);
            window.document.body.style.overflow = "hidden";

          }
        } else {
          const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0

          const transactionParameter = {
            to: timeBidContractAddress,
            from: accounts[0],
            nonce: `${nonce}`,

            gasPrice: `${await web3.eth.getGasPrice()}`,
            gasLimit: "100000",
            // customizable by user during MetaMask confirmation.
            gas: `${await web3.eth.estimateGas({
              to: timeBidContractAddress,
              from: accounts[0],
              value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
              data: await auctionInstance.methods
                .placeBid(parseInt(auctionId))
                .encodeABI(),
            })}`,
            value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
            data: await auctionInstance.methods
              .placeBid(parseInt(auctionId))
              .encodeABI(),
          };

          const txHash = await auctionInstance.methods
            .placeBid(parseInt(auctionId))
            .send(transactionParameter);

          if (txHash) {
            const transactionParameters = {
              date: new Date(),
              transactionReceipt:
                txHash?.events?.ReserveAuctionBidPlaced?.returnValues,
              transactionParameter,
              auctionPriceValue,
              userData,
            };

            const sendAuctionTransaction = await Axios.put(
              "/auction/transaction",
              {
                productId: finalResult._id,
                transaction: [transactionParameters],
              },
              {
                "Content-type": "Application/Json",
              }
            );



            const sendMailData = await Axios.post('/product/sendMailFirst?title=placeBid', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })

            const sendTopBider = await Axios.post('/product/sendMailFirst?title=topBiderTimeAuction', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })

            const senMailToData = await Axios.post('/product/sendMailSecond?title=newBidPlaced', {
              "fromID": finalResult?.userData?._id,
              "assetID": finalResult?._id
            })





            setMintingLoader(false);
            setOpenMenu0(false)
            setOpenMenu1(false)
            setOpenMenu(false)
            setMintingApproved(true);
            setTransactionHash(txHash?.transactionHash);
            setTransactionFrom(txHash?.from);
            setTransactionBidder(
              txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.bidder
            );
            setTransactionAuctionID(
              txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.auctionId
            );
            setTransactionGasUsed(txHash?.gasUsed);
            window.document.body.style.overflow = "hidden";

          }
        }

      }
    } catch (error) {
      console.log(error);
      setMintingLoader(false);
      setOpenMenu0(false)
      setOpenMenu1(false)
      setOpenMenu(false)
      setOpenCheckout(false)
      setOpenCheckoutbid(false)
      setErrorHandling(true);
      if (
        error?.message ===
        "Returned error: execution reverted: NFTMarketReserveAuction: You already have an outstanding bid"
      ) {
        // setTimeout(() => {
        //   setErrorHandling(false);
        //   window.location.reload();
        // }, 3000);
        setErrorHandling(true);
        setRejectWarning(
          "Returned error: execution reverted: NFTMarketReserveAuction: You already have an outstanding bid"
        );
      }
      if (
        error?.code === 4001 &&
        error.message ===
        "MetaMask Tx Signature: User denied transaction signature."
      ) {
        // setTimeout(() => {
        //   setErrorHandling(false);
        //   window.location.reload();
        // }, 5000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      // setTimeout(() => {
      //   setErrorHandling(false);
      //   window.location.reload();
      // }, 10000);
      setErrorHandling(true);
      setRejectWarning(error?.message);
      // window.location.reload();
    }
  };

  const handleTimeBidAuction = async () => {
    try {
      if (mintedUserData[0].timeBid === true) {

        // setMintingLoader(true);
        const auctionInstance = await new web3.eth.Contract(
          timeBidABI,
          timeBidContractAddress
        );
        const mintContractInstance = await new web3.eth.Contract(
          mintContractABI,
          mintContractAddress
        );
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: "0x1",
        });
        setBiddingApproved(true);
        const approveTokenTransactionParameter = {
          to: newAuctionContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
          gasLimit: '100000'
        };
        const approveToken = await mintContractInstance.methods
          .approve(
            timeBidContractAddress,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId
          )
          .send(approveTokenTransactionParameter);

        setStartAuction(true);
        const startTokenTransactionParameter = {
          to: timeBidContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
          gasLimit: '100000',

        };

        const auctionStarting = await auctionInstance.methods
          .createReserveAuction(
            finalResult?.mintTransaction?.to,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId,
            ethers.utils.parseEther(`${finalResult?.price}`)._hex,
            mintedUserData[0].timeDuration
          )
          .send(startTokenTransactionParameter);
        setApproveAuction(true);

        // if(auctionStarting){
        //  const data= await auctionInstance.methods.start().call()
        //  console.log(data);
        // }

        //updating the auction start and end date for auction

        if (auctionStarting) {
          const { data } = await Axios.put("/auction", {
            startDate: new Date(),
            mintId:
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
            auctionId:
              auctionStarting?.events?.ReserveAuctionCreated?.returnValues
                ?.auctionId,
            isActive: true,
          });

          const setTime = await Axios.put("/product", {
            _id: finalResult?._id,
            startDate: new Date(),
            mintId:
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
          });

          const sendMailToSender = await Axios.post('/product/sendMailFirst?title=startAuction', {
            "fromID": userData?._id,
            "assetID": finalResult?._id,
            "auctionID": data?.data?._id
          })

          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)

          setOpenCheckout(false)
          setOpenCheckoutbid(false)
          setMintingLoader(false);
          setBiddingApproved(false);
          window.location.reload();
        }
      } else if (mintedUserData[0].openBid === true) {
        // setMintingLoader(true);
        const auctionInstance = await new web3.eth.Contract(
          newAuctionContractABI,
          newAuctionContractAddress
        );

        const mintContractInstance = await new web3.eth.Contract(
          mintContractABI,
          mintContractAddress
        );

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: "0x1",
        });
        setBiddingApproved(true);

        const approveTokenTransactionParameter = {
          to: newAuctionContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
        };

        const approveToken = await mintContractInstance.methods
          .approve(
            newAuctionContractAddress,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId
          )
          .send(approveTokenTransactionParameter);

        setStartAuction(true);

        const startTokenTransactionParameter = {
          to: newAuctionContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
        };

        const auctionStarting = await auctionInstance.methods
          .createReserveAuction(
            finalResult?.mintTransaction?.to,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId,
            ethers.utils.parseEther(`${finalResult?.price}`)._hex
          )
          .send(startTokenTransactionParameter);

        setApproveAuction(true);

        // if(auctionStarting){
        //  const data= await auctionInstance.methods.start().call()
        //  console.log(data);
        // }

        //updating the auction start and end date for auction

        if (auctionStarting) {
          const { data } = await Axios.put("/auction", {
            startDate: new Date(),
            mintId:
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
            auctionId:
              auctionStarting?.events?.ReserveAuctionCreated?.returnValues
                ?.auctionId,
            isActive: true,
          });

          const setTime = await Axios.put("/product", {
            _id: finalResult?._id,
            startDate: new Date(),
            mintId:
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
          });

          const sendMailToSender = await Axios.post('/product/sendMailFirst?title=startAuction', {
            "fromID": userData?._id,
            "assetID": finalResult?._id,
            "auctionID": data?.data?._id
          })

          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)
          setMintingLoader(false);
          setBiddingApproved(false);
          window.location.reload();
        }
      }
    } catch (error) {
      setMintingLoader(false);
      setOpenMenu0(false)
      setOpenMenu1(false)
      setOpenMenu(false)
      setOpenCheckout(false)
      setOpenCheckoutbid(false)
      if (
        error?.code === 4001 &&
        error.message ===
        "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      if (error.code === -32603) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Transaction failed.....");
      }
      setBiddingApproved(false);
    }
  };

  const handleHeart = async () => {
    if (heartFill) {
      setHeartFill(false);
      const addLikes = await Axios.put(
        "/product/unlike",
        { _id: finalResult._id, favourite: userData?._id },
        {
          "Content-type": "Application/json",
        }
      );

      setLikeCount(addLikes?.data?.data?.favourite?.length);
    } else {
      setHeartFill(true);
      const addLikes = await Axios.put(
        "/product",
        { _id: finalResult._id, favourite: userData?._id },
        {
          "Content-type": "Application/json",
        }
      );

      setLikeCount(addLikes?.data?.data?.favourite?.length);
    }
  };

  const handleStartAuction = async () => {
    try {
      if(window.localStorage.getItem("token") === null){
        toast.error("Please Login To Continue", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else if(window.localStorage.getItem("useraddress") === null){
        toast.error("Please Connect Your Wallet to Continue", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else if((window.localStorage.getItem("token") !==null) && (window.localStorage.getItem("useraddress") !== null) ){
      if (finalResult?.blockChain === 'MATIC') {
        setMintingLoader(true);
        const auctionInstance = await new web3.eth.Contract(
          newAuctionContractABI,
          auctionPolygonContractAddress
        );

        const mintContractInstance = await new web3.eth.Contract(
          mintContractABI,
          polygonMintContractAddress
        );

        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: web3.utils.toHex(80001) }],
        });

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: web3.utils.toHex(80001),
        });
        setBiddingApproved(true);

        const approveTokenTransactionParameter = {
          to: auctionPolygonContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
        };
        const approveToken = await mintContractInstance.methods
          .approve(
            auctionPolygonContractAddress,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
          )
          .send(approveTokenTransactionParameter);

        setStartAuction(true);

        const startTokenTransactionParameter = {
          to: auctionPolygonContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
        }
        const auctionStarting = await auctionInstance.methods
          .createReserveAuction(
            finalResult?.mintTransaction?.to,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
            // ethers.utils.parseEther(`${finalResult?.price}`)._hex
            await web3.utils.toWei((finalResult?.price).toString(), "ether")
          ).send(startTokenTransactionParameter);

        setApproveAuction(true);

        // if(auctionStarting){
        //  const data= await auctionInstance.methods.start().call()
        //  console.log(data);
        // }

        //updating the auction start and end date for auction

        if (auctionStarting) {
          const { data } = await Axios.put("/auction", {
            "startDate": new Date(),
            "mintId": finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
            "auctionId": auctionStarting?.events?.ReserveAuctionCreated?.returnValues?.auctionId,
            "isActive": true,
          });

          const setTime = await Axios.put('/product', {
            "_id": finalResult?._id,
            "startDate": new Date(),
            "mintId": finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
          })

          setMintingLoader(false);
          setBiddingApproved(false);
          toast.success(`Your Assert- ${finalResult?.name} Auction Start Successfully`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(() => {
            window.location.reload();
          }, 4000);

        }
      }
      else if (finalResult?.blockChain === 'BNB') {
        // setMintingLoader(true);
        if (finalResult?.openBid == true) {
          const auctionInstance = await new web3.eth.Contract(
            newAuctionContractABI,
            newAuctionContractAddress
          );

          const mintContractInstance = await new web3.eth.Contract(
            mintContractABI,
            binanceContractAddress
          );

          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: web3.utils.toHex(97) }],
          });

          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            chainId: web3.utils.toHex(97),
          });
          setBiddingApproved(true);

          const approveTokenTransactionParameter = {
            to: newAuctionContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
          };
          const approveToken = await mintContractInstance.methods
            .approve(
              newAuctionContractAddress,
              finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
            )
            .send(approveTokenTransactionParameter);

          setStartAuction(true);

          const startTokenTransactionParameter = {
            to: newAuctionContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
          }
          const auctionStarting = await auctionInstance.methods
            .createReserveAuction(
              finalResult?.mintTransaction?.to,
              finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
              // ethers.utils.parseEther(`${finalResult?.price}`)._hex
              await web3.utils.toWei((finalResult?.price).toString(), "ether")
            ).send(startTokenTransactionParameter);

          setApproveAuction(true);

          // if(auctionStarting){
          //  const data= await auctionInstance.methods.start().call()
          //  console.log(data);
          // }

          //updating the auction start and end date for auction

          if (auctionStarting) {
            const { data } = await Axios.put("/auction", {
              "startDate": new Date(),
              "mintId": finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
              "auctionId": auctionStarting?.events?.ReserveAuctionCreated?.returnValues?.auctionId,
              "isActive": true,
            });

            const setTime = await Axios.put('/product', {
              "_id": finalResult?._id,
              "startDate": new Date(),
              "mintId": finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
            })

            setMintingLoader(false);
            setBiddingApproved(false);
            toast.success(`Your Assert- ${finalResult?.name} Auction Start Successfully`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);

          }
        } else if (finalResult?.openBid == false) {
          const auctionInstance = await new web3.eth.Contract(
            newTimeAuctionContractABI,
            newTimeAuctionContractAddress
          );
          const mintContractInstance = await new web3.eth.Contract(
            mintContractABI,
            binanceContractAddress
          );

          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: web3.utils.toHex(97) }],
          });

          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            chainId: web3.utils.toHex(97),
          });
          setBiddingApproved(true);

          const approveTokenTransactionParameter = {
            to: newTimeAuctionContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
          };
          const approveToken = await mintContractInstance.methods
            .approve(
              newTimeAuctionContractAddress,
              finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
            )
            .send(approveTokenTransactionParameter);

          setStartAuction(true);

          const startTokenTransactionParameter = {
            to: newTimeAuctionContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
          }
          const auctionStarting = await auctionInstance.methods
            .createReserveAuction(
              finalResult?.mintTransaction?.to,
              finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
              // ethers.utils.parseEther(`${finalResult?.price}`)._hex
              await web3.utils.toWei((finalResult?.price).toString(), "ether"),
              time
            ).send(startTokenTransactionParameter);

          setApproveAuction(true);

          // if(auctionStarting){
          //  const data= await auctionInstance.methods.start().call()
          //  console.log(data);
          // }

          //updating the auction start and end date for auction

          if (auctionStarting) {
            const { data } = await Axios.put("/auction", {
              "startDate": new Date(),
              "mintId": finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
              "auctionId": auctionStarting?.events?.ReserveAuctionCreated?.returnValues?.auctionId,
              "isActive": true,
            });

            const setTime = await Axios.put('/product', {
              "_id": finalResult?._id,
              "startDate": new Date(),
              "mintId": finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
            })

            setMintingLoader(false);
            setBiddingApproved(false);
            toast.success(`Your Assert- ${finalResult?.name} Auction Start Successfully`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);

          }
        }
      } else {
        setMintingLoader(true);
        const auctionInstance = await new web3.eth.Contract(
          newAuctionContractABI,
          timeAuctionPolygonContractAddress
        );

        const mintContractInstance = await new web3.eth.Contract(
          mintContractABI,
          polygonMintContractAddress
        );

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: '0x38'
        });
        setBiddingApproved(true);

        const approveTokenTransactionParameter = {
          to: timeAuctionPolygonContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
        };

        const approveToken = await mintContractInstance.methods
          .approve(
            newAuctionContractAddress,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
          )
          .send(approveTokenTransactionParameter);

        setStartAuction(true);

        const startTokenTransactionParameter = {
          to: newAuctionContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
        }

        const auctionStarting = await auctionInstance.methods
          .createReserveAuction(
            finalResult?.mintTransaction?.to,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
            ethers.utils.parseEther(`${finalResult?.price}`)._hex
          ).send(startTokenTransactionParameter);

        setApproveAuction(true);

        // if(auctionStarting){
        //  const data= await auctionInstance.methods.start().call()
        //  console.log(data);
        // }

        //updating the auction start and end date for auction

        if (auctionStarting) {
          const { data } = await Axios.put("/auction", {
            "startDate": new Date(),
            "mintId": finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
            "auctionId": auctionStarting?.events?.ReserveAuctionCreated?.returnValues?.auctionId,
            "isActive": true,
          });

          const setTime = await Axios.put('/product', {
            "_id": finalResult?._id,
            "startDate": new Date(),
            "mintId": finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
          })

          setMintingLoader(false);
          setBiddingApproved(false);
          window.location.reload();
        }
      }
    }
    } catch (error) {
      console.log("🚀 ~ handleStartAuction ~ error:", error)

      if (error?.code === 4001 && error.message === "MetaMask Tx Signature: User denied transaction signature.") {

        setTimeout(() => {
          setErrorHandling(false)
          window.location.reload()
        }, 3000)
        setErrorHandling(true)
        setRejectWarning('Metamask transaction user denied signature....')
      }
      if (error.code === -32603) {
        setTimeout(() => {
          setErrorHandling(false)
          window.location.reload()
        }, 3000)
        setErrorHandling(true)
        setRejectWarning('Transaction failed.....')
      }
      setBiddingApproved(false)
    }
  };

  const handleWithdraw = async () => {
    try {
      if(window.localStorage.getItem("token") === null){
        toast.error("Please Login To Continue", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else if(window.localStorage.getItem("useraddress") === null){
        toast.error("Please Connect Your Wallet to Continue", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else if((window.localStorage.getItem("token") !==null) && (window.localStorage.getItem("useraddress") !== null) ){

     if (finalResult?.blockChain === 'MATIC') {
        if (mintedUserData[0].openBid === true) {
          setMintingLoader(true);
          const auctionInstance = await new web3.eth.Contract(
            newAuctionContractABI,
            auctionPolygonContractAddress
          );
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: web3.utils.toHex(80001) }],
          });
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            chainId: web3.utils.toHex(80001),
          });

          const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0
          const transactionParameter = {
            to: auctionPolygonContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
            data: await auctionInstance.methods
              .finalizeReserveAuction(parseInt(auctionId))
              .encodeABI(),
          };
          // const txHash = await auctionInstance.methods.finalizeReserveAuction(18).send(transactionParameter);

          const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameter],
          });

          const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);

          if (txHash) {
            const { data } = await Axios.put("/auction/transaction", {
              productId: finalResult?._id,
              isVerified: true,
              claimId: lastBid?.userData?._id,
            });

            const setTime = await Axios.put("/product", {
              _id: finalResult?._id,
              isSold: true,
              auction: true,
              owners: historyValue?.userData?._id,
              userId: historyValue?.userData?._id
            });

            const sendMail = await Axios.post('/product/sendMailFirst?title=bidAccepted', {
              "fromID": historyValue?.userData?._id,
              "assetID": finalResult?._id
            })

            const sendMailToReceiver = await Axios.post('/product/sendMailSecond?title=sold', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })

            setOpenMenu0(false)
            setOpenMenu1(false)
            setOpenMenu(false)
            setMintingApproved(true);
            setTransactionHash(txHash);
            setTransactionFrom(transactionParameter?.from);
            setTransactionAuctionID(auctionId);
            setTransactionGasUsed(transactionParameter?.gasUsed);
            window.document.body.style.overflow = "hidden";
            setMintingLoader(false);
            toast.success(`Your Assert- ${finalResult?.name} Auction Start Successfully`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          }
        } else if (mintedUserData[0].timeBid === true) {

          setMintingLoader(true);
          const auctionInstance = await new web3.eth.Contract(
            timeBidABI,
            timeBidContractAddress
          );
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0
          const transactionParameter = {
            to: timeBidContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gasLimit: "800000",
            data: await auctionInstance.methods
              .finalizeReserveAuction(parseInt(auctionId))
              .encodeABI(),
          };
          const txHash = await auctionInstance.methods
            .finalizeReserveAuction(parseInt(auctionId))
            .send(transactionParameter);

          // const txHash = await window.ethereum.request({
          //   method: "eth_sendTransaction",
          //   params: [transactionParameter],
          // });

          const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);

          if (txHash) {
            const { data } = await Axios.put("/auction/transaction", {
              productId: finalResult?._id,
              isVerified: true,
              claimId: lastBid?.userData?._id,
            });





            const setTime = await Axios.put("/product", {
              _id: finalResult?._id,
              isSold: true,
              owners: historyValue?.userData?._id,
              userId: historyValue?.userData?._id
            });


            const sendMail = await Axios.post('/product/sendMailFirst?title=bidAccepted', {
              "fromID": historyValue?.userData?._id,
              "assetID": finalResult?._id
            })

            const sendMailToReceiver = await Axios.get('/product/sendMailSecond?title=sold', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })



            setOpenMenu0(false)
            setOpenMenu1(false)
            setOpenMenu(false)

            setMintingApproved(true);
            setTransactionHash(txHash);
            setTransactionFrom(transactionParameter?.from);
            setTransactionAuctionID(auctionId);
            setTransactionGasUsed(transactionParameter?.gasUsed);
            window.document.body.style.overflow = "hidden";
            setMintingLoader(false);
          }
        }
      } if (finalResult?.blockChain === 'BNB') {
        if (mintedUserData[0].openBid === true) {
          setMintingLoader(true);
          const auctionInstance = await new web3.eth.Contract(
            newAuctionContractABI,
            newAuctionContractAddress
          );
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: web3.utils.toHex(97) }],
          });
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            chainId: web3.utils.toHex(97),
          });

          const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0
          const transactionParameter = {
            to: newAuctionContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
            data: await auctionInstance.methods
              .finalizeReserveAuction(parseInt(auctionId))
              .encodeABI(),
          };
          // const txHash = await auctionInstance.methods.finalizeReserveAuction(18).send(transactionParameter);

          const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameter],
          });

          const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);

          if (txHash) {
            const { data } = await Axios.put("/auction/transaction", {
              productId: finalResult?._id,
              isVerified: true,
              claimId: lastBid?.userData?._id,
            });

            const setTime = await Axios.put("/product", {
              _id: finalResult?._id,
              isSold: true,
              auction: true,
              owners: historyValue?.userData?._id,
              userId: historyValue?.userData?._id
            });

            const sendMail = await Axios.post('/product/sendMailFirst?title=bidAccepted', {
              "fromID": historyValue?.userData?._id,
              "assetID": finalResult?._id
            })

            const sendMailToReceiver = await Axios.post('/product/sendMailSecond?title=sold', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })

            setOpenMenu0(false)
            setOpenMenu1(false)
            setOpenMenu(false)
            setMintingApproved(true);
            setTransactionHash(txHash);
            setTransactionFrom(transactionParameter?.from);
            setTransactionAuctionID(auctionId);
            setTransactionGasUsed(transactionParameter?.gasUsed);
            window.document.body.style.overflow = "hidden";
            setMintingLoader(false);
            toast.success(`Your Assert- ${finalResult?.name} Auction Start Successfully`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          }
        } else if (mintedUserData[0].timeBid === true) {

          setMintingLoader(true);
          const auctionInstance = await new web3.eth.Contract(
            timeBidABI,
            timeBidContractAddress
          );
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0
          const transactionParameter = {
            to: timeBidContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gasLimit: "800000",
            data: await auctionInstance.methods
              .finalizeReserveAuction(parseInt(auctionId))
              .encodeABI(),
          };
          const txHash = await auctionInstance.methods
            .finalizeReserveAuction(parseInt(auctionId))
            .send(transactionParameter);

          // const txHash = await window.ethereum.request({
          //   method: "eth_sendTransaction",
          //   params: [transactionParameter],
          // });

          const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);

          if (txHash) {
            const { data } = await Axios.put("/auction/transaction", {
              productId: finalResult?._id,
              isVerified: true,
              claimId: lastBid?.userData?._id,
            });





            const setTime = await Axios.put("/product", {
              _id: finalResult?._id,
              isSold: true,
              owners: historyValue?.userData?._id,
              userId: historyValue?.userData?._id
            });


            const sendMail = await Axios.post('/product/sendMailFirst?title=bidAccepted', {
              "fromID": historyValue?.userData?._id,
              "assetID": finalResult?._id
            })

            const sendMailToReceiver = await Axios.get('/product/sendMailSecond?title=sold', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })



            setOpenMenu0(false)
            setOpenMenu1(false)
            setOpenMenu(false)

            setMintingApproved(true);
            setTransactionHash(txHash);
            setTransactionFrom(transactionParameter?.from);
            setTransactionAuctionID(auctionId);
            setTransactionGasUsed(transactionParameter?.gasUsed);
            window.document.body.style.overflow = "hidden";
            setMintingLoader(false);
          }
        }
      }
      else {
        if (mintedUserData[0].openBid === true) {
          setMintingLoader(true);
          const auctionInstance = await new web3.eth.Contract(
            newAuctionContractABI,
            newAuctionContractAddress
          );
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0
          const transactionParameter = {
            to: newAuctionContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
            data: await auctionInstance.methods
              .finalizeReserveAuction(parseInt(auctionId))
              .encodeABI(),
          };
          // const txHash = await auctionInstance.methods.finalizeReserveAuction(18).send(transactionParameter);

          const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [transactionParameter],
          });

          const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);

          if (txHash) {
            const { data } = await Axios.put("/auction/transaction", {
              productId: finalResult?._id,
              isVerified: true,
              claimId: lastBid?.userData?._id,
            });

            const setTime = await Axios.put("/product", {
              _id: finalResult?._id,
              isSold: true,
              owners: historyValue?.userData?._id,
              userId: historyValue?.userData?._id
            });

            const sendMail = await Axios.post('/product/sendMailFirst?title=bidAccepted', {
              "fromID": historyValue?.userData?._id,
              "assetID": finalResult?._id
            })

            const sendMailToReceiver = await Axios.post('/product/sendMailSecond?title=sold', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })

            setOpenMenu0(false)
            setOpenMenu1(false)
            setOpenMenu(false)
            setMintingApproved(true);
            setTransactionHash(txHash);
            setTransactionFrom(transactionParameter?.from);
            setTransactionAuctionID(auctionId);
            setTransactionGasUsed(transactionParameter?.gasUsed);
            window.document.body.style.overflow = "hidden";
            setMintingLoader(false);
          }
        } else if (mintedUserData[0].timeBid === true) {

          setMintingLoader(true);
          const auctionInstance = await new web3.eth.Contract(
            timeBidABI,
            timeBidContractAddress
          );
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0
          const transactionParameter = {
            to: timeBidContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gasLimit: "800000",
            data: await auctionInstance.methods
              .finalizeReserveAuction(parseInt(auctionId))
              .encodeABI(),
          };
          const txHash = await auctionInstance.methods
            .finalizeReserveAuction(parseInt(auctionId))
            .send(transactionParameter);

          // const txHash = await window.ethereum.request({
          //   method: "eth_sendTransaction",
          //   params: [transactionParameter],
          // });

          const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);

          if (txHash) {
            const { data } = await Axios.put("/auction/transaction", {
              productId: finalResult?._id,
              isVerified: true,
              claimId: lastBid?.userData?._id,
            });





            const setTime = await Axios.put("/product", {
              _id: finalResult?._id,
              isSold: true,
              owners: historyValue?.userData?._id,
              userId: historyValue?.userData?._id
            });


            const sendMail = await Axios.post('/product/sendMailFirst?title=bidAccepted', {
              "fromID": historyValue?.userData?._id,
              "assetID": finalResult?._id
            })

            const sendMailToReceiver = await Axios.get('/product/sendMailSecond?title=sold', {
              "fromID": userData?._id,
              "assetID": finalResult?._id
            })



            setOpenMenu0(false)
            setOpenMenu1(false)
            setOpenMenu(false)

            setMintingApproved(true);
            setTransactionHash(txHash);
            setTransactionFrom(transactionParameter?.from);
            setTransactionAuctionID(auctionId);
            setTransactionGasUsed(transactionParameter?.gasUsed);
            window.document.body.style.overflow = "hidden";
            setMintingLoader(false);
          }
        }
      }
    }

    } catch (error) {
      setMintingLoader(false);
      setOpenMenu0(false)
      setOpenMenu1(false)
      setOpenMenu(false)

      if (
        error.message ===
        "Returned error: execution reverted: NFTMarketReserveAuction: Auction was already settled"
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning(
          "Returned error: execution reverted: NFTMarketReserveAuction: Auction was already settled"
        );
      }
      if (
        error?.message ===
        "Returned error: execution reverted: NFTMarketReserveAuction: You already have an outstanding bid"
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning(
          "Returned error: execution reverted: NFTMarketReserveAuction: You already have an outstanding bid"
        );
      }
      if (
        error?.code === 4001 &&
        error.message ===
        "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 5000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      setTimeout(() => {
        setErrorHandling(false);
        window.location.reload();
      }, 5000);
      setErrorHandling(true);
      setRejectWarning(error?.message);
      window.location.reload();
    }
  };


  const handleReListAuction = async () => {
    try {

      setReListingBox(true)


      const auctionInstance = await new web3.eth.Contract(
        timeBidABI,
        timeBidContractAddress
      );
      const mintContractInstance = await new web3.eth.Contract(
        mintContractABI,
        mintContractAddress
      );



      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
        chainId: "0x1",
      });

      const startTokenTransactionParameters = {
        to: timeBidContractAddress,
        from: accounts[0],
        gasPrice: `${await web3.eth.getGasPrice()}`,
        gasLimit: '100000',

      };


      const auctionStartings = await auctionInstance.methods
        .cancelReserveAuction(auctionId)
        .send(startTokenTransactionParameters);




      setCancelAuction(true)

      const approveTokenTransactionParameter = {
        to: newAuctionContractAddress,
        from: accounts[0],
        gasPrice: `${await web3.eth.getGasPrice()}`,
        gasLimit: '100000'
      };
      const approveToken = await mintContractInstance.methods
        .approve(
          timeBidContractAddress,
          finalResult?.mintTransaction?.events?.Transfer?.returnValues
            ?.tokenId
        )
        .send(approveTokenTransactionParameter);

      setStartAuction(true);
      const startTokenTransactionParameter = {
        to: timeBidContractAddress,
        from: accounts[0],
        gasPrice: `${await web3.eth.getGasPrice()}`,
        gasLimit: '100000',

      };

      const auctionStarting = await auctionInstance.methods
        .createReserveAuction(
          finalResult?.mintTransaction?.to,
          finalResult?.mintTransaction?.events?.Transfer?.returnValues
            ?.tokenId,
          ethers.utils.parseEther(`${finalResult?.price}`)._hex,
          mintedUserData[0].timeDuration
        )
        .send(startTokenTransactionParameter);

      setApproveAuction(true);

      // if(auctionStarting){
      //  const data= await auctionInstance.methods.start().call()
      //  console.log(data);
      // }

      //updating the auction start and end date for auction

      if (auctionStarting) {
        const { data } = await Axios.put("/auction", {
          startDate: new Date(),
          mintId:
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId,
          auctionId:
            auctionStarting?.events?.ReserveAuctionCreated?.returnValues
              ?.auctionId,
          isActive: true,
        });

        const setTime = await Axios.put("/product", {
          _id: finalResult?._id,
          startDate: new Date(),
          mintId:
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId,
        });

        const sendMailToSender = await Axios.post('/product/sendMailFirst?title=startAuction', {
          "fromID": userData?._id,
          "assetID": finalResult?._id,
          "auctionID": data?.data?._id
        })

        setOpenMenu0(false)
        setOpenMenu1(false)
        setOpenMenu(false)

        setOpenCheckout(false)
        setOpenCheckoutbid(false)
        setMintingLoader(false);
        setBiddingApproved(false);
        window.location.reload();
      }


    } catch (error) {
      console.log(error);
    }
  }

  const handleAuctionOpen = async () => {
    try {
      if(window.localStorage.getItem("token") === null){
        toast.error("Please Login To Continue", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else if(window.localStorage.getItem("useraddress") === null){
        toast.error("Please Connect Your Wallet to Continue", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else if((window.localStorage.getItem("token") !==null) && (window.localStorage.getItem("useraddress") !== null) ){
      setHandleOpen(true)
      setOpen(true);
      }
    } catch (error) {
      console.log(error,"Error");
    }
    
  }
  return (
    <>
      <ToastContainer style={{ top: "5em" }} />
      {/* {errorHandling &&

        <div className="checkout" >
          <div className="maincheckout modalColor">
            <button
              className="btn-close"
              onClick={()=>{setErrorHandling(false)}}
            >
              x
            </button>
            <h1 style={{ color: "red" }}>Error123</h1>
            <p>{rejectWarning}</p>

          </div>
        </div>
      } */}

      {biddingApproved && (

        <div className="checkout" >
          <div className="maincheckout modalColor">
            <button
              className="btn-close"
            >
              x
            </button>
            <div className="heading">
              <h3>Auction Listing In Progress...</h3>
            </div>

            <div
              style={{ display: "flex", gap: "20px", alignItems: "center" }}
            >
              {startAuction ? (
                <img width="50px" src={tick} alt="startAuction" />
              ) : (
                <img width="50px" src={NormalLoader} alt="startAuction" />
              )}
              <h5 style={{ textAlign: "left" }}>
                1. Waiting for Approval...
              </h5>
            </div>
            <div
              style={{ display: "flex", gap: "20px", alignItems: "center" }}
            >
              {approveAuction ? (
                <img width="50px" src={tick} alt="startAuction" />
              ) : (
                <img width="50px" src={NormalLoader} alt="startAuction" />
              )}
              <h5 style={{ textAlign: "left" }}>
                2. Waiting for Listing...
              </h5>
            </div>
          </div>
        </div>
      )}

      {mintingApproved ? (
        <div className="checkout" >
          <div className="maincheckout modalColor">
            <button
              className="btn-close"
              onClick={() => setMintingApproved(false)}
            >
              x
            </button>

            <div className="heading">
              <h3>  Your Asset has been Minted Successfully......</h3>
            </div>
            <p>Transaction Hash: {transactionHash}</p>
            <p>From: {transactionFrom}</p>
            {transactionBidder && <p>Bidder: {transactionBidder}</p>}

            <p>Gas Used: {transactionGasUsed}</p>
            {transactionAuctionID && (
              <p>Auction ID: {transactionAuctionID}</p>
            )}

            <div>
              <button className="btn-main ">
                {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://polygonscan.com/tx/${transactionHash}`}
                >
                  {" "}
                  Click to see transaction details
                </a>
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                className="btn-main1 "
                style={{ width: '100%' }}
                onClick={() => {
                  window.location.href = `${constants.MainURL}/Author/${userDataID}`;
                }}
              >
                Go to My Collection
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {mintingLoader ? (
        <div className="loaderContainer">
          <div className="loaderContainerInside">

            <ItemDetailContentLoader />
          </div>
        </div>
      ) : null}

      <div className="profile-container-section">
        <GlobalStyles />

        <section className="container-fluid" style={{ "marginTop": "60px" }}>

          <GridContainer container spacing={0} >
            <CenterGrid item xs={12} sm={12} md={5} xl={5} lg={5}>
              <div className="image-grid">
                <div className="icon-div">
                  <div>
                    <CurrencyBitcoinRoundedIcon /></div>
                  <div className="icons">
                    <OpenInNewRoundedIcon />
                    <FavoriteBorderRoundedIcon />

                  </div>
                </div>
                <div style={{ height: '100%', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                  <img
                    src={
                      finalResult?.ProductImage
                        ? finalResult?.ProductImage
                        : nft?.preview_image &&
                        api?.baseUrl + nft?.preview_image?.url
                    }
                    className="img-fluid mb-sm-30" id="image"
                    alt="imgage"
                    style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                  />
                </div>

              </div>
              <DecsAccordion finalResult={finalResult} />
              <AboutAccordion finalResult={finalResult} />
              <DetailAccordion finalResult={finalResult} />
            </CenterGrid>
            <CenterGrid item xs={12} sm={12} md={7} xl={7} lg={7} >
              <GridContainers container spacing={0} >
                <LeftGrid item xs={12} sm={12} md={6} xl={6} lg={6}>
                  <div><span>{finalResult?.name}</span><VerifiedIcon /></div>
                  <h2>{finalResult?.name}</h2>

                  <div className="iconpara">


                    <h6>Owned By <span>{finalResult?.creator?.name}</span>  </h6>
                  </div>
                </LeftGrid>
                <RightGrid item xs={12} sm={12} md={6} xl={6} lg={6}>

                  <DriveFolderUploadIcon />
                  <MoreHorizIcon />
                </RightGrid>

              </GridContainers>
              <GridContainer>
                <IconGrid item xs={12} sm={12} md={6} xl={6} lg={6}>
                  <div><VisibilityOutlinedIcon /><p>20M Views</p></div>
                  <div><FavoriteBorderOutlinedIcon /><p>1 Favourite</p></div>
                  <div><CategoryOutlinedIcon /><p>{finalResult?.category}</p></div>
                </IconGrid>
              </GridContainer>
              <div className="offerdiv" >

                {finalResult?.openBid !== true ?
                  <>
                    <span>{finalResult?.name}</span>
                    <h2>{finalResult?.price} {finalResult?.blockChain}  <span>{finalResult?.creator?.name}</span> </h2>
                  </>
                  :
                  <>
                  </>
                }
                {
                  userData?._id === finalResult?.userId?._id ?
                    <>
                      {
                        his?.length > 0 ?
                          <>
                            <button onClick={() => { handleWithdraw() }}>
                              {/* <LocalOfferOutlinedIcon />BUY */}
                              Result
                            </button>
                          </>
                          :
                          <>
                            {/* <button onClick={() => { handleStartAuction() }} > */}
                            <button onClick={() => { handleStartAuction() }} >
                              <LocalOfferOutlinedIcon />Start Auction
                            </button>
                          </>
                      }
                    </>

                    :

                    <>
                      {
                        finalResult.isAuction === true ?

                          <>

                            <button onClick={handleAuctionOpen} >
                              <LocalOfferOutlinedIcon />Make Offer
                            </button>

                          </>


                          :
                          <>
                            <button onClick={() => { handleSell() }}>
                              {/* <LocalOfferOutlinedIcon />BUY */}
                              BUY
                            </button>
                          </>
                      }
                    </>
                }


              </div>

              <PriceAccordion />
              <ListAccordion />
              <OfferAccordion his={his} blockchain={finalResult?.blockChain} />

            </CenterGrid>

          </GridContainer>
          <GridContainer container spacing={0} >
            <CenterGrid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <ItemAccordion />
            </CenterGrid>
            <CenterGrid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <CardAccordion collections={collections} />

            </CenterGrid>
          </GridContainer>
        </section>
        {/* <Footer /> */}
      </div >

      {handleOpen === true ?
        <>
          <div>
            {/* <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Make an offer
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <label>Price</label> <input type="text" width='30px' style={{ border: '2px !important' }} onChange={(e) => { setAuctionPriceWarning(false); setAuctionPriceValue(e.target.value) }} />
                {auctionPriceWarning === true ? <p style={{ color: 'red' }} >Please Enter Greater Amount</p> : <></>}
                <button style={{ padding: '9px', background: 'blue', border: 'none', borderRadius: '20px', color: 'white' }} onClick={() => { handleAuctionSell() }}>Make Offer</button>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Save changes
                </Button>
              </DialogActions>
            </BootstrapDialog> */}

            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {errorHandling ?

<>
<IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
                </IconButton>
           <h1 style={{ color: "red",flexFlow:'word-wrap' }}>Error!</h1>
           <p  style={{wordBreak:'break-word' }}>{rejectWarning}</p>

</>

: 

<>
<IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
                </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Make an offer
          </Typography>
<div className="mk-off">
<TextField id="outlined-basic" type="number" label="Enter the Amount" variant="outlined" onChange={(e) => { setAuctionPriceWarning(false); setAuctionPriceValue(e.target.value) }} />
</div>

        <div className="mk-off-btn">
          <Button onClick={() => { handleAuctionSell() }}>Make an Offer</Button>
        </div>
</>
}
       
        </Box>
      </Modal>
          </div>
        </>
        :
        <>
        </>
      }



      <Footer />
    </>
  );
};

export default memo(ItemDetailRedux);
