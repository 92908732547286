import React from 'react'
import {Link} from '@reach/router'

const Forgot = () => {
  return (
    <div>
        <div>
          <Link to='/'>Wallex</Link>
        </div>
        <div>
            <h4 style={{textAlign:'center'}}>Reset Password</h4>
            <p>A request to reset this account's password has been made.</p>
            <p>If you made the request click the link below to continue. If you did make this request please contact us.</p>
            <input />
        </div>
    </div>
  )
}

export default Forgot