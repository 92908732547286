import React from 'react';
import Particle from '../components/Particle';
import SliderMainParticle from '../components/SliderMainParticle';
import SliderMainZero from '../components/SliderMainZero1';
import FeatureBox from '../components/FeatureBox';
import ColumnNewsound from '../components/ColumnNewsound';
import Nftcarousal from '../components/Nftcarousal'
import Nftbycarousal from '../components/Nftbycategory'
import NftTabs from '../components/nftTabs';
import AuthorListReduxNew from '../components/AuthorListReduxNew';
import CatgorNew from '../components/CatgorNew';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import { useState } from 'react';
import { useEffect } from 'react';
import Axios from '../../api-instance'
import Backdrop from '@mui/material/Backdrop';
import loader from '../../assets/loader.gif'

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;


const Nftlist = () => {

  const [nfts, setNfts] = useState([])

  const getCollection = async () => {
    const data = await Axios.get(`/product/collections`)
    setNfts(data?.data?.data)
  }

  useEffect(() => {
    getCollection()
  }, [])

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

useEffect(()=>{
  setOpen(true)
  const timer = setTimeout(() => {
    setOpen(false)
  }, 4000);

return ()=>{
  clearTimeout(timer)
}
},[])

  return (
    <div>
      <GlobalStyles />
      <section className="jumbotron no-bg h-vh" style={{ background: 'linear-gradient(180deg, rgba(9,60,121,1) 25%, rgba(50,152,208,1) 100%)', height: '65vh' }}>
        <br /><br /><br /><br />
        <section className='container-fluid pb-0'>
          <div className='row'>
            <div className='col-lg-12'>
              <Nftbycarousal nfts={nfts} />
            </div>
          </div>
        </section>

      </section>
      {/* <br /><br /><br /><br /> */}

      <section className='container-fluid pb-0'>
        <div className='row'>
          <div className='col-lg-12'>
            <NftTabs />
          </div>
        </div>
      </section>

      <section className='container-fluid pb-0'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-left'>
              <h2>Top collections</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <Nftcarousal nfts={nfts} />
          </div>
        </div>
      </section>
      <section className='container-fluid pb-0'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-left'>
              <h2>Trending in Game</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <Nftcarousal nfts={nfts} />
          </div>
        </div>
      </section>
      <section className='container-fluid pb-0'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-left'>
              <h2>Trending in Art</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <Nftcarousal nfts={nfts} />
          </div>
        </div>
      </section>


      {/* <section className='container pb-0'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Top Sellers</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <AuthorListReduxNew/>
          </div>
        </div>
      </section>

      <section className='container pt-5'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center new_big_font'>
              <h2>Join Community</h2>
              <div className="small-border"></div>
            </div>
          </div>
        </div>
        <CatgorNew/>
      </section>

    <section className='container pt-0'>
    <div className="row">
      <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Latest News</h2>
              <div className="small-border"></div>
            </div>
          </div>

      <div className="col-lg-4 col-md-6 mb30">
        <div className="bloglist item">
            <div className="post-content">
                <div className="post-image">
                    <img alt="" src="./img/news/news-1.jpg" className="lazy"/>
                </div>
                <div className="post-text">
                    <span className="p-tagline">Tips &amp; Tricks</span>
                    <span className="p-date">October 28, 2020</span>
                    <h4><span>The next big trend in crypto<span></span></span></h4>
                    <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                    <span className="btn-main">Read more</span>
                </div>
            </div>
        </div>
      </div>
      
      <div className="col-lg-4 col-md-6 mb30">
        <div className="bloglist item">
            <div className="post-content">
                <div className="post-image">
                    <img alt="" src="./img/news/news-2.jpg" className="lazy"/>
                </div>
                <div className="post-text">
                    <span className="p-tagline">Tips &amp; Tricks</span>
                    <span className="p-date">October 28, 2020</span>
                    <h4><span>The next big trend in crypto<span></span></span></h4>
                    <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                    <span className="btn-main">Read more</span>
                </div>
            </div>
        </div>
      </div>
      
      <div className="col-lg-4 col-md-6 mb30">
        <div className="bloglist item">
            <div className="post-content">
                <div className="post-image">
                    <img alt="" src="./img/news/news-3.jpg" className="lazy"/>
                </div>
                <div className="post-text">
                    <span className="p-tagline">Tips &amp; Tricks</span>
                    <span className="p-date">October 28, 2020</span>
                    <h4><span>The next big trend in crypto<span></span></span></h4>
                    <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                    <span className="btn-main">Read more</span>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section> */}


      <Footer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backdropFilter:'blur(5px)',background:'transparent',cursor:'none' }}
        open={open}
        // onClick={handleClose}
      >
      <div className='loader' > 
      <img src={loader} alt='loader' />
      </div>

      </Backdrop>
    </div>
  );
}

export default Nftlist;