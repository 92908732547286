import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Clock from "../components/Clock";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import * as selectors from "../../store/selectors";
import { fetchNftDetail } from "../../store/actions/thunks";
/*import Checkout from "../components/Checkout";
import Checkoutbid from "../components/Checkoutbid";*/
import api from "../../core/api";
import moment from "moment";
import { useLocation } from "@reach/router";
import Loader from "../../assets/loader/giphy.gif";
import NormalLoader from '../../assets/loader/load.gif'
import Axios from "../../api-instance";
import constants from "../../constants";
import startAuctionLoader from "../../assets/loader/d.gif";
import tick from "../../assets/loader/tick.webp";
//IMPORT DYNAMIC STYLED COMPONENT
import { StyledHeader } from "../Styles";
import buyContractABI from "../../web3/metamaskConnection/ABI/buyABI.json";
import buyContractAddress from "../../web3/metamaskConnection/contractAddress/buyContractAddress";
// import auctionContractAddress from "../../web3/metamaskConnection/contractAddress/auctionContractAddress";
// import auctionContractABI from "../../web3/metamaskConnection/ABI/auctionABI.json";
import mintContractAddress from "../../web3/metamaskConnection/contractAddress/contractAddress";
import mintContractABI from "../../web3/metamaskConnection/ABI/contractABI.json";
import timeBidContractAddress from "../../web3/metamaskConnection/contractAddress/timeBidContractAddress";
import timeBidABI from "../../web3/metamaskConnection/ABI/timeBidABI.json";
import newAuctionContractAddress from "../../web3/metamaskConnection/contractAddress/newAuctionContractAddress";
import newAuctionContractABI from "../../web3/metamaskConnection/ABI/newAuctionABI.json";
import truncateEthAddress from "truncate-eth-address";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import cancel from "../../assets/loader/cancel.webp";
import FraktionLoader from "../components/Loader";
import Avatar from "react-avatar";
import {Link} from '@reach/router'
import ItemDetailContentLoader from '../components/itemDetailContentLoader'



// var CronJob = require('cron').CronJob;
// var job = new CronJob('* * * * * *', function() {
//   console.log('You will see this message every second');
// }, null, true, 'America/Los_Angeles');
// job.start();

// polygon url
// const alchemyKey ="https://polygon-mumbai.g.alchemy.com/v2/qlt3u9DeNuef36KsQWwzALRg1x23aBeE";
// https://eth-rinkeby.alchemyapi.io
const alchemyKey =
  "https://eth-mainnet.g.alchemy.com/v2/E11IswOPCxAsDPDlRkg7KV-RPwkjCafv";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
var ethers = require("ethers");

const PRIVATE_KEY = "0xqlt3u9DeNuef36KsQWwzALRg1x23aBeE";

//SWITCH VARIABLE FOR PAGE STYLE
const theme = "GREY"; //LIGHT, GREY, RETRO
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
    border-bottom: solid 1px #dddddd;
  }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #8364E2 !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;


const ItemDetailRedux = ({ nftId }) => {
  const location = useLocation();
  const [finalResult, setFinalResult] = useState(location.state)
  
   

  

  const [openMenu0, setOpenMenu0] = React.useState(true);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [balance, setBalance] = useState("");
  const [service, setService] = useState("");
  const [pay, setPay] = useState("");
  const [insufficientFunds, setInsufficientFunds] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [transactionFrom, setTransactionFrom] = useState("");
  const [transactionTo, setTransactionTo] = useState("");
  const [isAuctionTable, setIsAuctionTable] = useState(false);
  const [transactionBidder, setTransactionBidder] = useState("");
  const [transactionAuctionID, setTransactionAuctionID] = useState("");
  const [transactionGasUsed, setTransactionGasUsed] = useState("");
  const [mintingApproved, setMintingApproved] = useState(false);
  const [heartFill, setHeartFill] = useState(false);

  const [likeCount, setLikeCount] = useState(finalResult?.favourite?.length);
  const [totalViews, setTotalViews] = useState(finalResult?.view?.length);

  const [recentAuction, setRecentAuction] = useState("");

  const [openCheckout, setOpenCheckout] = React.useState(false);
  const [openCheckoutbid, setOpenCheckoutbid] = React.useState(false);
  const [mintingLoader, setMintingLoader] = React.useState(false);
  const [viewCount, setViewCount] = useState("");
  const [auctionPriceValue, setAuctionPriceValue] = useState("");
  const [auctionWarning, setAuctionWarning] = useState(false);
  const [auctionPriceWarning, setAuctionPriceWarning] = useState(false);
  const [lastBid, setLastBid] = useState("");
  const [userData, setUserData] = useState(null);
  const [isAlreadyBided, setIsAlreadyBided] = useState("");
  const [bidedValue, setBidedValue] = useState("");
  const [endTransaction, setEndTransaction] = useState(false);
  const [isMintedUser, setIsMintedUser] = useState(false);
  const [mintedUserBid, setMintedUserBid] = useState(false);
  const [auctionTable, setAuctionTable] = useState([]);

  const [startAuction, setStartAuction] = useState(false);
  const [approveAuction, setApproveAuction] = useState(false);
  const [biddingApproved, setBiddingApproved] = useState(false);
  const [owner, setOwner] = useState(false);
  const [claimButton, setClaimButton] = useState(false);

  const [errorHandling, setErrorHandling] = useState(false);
  const [rejectWarning, setRejectWarning] = useState(null);
  const [isDark, setIsDark] = useState(false);
  const [auctionId, setAuctionId] = useState("");
const [timeDuration, setTimeDuration] = useState(false)
  const [isNotMintedUser, setIsNotMintedUser] = useState(true);
  const [mintedUserData, setMintedUserData] = useState([]);
  const [timeBidOpen, setTimeBidOpen] = useState(false);
  const [userDataID, setUserDataID] = useState('')

  const handleBtnClick0 = () => {
    setOpenMenu0(!openMenu0);
    setOpenMenu(false);
    setOpenMenu1(false);
    setIsAuctionTable(false);
    setRecentAuction(false);
    document.getElementById("Mainbtn0").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
    // document.getElementById("Mainbtn1").classList.remove("active");
  };
  const handleBtnClick = async() => {
  try {
   
    // setOpenMenu(!openMenu);
    setOpenMenu1(false);
    setOpenMenu0(false);
    setIsAuctionTable(true);
    // setRecentAuction(true);
    document.getElementById("Mainbtn").classList.add("active");
    // document.getElementById("Mainbtn1").classList.remove("active");
    document.getElementById("Mainbtn0").classList.remove("active");
  } catch (error) {
    console.log(error);
  }
  };
  // const handleBtnClick1 = () => {
  //   setOpenMenu1(!openMenu1);
  //   setOpenMenu(false);
  //   setOpenMenu0(false);
  //   setIsAuctionTable(false);
  //   setRecentAuction(false);
  //   document.getElementById("Mainbtn1").classList.add("active");
  //   document.getElementById("Mainbtn").classList.remove("active");
  //   document.getElementById("Mainbtn0").classList.remove("active");
  // };

  const [initialLoader, setInitialLoader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setInitialLoader(false);
    }, 5000);
    setInitialLoader(true);
  }, []);

  const dispatch = useDispatch();
  const nftDetailState = useSelector(selectors.nftDetailState);
  const nft = nftDetailState.data ? nftDetailState.data : [];

  useEffect(() => {
    dispatch(fetchNftDetail(nftId));
  }, [dispatch, nftId]);

  useEffect(() => {
    if (localStorage.getItem("mode") === "dark") {
      setIsDark(true);
    } else if (localStorage.getItem("mode") === "light") {
      setIsDark(false);
    }
  }, []);

  const [historyValue, setHistoryValue] = useState('')

  useEffect(() => {
    const getUserData = async () => {
      try {


        if(!finalResult){
          const txHash = window.location.pathname.split('/').pop();
          const {data} = await Axios.get(`/product?transactionHash=${txHash}`)
          
          setFinalResult(data?.data);
        }



        const data = await Axios.get( `/auction?productId=${finalResult?._id}`);
       
        

        if(data?.data?.data[0]?.transaction?.length>0){

          setHistoryValue(data?.data?.data[0].transaction.pop())
        }else{
          setBidedValue(data?.data?.data[0]?.transaction)
        }
   
        if(window.location.pathname.split('/').pop()){
          if(finalResult?.mintTransaction?.transactionHash!== window.location.pathname.split('/').pop()){
               window.location.href=`${constants?.MainURL}/404`
          }
        }

      const mintedData = await Axios.get(
        `/auction?productId=${finalResult?._id}`
      );
      const productDatas = await Axios.get(`/product?id=${finalResult?._id}`);
      // const creatorData = await Axios.get('/auth/user?_id')
      setMintedUserData(mintedData?.data?.data);

      let arr = mintedData?.data?.data[0]?.transaction;
      
      if (finalResult?._id === mintedData?.data?.data[0]?.productId?._id) {
        setAuctionId(mintedData?.data?.data[0]?.auctionId);
        if (mintedData?.data?.data[0]?.isActive === true) {
          setLastBid(arr);
        }
      }

      const token = window.localStorage.getItem("token");

      if (token) {
        const { data } = await Axios.get(`/auth/getdata/${token}`);
        setUserDataID(data?.data)
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        //finding the minting user or not for not displaying buynow button
        if (data) {
          if (data?.data?._id === productDatas?.data?.data[0]?.userId?._id) {
            if (finalResult?.makeMarketTransaction?.from === accounts[0]) {
              setIsNotMintedUser(false);
            }
          }
        }

        if (mintedData?.data?.data[0]?.userId === data?.data?._id) {
          if (finalResult?.mintTransaction?.from === accounts[0]) {
            if (finalResult?.isAuction) {
              if (mintedData?.data?.data[0]?.isActive === false) {
                setIsMintedUser(true);
              }
              if (mintedData?.data?.data[0]?.isActive === true) {
                if (mintedData?.data?.data[0]?.productId?._id === finalResult?._id) {
                  if (mintedData?.data?.data[0].timeBid) {
                    if (
                      moment().format("lll") >
                      moment(mintedData?.data?.data[0]?.expireDate).format(
                        "lll"
                      )
                    ) {
                      setClaimButton(true);
                      setTimeDuration(true)
                    }
                    else{
                      setClaimButton(true);
                      setTimeDuration(false)
                    }
                  }
                  if(mintedData?.data?.data[0].openBid){
                    setTimeDuration(true)
                  }

                  if (!mintedData?.data?.data[0].timeBid) {
                   
                    if (mintedData?.data?.data[0]?.transaction.length > 0) {
                      
                      setClaimButton(true);
                      if (mintedData?.data?.data[0]?.isVerified) {
                        // const getData = async()=>{
                        //   await Axios.put("/product",{'_id':finalResult?._id,"isReceived":true},{"Content-type":"Application/json"});
                        // }
                        // getData()
                      }
                    }
                  }
                  if (mintedData?.data?.data[0]?.isVerified) {
                    setClaimButton(false);
                  }
                }
              }
            }
          }
        }

       

        if (finalResult?.mintTransaction?.from !== accounts[0]) {
          if (finalResult?.isAuction) {
            if (mintedData?.data?.data[0]?.isActive === true) {
              if(mintedData?.data?.data[0].timeBid===true){
              if (
                moment().format("lll") <
                moment(mintedData?.data?.data[0]?.expireDate).format(
                  "lll"
                )
              ){
                setMintedUserBid(true);
              }
            }

            } 
            if(mintedData?.data?.data[0].openBid===true){
              setMintedUserBid(true);
            }
           
            
            else if (mintedData?.data?.data[0]?.isActive === false) {
              setMintedUserBid(false);
            }
          }
        }

        //This condition will explain the minted user in itemDetails page

        setUserData(data?.data);

        // if (finalResult?.isAuction) {
        //   const auctionCon =  finalResult?.auctionTransaction?.filter(
        //      (item, i) => {
        //        if (item?.userData?._id===data?.data?._id) {

        //          return item
        //        }
        //      }
        //    );

        //  if(auctionCon?.length!==0){
        //    const alreadyAuctionPrice = auctionCon[0];
        //    setIsAlreadyBided(true)
        //    setBidedValue(alreadyAuctionPrice)
        //  }
        //  }
      }
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (productDatas?.data?.data[0]?.mintTransaction?.from === accounts[0]) {
        setEndTransaction(true);
      }

      const account = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (account) {
        web3.eth.getBalance(account[0], function (error, wei) {
          if (!error) {
            var balance = web3.utils.fromWei(wei, "ether");
            if (balance < finalResult?.price) {
              setInsufficientFunds(true);
            }
            setBalance(parseFloat(balance).toFixed(5) + " ETH");
            setService(parseFloat((finalResult?.admin?.adminFees/100)).toFixed(7));
          }
        });
      }

      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, []);

  

  const handleSell = async () => {
    try {
      // console.log( ethers.utils.parseEther(finalResult?.price)._hex);
      // try {
      setMintingLoader(true);
      const account = await window.ethereum.request({
        method: "eth_requestAccounts",
        chainId: "0x137",
      });
        
      const contractInstance = new web3.eth.Contract(
        buyContractABI,
        buyContractAddress
      );

      const mintContractInstace = new web3.eth.Contract(
        mintContractABI,mintContractAddress
      )

      const trans = {
        from:account[0],
        to:buyContractAddress,
        gas:await web3.eth.getGasPrice()
      }



      // const nonce = await web3.eth.getTransactionCount(account[0], "latest");
      const transactionParams = {
        to:buyContractAddress,
        from: account[0], // must match user's active address.
        // nonce: `${nonce}`, // ignored by MetaMask
        gasPrice: `${await web3.eth.getGasPrice()}`,
        gasLimit: "100000",
        gas: `${await web3.eth.estimateGas({
          to: buyContractAddress,
          from: account[0],
          value: ethers.utils.parseEther(`${finalResult?.price}`)._hex,
          data: await contractInstance.methods.createMarketsale(
            mintContractAddress,
            finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
          ).encodeABI(),
        })}`,
        value: ethers.utils.parseEther(`${finalResult?.price}`)._hex,
        // data: await contractInstance.methods.createMarketsale(
        //   mintContractAddress,
        //   finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
        // ).encodeABI()
      };

      // const txHash = await window.ethereum.request({
      //   method: "eth_sendTransaction",
      //   params: [transactionParams],
      // });

      

      const txHash = await contractInstance.methods.createMarketsale(
          mintContractAddress,
          finalResult?.makeMarketTransaction?.events?.MarkettokenMinted?.returnValues?.itemId
        ).send(transactionParams);

 
      if (txHash) {
        const transactionReceipt = {
          to: finalResult?.mintTransaction?.from,
          from: account[0],
          hash: txHash.transactionHash,
        };
        const singleMint = await Axios.put(
          "/product",
          {
            _id: finalResult._id,
            buyTransaction: txHash,
            owners: userData?._id,
            isSold: true,
            userId:userData?._id
          },
          { "Content-type": "application/json" }
        );


        const sendMailtoBuyer = await Axios.post('/product/sendMailFirst?title=buy',{
          "fromID":userData?._id,
          "assetID":finalResult?._id
        })
        const sendMailtoReceiver = await Axios.post('/product/sendMailSecond?title=sold',{
          "fromID":finalResult?.userId,
          "assetID":finalResult?._id,
          "toID":userData?._id
        })
        setMintingLoader(false);
        setOpenMenu0(false)
        setOpenMenu1(false)
        setOpenMenu(false)
        setOpenCheckout(false)
        setOpenCheckoutbid(false)
        setMintingApproved(true);
        setTransactionHash(txHash?.transactionHash);
        setTransactionFrom(txHash?.from);
        setTransactionGasUsed(txHash?.gasUsed);
        setTransactionHash(transactionReceipt?.hash);
        window.document.body.style.overflow = "hidden";
      }
    } catch (error) {
      setMintingLoader(false);
      setOpenMenu0(false)
      setOpenMenu1(false)
      setOpenMenu(false)
      setOpenCheckout(false)
      setOpenCheckoutbid(false)
      if (
        error?.code === 4001 &&
        error.message ===
          "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      if (error.code === -32603) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Transaction failed.....");
      }
    }
  };

  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }

  const handleAuctionSell = async () => {
    try {
      if (mintedUserData[0].openBid === true) {
        console.log("openBID");
        const date = getNumberOfDays(
          `${new Date(finalResult?.auctionFromDate).getMonth()}/${new Date(
            finalResult?.auctionFromDate
          ).getDate()}/${new Date(finalResult?.auctionFromDate).getFullYear()}`,
          `${new Date(finalResult?.auctionToDate).getMonth()}/${new Date(
            finalResult?.auctionToDate
          ).getDate()}/${new Date(finalResult?.auctionToDate).getFullYear()}`
        );

        const auctionData = await Axios.get("/auction");

        auctionData?.data?.data?.map((item, i) => {
          if (finalResult?._id === item?.productId?._id) {
            if (item?.transaction?.length > 0) {
              if (
                auctionPriceValue <
                item?.transaction[item?.transaction?.length - 1]
                  ?.auctionPriceValue
              ) {
                setAuctionPriceWarning(true);
                return false;
              }
            }
          }
        });

        const auctionInstance = await new web3.eth.Contract(
          newAuctionContractABI,
          newAuctionContractAddress
        );

        setMintingLoader(true);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0
      
        const transactionParameter = {
          to: newAuctionContractAddress,
          from: accounts[0],
          nonce: `${nonce}`,
          gasPrice: `${await web3.eth.getGasPrice()}`,
          gasLimit: "100000",
          // customizable by user during MetaMask confirmation.
          gas: `${await web3.eth.estimateGas({
            to: newAuctionContractAddress,
            from: accounts[0],
            value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
            data: await auctionInstance.methods.placeBid(auctionId).encodeABI(),
          })}`,
          value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
          data: await auctionInstance.methods.placeBid(auctionId).encodeABI(),
        };

        

        const txHash = await auctionInstance.methods
          .placeBid(auctionId)
          .send(transactionParameter);




        if (txHash) {
          const transactionParameters = {
            date: new Date(),
            transactionReceipt:
              txHash?.events?.ReserveAuctionBidPlaced?.returnValues,
            transactionParameter,
            auctionPriceValue,
            userData,
          };

          const sendAuctionTransaction = await Axios.put(
            "/auction/transaction",
            {
              productId: finalResult._id,
              transaction: [transactionParameters],
            },
            {
              "Content-type": "Application/Json",
            }
          );


          const sendMailData = await Axios.post('/product/sendMailFirst?title=placeBid',{
            "fromID":userData?._id,
            "assetID":finalResult?._id
          })

          const senMailToData = await Axios.post('/product/sendMailSecond?title=newBidPlaced',{
            "fromID":finalResult?.userData?._id,
            "assetID":finalResult?._id
          })




          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)
          setOpenCheckout(false)
          setOpenCheckoutbid(false)
          setMintingLoader(false);
          setMintingApproved(true);
          setTransactionHash(txHash?.transactionHash);
          setTransactionFrom(txHash?.from);
          setTransactionBidder(
            txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.bidder
          );
          setTransactionAuctionID(
            txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.auctionId
          );
          setTransactionGasUsed(txHash?.gasUsed);
          window.document.body.style.overflow = "hidden";
          setMintingLoader(false);
        }
      } else if (mintedUserData[0].timeBid === true) {
        console.log("TIMEBID");
        const date = getNumberOfDays(
          `${new Date(finalResult?.auctionFromDate).getMonth()}/${new Date(
            finalResult?.auctionFromDate
          ).getDate()}/${new Date(finalResult?.auctionFromDate).getFullYear()}`,
          `${new Date(finalResult?.auctionToDate).getMonth()}/${new Date(
            finalResult?.auctionToDate
          ).getDate()}/${new Date(finalResult?.auctionToDate).getFullYear()}`
        );

        const auctionData = await Axios.get("/auction");

        auctionData?.data?.data?.map((item, i) => {
          if (finalResult?._id === item?.productId?._id) {
            if (item?.transaction?.length > 0) {
              if (
                auctionPriceValue <
                item?.transaction[item?.transaction?.length - 1]
                  ?.auctionPriceValue
              ) {
                setAuctionPriceWarning(true);
                return false;
              }
            }
          }
        });

        const auctionInstance = await new web3.eth.Contract(
          timeBidABI,
          timeBidContractAddress
        );

        setMintingLoader(true);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0

        const transactionParameter = {
          to: timeBidContractAddress,
          from: accounts[0],
          nonce: `${nonce}`,

          gasPrice: `${await web3.eth.getGasPrice()}`,
          gasLimit: "100000",
          // customizable by user during MetaMask confirmation.
          gas: `${await web3.eth.estimateGas({
            to: timeBidContractAddress,
            from: accounts[0],
            value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
            data: await auctionInstance.methods
              .placeBid(parseInt(auctionId))
              .encodeABI(),
          })}`,
          value: ethers.utils.parseEther(`${auctionPriceValue}`)._hex,
          data: await auctionInstance.methods
            .placeBid(parseInt(auctionId))
            .encodeABI(),
        };

        const txHash = await auctionInstance.methods
          .placeBid(parseInt(auctionId))
          .send(transactionParameter);

        if (txHash) {
          const transactionParameters = {
            date: new Date(),
            transactionReceipt:
              txHash?.events?.ReserveAuctionBidPlaced?.returnValues,
            transactionParameter,
            auctionPriceValue,
            userData,
          };

          const sendAuctionTransaction = await Axios.put(
            "/auction/transaction",
            {
              productId: finalResult._id,
              transaction: [transactionParameters],
            },
            {
              "Content-type": "Application/Json",
            }
          );



          const sendMailData = await Axios.post('/product/sendMailFirst?title=placeBid',{
            "fromID":userData?._id,
            "assetID":finalResult?._id
          })

          const sendTopBider = await Axios.post('/product/sendMailFirst?title=topBiderTimeAuction',{
            "fromID":userData?._id,
            "assetID":finalResult?._id
          })

          const senMailToData = await Axios.post('/product/sendMailSecond?title=newBidPlaced',{
            "fromID":finalResult?.userData?._id,
            "assetID":finalResult?._id
          })





          setMintingLoader(false);
          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)
          setMintingApproved(true);
          setTransactionHash(txHash?.transactionHash);
          setTransactionFrom(txHash?.from);
          setTransactionBidder(
            txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.bidder
          );
          setTransactionAuctionID(
            txHash?.events?.ReserveAuctionBidPlaced?.returnValues?.auctionId
          );
          setTransactionGasUsed(txHash?.gasUsed);
          window.document.body.style.overflow = "hidden";
          
        }
      }
    } catch (error) {
      console.log(error);
      setMintingLoader(false);
      setOpenMenu0(false)
      setOpenMenu1(false)
      setOpenMenu(false)
      setOpenCheckout(false)
      setOpenCheckoutbid(false)
      setErrorHandling(true);
      if (
        error?.message ===
        "Returned error: execution reverted: NFTMarketReserveAuction: You already have an outstanding bid"
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning(
          "Returned error: execution reverted: NFTMarketReserveAuction: You already have an outstanding bid"
        );
      }
      if (
        error?.code === 4001 &&
        error.message ===
          "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 5000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      setTimeout(() => {
        setErrorHandling(false);
        window.location.reload();
      }, 10000);
      setErrorHandling(true);
      setRejectWarning(error?.message);
      window.location.reload();
    }
  };

  const handleTimeBidAuction = async () => {
    try {
      if (mintedUserData[0].timeBid === true) {
       
        // setMintingLoader(true);
        const auctionInstance = await new web3.eth.Contract(
          timeBidABI,
          timeBidContractAddress
        );
        const mintContractInstance = await new web3.eth.Contract(
          mintContractABI,
          mintContractAddress
        );
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: "0x137",
        });
        setBiddingApproved(true);
        const approveTokenTransactionParameter = {
          to: newAuctionContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
          gasLimit:'100000'
        };
        const approveToken = await mintContractInstance.methods
          .approve(
            timeBidContractAddress,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId
          )
          .send(approveTokenTransactionParameter);

        setStartAuction(true);
        const startTokenTransactionParameter = {
          to: timeBidContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
          gasLimit:'100000',
        
        };
       
        const auctionStarting = await auctionInstance.methods
          .createReserveAuction(
            finalResult?.mintTransaction?.to,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId,
            ethers.utils.parseEther(`${finalResult?.price}`)._hex,
            mintedUserData[0].timeDuration
          )
          .send(startTokenTransactionParameter);
     console.log(auctionStarting);
        setApproveAuction(true);

        // if(auctionStarting){
        //  const data= await auctionInstance.methods.start().call()
        //  console.log(data);
        // }

        //updating the auction start and end date for auction

        if (auctionStarting) {
          const { data } = await Axios.put("/auction", {
            startDate: new Date(),
            mintId:
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
            auctionId:
              auctionStarting?.events?.ReserveAuctionCreated?.returnValues
                ?.auctionId,
            isActive: true,
          });

          const setTime = await Axios.put("/product", {
            _id: finalResult?._id,
            startDate: new Date(),
            mintId:
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
          });

          const sendMailToSender = await Axios.post('/product/sendMailFirst?title=startAuction',{
            "fromID":userData?._id,
            "assetID":finalResult?._id,
            "auctionID":data?.data?._id
          })

          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)
          
          setOpenCheckout(false)
          setOpenCheckoutbid(false)
          setMintingLoader(false);
          setBiddingApproved(false);
          window.location.reload();
        }
      } else if (mintedUserData[0].openBid === true) {
        console.log("openbid");
        // setMintingLoader(true);
        const auctionInstance = await new web3.eth.Contract(
          newAuctionContractABI,
          newAuctionContractAddress
        );

        const mintContractInstance = await new web3.eth.Contract(
          mintContractABI,
          mintContractAddress
        );

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          chainId: "0x137",
        });
        setBiddingApproved(true);

        const approveTokenTransactionParameter = {
          to: newAuctionContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
        };

        const approveToken = await mintContractInstance.methods
          .approve(
            newAuctionContractAddress,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId
          )
          .send(approveTokenTransactionParameter);

        setStartAuction(true);

        const startTokenTransactionParameter = {
          to: newAuctionContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
        };

        const auctionStarting = await auctionInstance.methods
          .createReserveAuction(
            finalResult?.mintTransaction?.to,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues
              ?.tokenId,
            ethers.utils.parseEther(`${finalResult?.price}`)._hex
          )
          .send(startTokenTransactionParameter);

        setApproveAuction(true);

        // if(auctionStarting){
        //  const data= await auctionInstance.methods.start().call()
        //  console.log(data);
        // }

        //updating the auction start and end date for auction

        if (auctionStarting) {
          const { data } = await Axios.put("/auction", {
            startDate: new Date(),
            mintId:
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
            auctionId:
              auctionStarting?.events?.ReserveAuctionCreated?.returnValues
                ?.auctionId,
            isActive: true,
          });

          const setTime = await Axios.put("/product", {
            _id: finalResult?._id,
            startDate: new Date(),
            mintId:
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
          });

          const sendMailToSender = await Axios.post('/product/sendMailFirst?title=startAuction',{
            "fromID":userData?._id,
            "assetID":finalResult?._id,
            "auctionID":data?.data?._id
          })

          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)
          setMintingLoader(false);
          setBiddingApproved(false);
          window.location.reload();
        }
      }
    } catch (error) {
      setMintingLoader(false);
      setOpenMenu0(false)
      setOpenMenu1(false)
      setOpenMenu(false)
      setOpenCheckout(false)
      setOpenCheckoutbid(false)
      if (
        error?.code === 4001 &&
        error.message ===
          "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      if (error.code === -32603) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Transaction failed.....");
      }
      setBiddingApproved(false);
    }
  };


  const handleHeart = async () => {
    if (heartFill) {
      setHeartFill(false);
      const addLikes = await Axios.put(
        "/product/unlike",
        { _id: finalResult._id, favourite: userData?._id },
        {
          "Content-type": "Application/json",
        }
      );

      setLikeCount(addLikes?.data?.data?.favourite?.length);
    } else {
      setHeartFill(true);
      const addLikes = await Axios.put(
        "/product",
        { _id: finalResult._id, favourite: userData?._id },
        {
          "Content-type": "Application/json",
        }
      );

      setLikeCount(addLikes?.data?.data?.favourite?.length);
    }
  };

  //   const handleStartAuction = async () => {
  //     try {
  //       setMintingLoader(true);
  //       const auctionInstance = await new web3.eth.Contract(
  //         newAuctionContractABI,
  //         newAuctionContractAddress
  //       );

  //       const mintContractInstance = await new web3.eth.Contract(
  //         mintContractABI,
  //         mintContractAddress
  //       );

  //       const accounts = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //         chainId:'0x137'
  //       });
  //       setBiddingApproved(true);

  //       const approveTokenTransactionParameter = {
  //         to: newAuctionContractAddress,
  //         from: accounts[0],
  //         gasPrice: `${await web3.eth.getGasPrice()}`,
  //       };

  //       const approveToken = await mintContractInstance.methods
  //         .approve(
  //           newAuctionContractAddress,
  //           finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
  //         )
  //         .send(approveTokenTransactionParameter);

  //       setStartAuction(true);

  //       const startTokenTransactionParameter = {
  //         to: newAuctionContractAddress,
  //         from: accounts[0],
  //         gasPrice: `${await web3.eth.getGasPrice()}`,
  //       }

  //       const auctionStarting = await auctionInstance.methods
  //         .createReserveAuction(
  //           finalResult?.mintTransaction?.to,
  //           finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
  //           ethers.utils.parseEther(`${finalResult?.price}`)._hex
  //         ).send(startTokenTransactionParameter);

  //       setApproveAuction(true);

  //       // if(auctionStarting){
  //       //  const data= await auctionInstance.methods.start().call()
  //       //  console.log(data);
  //       // }

  //       //updating the auction start and end date for auction

  // if(auctionStarting){
  //   const { data } = await Axios.put("/auction", {
  //     "startDate": new Date(),
  //     "mintId":finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
  //       "auctionId":auctionStarting?.events?.ReserveAuctionCreated?.returnValues?.auctionId,
  //     "isActive": true,
  //   });

  //   const setTime = await Axios.put('/product',{
  //     "_id":finalResult?._id,
  //     "startDate":new Date(),
  //     "mintId":finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
  //   })

  //   setMintingLoader(false);
  //   setBiddingApproved(false);
  //   window.location.reload();
  // }

  //     } catch (error) {

  //       if(error?.code===4001 && error.message === "MetaMask Tx Signature: User denied transaction signature."){

  //         setTimeout(()=>{
  //           setErrorHandling(false)
  //           window.location.reload()
  //         },3000)
  //           setErrorHandling(true)
  //           setRejectWarning('Metamask transaction user denied signature....')
  //       }
  //       if(error.code=== -32603){
  //         setTimeout(()=>{
  //           setErrorHandling(false)
  //           window.location.reload()
  //         },3000)
  //           setErrorHandling(true)
  //           setRejectWarning('Transaction failed.....')
  //       }
  //       setBiddingApproved(false)
  //     }
  //   };

  const handleWithdraw = async () => {
    try {
      if (mintedUserData[0].openBid === true) {
        setMintingLoader(true);
        const auctionInstance = await new web3.eth.Contract(
          newAuctionContractABI,
          newAuctionContractAddress
        );
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0
        const transactionParameter = {
          to: newAuctionContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
          data: await auctionInstance.methods
            .finalizeReserveAuction(parseInt(auctionId))
            .encodeABI(),
        };
        // const txHash = await auctionInstance.methods.finalizeReserveAuction(18).send(transactionParameter);

        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [transactionParameter],
        });

        const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);

        if (txHash) {
          const { data } = await Axios.put("/auction/transaction", {
            productId: finalResult?._id,
            isVerified: true,
            claimId: lastBid?.userData?._id,
          });

          const setTime = await Axios.put("/product", {
            _id: finalResult?._id,
            isSold: true,
            owners:historyValue?.userData?._id,
            userId:historyValue?.userData?._id
          });

          const sendMail = await Axios.post('/product/sendMailFirst?title=bidAccepted',{
            "fromID":historyValue?.userData?._id,
            "assetID":finalResult?._id
          })

          const sendMailToReceiver = await Axios.post('/product/sendMailSecond?title=sold',{
            "fromID":userData?._id,
            "assetID":finalResult?._id
          })

          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)
          setMintingApproved(true);
          setTransactionHash(txHash);
          setTransactionFrom(transactionParameter?.from);
          setTransactionAuctionID(auctionId);
          setTransactionGasUsed(transactionParameter?.gasUsed);
          window.document.body.style.overflow = "hidden";
          setMintingLoader(false);
        }
      } else if (mintedUserData[0].timeBid === true) {
        
        setMintingLoader(true);
        const auctionInstance = await new web3.eth.Contract(
          timeBidABI,
          timeBidContractAddress
        );
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const nonce = await web3.eth.getTransactionCount(accounts[0], "latest"); // nonce starts counting from 0
        const transactionParameter = {
          to: timeBidContractAddress,
          from: accounts[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
          gasLimit: "800000",
          data: await auctionInstance.methods
            .finalizeReserveAuction(parseInt('2'))
            .encodeABI(),
        };
        const txHash = await auctionInstance.methods
          .finalizeReserveAuction(parseInt('2'))
          .send(transactionParameter);

        // const txHash = await window.ethereum.request({
        //   method: "eth_sendTransaction",
        //   params: [transactionParameter],
        // });

        const transactionReceipt = await web3.eth.getTransactionReceipt(txHash);

        if (txHash) {
          const { data } = await Axios.put("/auction/transaction", {
            productId: finalResult?._id,
            isVerified: true,
            claimId: lastBid?.userData?._id,
          });



          

          const setTime = await Axios.put("/product", {
            _id: finalResult?._id,
            isSold: true,
            owners:historyValue?.userData?._id,
            userId:historyValue?.userData?._id
          });


          const sendMail = await Axios.post('/product/sendMailFirst?title=bidAccepted',{
            "fromID":historyValue?.userData?._id,
            "assetID":finalResult?._id
          })

          const sendMailToReceiver = await Axios.get('/product/sendMailSecond?title=sold',{
            "fromID":userData?._id,
            "assetID":finalResult?._id
          })

         

          setOpenMenu0(false)
          setOpenMenu1(false)
          setOpenMenu(false)

          setMintingApproved(true);
          setTransactionHash(txHash);
          setTransactionFrom(transactionParameter?.from);
          setTransactionAuctionID(auctionId);
          setTransactionGasUsed(transactionParameter?.gasUsed);
          window.document.body.style.overflow = "hidden";
          setMintingLoader(false);
        }
      }
    } catch (error) {
      setMintingLoader(false);
      setOpenMenu0(false)
      setOpenMenu1(false)
      setOpenMenu(false)

      if (
        error.message ===
        "Returned error: execution reverted: NFTMarketReserveAuction: Auction was already settled"
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning(
          "Returned error: execution reverted: NFTMarketReserveAuction: Auction was already settled"
        );
      }
      if (
        error?.message ===
        "Returned error: execution reverted: NFTMarketReserveAuction: You already have an outstanding bid"
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning(
          "Returned error: execution reverted: NFTMarketReserveAuction: You already have an outstanding bid"
        );
      }
      if (
        error?.code === 4001 &&
        error.message ===
          "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 5000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      setTimeout(() => {
        setErrorHandling(false);
        window.location.reload();
      }, 5000);
      setErrorHandling(true);
      setRejectWarning(error?.message);
      window.location.reload();
    }
  };

  // console.log(finalResult?._id,mintedUserData[0]?.productId,mintedUserData[0]?.transaction);

  ;



  return (
    <>
      {initialLoader ? (
        <section className="container">
                <div className="row mt-md-5 pt-md-4">
                  <div className="col-md-6 text-center">
        <ItemDetailContentLoader/>
        </div>
        </div>
        </section>
      ) : (
        <>

{errorHandling && 

<div className="checkout" >
<div className="maincheckout">
<button
            className="btn-close"
          >
            x
          </button>
          <h1 style={{ color: "red" }}>Error</h1>
                <p>{rejectWarning}</p>
              
          </div>
          </div>

          }




          {biddingApproved && (

<div className="checkout" >
<div className="maincheckout">
<button
            className="btn-close"
          >
            x
          </button>
          <div className="heading">
                      <h3>Auction Listing In Progress...</h3>
                    </div>
          
                <div
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  {startAuction ? (
                    <img width="50px" src={tick} alt="startAuction" />
                  ) : (
                    <img width="50px" src={NormalLoader} alt="startAuction" />
                  )}
                  <h5 style={{  textAlign: "left" }}>
                    1. Waiting for Approval...
                  </h5>
                </div>
                <div
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  {approveAuction ? (
                    <img width="50px" src={tick} alt="startAuction" />
                  ) : (
                    <img width="50px" src={NormalLoader} alt="startAuction" />
                  )}
                  <h5 style={{  textAlign: "left" }}>
                    2. Waiting for Listing...
                  </h5>
                </div>
              </div>
            </div>
          )}

          
        
          {mintingApproved ? (
<div className="checkout" >
          <div className="maincheckout">
          <button
                      className="btn-close"
                      onClick={()=>setMintingApproved(false)}
                    >
                      x
                    </button>

                <div className="heading">
                      <h3>  Your Asset has been Minted Successfully......</h3>
                    </div>
                <p>Transaction Hash: {transactionHash}</p>
                <p>From: {transactionFrom}</p>
                {transactionBidder && <p>Bidder: {transactionBidder}</p>}

                <p>Gas Used: {transactionGasUsed}</p>
                {transactionAuctionID && (
                  <p>Auction ID: {transactionAuctionID}</p>
                )}

                <div>
                  <button  className="btn-main ">
                    {" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://polygonscan.com/tx/${transactionHash}`}
                    >
                      {" "}
                      Click to see transaction details
                    </a>
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                  className="btn-main1 "
                  style={{width:'100%'}}
                    onClick={() => {
                      window.location.href = `${constants.MainURL}/Author/${userDataID}`;
                    }}
                  >
                    Go to My Collection
                  </button>
                  </div>
                    </div>
                    </div>
          ) : null}



          {mintingLoader ? (
            <div className="loaderContainer">
              <div className="loaderContainerInside">
               <ItemDetailContentLoader/>
                {/* <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: "30vw",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                /> */}
              </div>
            </div>
          ) : null}

          {isDark ? (
            <div className="greyscheme">
              <StyledHeader theme={theme} />
              <section className="container">
                <div className="row mt-md-5 pt-md-4">
                  <div className="col-md-6 text-center">
                    <img
                      src={
                        nft.preview_image && api.baseUrl + nft.preview_image.url
                      }
                      className="img-fluid img-rounded mb-sm-30"
                      alt=""
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="item_info">
                      {finalResult?.isAuction && finalResult?.auctionTime && !finalResult?.openBid  && (
                        <>
                          Auctions ends in
                          <div className="de_countdown">
                            <Clock deadline={nft.deadline} />
                          </div>
                        </>
                      )}
                      <h2>{nft.title}</h2>
                      <div className="item_info_counts">
                        <div className="item_info_type">
                          <i className="fa fa-image"></i>
                          {nft.category}
                        </div>
                        <div className="item_info_views">
                          <i className="fa fa-eye"></i>
                          {nft.views}
                        </div>
                        <div className="item_info_like">
                          <i className="fa fa-heart" onClick={handleHeart}></i>
                          {nft.likes}
                        </div>
                      </div>
                      <p>{nft.description}</p>
                   

                      <div className="spacer-40"></div>

                      <div className="de_tab">
                        <ul className="de_nav">
                          <li id="Mainbtn0" className="active">
                            <span onClick={handleBtnClick0}>Details</span>
                          </li>
                          {
                            finalResult?.isAuction&&
                            <li id="Mainbtn">
                            <span onClick={handleBtnClick}>Recent Bids</span>
                          </li>
                          }
                          
                          {/* <li id="Mainbtn1" className="">
                            <span onClick={handleBtnClick1}>History</span>
                          </li> */}
                        </ul>

                        <div className="de_tab_content">
                          {openMenu0 && (
                            <div className="tab-1 onStep fadeIn">
                              <div className="d-block mb-3">
                                <div className="mr40">
                                  <h6>Owner</h6>
                                  <div className="item_author">
                                    <div className="author_list_pp">
                                      <span>
                                        <img
                                          className="lazy"
                                          src={
                                            nft.author &&
                                            api.baseUrl + nft.author.avatar.url
                                          }
                                          alt=""
                                        />
                                        <i className="fa fa-check"></i>
                                      </span>
                                    </div>
                                    <div className="author_list_info">
                                      <span>
                                        {nft.author && nft.author.username}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {openMenu && (
                            <div className="tab-1 onStep fadeIn">
                              {nft.bids &&
                                nft.bids.map((bid, index) => (
                                  <div className="p_list" key={index}>
                                    <div className="p_list_pp">
                                      <span>
                                        <img
                                          className="lazy"
                                          src={
                                            api.baseUrl + bid.author.avatar.url
                                          }
                                          alt=""
                                        />
                                        <i className="fa fa-check"></i>
                                      </span>
                                    </div>
                                    <div className="p_list_info">
                                      Bid{" "}
                                      {bid.author.id === nft.author.id &&
                                        "accepted"}{" "}
                                      <b>{bid.value} ETH</b>
                                      <span>
                                        by <b>{bid.author.username}</b> at{" "}
                                        {moment(bid.created_at).format("L, LT")}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}

                          {openMenu1 && (
                            <div className="tab-2 onStep fadeIn">
                              {nft.history &&
                                nft.history.map((bid, index) => (
                                  <div className="p_list" key={index}>
                                    <div className="p_list_pp">
                                      <span>
                                        <img
                                          className="lazy"
                                          src={
                                            api.baseUrl + bid.author.avatar.url
                                          }
                                          alt=""
                                        />
                                        <i className="fa fa-check"></i>
                                      </span>
                                    </div>
                                    <div className="p_list_info">
                                      Bid{" "}
                                      {bid.author.id === nft.author.id &&
                                        "accepted"}{" "}
                                      <b>{bid.value} ETH</b>
                                      <span>
                                        by <b>{bid.author.username}</b> at{" "}
                                        {moment(bid.created_at).format("L, LT")}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}

                          {/* button for checkout */}
                          <div className="d-flex flex-row mt-5">
                            <button
                              className="btn-main lead mb-5 mr15"
                              onClick={() => {
                                setOpenCheckout(true);
                              }}
                            >
                              Buy Now
                            </button>
                            <button
                              className="btn-main btn2 lead mb-5"
                              onClick={() => setOpenCheckoutbid(true)}
                            >
                              Place Bid
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
           
              {openCheckout && (
                <div className="checkout">
                  <div className="maincheckout">
                    <button
                      className="btn-close"
                      onClick={() => setOpenCheckout(false)}
                    >
                      x
                    </button>
                    <div className="heading">
                      <h3>Checkout</h3>
                    </div>
                    <p>
                      You are about to purchase a{" "}
                      <span className="bold">
                        {finalResult?.name}
                        {"#" +
                          finalResult?.mintTransaction?.events?.Transfer
                            ?.returnValues?.tokenId}
                      </span>
                      <span className="bold">
                        {" "}
                        from {finalResult?.userId?.name}
                      </span>
                    </p>
                    {/* <div className="detailcheckout mt-4">
                      <div className="listcheckout">
                        <h6>
                          Enter quantity.
                          <span className="color">1 available</span>
                        </h6>
                        <input
                          type="text"
                          name="buy_now_qty"
                          id="buy_now_qty"
                          className="form-control"
                          value="1"
                        />
                      </div>
                    </div> */}
                    <div className="heading mt-3">
                      <p>Your balance</p>
                      <div className="subtotal">{balance}</div>
                    </div>
                    <div className="heading">
                      <p>Service fee {parseInt(finalResult?.admin?.adminFees)}%</p>
                      <div className="subtotal">{service * finalResult?.price} ETH</div>
                    </div>
                    <div className="heading">
                      <p>You will pay</p>
                      <div className="subtotal">{historyValue?.auctionPriceValue?
                     historyValue?.auctionPriceValue:
                      finalResult?.price} ETH</div>
                    </div>
                    <button className="btn-main lead mb-5">Checkout</button>
                  </div>
                </div>
              )}
              {openCheckoutbid && (
                <div className="checkout">
                  <div className="maincheckout">
                    <button
                      className="btn-close"
                      onClick={() => setOpenCheckoutbid(false)}
                    >
                      x
                    </button>
                    <div className="heading">
                      <h3>Place a Bid</h3>
                    </div>
                    <p>
                      You are about to purchase a{" "}
                      <span className="bold">
                        {finalResult?.name}
                        {"#" +
                          finalResult?.mintTransaction?.events?.Transfer
                            ?.returnValues?.tokenId}
                      </span>
                      <span className="bold">
                        {" "}
                        from {finalResult?.userId?.name}
                      </span>
                    </p>
                    <div className="detailcheckout mt-4">
                      <div className="listcheckout">
                        <h6>Your bid (ETH)</h6>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    {/* <div className="detailcheckout mt-3">
                      <div className="listcheckout">
                        <h6>
                          Enter quantity.
                          <span className="color">1 available</span>
                        </h6>
                        <input
                          type="text"
                          name="buy_now_qty"
                          id="buy_now_qty"
                          className="form-control"
                          value="1"
                        />
                      </div>
                    </div> */}
                    <div className="heading mt-3">
                      <p>Your balance</p>
                      <div className="subtotal">{balance}</div>
                    </div>
                    <div className="heading">
                      <p>Service fee {parseInt(finalResult?.admin?.adminFees)}%</p>
                      <div className="subtotal">{service * finalResult?.price} ETH</div>
                    </div>
                    <div className="heading">
                      <p>You will pay</p>
                      <div className="subtotal">{historyValue?.auctionPriceValue?
                      historyValue?.auctionPriceValue:
                      finalResult?.price} ETH</div>
                    </div>
                    <button className="btn-main lead mb-5">Checkout</button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <GlobalStyles />

              <section className="container">
                <div className="row mt-md-5 pt-md-4">
                  <div className="col-md-6 text-center">
                    <img
                     width='100%'
                      src={
                        finalResult?.ProductImage
                          ? finalResult?.ProductImage
                          : nft.preview_image &&
                            api.baseUrl + nft.preview_image.url
                      }
                      className="img-fluid img-rounded mb-sm-30"
                      alt="images"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="item_info">
                      {finalResult?.isAuction && finalResult?.auctionTime && !finalResult?.openBid  && (
                        <>
                          Auctions ends in
                          <div className="de_countdown">
                            <Clock deadline={finalResult?.auctionTime} />
                          </div>
                        </>
                      )}
                      <h2>{finalResult?.name && finalResult?.name}</h2>
                      <div className="item_info_counts">
                        <div className="item_info_type">
                          <i
                            className="fa fa-image"
                            style={{ color: "orange" }}
                          ></i>
                          {finalResult?.category}
                        </div>
                        <div   className="item_info_views">
                          <i
                            className="fa fa-eye"
                            style={{ color: "blue" }}
                          ></i>
                          {totalViews} Views
                        </div>
                        <div  onClick={handleHeart} className="item_info_like" style={{cursor:'pointer'}}>
                          {heartFill ? (
                            <i
                              className="fa fa-heart "
                              style={{ color: "red" }}
                              
                            ></i>
                          ) : (
                            <i
                              className="fa fa-heart "
                             
                            ></i>
                          )}
                          {likeCount} Likes
                        </div>
                      </div>

                     

                     
                      <div className="mr40">
                                  <h6>Owner</h6>
                                  <div className="item_author">
                                    <Link state={finalResult?.userId} to={`/Profile/${finalResult?.userId._id}`}>
                                    <div className="author_list_pp">
                                      <span>
                                        <img
                                          className="lazy"
                                          src={
                                            finalResult?.userId?.profilePicture
                                              ? finalResult?.userId
                                                  ?.profilePicture
                                              : nft.author &&
                                                api.baseUrl +
                                                  nft.author.avatar.url
                                          }
                                          alt=""
                                        />
                                        <i className="fa fa-check"></i>
                                      </span>
                                    </div>
                                    </Link>
                                    <div className="author_list_info">
                                      <span>
                                        {finalResult?.userId &&
                                          finalResult?.userId?.username}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                      <div className="spacer-40"></div>
                      <div > 
                              {
                                finalResult?.isAuction&&
                                finalResult?.price&&
                                <div>
                                  <h3>
                                    {
                                      historyValue?.auctionPriceValue?
                                      "Current Bid":"Min Bid"
                                    }
                                    </h3>
                                  <p style={{marginTop:'-20px'}}>
                                  {historyValue?.auctionPriceValue?
                      historyValue?.auctionPriceValue:
                      finalResult?.price} ETH (${historyValue?.auctionPriceValue?
                        historyValue?.auctionPriceValue:
                        finalResult?.price*parseFloat(finalResult?.admin?.amount)})
                                   </p>
                                </div>
                              }

                              {
                                true&&
                                !finalResult?.isAuction&&
                                <div>
                                   <h3>Price</h3>
                                   <p style={{marginTop:'-20px'}}>{finalResult?.price}ETH(${finalResult?.price*parseFloat(finalResult?.admin?.amount)})</p>
                                  </div>
                              }

                        </div>



                      <div className="spacer-10"></div>

                      <div className="d-flex flex-row mt-3">
                            {/* {finalResult?.isAuction ? 

                        isAlreadyBided?
                           <p>You already bid this asset </p>
                           : <button
                            className="btn-main btn2 lead mb-5"
                            onClick={() => setOpenCheckoutbid(true)}
                          >
                            Place Bid
                          </button>
                         :
                          <button
                            className="btn-main lead mb-5 mr15"
                            onClick={
                              () => setOpenCheckout(true)
                              // setOpenCheckout(true)
                            }
                          >
                            Buy Now
                          </button>
                        
                        } */}

                            {mintedUserBid ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "20px",
                                }}
                              >
                                <button
                                  className="btn-main btn2 lead mb-5"
                                  onClick={() => setOpenCheckoutbid(true)}
                                  style={{ boxShadow: "0px 0px 5px grey" }}
                                >
                                  Place Bid
                                </button>
                                {/* <button
                              className="btn-main lead mb-5 mr15"
                              onClick={handleCancel}
                            >
                              Cancel
                            </button> */}
                              </div>
                            ) : null}

                            {isMintedUser ? (
                              !mintedUserBid ? (
                                <button
                                  className="btn-main lead mb-5 mr15"
                                  onClick={handleTimeBidAuction}
                                >
                                  Start Auction
                                </button>
                              ) : null
                            ) : null}

                            {true ? (
                              claimButton  ?
                              timeDuration ?
                              // true?true?
                              (
                                <button
                                  className="btn-main lead mb-5 mr15"
                                  onClick={handleWithdraw}
                                >
                                  Claim
                                </button>
                              ) :<h4 style={{color:'purple'}}>The Auction is Still In-Progress...</h4>
                              : null
                            ) : null}

                            {false &&
                            // isNotMintedUser === true && finalResult?.isAuction === false 
                            // false&& 
                            (
                              <button
                                className="btn-main lead mb-5 mr15"
                                onClick={
                                  () => setOpenCheckout(true)
                                  // setOpenCheckout(true)
                                }
                              >
                                Buy Now
                              </button>
                            ) }
                          </div>                      
                      <div className="de_tab">
                        <ul className="de_nav">
                          <li id="Mainbtn0" className="active">
                            <span onClick={handleBtnClick0}>Details</span>
                          </li>
                          {
                            finalResult?.isAuction&&
                            <li id="Mainbtn">
                            <span onClick={handleBtnClick}>Recent Bids</span>
                          </li>
                          }

                          {/* <li id="Mainbtn1" className="">
                            <span onClick={handleBtnClick1}>History</span>
                          </li> */}
                        </ul>
                        {/* {
  true&&
  recentAuction&&
  <div>
   <ul className="activity-list">
   <li className="act_follow">
                                    <img className="lazy" src={lastBid?.userData?.profilePicture} alt={lastBid?.userData?.name}/>
                                    <div className="act_list_text">
                                        <h4>{lastBid?.userData?.name}</h4>
                                        Asset bidded by  <span className='color'>{lastBid?.transactionReceipt?.bidder}</span> <br/>
                                        Amount Spended: <span className='color'>{lastBid?.auctionPriceValue} ETH</span>
                                        <span className="act_list_date">
                                            {new Date().toLocaleString(lastBid?.date)}
                                        </span>
                                    </div>
                                </li>
   </ul>
  </div>
} */}

                        {isAuctionTable && (
                          <div>
                            {finalResult?._id ===
                              mintedUserData[0]?.productId?._id &&
                              finalResult?.isAuction && (
                                <ul className="activity-list">
                                  {mintedUserData[0]?.transaction?.reverse().map(
                                    (item, i) => {
                                      return (
                                        <li className="act_follow">
                                          <img
                                            className="lazy"
                                            src={item?.userData?.profilePicture}
                                            alt={item?.userData?.name}
                                          />
                                          <div className="act_list_text">
                                            <h4>{item?.userData?.name}</h4>
                                            Asset bidder:{" "}
                                            <span className="color">
                                              {item?.transactionReceipt?.bidder}
                                            </span>{" "}
                                            <br />
                                            Amount Spent:{" "}
                                            <span className="color">
                                              {item?.auctionPriceValue} ETH
                                            </span>
                                            <span className="act_list_date">
                                              {moment(item?.date).format(
                                                "llll"
                                              )}
                                            </span>
                                          </div>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              )}
                          </div>
                        )}
                        <div className="de_tab_content">
                          {openMenu0 && (
                            <div className="tab-1 onStep fadeIn">
                              <div className="d-block mb-3">
                                <div className="mr40">
                                  {/* <h6>Owner</h6> */}
                                  {/* <div className="item_author">
                                    <div className="author_list_pp">
                                      <span>
                                        <img
                                          className="lazy"
                                          src={
                                            finalResult?.userId?.profilePicture
                                              ? finalResult?.userId
                                                  ?.profilePicture
                                              : nft.author &&
                                                api.baseUrl +
                                                  nft.author.avatar.url
                                          }
                                          alt=""
                                        />
                                        <i className="fa fa-check"></i>
                                      </span>
                                    </div>
                                    <div className="author_list_info">
                                      <span>
                                        {finalResult?.userId &&
                                          finalResult?.userId?.name}
                                      </span>
                                    </div>
                                  </div> */}
                                </div>
                                <div style={{ marginTop: "30px" }}>
                                  <p>{finalResult?.description}</p>
                                </div>
                              </div>
                            </div>
                          )}
                          
                          {
                            true&&
                          openMenu && (
                            <div className="tab-1 onStep fadeIn">
                               <div>
   <ul className="activity-list">
   <li className="act_follow">
                                    <img className="lazy" src={bidedValue?.userData?.profilePicture} alt={bidedValue?.userData?.name}/>
                                    <div className="act_list_text">
                                        <h4>{bidedValue?.userData?.name}</h4>
                                        Asset bidded by  <span className='color'>{bidedValue?.transactionReceipt?.bidder}</span> <br/>
                                        Amount Spended: <span className='color'>{bidedValue?.auctionPriceValue} ETH</span>
                                        <span className="act_list_date">
                                              {moment(bidedValue.date).format(
                                                "llll"
                                              )}
                                            </span>
                                    </div>
                                </li>
   </ul>
  </div>
                             
                            </div>
                          )}

                          {openMenu1 && (
                            <div className="tab-2 onStep fadeIn">
                              {nft.history &&
                                nft.history.map((bid, index) => (
                                  <div className="p_list" key={index}>
                                    <div className="p_list_pp">
                                      <span>
                                        <img
                                          className="lazy"
                                          src={
                                            api.baseUrl + bid.author.avatar.url
                                          }
                                          alt=""
                                        />
                                        <i className="fa fa-check"></i>
                                      </span>
                                    </div>
                                    <div className="p_list_info">
                                      Bid{" "}
                                      {bid.author.id === nft.author.id &&
                                        "accepted"}{" "}
                                      <b>{bid.value} ETH</b>
                                      <span>
                                        by <b>{bid.author.username}</b> at{" "}
                                        {moment(bid.created_at).format("L, LT")}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}

                          {/* button for checkout */}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              
              {openCheckout && (
                <div className="checkout">
                  <div className="maincheckout">
                    <button
                      className="btn-close"
                      onClick={() => setOpenCheckout(false)}
                    >
                      x
                    </button>
                    <div className="heading">
                      <h3>Checkout</h3>
                    </div>
                    <p>
                      You are about to purchase a{" "}
                      <span className="bold">
                        {finalResult?.name}
                        {"#" +
                          finalResult?.mintTransaction?.events?.Transfer
                            ?.returnValues?.tokenId}
                      </span>
                      <span className="bold">
                        {" "}
                        from {finalResult?.userId?.name}{" "}
                      </span>
                    </p>
                    {/* <div className="detailcheckout mt-4">
                      <div className="listcheckout">
                        <h6>
                          Enter quantity.
                          <span className="color">1 available</span>
                        </h6>
                        <input
                          type="text"
                          name="buy_now_qty"
                          id="buy_now_qty"
                          className="form-control"
                          value="1"
                        />
                      </div>
                    </div> */}
                    <div className="heading mt-3">
                      <p>Your balance</p>
                      <div className="subtotal">{balance}</div>
                    </div>
                    <div className="heading">
                      <p>Service fee {parseInt(finalResult?.admin?.adminFees)}%</p>
                      <div className="subtotal">{service * finalResult?.price} ETH</div>
                    </div>
                    <div className="heading">
                      <p>You will pay</p>
                      <div className="subtotal">{
                      historyValue?.auctionPriceValue?
                      historyValue?.auctionPriceValue:
                      finalResult?.price} ETH</div>
                    </div>

                    {insufficientFunds ? (
                      <p style={{ textAlign: "center", color: "red" }}>
                        ! Insufficient Funds
                      </p>
                    ) : (
                      <button
                        className="btn-main lead mb-5"
                        onClick={handleSell}
                      >
                        Checkout
                      </button>
                    )}
                  </div>
                </div>
              )}
              {openCheckoutbid && (
                <div className="checkout">
                  <div className="maincheckout">
                    <button
                      className="btn-close"
                      onClick={() => setOpenCheckoutbid(false)}
                    >
                      x
                    </button>
                    <div className="heading">
                      <h3>Place a Bid</h3>
                    </div>
                    <p>
                      You are about to purchase a{" "}
                      <span className="bold">
                        {finalResult?.name}
                        {"#" +
                          finalResult?.mintTransaction?.events?.Transfer
                            ?.returnValues?.tokenId}
                      </span>
                      <span className="bold">
                        {" "}
                        from {finalResult?.userId?.name}
                      </span>
                    </p>
                    <div className="detailcheckout mt-4">
                      <div className="listcheckout">
                        <h6>Your bid (ETH)</h6>
                        <input
                          onChange={(e) => {
                            setAuctionPriceValue(e.target.value);
                            setAuctionWarning(false);
                            setAuctionPriceWarning(false);
                          }}
                          type="number"
                          className="form-control"
                        />
                        {auctionWarning ? (
                          <p style={{ color: "red", textAlign: "center" }}>
                            *Please enter this field
                          </p>
                        ) : null}
                        {auctionPriceWarning ? (
                          <p style={{ color: "red", textAlign: "center" }}>
                            *This Price/Value must be greater than bid value
                          </p>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="detailcheckout mt-3">
                      <div className="listcheckout">
                        <h6>
                          Enter quantity.
                          <span className="color">1 available</span>
                        </h6>
                        <input
                          type="text"
                          name="buy_now_qty"
                          id="buy_now_qty"
                          className="form-control"
                          value="1"
                        />
                      </div>
                    </div> */}
                    <div className="heading mt-3">
                      <p>Your balance</p>
                      <div className="subtotal">{balance}</div>
                    </div>
                    <div className="heading">
                      <p>Service fee {parseInt(finalResult?.admin?.adminFees)}%</p>
                      <div className="subtotal">{service * finalResult?.price} ETH</div>
                    </div>
                    <div className="heading">
                      <p>Minimum bid amount</p>
                      <div className="subtotal">{historyValue?.auctionPriceValue?
                      historyValue?.auctionPriceValue:
                      finalResult?.price} ETH</div>
                    </div>
                    {insufficientFunds ? (
                      <p style={{ textAlign: "center", color: "red" }}>
                        ! Insufficient Funds
                      </p>
                    ) : (
                      <button
                        className="btn-main lead mb-5"
                        onClick={handleAuctionSell}
                      >
                        Checkout
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <Footer />
        </>
      )}
    </>
  );
};

export default memo(ItemDetailRedux);
