import React, { memo, useEffect, useState } from 'react';
import styled from "styled-components";
import { navigate } from '@reach/router';
import api from '../../core/api';
import { Link } from '@reach/router';
import Avatar from 'react-avatar';
// import { Axios } from '../../core/axios';
import Axios from '../../api-instance'
import ethereumImage from '../../assets/ethereum.webp'
import polygonImage from '../../assets/polygon.webp'
import binanceImage from '../../assets/binance.webp'
import VerifiedIcon from '@mui/icons-material/Verified';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({ setNftAddress, nftTime, handleView, userData, isApproved, nft,datas,className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad }) => {




    // if(nft?.favourite){
    const [likedOrNot, setLikedOrNot] = useState(false)
    const a = nft?.favourite?.filter((item, i) => {
        if (item === userData?._id) {
            setLikedOrNot(true)
        }
    })
    // console.log(a);
    // }

    const [checkTimeOrNot, setCheckTimeOrNot] = useState('')
    const [auctionTime, setAuctionTime] = useState(false)
    const [dollar, setDollar] = useState('')

    useEffect(() => {




        const getData = async () => {

            try {
                const { data } = await Axios.get(`/admin`)
                nft.admin = data?.data[0]
                setDollar(data?.data[0])



            } catch (error) {
                console.log(error);
            }
        }

        getData()
    }, [])


    const handleAddress = () => {
        setNftAddress(nft?.mintTransaction?.transactionHash)
        
    }


    console.log(datas, 'name');
    return (
       
           
        
        <img src={nft?.ProductImage} className="lazy img-fluid" alt="" />


    );
};

export default memo(NftCard);