import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Clock from "./Clock";
import { carouselNew5 } from "./constants";
import * as selectors from "../../store/selectors";
import { fetchNftsBreakdown } from "../../store/actions/thunks";
import api from "../../core/api";
import Axios from "../../api-instance";
import constants from "../../constants";
import {Link} from '@reach/router'

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const CarouselNewRedux = () => {
  const dispatch = useDispatch();
  const nftsState = useSelector(selectors.nftBreakdownState);
  const nfts = nftsState.data ? nftsState.data : [];

  const [height, setHeight] = useState(0);

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };
  const [nftData, setNftData] = useState([]);
  const [auctionData, setAuctionData] = useState([])

  useEffect(() => {
    dispatch(fetchNftsBreakdown());

    const getData = async () => {
      try {
        const { data } = await Axios.get(`/product`);
        const auctionData = await Axios.get('/auction')
        setAuctionData(auctionData?.data?.data)
        const newCollectionData = data?.data?.filter((item, i) => {
          if (item?.isSold === false && item.isVerified === true) {
            // return new Date(item?.createdAt).toDateString() === new Date().toDateString();
            
         
            if (
                new Date().getDate()-constants.newCollectionDate <=
                new Date(item.updatedAt).getDate()
            ){
                return item
            }
          }
        });
        setNftData(newCollectionData);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [dispatch]);



  return (
    <div className="nft">
   
<Slider {...carouselNew5}>
          {nftData &&
            nftData.map((nft, index) => {
             if(!nft?.isAuction){
              return (
                <Link state={nft} to={`/ItemDetail/${nft.mintTransaction?.transactionHash}`} >
                <div className="itm" key={index}>
                  <div className="d-item">
                    <div className="nft__item">
                      {
                         auctionData?.map((item,i)=>{
                           if(nft?._id===item?.productId){
                             if(nft?.isAuction){
                               return(
                                <div className="de_countdown">
                                <Clock deadline={item?.expireDate} />
                              </div>
                               )
                             }
                           }
                         })
                      }
                      
                      <div className="author_list_pp">
                        <span onClick={() => window.open("/home1", "_self")}>
                          <img
                            className="lazy"
                            src={nft?.userId?.profilePicture}
                            alt=""
                          />
                          <i className="fa fa-check"></i>
                        </span>
                      </div>
                      <div
                        className="nft__item_wrap"
                        style={{ height: `${height}px` }}
                      >
                        <Outer>
                          <span>
                            <img
                              src={nft?.ProductImage && nft?.ProductImage}
                              className="lazy nft__item_preview"
                              onLoad={onImgLoad}
                              alt=""
                            />
                          </span>
                        </Outer>
                      </div>
                      <div className="mt-4"></div>
                      <div className="nft__item_info">

                        <span onClick={() => window.open("/#", "_self")}>
                          <h4>{nft?.name}</h4>
                        </span>
                        <div className="nft__item_price">
                          {nft?.price} ETH
                          {/* <span>{nft?.bid}/{nft?.max_bid}</span> */}
                        </div>

                      


                        {
                          
  
                          nft?.isAuction?
                     <div className="nft__item_action">
                            <span
                              onClick={() =>
                                window.open(nft?.bid_link, "_self")
                              }
                            >
                              Place a bid
                            </span>
                          </div>
                          :
                          <div className="nft__item_action">
                          <span
                            onClick={() =>
                              window.open(nft?.bid_link, "_self")
                            }
                          >
                            Buy Now
                          </span>
                        </div>
                        }

                        <div className="nft__item_like">
                          <i className="fa fa-heart"></i>
                          <span>{nft?.likes}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </Link>
              );}
            })}
             
        </Slider>

    </div>
  );
};

export default memo(CarouselNewRedux);










// {nftData?.length === 0 ? (
//   <h4>No data found.....</h4>
// ) : nftData?.length < 5 ? (
//     <div style={{display:'flex',alignItems:'center'}}>
//         {
//             nftData &&
//             nftData.map((nft, index) => {
//               return (
//                 <div className="itm" key={index} style={{ width: "20%" }}>
//                   <div className="d-item">
//                     <div className="nft__item">
//                       {nft?.deadline && (
//                         <div className="de_countdown">
//                           <Clock deadline={nft?.deadline} />
//                         </div>
//                       )}
//                       <div className="author_list_pp">
//                         <span onClick={() => window.open("/home1", "_self")}>
//                           <img
//                             className="lazy"
//                             src={nft?.userId?.profilePicture}
//                             alt=""
//                           />
//                           <i className="fa fa-check"></i>
//                         </span>
//                       </div>
//                       <div
//                         className="nft__item_wrap"
//                         style={{ height: `${height}px` }}
//                       >
//                         <Outer>
//                           <span>
//                             <img
//                               src={nft?.ProductImage && nft?.ProductImage}
//                               className="lazy nft__item_preview"
//                               onLoad={onImgLoad}
//                               alt=""
//                             />
//                           </span>
//                         </Outer>
//                       </div>
//                       <div className="nft__item_info">
//                         <span onClick={() => window.open("/#", "_self")}>
//                           <h4>{nft?.title}</h4>
//                         </span>
//                         <div className="nft__item_price">
//                           {nft?.price} ETH
//                           {/* <span>{nft?.bid}/{nft?.max_bid}</span> */}
//                         </div>
    
//                         {nft?.status === "on_auction" ? (
//                           <div className="nft__item_action">
//                             <span
//                               onClick={() => window.open(nft?.bid_link, "_self")}
//                             >
//                               Place a bid
//                             </span>
//                           </div>
//                         ) : null}
    
//                         {nft?.status === "buy_now" ? (
//                           <div className="nft__item_action">
//                             <span
//                               onClick={() => window.open(nft?.bid_link, "_self")}
//                             >
//                               Buy Now
//                             </span>
//                           </div>
//                         ) : null}
    
//                         <div className="nft__item_like">
//                           <i className="fa fa-heart"></i>
//                           <span>{nft?.likes}</span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               );
//             })
//         }
//   </div>
// ) : (
  
// )}