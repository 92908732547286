import React, { Component, useState, useEffect, useCallback } from "react";
import Clock from "../components/Clock";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import '../pages/style.css'
import contractABI from "../../web3/metamaskConnection/ABI/contractABI.json";
import contractAddress from "../../web3/metamaskConnection/contractAddress/contractAddress";
import timeBidContractAddress from "../../web3/metamaskConnection/contractAddress/timeBidContractAddress";
import timeBidABI from "../../web3/metamaskConnection/ABI/timeBidABI.json";
import newAuctionContractAddress from "../../web3/metamaskConnection/contractAddress/newAuctionContractAddress";
import newAuctionContractABI from "../../web3/metamaskConnection/ABI/newAuctionABI.json";
import FormData from "form-data";
import axios from "axios";
import { pinJSONToIPFS } from "../../web3/metamaskConnection/pinata/pinata";
import constant from "../../constants";
import Axios from "../../api-instance";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../assets/loader/giphy.gif";
import NormalLoader from '../../assets/loader/load.gif'
import TopFilterBar from "../components/TopFilterBar";
import TopStatus from "../components/TopStatus";
import { useDispatch } from "react-redux";
import {
  filterCategories,
  filterStatus,
  filterItemsType,
  filterNftTitle,
} from "../../store/actions";
import newContractAddress from "../../web3/metamaskConnection/contractAddress/newAuctionContractAddress";
import newContractABI from "../../web3/metamaskConnection/ABI/newAuctionABI.json";
import cancel from "../../assets/loader/cancel.webp";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import buyContractAddress from "../../web3/metamaskConnection/contractAddress/buyContractAddress";
import buyContractABI from '../../web3/metamaskConnection/ABI/buyABI.json'
import TreeItem from "@mui/lab/TreeItem";
import tick from '../../assets/loader/tick.webp'
import { useLocation } from "@reach/router";
import Select from 'react-select';
var ethers = require("ethers");

// alchemyUrl for polygon
// const alchemyKey ="https://polygon-mumbai.g.alchemy.com/v2/qlt3u9DeNuef36KsQWwzALRg1x23aBeE";

const alchemyKey =
  "https://eth-mainnet.g.alchemy.com/v2/E11IswOPCxAsDPDlRkg7KV-RPwkjCafv";

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const GlobalStyles = createGlobalStyle` 
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;
const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#fff",
    color: "#333",
    borderRadius: state.isFocused ? "0" : 0,
    "&:hover": {
      background: "#eee",
    }
  }),
  menu: base => ({
    ...base,
    borderRadius: 0,
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    padding: 0
  }),
  control: (base, state) => ({
    ...base,
    padding: 2
  })
};
const GlobalStylesDark = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid #ff343f;
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: #ff343f;
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 #ff343f;
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: rgba(33, 36, 40, .9);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{
    color: #fff !important;
    background: #ff343f;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{ 
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: #ff343f;
  }
  @media only screen and (max-width: 1199px) { 
    .navbar {
      background: #212428;
    }
  }
`;

const Create = () => {
  var bodyFormData = new FormData();
  const location = useLocation();
  const finalResult = location.state;

  const [isAuctionContract, setIsAuctionContract] = useState(false);

  const [openBidValues, setOpenBidValues] = useState(1);
  const [isOpenBid, setIsOpenBid] = useState(false);
  const [removePrice, setRemovePrice] = useState(false);

  const handleShow = () => {
    document.getElementById("tab_opt_1").classList.add("show");
    document.getElementById("tab_opt_1").classList.remove("hide");
    document.getElementById("tab_opt_2").classList.remove("show");
    document.getElementById("btn1").classList.add("active");
    document.getElementById("btn2").classList.remove("active");
    document.getElementById("btn3").classList.remove("active");
    setIsAuctionContract(false);
    setIsOpenBid(false);
    setRemovePrice(false);
  };
  const handleShow1 = () => {
    document.getElementById("tab_opt_1").classList.add("hide");
    document.getElementById("tab_opt_1").classList.remove("show");
    document.getElementById("tab_opt_2").classList.add("show");
    document.getElementById("btn1").classList.remove("active");
    document.getElementById("btn2").classList.add("active");
    document.getElementById("btn3").classList.remove("active");
    setIsAuctionContract(true);
    setIsOpenBid(false);
    setRemovePrice(true);
  };
  const handleShow2 = () => {
    document.getElementById("tab_opt_1").classList.add("hide");
    document.getElementById("tab_opt_1").classList.remove("show");
    document.getElementById("tab_opt_2").classList.add("show");
    document.getElementById("btn1").classList.remove("active");
    document.getElementById("btn3").classList.add("active");
    document.getElementById("btn2").classList.remove("active");
    setIsAuctionContract(true);
    setOpenBidValues(1000);
    setIsOpenBid(true);
    setRemovePrice(true);
  };

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [sendImage, setSendImage] = useState(null);
  //warning for undefined field in minting
  const [imageWarning, setImageWarning] = useState(false);
  const [nameWarning, setNameWarning] = useState(false);
  const [descriptionWarning, setDescriptionWarning] = useState(false);
  const [priceWarning, setPriceWarning] = useState(false);
  const [categoryWarning, setCategoryWarning] = useState(false);
  const [minBidValueWarning, setMinBidValueWarning] = useState(false);
  const [auctionFromDateWarning, setAuctionFromDateWarning] = useState(false);
  const [auctionToDateWarning, setAuctionToDateWarning] = useState(false);
  const [startAuction, setStartAuction] = useState(false);
  const [approveAuction, setApproveAuction] = useState(false);
  const [biddingApproved, setBiddingApproved] = useState(false);

  //switching chain network using chain id

  const onChangePicture = async (e) => {
    try {
      setImageWarning(false);
      if (e.target.files[0]) {
        setPicture(e.target.files[0]);
        setSendImage(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }

      //   const ipfs = await IPFS.create();

      //   const ab = await ipfs.add(
      //     {
      //       path: `${e.target.files[0].name}`,
      //       content: e.target.files[0],
      //     },
      //     { wrapWithDirectory: true }
      //   );

      //  console.log(ab);
      //   const url = `https://gateway.pinata.cloud/ipfs/${ab.cid._baseCache.get(
      //     "z"
      //   )}/${e.target.files[0].name}`;
      //   setPicture(url)
    } catch (error) {
      console.log(error);
    }
  };

  const [formContent, setFormContent] = useState({
    item_image: picture,
    item_title: null,
    item_desc: null,
    item_price: null,
    bid_starting_date: null,
    bid_expiration_date: null,
    item_price_bid: null,
    auctionTimeline: null,
  });

  useEffect(() => {
    setFormContent({
      item_price: finalResult?.price
    })
  }, [])

  const [userData, setUserData] = useState(null);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const category = await Axios.get(`/category?isDeleted=${false}`);
        setCategoryData(category?.data?.data);
        const token = window.localStorage.getItem("token");
        if (token) {
          const { data } = await Axios.get(`/auth/getdata/${token}`);
          setUserData(data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, []);

  const [mintingLoader, setMintingLoader] = React.useState(false);
  const [mintingApproved, setMintingApproved] = useState(false);
  const [mintedHash, setMintedHash] = useState(null);

  const [transactionHash, setTransactionHash] = useState("");
  const [transactionFrom, setTransactionFrom] = useState("");
  const [transactionTo, setTransactionTo] = useState("");
  const [transactionBlockNumber, setTransactionBlockNumber] = useState("");
  const [transactionTokenID, setTransactionTokenID] = useState("");
  const [transactionGasUsed, setTransactionGasUsed] = useState("");
  const [ethPriceValue, setEthPriceValue] = useState("");
  const [usdPriceValue, setUsdPriceValue] = useState("");
  const [isEthValue, setIsEthValue] = useState(true);
  const [ethPrice, setEthPrice] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const [equalPrice, setEqualPrice] = useState(false);
  const [auctionTimelineWarning, setAuctionTimelineWarning] = useState(false);
  const [auctionTimelineProperWarning, setAuctionTimelineProperWarning] = useState(false);
  const [mintingApproveBox, setMintingApproveBox] = useState(false)
  const [mintingStart, setMintingStart] = useState(false)
  const [approveStart, setApproveStart] = useState(false)
  const [makeMarketStart, setMakeMarketStart] = useState(false)
  const [errorHandling, setErrorHandling] = useState(false);
  const [rejectWarning, setRejectWarning] = useState(null);

  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [isCategory, setIsCategory] = useState(false)
  const [subCategoryData, setSubCategoryData] = useState([])
  const [mainCategoryNewValue, setMainCategoryNewValue] = useState('')
  const [subCategoryNewValue, setSubCategoryNewValue] = useState('')

  const dispatch = useDispatch();
  const handleCategory = useCallback(
    (option) => {
      setIsCategory(false)
      setMainCategoryNewValue(option?.value)
      if (option?.subCategory?.length > 0) {
        const options = option?.subCategory?.map((item, i) => {
          return {
            value: item,
            label: item
          }
        })
        setSubCategoryData(options)
        setIsCategory(true)
      }
      setCategory(option);
      const { value } = option;
      dispatch(filterCategories({ value, singleSelect: true }));
    },
    [dispatch]
  );

  const handleSubCategory = useCallback(
    (option) => {
      setCategory(option);
      setSubCategoryNewValue(option?.value)
      const { value } = option;
      dispatch(filterCategories({ value, singleSelect: true }));
    },
    [dispatch]
  );

  const handleStatus = useCallback(
    (option) => {
      const { value } = option;
      setStatus(option);
      dispatch(filterStatus({ value, singleSelect: true }));
    },
    [dispatch]
  );



  const [categoryValue, setCategoryValue] = useState('')

  const [isCategoryOpen, setIsCategoryOpen] = useState(false)
  const [categoryMain, setCategoryMain] = useState('')
  const [mainCategoryValue, setMainCategoryValue] = useState('')

  const handleCategoryMain = (item) => {
    setCategoryMain(item)
  }
  const handleCategoryData = async (item, data) => {
    try {
      setCategoryValue(data)
      setMainCategoryValue(item?.categoryName)
      setIsCategoryOpen(false)
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryDatas = async (item) => {
    try {
      setCategoryValue(item?.categoryName)
      setMainCategoryValue('')
      setIsCategoryOpen(false)
    } catch (error) {
      console.log(error);
    }
  };



  const toggleCategory = () => {
    if (isCategoryOpen) {
      setIsCategoryOpen(false)
    } else {
      setIsCategoryOpen(true)
    }
  }

  const handleContract = async () => {
    let mainValue;
    let subValue;
    if (mainCategoryNewValue) {
      mainValue = mainCategoryNewValue;
      subValue = subCategoryNewValue
    }
    else if (!mainCategoryNewValue) {
      mainValue = categoryValue
    }

    if (subCategoryNewValue === undefined) {
      subValue = mainCategoryNewValue
    }

    if (!removePrice) {
      if (formContent.item_price === null) {
        return setPriceWarning(true);
      }
    }

    if (isAuctionContract) {
      //  if(formContent.bid_expiration_date===null){
      //   return setAuctionToDateWarning(true)
      //  }
      //  if(formContent.bid_starting_date===null){
      //   return setAuctionFromDateWarning(true)
      //  }
      //  if(formContent.item_price_bid===null){
      //   return setMinBidValueWarning(true)
      //  }
      //  if(formContent?.item_price !==formContent?.item_price_bid){
      //   return setEqualPrice(true)
      // }

      if (isAuctionContract) {
        if (!isOpenBid) {
          if (formContent?.auctionTimeline === null) {
            return setAuctionTimelineWarning(true);
          }

          if (formContent?.auctionTimeline <= 0) {
            return setAuctionTimelineProperWarning(true);
          }
        }
      }
    }

    // setMintingLoader(true);
    document.body.style.overflow = "hidden";

    // const accounts = await window.ethereum.request({
    //   method: "wallet_switchEthereumChain",
    //   params:[{ chainId: '0x4' }]
    // });

    const account = await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [{ chainId: "0x137" }],
    });



    try {
      const contractInstance = new web3.eth.Contract(
        contractABI,
        contractAddress
      );
      const buyContractInstance = new web3.eth.Contract(
        buyContractABI,
        buyContractAddress
      );





      if (isAuctionContract) {


        if (isAuctionContract) {
          if (!isOpenBid) {
            if (formContent?.auctionTimeline === null) {
              return setAuctionTimelineWarning(true);
            }

            if (formContent?.auctionTimeline <= 0) {
              return setAuctionTimelineProperWarning(true);
            }
          }
        }

        const account = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [{ chainId: "0x137" }],
        });

        bodyFormData.append("name", formContent.item_title);
        bodyFormData.append("description", formContent.item_desc);
        if (removePrice) {
          bodyFormData.append("price", formContent.item_price_bid);
        } else {
          bodyFormData.append("price", formContent.item_price);
        }

        bodyFormData.append("ProductImage", sendImage);
        bodyFormData.append("userId", userData?._id);
        bodyFormData.append("creator", userData?._id);
        bodyFormData.append("category", mainValue);
        bodyFormData.append('subCategory', subValue)
        //  bodyFormData?.append('status',status?.value)
        bodyFormData?.append("status", "buy_now");
        bodyFormData.append("auctionMinBid", formContent.item_price_bid);
        bodyFormData.append("isAuction", true);

        if (!isOpenBid) {
          console.log("timebid");
          const auctionInstance = await new web3.eth.Contract(
            timeBidABI,
            timeBidContractAddress
          );
          const mintContractInstance = await new web3.eth.Contract(
            contractABI,
            contractAddress
          );
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            chainId: "0x137",
          });

          setBiddingApproved(true);
          const approveTokenTransactionParameter = {
            to: newAuctionContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
            gasLimit: '1000000'
          };


          const approveToken = await mintContractInstance.methods
            .approve(
              timeBidContractAddress,
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId
            )
            .send(approveTokenTransactionParameter);

          setStartAuction(true);
          const startTokenTransactionParameter = {
            to: timeBidContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
          };
          const auctionStarting = await auctionInstance.methods
            .createReserveAuction(
              buyContractAddress,
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
              ethers.utils.parseEther(`${finalResult?.price}`)._hex,
              formContent?.auctionTimeline
            )
            .send(startTokenTransactionParameter);

          setApproveAuction(true);

          // if(auctionStarting){
          //  const data= await auctionInstance.methods.start().call()
          //  console.log(data);
          // }

          //updating the auction start and end date for auction

          if (auctionStarting) {
            const { data } = await Axios.put("/auction", {
              startDate: new Date(),
              mintId:
                finalResult?.mintTransaction?.events?.Transfer?.returnValues
                  ?.tokenId,
              auctionId:
                auctionStarting?.events?.ReserveAuctionCreated?.returnValues
                  ?.auctionId,
              isActive: true,
            });

            const setTime = await Axios.put("/product", {
              _id: finalResult?._id,
              startDate: new Date(),
              mintId:
                finalResult?.mintTransaction?.events?.Transfer?.returnValues
                  ?.tokenId,
            });
            const sendMailToSender = await Axios.post('/product/sendMailFirst?title=startAuction', {
              "fromID": userData?._id,
              "assetID": setTime?.data?.data?._id,
              "auctionID": data?.data?._id
            })
            setMintingLoader(false);
            setBiddingApproved(false);
            window.location.reload();
          }
        }

        if (isOpenBid) {

          const auctionInstance = await new web3.eth.Contract(
            newAuctionContractABI,
            newAuctionContractAddress
          );

          const mintContractInstance = await new web3.eth.Contract(
            contractABI,
            contractAddress
          );

          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            chainId: "0x137",
          });
          setBiddingApproved(true);

          const approveTokenTransactionParameter = {
            to: newAuctionContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
          };

          const approveToken = await mintContractInstance.methods
            .approve(
              newAuctionContractAddress,
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId
            )
            .send(approveTokenTransactionParameter);

          setStartAuction(true);

          const startTokenTransactionParameter = {
            to: newAuctionContractAddress,
            from: accounts[0],
            gasPrice: `${await web3.eth.getGasPrice()}`,
          };

          const auctionStarting = await auctionInstance.methods
            .createReserveAuction(
              finalResult?.mintTransaction?.to,
              finalResult?.mintTransaction?.events?.Transfer?.returnValues
                ?.tokenId,
              ethers.utils.parseEther(`${finalResult?.price}`)._hex
            )
            .send(startTokenTransactionParameter);

          setApproveAuction(true);

          // if(auctionStarting){
          //  const data= await auctionInstance.methods.start().call()
          //  console.log(data);
          // }

          //updating the auction start and end date for auction

          if (auctionStarting) {
            const { data } = await Axios.put("/auction", {
              startDate: new Date(),
              mintId:
                finalResult?.mintTransaction?.events?.Transfer?.returnValues
                  ?.tokenId,
              auctionId:
                auctionStarting?.events?.ReserveAuctionCreated?.returnValues
                  ?.auctionId,
              isActive: true,
            });

            const setTime = await Axios.put("/product", {
              _id: finalResult?._id,
              startDate: new Date(),
              mintId:
                finalResult?.mintTransaction?.events?.Transfer?.returnValues
                  ?.tokenId,
            });

            const sendMailToSender = await Axios.post('/product/sendMailFirst?title=startAuction', {
              "fromID": userData?._id,
              "assetID": setTime?.data?.data?._id,
              "auctionID": data?.data?._id
            })
            setMintingLoader(false);
            setBiddingApproved(false);
            window.location.reload();
            const datas = await Axios.post(
              `/auction`,
              {
                mintId:
                  finalResult?.tokenId,
                productId: finalResult?._id,
                userId: finalResult?.userId?._id,
                timeDuration: openBidValues,
                openBid: true,
                timeBid: false,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials": "true",
                  "Access-Control-Allow-Methods":
                    "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                },
              }
            );
          }

        }


      } else if (!isAuctionContract) {




        const nonce = await web3.eth.getTransactionCount(account[0], "latest"); // nonce starts counting from 0
        const transactionParameters = {
          to: buyContractAddress, // Required except during contract publications.
          from: account[0], // must match user's active address.
          // nonce: `${nonce}`, // ignored by MetaMask
          gasPrice: `${await web3.eth.getGasPrice()}`,
          gasLimit: "0x800009",
          // customizable by user during MetaMask confirmation. 
          value: ethers.utils.parseEther(`${formContent?.item_price}`)._hex,
          // value:'0xa',
        };


        setMintingApproveBox(true)

        const approveTokenTransactionParameter = {
          to: buyContractAddress,
          from: account[0],
          gasPrice: `${await web3.eth.getGasPrice()}`,
          gasLimit: '800009'
        };


        const approveToken = await contractInstance.methods
          .approve(
            buyContractAddress,
            finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId
          )
          .send(approveTokenTransactionParameter);


        if (approveToken) {
          setMintingStart(true)


          const transactionGetID = await buyContractInstance.methods
            .makeMarketItem(
              contractAddress,
              finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId,
              ethers.utils.parseEther(`${formContent?.item_price}`)._hex
            )
            .send(transactionParameters)




          if (transactionGetID) {

            setApproveStart(true)


            const { data } = await Axios.put('/product',
              { "_id": finalResult._id, "userId": userData?._id, "isSold": "false", "makeMarketTransaction": transactionGetID, "price": formContent?.item_price, 'category': mainValue, 'subCategory': subValue },
              { "Content-type": "application/json" }
            )

            const sendMail = await Axios.post('/product/sendMailFirst?title=mint', {
              "fromID": userData?._id,
              "assetID": data?.data?.data?._id
            })



            setMintingApproved(true);
            setMintingLoader(false)
            setTransactionHash(transactionGetID?.transactionHash);
            setTransactionFrom(transactionGetID?.from);
            setTransactionTo(transactionGetID?.to);
            setTransactionBlockNumber(transactionGetID?.blockNumber);
            setTransactionGasUsed(transactionGetID?.gasUsed);
            setTransactionTokenID(
              transactionGetID?.events?.Transfer?.returnValues?.tokenId
            );

            window.document.body.style.overflow = "hidden";
          }


        }









        // 0x13881


      }
    } catch (error) {
      if (
        error?.code === 4001 &&
        error.message ===
        "MetaMask Tx Signature: User denied transaction signature."
      ) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Metamask transaction user denied signature....");
      }
      if (error.code === -32603) {
        setTimeout(() => {
          setErrorHandling(false);
          window.location.reload();
        }, 3000);
        setErrorHandling(true);
        setRejectWarning("Transaction failed.....");
      }
      setTimeout(() => {
        setErrorHandling(false);
        window.location.reload();
      }, 3000);
      setErrorHandling(true);
      setRejectWarning(error?.message);

    }
  };

  const [isDark, setIsDark] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("mode") === "dark") {
      setIsDark(true);
    } else if (localStorage.getItem("mode") === "light") {
      setIsDark(false);
    }
  }, []);

  const handleEthSwitch = (e) => {
    if (e.target.value === "USD") {
      setIsEthValue(false);
    } else {
      setIsEthValue(true);
    }
  };

  const handleChange = (e) => {
    setNameWarning(false);
    setDescriptionWarning(false);
    setCategoryWarning(false);
    setPriceWarning(false);
    setAuctionFromDateWarning(false);
    setAuctionToDateWarning(false);
    setMinBidValueWarning(false);
    setEqualPrice(false);
    setAuctionTimelineProperWarning(false);
    setAuctionTimelineWarning(false);
    getUsdValue();

    const { name, value } = e.target;
    setFormContent((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (e.target.name === "item_price") {
      if (isEthValue) {
        getUsdValue(e?.target?.value);
      } else {
        getEthValue(e?.target?.value);
      }
    }
  };

  const getUsdValue = async (price) => {
    const { data } = await axios.post(
      "https://min-api.cryptocompare.com/data/price",
      {
        fsym: "ETH",
        tsyms: "USD",
        sign: true,
      },
      { "Content-type": "Application/json" }
    );

    await setEthPriceValue(data?.USD * price);
  };

  const getEthValue = async (price) => {
    const { data } = await axios.post(
      "https://min-api.cryptocompare.com/data/price",
      {
        fsym: "USD",
        tsyms: "ETH",
        sign: true,
      },
      { "Content-type": "Application/json" }
    );

    await setUsdPriceValue(data?.ETH * price);
  };


  const options = categoryData.map((item, i) => {
    return {
      value: item?.categoryName,
      label: item?.categoryName,
      subCategory: item?.subCategory
    }
  })


  return (
    <>
      {mintingApproveBox ? (
        <div className="checkout" >
          <div className="maincheckout">
            <button
              className="btn-close"
            >
              x
            </button>
            <h1 className="heading">Re-Selling In-progress...</h1>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {mintingStart ? (
                <img width="50px" src={tick} alt="startAuction" />
              ) : (
                <img width="50px" src={NormalLoader} alt="startAuction" />
              )}
              <h5 style={{ textAlign: "left" }}>
                1. Waiting for Approval...
              </h5>
            </div>
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {approveStart ? (
                <img width="50px" src={tick} alt="startAuction" />
              ) : (
                <img width="50px" src={NormalLoader} alt="startAuction" />
              )}
              <h5 style={{ textAlign: "left" }}>
                2. Waiting for Listing...
              </h5>
            </div>
          </div>
        </div>
      ) : null}


      {errorHandling && (

        <div className="checkout" >
          <div className="maincheckout">
            <button
              className="btn-close"
            >
              x
            </button>
            <h1 style={{ textAlign: 'center', color: "red" }}>Error</h1>
            <p>{rejectWarning}</p>
          </div>
        </div>
      )}


      {mintingApproved && (
        <div className="checkout" >
          <div className="maincheckout">
            <button
              className="btn-close"
            >
              x
            </button>
            <h1 className="heading">
              Your Asset ha been Minted successfully.....
            </h1>
            <p>Transaction Hash: {transactionHash}</p>
            <p>Block Number: {transactionBlockNumber}</p>
            <p>From Address: {transactionFrom}</p>
            {/* <p>To Address: {transactionTo}</p> */}
            <p>Token ID : {transactionTokenID}</p>
            <p>Gas Used: {transactionGasUsed}</p>
            <div>
              <button>
                {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://polygonscan.com/tx/${transactionHash}`}
                >
                  {" "}
                  Click to see transaction details
                </a>
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                onClick={() => {
                  window.location.href = `${constant.MainURL}/Author/${userData?._id}`;
                }}
              >

                View in Explorer
              </button>
            </div>
          </div>
        </div>
      )}
      {mintingLoader && (
        <div className="loaderContainer">
          <div className="loaderContainerInside">
            {/* <LoadingOverlay
  active={mintingLoader}
  spinner
  text='Loading.....'
  
  >
  <h1 style={{color:isDark?"white":"black"}}>Loading.....</h1>
</LoadingOverlay> */}
            <img
              src={Loader}
              alt="loader"
              style={{
                width: "30vw",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            />
          </div>
        </div>
      )}

      {isDark ? (
        <div className="greyscheme">
          <GlobalStylesDark />

          <section
            className="jumbotron breadcumb no-bg"
            style={{
              backgroundImage: `url(${"./img/background/subheader.webp"})`,
            }}
          >
            <div className="mainbreadcumb">
              <div className="container">
                <div className="row m-10-hor">
                  <div className="col-12">
                    <h1 className="text-center">Create</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container">
            <div className="row">
              <div className="col-lg-7 offset-lg-1 mb-5">
                <form id="form-create-item" className="form-border" action="#">
                  <div className="field-set">
                    <h5>Upload file</h5>
                    <div className="d-create-file">
                      <p id="file_name">
                        PNG, JPG, GIF, WEBP or MP4. Max 200mb.
                      </p>

                      <div className="browse">
                        <input
                          type="button"
                          id="get_file"
                          className="btn-main"
                          value="Browse"
                        />
                        <input
                          onChange={onChangePicture}
                          id="upload_file"
                          type="file"
                          multiple
                        />
                      </div>
                    </div>

                    <div className="spacer-single"></div>

                    <h5>Select method</h5>
                    <div className="de_tab tab_methods">
                      <ul className="de_nav">
                        <li id="btn1" className="active" onClick={handleShow}>
                          <span>
                            <i className="fa fa-tag"></i>Fixed price
                          </span>
                        </li>
                        <li id="btn2" onClick={handleShow1}>
                          <span>
                            <i className="fa fa-hourglass-1"></i>Timed auction
                          </span>
                        </li>
                        <li id="btn3" onClick={handleShow2}>
                          <span>
                            <i className="fa fa-users"></i>Make Offer
                          </span>
                        </li>
                      </ul>

                      <div className="de_tab_content pt-3">
                        <div id="tab_opt_1">
                          {/* <h5>Price</h5>
                    <input
                      type="text"
                      name="item_price"
                      id="item_price"
                      className="form-control"
                      placeholder="enter price for one item (ETH)"
                    /> */}
                        </div>

                        <div id="tab_opt_2" className="hide">
                          <h5>Minimum bid</h5>
                          <input
                            type="number"
                            name="item_price_bid"
                            id="item_price_bid"
                            className="form-control"
                            placeholder="enter minimum bid"
                          />

                          <div className="spacer-20"></div>

                          {/* <div className="row">
                            <div className="col-md-6">
                              <h5>Starting date</h5>
                              <input
                                type="date"
                                name="bid_starting_date"
                                id="bid_starting_date"
                                className="form-control"
                                min="1997-01-01"
                              />
                            </div>
                            <div className="col-md-6">
                              <h5>Expiration date</h5>
                              <input
                                type="date"
                                name="bid_expiration_date"
                                id="bid_expiration_date"
                                className="form-control"
                              />
                            </div>
                          </div> */}
                        </div>

                        <div id="tab_opt_3"></div>
                      </div>
                    </div>

                    <div className="spacer-20"></div>

                    <div className="switch-with-title">
                      <h5>
                        <i className="fa fa- fa-unlock-alt id-color-2 mr10"></i>
                        Unlock once purchased
                      </h5>
                      <div className="de-switch">
                        <input
                          type="checkbox"
                          id="switch-unlock"
                          className="checkbox"
                        />
                        {/* {state.isActive ?(
                                      <label htmlFor="switch-unlock" onClick={unlockHide}></label>
                                      ) : (
                                      <label htmlFor="switch-unlock" onClick={unlockClick}></label>
                                      )} */}
                      </div>
                      <div className="clearfix"></div>
                      <p className="p-info pb-3">
                        Unlock content after successful transaction.
                      </p>

                      {/* {state.isActive ?
                                    <div id="unlockCtn" className="hide-content">
                                        <input type="text" name="item_unlock" id="item_unlock" className="form-control" placeholder="Access key, code to redeem or link to a file..." />             
                                    </div>
                                    : null } */}
                    </div>

                    <h5>Title</h5>
                    <input
                      type="text"
                      name="item_title"
                      id="item_title"
                      className="form-control"
                      placeholder="e.g. 'Crypto Funk"
                      onChange={handleChange}
                      value={formContent.item_title}
                    />

                    <div className="spacer-10"></div>

                    <h5>Description</h5>
                    <textarea
                      data-autoresize
                      name="item_desc"
                      id="item_desc"
                      className="form-control"
                      placeholder="e.g. 'This is very limited item'"
                      onChange={handleChange}
                      value={formContent.item_desc}
                    ></textarea>

                    <div className="spacer-10"></div>

                    <h5>Price</h5>
                    <input
                      type="number"
                      name="item_price"
                      id="item_price"
                      className="form-control"
                      placeholder="enter price for one item (ETH)"
                      onChange={handleChange}
                      value={formContent.item_price}
                    />

                    <div className="spacer-10"></div>

                    {/* <h5>Royalties</h5>
              <input
                type="text"
                name="item_royalties"
                id="item_royalties"
                className="form-control"
                placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%"
              /> */}

                    <div className="spacer-10"></div>

                    <input
                      type="button"
                      id="submit"
                      className="btn-main"
                      value="Create Item"
                      onClick={handleContract}
                    />
                  </div>
                </form>
              </div>

              <div className="col-lg-3 col-sm-6 col-xs-12">
                <h5>Preview item</h5>
                <div className="nft__item m-0">
                  {/* <div className="de_countdown">
                    <Clock deadline="December, 30, 2021" />
                  </div> */}
                  <div className="author_list_pp">
                    <span>
                      <img
                        className="lazy"
                        src={imgData ? imgData : "./img/author/author-1.jpg"}
                        alt=""
                      />
                      <i className="fa fa-check"></i>
                    </span>
                  </div>
                  <div className="nft__item_wrap">
                    <span>
                      <img
                        src={imgData ? imgData : "./img/author/author-1.jpg"}
                        id="get_file_2"
                        className="lazy nft__item_preview"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="nft__item_info">
                    <span>
                      <h4>{formContent?.item_title}</h4>
                    </span>
                    <div className="nft__item_price">
                      {formContent?.item_price}
                    </div>
                    {/* <div className="nft__item_action">
                      <span>Place a bid</span>
                    </div> */}
                    {/* <div className="nft__item_like">
                      <i className="fa fa-heart"></i>
                      <span>50</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      ) : (
        <div>
          <GlobalStyles />

          <section
            className="jumbotron breadcumb no-bg"
            style={{
              backgroundImage: `url(${"./img/background/subheader.webp"})`,
            }}
          >
            <div className="mainbreadcumb">
              <div className="container">
                <div className="row m-10-hor">
                  <div className="col-12">
                    <h1 className="text-center">Create</h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container">
            <div className="row">
              <div className="col-lg-7 offset-lg-1 mb-5">
                <form id="form-create-item" className="form-border" action="#">
                  <div className="field-set">



                    <div className="spacer-single"></div>

                    <h5>Select method</h5>
                    <div className="de_tab tab_methods">
                      <ul className="de_nav">
                        <li id="btn1" className="active" onClick={handleShow}>
                          <span>
                            <i className="fa fa-tag"></i>Fixed price
                          </span>
                        </li>
                        <li id="btn2" onClick={handleShow1}>
                          <span>
                            <i className="fa fa-hourglass-1"></i>Timed auction
                          </span>
                        </li>
                        <li id="btn3" onClick={handleShow2}>
                          <span>
                            <i className="fa fa-users"></i>Make Offer
                          </span>
                        </li>
                      </ul>

                      <div className="de_tab_content pt-3">
                        <div id="tab_opt_1">

                        </div>

                        <div id="tab_opt_2" className="hide">
                          <h5>Minimum bid</h5>
                          <input
                            type="number"
                            name="item_price_bid"
                            id="item_price_bid"
                            className="form-control"
                            placeholder="enter minimum bid"
                            onChange={handleChange}
                          />

                          <div className="spacer-20"></div>

                          {isAuctionContract ? (
                            isOpenBid ? (
                              <div>
                                <h5>Auction Timeline</h5>
                                {/* <input
                                  type="number"
                                  name="auctionTimeline"
                                  id="auctionTimeline"
                                  className="form-control"
                                  placeholder="Enter the timeline for Auction"
                                  value={openBidValues}
                                /> */}
                                <span style={{ color: "grey" }}>
                                  The Open bid Auction ends in One day, After
                                  the admin approval.
                                </span>
                              </div>
                            ) : (
                              <div>
                                <h5>Auction Timeline</h5>
                                <input
                                  type="number"
                                  name="auctionTimeline"
                                  id="auctionTimeline"
                                  className="form-control"
                                  placeholder="Enter the timeline for Auction"
                                  onChange={handleChange}
                                  value={formContent.auctionTimeline}
                                />
                                <span style={{ color: "grey" }}>
                                  After Admin approval, The auction will be
                                  started.
                                </span>
                              </div>
                            )
                          ) : null}

                          {auctionTimelineWarning ? (
                            <p style={{ color: "red", textAlign: "center" }}>
                              *Please enter this field.
                            </p>
                          ) : null}
                          {auctionTimelineProperWarning ? (
                            <p style={{ color: "red", textAlign: "center" }}>
                              The Timeline values must be greater than 0.
                            </p>
                          ) : null}

                        </div>

                        <div id="tab_opt_3"></div>
                      </div>
                    </div>

                    <div className="spacer-20"></div>

                    <div className="switch-with-title">
                      <h5>
                        <i className="fa fa- fa-unlock-alt id-color-2 mr10"></i>
                        Unlock once purchased
                      </h5>
                      <div className="de-switch">
                        <input
                          type="checkbox"
                          id="switch-unlock"
                          className="checkbox"
                        />
                        {/* {state.isActive ?(
                                      <label htmlFor="switch-unlock" onClick={unlockHide}></label>
                                      ) : (
                                      <label htmlFor="switch-unlock" onClick={unlockClick}></label>
                                      )} */}
                      </div>
                      <div className="clearfix"></div>
                      <p className="p-info pb-3">
                        Unlock content after successful transaction.
                      </p>

                      {/* {state.isActive ?
                                    <div id="unlockCtn" className="hide-content">
                                        <input type="text" name="item_unlock" id="item_unlock" className="form-control" placeholder="Access key, code to redeem or link to a file..." />             
                                    </div>
                                    : null } */}
                    </div>

                    <h5>Title</h5>
                    <input
                      type="text"
                      name="item_title"
                      id="item_title"
                      className="form-control"
                      placeholder="e.g. 'Crypto Funk"
                      onChange={handleChange}
                      value={finalResult?.name}
                    />

                    <div className="spacer-10"></div>

                    <h5>Description</h5>
                    <textarea
                      data-autoresize
                      name="item_desc"
                      id="item_desc"
                      className="form-control"
                      placeholder="e.g. 'This is very limited item'"
                      onChange={handleChange}
                      value={finalResult?.description}
                    ></textarea>
                    {descriptionWarning ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Please enter this field
                      </p>
                    ) : null}
                    <div className="spacer-10"></div>



                    <h5>Category</h5>
                    <Select
                      styles={customStyles}
                      menuContainerStyle={{ 'zIndex': 999 }}
                      options={[...options]}
                      onChange={handleCategory}

                    />
                    {categoryWarning ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Please enter this field
                      </p>
                    ) : null}
                    {
                      isCategory &&
                      <div>
                        <div className="spacer-40"></div>
                        <h5>Sub Category</h5>
                        <Select
                          styles={customStyles}
                          menuContainerStyle={{ 'zIndex': 999 }}
                          options={[...subCategoryData]}
                          onChange={handleSubCategory}

                        />
                      </div>
                    }
                    <div className="spacer-10"></div>

                    {/* <h5>Status</h5> */}
                    {/* <input
                      type="text"
                      name="item_title"
                      id="item_title"
                      className="form-control"
                      placeholder="e.g. 'Crypto Funk"
                      onChange={handleChange}
                      value={formContent.item_title}
                    /> */}
                    <br />
                    {/* <TopStatus handleStatus={handleStatus}></TopStatus>
              
                    <div className="spacer-10"></div> */}

                    {/* <div className="form-control">
                   {
                     isEthValue?
                     <input
                     type="number"
                     name="item_price"
                     id="item_price"
                     style={{width:'40%'}}
                     placeholder="enter price for one item (ETH)"
                     onChange={handleChange}
                     value={formContent.item_price}
                   />:
                     <input
                      type="number"
                      name="item_price"
                      id="item_price"
                      style={{width:'40%'}}
                      placeholder="enter price for one item (USD)"
                      onChange={handleChange}
                      value={formContent.item_price}
                    /> 
                   }
                   {
                     isEthValue?
                     <input
                     type="text"
                     name="item_price"
                     id="item_price"
                     style={{width:'40%'}}
                     value={`${ethPriceValue} USD`}
                     
                   />:
                     <input
                      type="text"
                      name="item_price"
                      id="item_price"
                      style={{width:'40%'}}
                      value={`${usdPriceValue} ETH`}
                       
                    /> 
                   }
                   <select onChange={handleEthSwitch}>  
<option value = "ETH" > ETH </option>  
<option value = "USD" > USD  </option>  
</select>  
                    
                    </div>  */}

                    {removePrice ? null : (
                      <>
                        <h5>Price</h5>
                        <input
                          type="number"
                          name="item_price"
                          id="item_price"
                          className="form-control"
                          placeholder="enter price for one item (ETH)"
                          onChange={handleChange}
                          value={formContent.item_price}
                        />
                      </>
                    )}

                    {priceWarning ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Please enter this field
                      </p>
                    ) : null}
                    {equalPrice ? (
                      <p style={{ color: "red", textAlign: "center" }}>
                        *Price must be equal to minimum bid price
                      </p>
                    ) : null}
                    <div className="spacer-10"></div>

                    {/* <h5>Royalties</h5>
              <input
                type="text"
                name="item_royalties"
                id="item_royalties"
                className="form-control"
                placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%"
              /> */}

                    <div className="spacer-10"></div>

                    <input
                      type="button"
                      id="submit"
                      className="btn-main"
                      value="Create Item"
                      onClick={handleContract}
                    />
                  </div>
                </form>
              </div>

              <div className="col-lg-3 col-sm-6 col-xs-12">
                <h5>Preview item</h5>
                <div className="nft__item m-0">
                  {/* <div className="de_countdown">
                    <Clock deadline="December, 30, 2022" />
                  </div> */}
                  <div className="author_list_pp">
                    <span>
                      <img
                        className="lazy"
                        src={
                          userData?.profilePicture
                            ? userData?.profilePicture
                            : "./img/author/author-1.jpg"
                        }
                        alt=""
                      />
                      <i className="fa fa-check"></i>
                    </span>
                  </div>
                  <div className="nft__item_wrap">
                    <span>
                      <img
                        src={finalResult?.ProductImage}
                        id="get_file_2"
                        className="lazy nft__item_preview"
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="nft__item_info">
                    <span>
                      <h6>{finalResult?.name}</h6>
                    </span>
                    <div className="nft__item_price">
                      <span>
                        {formContent?.item_price
                          ? "Price : " + formContent?.item_price + " ETH"
                          : null}
                      </span>
                    </div>
                    <div className="nft__item_action">
                      <span>Place a bid</span>
                    </div>
                    <div className="nft__item_like">
                      <i className="fa fa-heart"></i>
                      <span>50</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      )}
    </>
  );
};

export default Create;

//   const val = web3.utils.toWei(String(0.004), "ether");
//  console.log(val);
//     const transactionParameters = {
//       to:'0x418281500b7854a6f6E6baf52D9Ff13fbb5F44ef', // Required except during contract publications.
//       from: account[0], // must match user's active address.
//       nonce: "0x00", // ignored by MetaMask
//       gasPrice: "0x21000",
//       gasLimit: "800009",
//       // customizable by user during MetaMask confirmation.
//       gas: "0x25000",
//       value: val,
//      data :await await contractInstance.methods
//      .createToken(
//       "https://ezedraa-test.s3.ap-south-1.amazonaws.com/assets/ce21a620-cbd9-4064-94c6-e7cb498b71a7.jpg",val
//      ).encodeABI()

//     };

//     console.log(transactionParameters);

//       const txHash = await window.ethereum.request({
//         method: "eth_sendTransaction",
//         params: [transactionParameters],
//       });
