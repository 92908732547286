import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { status, itemsType, collections } from './constants/filters';
import { filterCategories, filterStatus, filterItemsType, filterCollections } from '../../store/actions';
// import { Axios } from '../../core/axios';
import Axios from '../../api-instance'
import down from '../../assets/icons/down.svg'
import right from '../../assets/icons/right.svg'
import { Box } from '@mui/material';

const CheckboxFilter = ({ handleReset, handleSubCategory, handleCategory, handleStatus }) => {
    const dispatch = useDispatch();

    // const handleCategory = useCallback((event) => {
    //     const { id } = event.target;
    //     dispatch(filterCategories({value: id, singleSelect: false}));
    // }, [dispatch]);

    // const handleStatus = useCallback((event) => {
    //     const { id } = event.target;
    //     dispatch(filterStatus({value: id, singleSelect: false}));
    // }, [dispatch]);
    const [categories, setCategories] = useState([])
    const currency = [{categoryName:"Polygon",value:"polygon"},{categoryName:"ETH",value:"eth"},{categoryName:"BNB",value:"bnb"}]
    useEffect(() => {
        const getData = async () => {
            const { data } = await Axios.get('/category?isDeleted=false');
            setCategories(data?.data);
        }
        getData();
    }, [])

    const handleItemsType = useCallback((event) => {
        const { id } = event.target;
        dispatch(filterItemsType({ value: id, singleSelect: false }));
    }, [dispatch]);

    const handleCollections = useCallback((event) => {
        const { id } = event.target;
        dispatch(filterCollections({ value: id, singleSelect: false }));
    }, [dispatch]);


    const [statusOpen, setStatusOpen] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [quantityOpen, setQuantityOpen] = useState(false)
    const [CurrencyOpen, setCurrencyOpen] = useState(false)


    return (
        <>
            <span onClick={handleReset} style={{ color: 'black', textDecoration: 'underline', cursor: 'pointer', transform: 'translateY(-5px)' }} className="black-color">Reset</span>
            <br /><br />
            <div className="item_filter_group" style={{ padding: '10px 10px 0px 10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => { setFilterOpen(!filterOpen) }}>
                    <h4 className="black-color">Category</h4>
                    {/* <i className={filterOpen ?"arrow_down" :"arrow_right"} ></i>    */}
                    {
                        filterOpen
                            ?
                            <img src={down} alt="dropdown-icons black-color" style={{ width: '15px', height: '15px', color: 'black', marginTop: '-10px' }} />
                            :
                            <img src={right} alt="dropdown-icons black-color" style={{ width: '15px', height: '15px', color: 'black', marginTop: '-10px' }} />
                    }


                </div>
                <div className="de_form">
                    {
                        categories &&
                        categories.map((item, index) => (
                            <div className="de_checkboxsss" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} key={index} htmlFor={item?.categoryName}>

                                {
                                    filterOpen &&
                                    <label className="black-color" htmlFor={item.value} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer', padding: '10px' }} >
                                        <label className="black-color" onChange={(e) => handleCategory(e, item)} htmlFor={item?.categoryName} className="labelFilter">{item?.categoryName}</label>
                                        <input
                                            id={item?.categoryName}
                                            name={item?.categoryName}
                                            type="checkbox"
                                            value={item?.categoryName}
                                            onChange={(e) => handleCategory(e, item)}
                                            className="checkBoxFilter"
                                        />
                                    </label>
                                }
                                {/* {
                                   item?.subCategory?.length>0&&
                                   item?.subCategory?.map((data,i)=>{
                                   return(
                                    <div class="de_checkbox" key={i}>
                                    <input type='checkbox' value={data} id={data} onClick={handleSubCategory}  />
                                    <label htmlFor={data}>{data}</label>
                                   
                                </div>
                                   )
                                   })
                               } */}

                            </div>
                        ))}
                </div>
                {/* <div style={{position:'relative',marginTop:'20px'}}>
             
              </div> */}
            </div>

            <div className="item_filter_group" style={{ padding: '10px 10px 0px 10px', }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => { setStatusOpen(!statusOpen) }}>
                    <h4 className="black-color">Price</h4>
                    {/* <i className={statusOpen ?"arrow_down" :"arrow_right"}></i>    */}
                    {
                        statusOpen
                            ?
                            <img src={down} className="black-color" alt="dropdown-icons" style={{ width: '15px', height: '15px', marginTop: '-10px' }} />
                            :
                            <img src={right} className="black-color" alt="dropdown-icons" style={{ width: '15px', height: '15px', marginTop: '-10px' }} />
                    }
                </div>

                <div className="de_form">
                    {status.map((item, index) => (
                        <div className="de_checkboxsss" key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {
                                statusOpen &&
                                <label className="black-color" htmlFor={item.value} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer', padding: '10px' }} >
                                    <label htmlFor={item.value} className="labelFilter black-color">{item.label}</label>
                                    <input
                                        id={item.value}
                                        name={item.value}
                                        type="checkbox"
                                        value={item.value}
                                        onChange={handleStatus}
                                        className="checkBoxFilter"
                                    />
                                </label>
                            }

                        </div>
                    ))}
                </div>
            </div>

           

            <div className="item_filter_group" style={{ padding: '10px 10px 0px 10px', }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => { setCurrencyOpen(!CurrencyOpen) }}>
                    <h4 className="black-color">Currency</h4>
                    {/* <i className={statusOpen ?"arrow_down" :"arrow_right"}></i>    */}
                    {
                        CurrencyOpen
                            ?
                            <img src={down} className="black-color" alt="dropdown-icons" style={{ width: '15px', height: '15px', marginTop: '-10px' }} />
                            :
                            <img src={right} className="black-color" alt="dropdown-icons" style={{ width: '15px', height: '15px', marginTop: '-10px' }} />
                    }
                </div>

                <div className="de_form">
                    {console.log("caate",currency)}
                    {currency.map((item, index) => (
                        <div className="de_checkboxsss" key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {
                                CurrencyOpen &&
                                <label className="black-color" htmlFor={item.value} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', cursor: 'pointer', padding: '10px' }} >
                                    <label htmlFor={item.value} className="labelFilter black-color">{item.categoryName}</label>
                                    <input
                                        id={item.value}
                                        name={item.categoryName}
                                        type="checkbox"
                                        value={item.categoryName}
                                        onChange={handleStatus}
                                        className="checkBoxFilter"
                                    />
                                </label>
                            }

                        </div>
                    ))}
                </div>
            </div>


        </>
    );
}

export default memo(CheckboxFilter)