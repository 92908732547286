import React,{useEffect} from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import "./index.scss";
import { Link } from '@reach/router';


const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;



const Featurebox = () => {

   const isAuth = localStorage.getItem('isAuth')

  console.log(isAuth,'value');
  
  return(
  <div className="second-section-container" >
    <h1 className="get-started-title">
      Get Started with the <br /> Wallex NFT Marketplace
    </h1>

    <div className="second-section-middle-container">
      <div className="second-section-first-card" data-aos="zoom-out-down">
        <center>
          <img src="./signup-second.png" alt="signup" width="100px" />
        </center>

        <h3 className="second-section-card-title">1. Sign up</h3>
        <p className="second-section-card-content">
          Create your Wallex account <br />
          and customize your profile
        </p>
      </div>
      <div className="second-section-second-card" data-aos="zoom-out-down">
        <center>
          <img src="./collection-second.png" alt="signup" width="100px" />
        </center>

        <h3 className="second-section-card-title">2. Browse the collections</h3>
        <p className="second-section-card-content">
          Explore NFT collection across the <br /> Ethereum and Solana
          blockchains. <br />
          Expect more collection to be <br />
          added each week.
        </p>
      </div>
      <div className="second-section-third-card" data-aos="zoom-out-down">
        <center>
          <img src="./portfolio-second.png" alt="signup" width="100px" />
        </center>

        <h3 className="second-section-card-title">3. Curate your portfolio</h3>
        <p className="second-section-card-content">
          List NFTs, place bids and build your collection with zero gas fees*.
        </p>
      </div>
    </div>
    <a href={isAuth === 'true'  ? '/Explore':'/register'}>
    <button className="second-section-get-started btn-main" >{isAuth === 'true' ? 'Explore' :'Get Started'}</button>
    </a>
  
  </div>
  //  <div className='row featurecards' style={{marginTop:'100px'}}>
  //             <div className="col-lg-3 col-md-6 mb-3" >
  //                 <div className="feature-box f-boxed style-3 walletCard" >
  //                   <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
  //                     <i className="bg-color-2 i-boxed icon_wallet"></i>
  //                   </Reveal>
  //                     <div className="text">
  //                       <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
  //                         <h4 className="">Set up your wallet</h4>
  //                       </Reveal>
  //                       <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
  //                         <p className="">Set up your Metamask Wallet by visiting the browser extension marketplace or simply import your Metamask wallet. </p>
  //                       </Reveal>
  //                     </div>
  //                     <i className="wm icon_wallet"></i>
  //                 </div>
  //             </div>
  //             <div className="col-lg-3 col-md-6 mb-3">
  //                 <div className="feature-box f-boxed style-3 walletCard">
  //                   <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
  //                     <i className="bg-color-2 i-boxed icon_wallet"></i>
  //                   </Reveal>
  //                     <div className="text">
  //                       <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
  //                         <h4 className="">Explore Collectibles</h4>
  //                       </Reveal>
  //                       <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
  //                         <p className="">Explore the available collectibles available to buy now, or those that are on auction, and make offers for NFTs.</p>
  //                       </Reveal>
  //                     </div>
  //                     <i className="wm icon_wallet"></i>
  //                 </div>
  //             </div>

  //           <div className="col-lg-3 col-md-6 mb-3">
  //               <div className="feature-box f-boxed style-3 walletCard">
  //                 <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
  //                   <i className=" bg-color-2 i-boxed icon_cloud-upload_alt"></i>
  //                 </Reveal>
  //                   <div className="text">
  //                     <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
  //                       <h4 className="">Create your NFTs</h4>
  //                     </Reveal>
  //                     <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
  //                       <p className="">Create your unique NFTs from different categories like Music, Art, and Sports in various file formats.</p>
  //                     </Reveal>
  //                   </div>
  //                   <i className="wm icon_cloud-upload_alt"></i>
  //               </div>
  //           </div>

  //           <div className="col-lg-3 col-md-6 mb-3">
  //               <div className="feature-box f-boxed style-3 walletCard">
  //                 <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
  //                   <i className=" bg-color-2 i-boxed icon_tags_alt"></i>
  //                 </Reveal>
  //                   <div className="text">
  //                     <Reveal className='onStep' keyframes={fadeInUp} delay={100} duration={600} triggerOnce>
  //                       <h4 className="">Sell your NFT's</h4>
  //                     </Reveal>
  //                     <Reveal className='onStep' keyframes={fadeInUp} delay={200} duration={600} triggerOnce>
  //                       <p className="">List your NFTs for sale and sell them to other artists, trade your NFTs, and manage them easily.</p>
  //                     </Reveal>
  //                   </div>
  //                   <i className="wm icon_tags_alt"></i>
  //               </div>
  //           </div>
  //         </div>
)};
export default Featurebox;
