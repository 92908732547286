import React, { memo,useState,useEffect } from 'react';
import styled from "styled-components";
import Clock from "./Clock";
import { navigate } from '@reach/router';
import api from '../../core/api';
import { Link } from '@reach/router';
import Avatar from 'react-avatar';
import Axios from '../../api-instance'


import newAuctionContractAddress from "../../web3/metamaskConnection/contractAddress/newAuctionContractAddress";
import newAuctionContractABI from "../../web3/metamaskConnection/ABI/newAuctionABI.json";

const alchemyKey =
  "https://eth-mainnet.g.alchemy.com/v2/E11IswOPCxAsDPDlRkg7KV-RPwkjCafv";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
var ethers = require("ethers");

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

//react functional component
const NftCard = ({ isApproved,nft, className = 'd-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4', clockTop = true, height, onImgLoad }) => {

const [endTransaction, setEndTransaction] = useState(false)

    useEffect(()=>{
       const getData = async()=>{
           try {
            if(window?.ethereum){
            const accounts = await window?.ethereum?.request({
                method: "eth_requestAccounts",
              });
      
              const product = await Axios.get('/product')
            
              product?.data?.data?.map((item,i)=>{
                if(isApproved){
              if(nft?.isAuction){
                
                if(item?.mintTransaction?.from===accounts[0]){
                  setEndTransaction(true)
                }
            }
              }
            })
        }
           } catch (error) {
               console.log(error);
           }
       }

       getData()
  
    },[])
    
   


    return (
        <div >
            {/* <Link state={nft} to='/ItemDetail/1'> */}
            <div className="nft__item m-0 blue_card">
            { nft?.item_type === 'single_items' ? (
             <div className='icontype'><i className="fa fa-bookmark"></i></div>   
             ) : (  
             <div className='icontype'><i className="fa fa-shopping-basket"></i></div>
                )
            }
             {/* { nft?.created && clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft?.created} />
                    </div>
                } */}
                
                <div className="author_list_pp">
                    <span >
                    {/* onClick={()=> navigateTo(nft?.Link)} */}
                    {
nft?.userId?.profilePicture?
<img className="lazy" src={nft?.userId?.profilePicture} alt=""/>
:
<Avatar name={nft?.userId?.name} size="40" round="50px"/>
                    }
                     

                        
                        <i className="fa fa-check"></i>
                    </span>
                </div>
                <div  className="nft__item_wrap imageBoxContainer" >
                <Outer>
                    <span>
                        <img   src={nft?.ProductImage} className="lazy nft__item_preview imageBox" alt="imagess" />
                    </span>
                </Outer>
                </div>
                { nft?.created && !clockTop &&
                    <div className="de_countdown">
                        <Clock deadline={nft?.created} />
                    </div>
                }
                <div className="nft__item_info">
                    <span >
              
                        <h4>{nft?.name}</h4>
                    </span>
                    { nft?.status === 'has_offers' ? (
                            <div className="has_offers">
                                <span className='through'>{nft?.price}</span> {nft?.price} ETH
                            </div> 
                        ) : (
                            <div className="nft__item_price">
                                {nft?.price} ETH
                                { nft?.status === 'on_auction' && 
                                    <span>{nft?.bid}/{nft?.max_bid}</span>
                                }
                            </div>
                        )
                    }
                    <div className="nft__item_action">
                        <span>{ nft?.status === 'on_auction' ? 'Place a bid' : 'Buy Now' }</span>
                    </div>
                    <div className="nft__item_like">
                        <i className="fa fa-heart"></i><span>{nft?.likes}</span>
                    </div>                            
                </div> 
                <div>
                {
                    isApproved?
                    <h5 style={{color:'green',textAlign:'center'}}>Admin Approved</h5>:
                    <h5 style={{color:'red',textAlign:'center'}}>Admin not Approved</h5>
                }

{/* {
    endTransaction?
    <button 
    style={{margin:'10px auto'}}
                          className="btn-main lead mb-5 mr15"
                         onClick={handleWithdraw}>
                        
                          Claim

                        </button>:null
} */}
            </div>
            </div>
           
            {/* </Link> */}
        </div>             
    );
};

export default memo(NftCard);