import React, { useState, useRef, useEffect } from 'react'
// import { useSearchParams } from 'react-router-dom'
// import Axios from '../../api-instance'
import constant from '../../constants'
import { useNavigate } from '@reach/router';
import Axios from '../../api-instance'
// import { useSearchParams } from 'react-router-dom';

const EmailVerify = () => {
    const navigate = useNavigate()
    // const [searchParams, setSearchParams] = useSearchParams()
    const searchParams = new URLSearchParams(document.location.search)
    // const dataa = window.localStorage.getItem('token')
    const dataa = searchParams.get('token')         
    console.log(dataa,"adfuyggyd");
    const firstrun = async () => {
        try {
                const { data } = await Axios.post(`/auth/userVerify`,{"_id":dataa}, {
                    headers: {
                        "Authorization": dataa
                    }
                })
                console.log(data,"datssss")
                if (data?.success === false) {
                    navigate(`${constant.MainURL}/login`, { state: { status: "Already Verified", page: "email" } })
                } else {
                    navigate(`${constant.MainURL}/login`, { state: { status: "Verified", page: "email" } })
                }
        } catch ({ response }) {
            if (response?.data === "Unauthorized") {
                console.log(response?.data, "error")
                navigate(`${constant.MainURL}/login`, { state: { status: "error", page: "email" } })
            }
        }
    }
    useEffect(() => {
        firstrun()
    }, [])

    return (
        <>
        </>
    )
}
export default EmailVerify