import React, { memo, useEffect, useState } from 'react';
import NftMusicCard from "./NftMusicCard";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from '@reach/router';
import * as selectors from '../../store/selectors';
import * as actions from '../../store/actions/thunks';
import { clearNfts, clearFilter } from '../../store/actions';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import api from '../../core/api';
import { Button, Grid } from '@mui/material';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from "react-owl-carousel";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import NftToptable from '../../components/components/NFtToptable'
import NftTrendtable from '../../components/components/Nfttrenttable'
import { shuffleArray } from '../../store/utils';
import VerifiedIcon from '@mui/icons-material/Verified';
import Axios from '../../api-instance'

const CustomTabs = styled(Tabs)(({ theme }) => ({


    borderRadius: "10px",
    background: "#f6f6f6",
    display: "flex",
    alignItems: "center",
    minHeight: "48px",
    color: "#030303",

    '& .MuiTab-root': {
        fontSize: '15px',
        fontWeight: 600,
        minHeight: "30px",
        padding: "10px",
        margin: "4px 4px",
        transition: "all 0.3s ease 0s",
        color: "#545454 ",

    },
    '& .Mui-selected': {
        background: "#fff",
        borderRadius: "10px",
        color: "#030303 !important",
        transition: "all 0.3s ease 0s",
        boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    }
    ,
    '& .MuiTabs-indicator': {
        display: "none",
        backgroundColor: 'red',
    },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
    fontSize: '15px',
    // fontWeight: 500,
    // transition: "all 0.3s ease 0s"
}));

const RightGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
        display: "flex",
        justifyContent: "center",
    },
}));

const CenterGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "center",
    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        width: "90%",
        color: "#030303",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        },
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        }
    },

}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function TabPanel(props) {
    const { children, values, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={values !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {values === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function a11yProps(index) {
    console.log("index", index)
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function a11yPropss(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Nftcarousal = ({ showLoadMore = true, shuffle = false, authorId = null, nfts }) => {

    console.log(nfts, "nfts");
    const dispatch = useDispatch();
    // const nftItems = useSelector(selectors.nftItems);
    // const nfts = nftItems ? shuffle ? shuffleArray(nftItems) : nftItems : [];

    const [height, setHeight] = useState(0);
    const [value, setValue] = React.useState(0);
    const [values, setValues] = React.useState(0);
    const navigate = useNavigate()
    const [show, setshow] = useState(false)

    useEffect(() => {
        if (nfts.length > 0) {
            setshow(true)
        }
    }, [nfts])

    // const [nfts1, setNfts] = useState([])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const options = {
        margin: 30,
        loop: true,
        responsiveClass: true,
        nav: true,
        dots: true,
        items:4,
        autoplay: true,
        autoplayTimeout: 3500,
        smartSpeed: 400,
        slideby:15,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            600: {
                items: 3,
            },
            700: {
                items: 4,
            },
            1000: {
                items: 5,
            },
            1400: {
                items: 5,
            },
        },
    };
    const handleChanges = (event, newValue) => {
        // alert(newValue)
        setValues(newValue);
    };
    const onImgLoad = ({ target: img }) => {
        let currentHeight = height;
        if (currentHeight < img.offsetHeight) {
            setHeight(img.offsetHeight);
        }
    }

    const navigationto = () => {
        navigate("/viewall")
    }
    useEffect(() => {
        dispatch(clearNfts());
        dispatch(actions.fetchNftsBreakdown(authorId, true));
    }, [dispatch, authorId]);
    //will run when component unmounted
    useEffect(() => {
        return () => {
            dispatch(clearFilter());
            dispatch(clearNfts());
        }
    }, [dispatch]);

    const loadMore = () => {
        dispatch(actions.fetchNftsBreakdown(authorId, true));
    }

    // useEffect({

    // },[nfts]);

    return (
        <div className='row' >
            <Box >
                {show === true ?
                    <>
                        <OwlCarousel
                            className="card-wrapper"
                            {...options}
                            items={4}
                            // loop={true}
                            // margin={10}
                            // nav={true}
                            // slideBy={15}
                            // autoplay={true} // Add the autoplay option
                            // autoplayTimeout={3000}
                        >

                            {
                                nfts !== [] ?
                                    <>
                                        {nfts && nfts?.map((nft) => (
                                            <div className="nft_pic" onClick={() => { navigate('/Items', { state: { data: nft } }) }} >
                                                <div className="nft_pic_wrap">

                                                    {/* <img src={api?.baseUrl + nft?.preview_image.url} className="lazy img-fluid" alt="" /> */}
                                                    <div style={{ height: '300px', width: '300px', marginLeft: 'auto', marginRight: 'auto' }}>
                                                        <img src={nft?.Image} className="lazy img-fluid" alt="" style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                                                    </div>

                                                    <div className='nft_text_wrap'>
                                                        <div className='textCell'>
                                                            <p>{nft?.Collection_Name} <VerifiedIcon style={{ "color": '#2081e2', "font-size": "20px" }} /></p>
                                                        </div>
                                                        <div className='nft_flex_wrap'>
                                                            <div className='floors'>
                                                                Floor
                                                                <h5>{nft?.florPrice} ETH</h5>
                                                            </div>
                                                            <div className='totals'>
                                                                Total
                                                                <h5>{nft?.total_volumn.toFixed(8)} ETH</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <>
                                    </>
                            }



                            {/* <div className="nft_pic">
                        <span>
                            <span className="nft_pic_info">
                                <span className="nft_pic_title">Glass Cube</span>
                                <span className="nft_pic_by">Mamie Barnett</span>
                            </span>
                        </span>
                        <div className="nft_pic_wrap">
                            <img src="./img/carousel/crs-1.jpg" className="lazy img-fluid" alt="" />
                        </div>
                    </div> */}
                        </OwlCarousel>
                    </>
                    :
                    <>
                    </>}


            </Box>

        </div>
    );
}

export default memo(Nftcarousal);