import React, { useState, useEffect,useRef } from 'react'
import Reveal from 'react-awesome-reveal'
import { keyframes } from '@emotion/react'
import car from '../../assets/ford.glb'
import './index.scss'
import logoSmall from '../../assets/logo/NFT_only_white.svg'
import { Link } from '@reach/router';
// import Tilt from 'tilt.js';
import { useSpring, animated } from '@react-spring/web';
import beta from '../../assets/beta.webp'

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`

const Slidermainzero = () => {
  const [imageData, setImageData] = useState(
    'https://dailyhodl.com/wp-content/uploads/2021/12/ross-sells-nft-1280x720.jpg',
  )
  const [initial, setInitial] = useState(1)

  var arr = [
    'https://cdn.pixabay.com/photo/2022/03/01/02/51/galaxy-7040416__340.png',
    'https://cdn.pixabay.com/photo/2022/04/20/12/16/abstract-art-7145098__340.jpg',
    'https://cdn.pixabay.com/photo/2022/02/23/15/56/technology-7030913__340.png',
    'https://cdn.pixabay.com/photo/2022/09/20/15/15/bitcoin-7468145__340.jpg',
    'https://cdn.pixabay.com/photo/2022/04/16/02/02/planets-7135545__340.jpg',
  ]
  const [hover, setHover] = useState(false);
  const animationProps = useSpring({
    transform: hover ? 'scale(1.1)' : 'scale(1)',
  });


  const isAuth = localStorage.getItem('isAuth')

  console.log(isAuth,'value');
  
  // const tiltRef = useRef(null);

  // useEffect(() => {
  //   const tiltInstance = new Tilt(tiltRef.current, {
  //     max: 25,
  //     perspective: 1000,
  //     easing: "cubic-bezier(.03,.98,.52,.99)",
  //     scale: 1,
  //     speed: 300,
  //     transition: true,
  //     axis: null,
  //     reset: true
  //   });

  //   return () => {
  //     tiltInstance.destroy();
  //   };
  // }, []);

  //   useEffect(()=>{

  // setInterval(()=>{

  //     setImageData(arr[initial])
  //   setInitial(initial+1)

  //  if(initial===5){
  //   setInitial(1)
  //  }
  // },5000)
  //   },[imageData,arr,initial])

  const snowArray = []
  snowArray.length = 50;

  return (
//     <div
//       style={{
//         position: 'absolute',
//         top: '55%',
//         left: '50%',
//         transform: 'translate(-50%,-50%)',
//         width: '90%',
//         height: '100vh',
//       }}
//     >
//       <div className="row align-items-center">
//         <div
//           style={{
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-around',
//             flexWrap:'wrap',
//             width: '100%',
//             gap: '50px',
//             height: '100vh',
//           }}
//         >
//           <div style={{ width: '50%', textAlign: 'left', marginLeft: '50px' }}>
//             <Reveal
//               className="onStep"
//               keyframes={fadeInUp}
//               delay={300}
//               duration={600}
//             >
//               <h1
//                 className="text-left lightBlueColor textShadow"
//                 style={{ width: '100%', textAlign: 'left' }}
//               >
//                 NFT Marketplace For Arts, 3D Models & Music
//               </h1>
//             </Reveal>
//             <Reveal
//               className="onStep"
//               keyframes={fadeInUp}
//               delay={600}
//               duration={600}
//             >
//               <p
//                 className="lead text-left  new_font"
//                 style={{ width: '60%', textAlign: 'left', color: 'grey' }}
//               >
//                 Unit of data stored on a digital ledger, called a blockchain,
//                 that certifies a digital asset to be unique and therefore not
//                 interchangeable
//               </p>
//             </Reveal>
//             <Reveal
//               className="onStep"
//               keyframes={fadeInUp}
//               delay={800}
//               duration={600}
//             >
//               <div className="d-flex " style={{ marginTop: '50px' }}>
//                 <span
//                   onClick={() => window.open('/explore', '_self')}
//                   className="btn-main inline lead"
//                 >
//                   Explore
//                 </span>
//               </div>
//             </Reveal>
//           </div>
//           <div
//             style={{
//               width: '50%',
//               // boxShadow:
//               //   'inset -9px -9px 6px #d9d9d9,inset 9px 9px 6px #ffffff',
//               // borderRadius: '20px',
//             }}
//           >
//             {/* <img src={imageData} alt="nft" style={{width:"100%",height:'60vh',borderRadius:'20px',opacity:'0.8',
// }} /> */}

//             <model-viewer
//               alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
//               src={car}
//               ar
//                         ar-modes="webxr scene-viewer quick-look"
//                         shadow-intensity="1"
//                         camera-controls
//                         touch-action="pan-y"
//                         style={{width:'800px',height:'600px',objectFit:'fill'}}
//             ></model-viewer>
//           </div>
//         </div>
//       </div>
//     </div>
<div >

{/* <div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div>
<div class="snowflake"></div> */}

<div className='banner-main-container-flex'>
<div className='main-banner-container'> 

<animated.div
      style={animationProps}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
   <img  src="./banner-back.svg" alt="banner-imagess" style={{width:'100%',marginTop:'50px'}}/>
    </animated.div>


 
{/* <div className='banner-image-card banner-first-card'>
  <img src="girl.png" alt="girl" width="200px" style={{borderRadius:'10px'}}/>
   <h6 className="banner-image-description">World of Woman</h6>
</div>

<div className='banner-image-card banner-second-card'>
  <img src="santa.png" alt="santa"  width="200px"  style={{borderRadius:'10px'}}/>
  <h6 className="banner-image-description">The Santa</h6>
</div>

<div className='banner-image-card banner-third-card'>
  <img src="tree.png" alt="tree" width="200px" style={{borderRadius:'10px'}}/>
  <h6 className="banner-image-description">Tree</h6>
</div>

<div className='banner-image-card banner-four-card'>
  <img src="cat.png" alt="cat" width="200px" style={{borderRadius:'10px'}}/>
  <h6 className="banner-image-description">Cryptocat</h6>
</div>

<div className='banner-image-card banner-five-card'>
  <img src="bird.png" alt="bird" width="150px" style={{borderRadius:'10px'}}/>
  <h6 className="banner-image-description">Moonbirds</h6>
</div>

<div className='banner-image-card banner-six-card'>
  <img src="wonder.png" alt="bird" width="200px" style={{borderRadius:'10px'}}/>
  <h6 className="banner-image-description">Wonder Woman</h6>
</div> */}

</div>

<div className='banner-title-content'>
<img
                   src={logoSmall}
                    alt="#"
                    width='50%'
                    height='50%'
                    style={{marginTop:'-10px'}}
                  />
                  <div style={{position:'absolute',left:'160px',top:"5px"}}>
                    <img style={{width:'35px',height:'15px'}} src={beta} alt="alt"/>
                  </div>
                  <br />
                  <br />
    <h1>Trade NFTs <br /> with zero <br /> gas fees*</h1>
    <a href={isAuth === 'true'  ? '/Explore':'/register'}>
    <button className="btn-main whiteColor" style={{color:"white"}}>{isAuth === 'true' ? 'Explore' :'Get Started'}</button>
    </a>
</div>

<div className='banner-title-content-mobile'>
<img
                   src={logoSmall}
                    alt="#"
                    width='50%'
                    height='50%'
                    style={{marginTop:'-10px'}}
                  />
                  <br />
                  <br />
    <h1>Trade NFTs with zero <br /> gas fees*</h1>
    <a href='/register'>
    <button className="btn-main">Get Started</button>
    </a>
</div>
</div>


 
</div>
  )
}
export default Slidermainzero

// <div className="col-md-6 m-auto bannerFont">
//               <div className="spacer-single"></div>
//               <div className="spacer-double"></div>

//               <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
//               {/* <h6 className="text-center"><span className="text-uppercase color whiteColor" style={{width:'100%'}} >Wallex Market</span></h6> */}
//               </Reveal>
//               {/* <div className="spacer-half"></div> */}
//               <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600}>
//               <h1 className="text-center lightBlueColor textShadow" style={{width:'100%'}}>NFT Marketplace For Arts</h1>
//               </Reveal>
//               <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600}>
//               <p className="lead text-center whiteColor new_font" style={{width:'100%'}}>
//               Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable
//               </p>
//               </Reveal>
//               <div className="spacer-10"></div>
//               <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={600}>
//               <div className='d-flex justify-content-center'>
//                 <span onClick={()=> window.open("/explore", "_self")} className="btn-main inline lead">Explore</span>
//               </div>
//               <div className="spacer-single"></div>
//               <div className="spacer-half"></div>
//               </Reveal>
//               <Reveal>
//                 <div className='d-flex justify-content-center'>
//                   <div style={{marginBottom:'10px',marginTop:'-30px'}} className="gradient-border">
//                     <img width='500px'   src="https://miro.medium.com/max/1400/1*SNOwGBmvP12qQA-NA4FXmg.gif" alt="NFTImages"/>
//                   </div>
//                 </div>
//               </Reveal>
//               <Reveal className='onStep' keyframes={fadeInUp} delay={1000} duration={600}>
//               <div className="wallet-images-group-1 glassCard" style={{marginTop:'10px'}}>
//                 <img src="./img/wallet/1.png" alt=""/>
//                 <img src="./img/wallet/2.png" alt=""/>
//                 <img src="./img/wallet/3.png" alt=""/>
//                 <img src="./img/wallet/4.png" alt=""/>
//                 <img src="./img/wallet/5.png" alt=""/>
//                 <img src="./img/wallet/6.png" alt=""/>
//                 <img src="./img/wallet/7.png" alt=""/>
//                 <img src="./img/wallet/8.png" alt=""/>
//               </div>
//               </Reveal>
//           </div>


//!new backup code

// <div className="col-md-6 m-auto bannerFont" style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}>
//                <div className="spacer-single"></div>
//                <div className="spacer-double"></div>
               
//                {/* <div className="spacer-half"></div> */}
//                <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600}>
//                <h1 className="text-center lightBlueColor textShadow" >NFT Marketplace For Arts & 3D Models</h1>
//                </Reveal>
//                <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600}>
//                <p className="lead text-center  new_font" style={{width:'80%',textAlign:'center',color:'grey',margin:'auto'}}>
//                Unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable
//                </p>
//                </Reveal>
//                <div className="spacer-10"></div>
//                <Reveal className='onStep' keyframes={fadeInUp} delay={800} duration={600}>
//                <div className='d-flex justify-content-center'>
//                  <span onClick={()=> window.open("/explore", "_self")} className="btn-main inline lead">Explore</span>
//                </div>
//                <div className="spacer-single"></div>
//                <div className="spacer-half"></div>
//                </Reveal>
//                <div style={{
//                 display:'flex',
//                 justifyContent:'center',
//                 margin:'auto'
//                }}>
//                {/* <model-viewer
//               alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
//               src={car}
//               ar
//                         ar-modes="webxr scene-viewer quick-look"
//                         shadow-intensity="1"
//                         camera-controls
//                         touch-action="pan-y"
//                         style={{width:'100vw',height:'50vh',objectFit:'fill'}}
//             ></model-viewer> */}
//                </div>
              
//            </div>