import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Table, Grid } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionActions from '@mui/material/AccordionActions';
import { useNavigate } from '@reach/router';
import Button from '@mui/material/Button';
import Axios from "../../api-instance";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Nftbycarousal from '../components/Nftbycategory'
import ExploreContentLoader from '../components/exploreContentLoader'
import { useState } from 'react';
import { useEffect } from 'react';


const StyledTable = styled(Table)(({ theme }) => ({
  border: "none !important",
  fontFamily: "inherit !important",
  '& thead th': {
    backgroundColor: "white !important",
    fontSize: "14px !important",
    fontFamily: "inherit !important",
    fontWeight: 400,
    color: "#545454 !important",
    border: "none !important",
    textAlign: "left !important",
    padding: "16px !important",
    borderBottom: "1px solid rgba(0,0,0,.1) !important"
  },
}));

// Styled TableCell component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "10px",
  fontFamily: "inherit !important",
  fontWeight: 600,
  fontSize: "16px",
  border: "none !important",

}));

const GridContainer = styled(Grid)(({ theme }) => ({

  display: "flex",


}));
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({

  // border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  borderRadius: '10px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(f, f, f, 1)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    marginTop: "20px",
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiTypography-root': {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "600"
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // height: "350px",
  overflow: "auto !important",
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  '& .card_section': {
    display: "flex",
    alignItems: "center",
    flexWrap:"nowrap",
    fontSize: "18px",
    fontWeight: "600"
  },
  '& Button':{
    marginTop:"10px",
    color:"white !important",
    background:"#147fbf !important",
    border:"1px solid #147fbf"
  },
}));

const AccordionActions = styled(MuiAccordionActions)(({ theme }) => ({
  padding: theme.spacing(2),
  // height: "350px",
  justifyContent:"center !important",
  overflow: "auto !important",
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  
  '& Button':{
    marginTop:"10px",
    width:"150px !important",
    color:"white !important",
    background:"#147fbf !important",
    border:"1px solid #147fbf"
  },
}));

export default function CustomizedAccordions({ collections }) {
  const [expanded, setExpanded] = React.useState('');
  const collection = collections.products
  const [isLoaded, setIsLoaded] = useState(true)
  const navigate = useNavigate()
  // const [collections, setcollections] = useState([])
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
 
//  useEffect(()=>{
//   console.log("collection",finalResult )
//   getCollection(finalResult?._id)
//  },[])

//  const getCollection = async(idData)=>{
  
//   const collectionData =await Axios.post(`/product/getproductbycollection`,{_id:idData})
//   console.log("===>",collectionData?.data)
//   setcollections(collectionData?.data?.data)
//  }
  return (
    <div style={{ "margin": "20px" }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography> <ListRoundedIcon />More From this Collection</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {console.log(collection)}
       
<div className='card_section'>
       { collection?.length !== 0 ?
                          collection?.map((item, i) => {
                            return (
                              <div >
                               <div className="nft_pic" style={{ "width": "300px" }}>
                    <div className="nft_pic_wrap" style={{ "height": "250px" }}>
                        <img src={item?.products?.ProductImage} className="lazy img-fluid" alt="" />

                    </div>
                    <div className='nft_text_wrap'>
                        <div className='textCell'>
                            <h4 className="black-color">{item?.products?.name}</h4>
                        </div>
                        <div className='nft_noflex_wrap'>
                            <div>
                                {item?.products?.status === 'has_offers' ? (
                                    <div className="has_offers">
                                        <span className='through black-color'>{item?.products?.price}</span> {item?.products?.price} {item?.products?.blockChain === "MATIC" ? "MATIC" : item?.products?.blockChain === "BNB" ? "BNB" : "ETH"} 
                                        {/* (${(item?.products?.price * parseInt(dollar?.amount)).toFixed(5)}) */}
                                    </div>
                                ) : (
                                    <div className="nft__item_price black-color">
                                        {console.log(item?.products?.price, "price")}
                                        {item?.products?.price} {item?.products?.blockChain === "MATIC" ? "MATIC" : item?.products?.blockChain === "BNB" ? "BNB" : "ETH"} 
                                        {item?.products?.status === 'on_auction' &&
                                            <span className="black-color">{item?.products?.bid}/{item?.products?.max_bid}</span>
                                        }
                                    </div>
                                )
                                }
                                <div className="nft__item_action">
                                    <Button className="black-color"  onClick={() => { navigate(`/itemdetails/${item?.products?.mintTransaction?.transactionHash}`, { state: { data: collections } }) }}>{item?.products?.isAuction ? 'Place a bid' : 'Buy Now'}</Button>
                                </div>
                                <div className="nft__item_like">
                                    {/* {
                                        likedOrNot ?
                                            <i className="fa fa-heart" style={{ color: 'red' }}></i>
                                            :
                                            <i className="fa fa-heart"></i>
                                    } */}
                                    <span className="black-color">{item?.products?.favourite?.length}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                              </div>
                            )
                          }) :


                          <h1 className='noDataFound' style={{ height: '100vh' }}>No data found...</h1>}
    </div>
   
                        {/* <Nftbycarousal collections={collections} /> */}
                        
        </AccordionDetails>
        <AccordionActions>
        <Button onClick={() => { navigate('/Items', { state: { data: collections } }) }}>More</Button>
    </AccordionActions>
      </Accordion>

    </div>
  );
}
