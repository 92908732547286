import React, { memo, useState, useEffect } from 'react';
import './Explorenfttable.css'
import { navigate } from '@reach/router';
import { styled } from '@mui/material/styles';
import { Link } from '@reach/router';
import api from '../../core/api';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import Axios from '../../api-instance'
const StyledTable = styled(Table)(({ theme }) => ({
    border: "none !important",
    fontFamily: "inherit !important",
    '& thead th': {
        backgroundColor: "white !important",
        fontSize: "14px !important",
        fontFamily: "inherit !important",
        fontWeight: 600,
        color: "#545454 !important",
        border: "none !important",
        textAlign: "left !important",
        padding: "16px !important",
        borderBottom: "1px solid rgba(0,0,0,.1) !important"
    },
}));

// Styled TableCell component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "10px",
    fontFamily: "inherit !important",
    fontWeight: 300,
    fontSize: "16px",
    border: "none !important",
textAlign:"center !important",
}));

const GridContainer = styled(Grid)(({ theme }) => ({

    display: "flex",


}));

const RightGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 30px",
    '& p': {
        fontSize: '15px',
        marginBottom: "1px"
    },
    [theme.breakpoints.down('sm')]: {
        display: "flex",
        justifyContent: "center",
    },
}));
const RightGrids = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    justifyContent: "flex-start",
    margin: "10px 30px",
    [theme.breakpoints.down('md')]: {
        display: "flex",
        justifyContent: "center",
    },
}));

const GridContainers = styled(Grid)(({ theme }) => ({

    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
        flexWrap: "wrap",
    },
    '& .MuiSvgIcon-root': {
        margin: "1px 10px",
        fontSize: "30px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px"
        },
    }
}));
const GridContainered = styled(Grid)(({ theme }) => ({

    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.down('md')]: {
        flexWrap: "wrap",
        justifyContent: "center",
    },
    '& .MuiSvgIcon-root': {
        margin: "1px 10px",
        fontSize: "30px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px"
        },
    }
}));

const LeftGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    margin: "10px",
    '& h2': {
        marginBottom: "-1px !important"
    },
    '& p': {
        fontSize: '20px',
        marginBottom: "1px"
    },
    '& div': {
        width: '50px',
        height: "50px",
        borderRadius: '50%',
        boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
    },
    '& img': {
        width: '100%',
        height: "100%",
        borderRadius: '50%',
        ObjectFit: "cover"
    },
    [theme.breakpoints.down('sm')]: {
        margin: "10px",
        textTlign: "center",
    },

    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        width: "90%",
        color: "#030303",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        },
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        }
    },

}));
const ButtonGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    margin: "20px",
    justifyContent: "flex-start",
    [theme.breakpoints.down('sm')]: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
    },

    '& .MuiButton-root': {
        fontSize: '12px',
        fontWeight: 600,
        borderRadius: "10px",
        width: "80%",
        background: "#fff",
        boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.2)",
        margin: "0px 10px",

        height: "45px",
        textAlign: "center",
        color: "#030303",
        "&:hover,&:focus": {
            background: "#f6f6f6",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",

        },
        [theme.breakpoints.down('sm')]: {

            margin: "5px",
            padding: "0px 20px",
            fontSize: "13px",
        },
    },

}));
const CenterGrid = styled(Grid)(({ theme }) => ({
    fontSize: '15px',
    display: "flex",
    margin: "10px 30px",
    justifyContent: "flex-start",
    '& .MuiOutlinedInput-root': {
        height: "50px",
        width: "100%",
        //border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: "10px"
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: "1px solid rgba(0,0,0,0.3)"
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: "1px solid rgba(0,0,0,0.3)"
    },
    '& .MuiButton-root': {
        fontSize: '15px',
        fontWeight: 600,
        borderRadius: "10px",
        background: "#f6f6f6",
        width: "90%",
        color: "#030303",
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
        },
        "&:hover,&:focus": {
            background: "#fff",
            borderRadius: "10px",
            color: "#030303 !important",
            transition: "all 0.3s ease 0s",
            boxShadow: "1px 1px 6px 1px rgb(0,0,0,0.20)"
        }
    },

}));
//react functional component
const NftToptable = ({ nfts }) => {
    const [userId, setUserId] = useState(null)
    const [mintedData, setMintedData] = useState([])
    const [userContent, setUserContent] = useState({});
    const [walletAddress, setWalletAddress] = useState('')
    const [currentMetamaskAddress, setCurrentMetamaskAddress] = useState(false)
    const [showcard, setShowcard] = useState(true)
    const navigateTo = (link) => {
        navigate(link);
    }

    const getUserData = async (id) => {
        const account = await window.ethereum.request({
            method: "eth_requestAccounts"
        });
        setCurrentMetamaskAddress(account[0])

        try {
            const token = window.localStorage.getItem('token')
            const { data } = await Axios.get(`/auth/getuserdata/${id}`);
            setUserId(data?.data?._id);
            setUserContent(data?.data)
            setShowcard(true)

        } catch (error) {
            console.log(error);
        }

        if (account) {
            setWalletAddress(account[0])
        }
    }
    const closeCard = () => {
        setUserId(null);
        setUserContent({})
    }
    const midIndex = Math.ceil(nfts?.length / 2);
    const nftsFirstHalf = nfts?.slice(0, midIndex);
    const nftsSecondHalf = nfts?.slice(midIndex);
    // const useAudio = (url) => {
    //     const [audio] = useState(new Audio(url));
    //     const [playing, setPlaying] = useState(false);

    //     const toggle = () => setPlaying(!playing);

    //     useEffect(() => {
    //         playing ? audio.play() : audio.pause();
    //       },
    //       [playing]
    //     );

    //     useEffect(() => {
    //       audio.addEventListener('ended', () => setPlaying(false));
    //       return () => {
    //         audio.removeEventListener('ended', () => setPlaying(false));
    //         audio.pause();
    //       };
    //     }, [audio]);

    //     return [playing, toggle];
    // };

    // const [playing, toggle] = useAudio(audioUrl);

    return (
        <>
            <GridContainer container spacing={1}>
                {console.log(nfts, "nft")}
                <TableContainer >
                    <StyledTable aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>Rank</TableCell>
                                <TableCell align='left'>Item</TableCell>
                                <TableCell align='left'>Price</TableCell>
                                <TableCell align='left'>Minimum Auction Bid</TableCell>
                                <TableCell align='left'>Mint Id</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {nfts?.map((nft, index) => (

                                < TableRow key={index} style={{ cursor: "pointer" }}>

                                    {console.log(nft?.productId,"products")}
                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                    <StyledTableCell >
                                    <Link  to={`/itemdetails/${nft?.productId?.mintTransaction?.transactionHash}`}> 
                                    <div className='imgtextCell' >
                                        <div className='imgtextCell' >
                                            <div className='imgCell'>
                                                <img src={nft?.productId.ProductImage} alt="" />
                                            </div>
                                            <div className='textCell'>
                                                <p>{nft?.productId?.name}</p>
                                            </div>
                                        </div>
                                        </div>
                                        </Link>
                                    </StyledTableCell>
                                    <StyledTableCell>  <div className='textCell'>
                                        <p>{nft?.productId?.price} {nft?.productId?.blockChain}</p>
                                    </div></StyledTableCell>
                                    <StyledTableCell>
                                        <div className='textCell'>
                                            {typeof(nft?.productId?.auctionMinBid) !== "undefined" ? <p>{nft?.productId?.auctionMinBid} {nft?.productId?.blockChain}</p> :  <p> - </p> }
                                        </div>
                                    </StyledTableCell>
                                      <StyledTableCell>
                                        <div className='textCells'>
                                            <a>{nft?.productId?.mintId}</a>
                                            
                                        </div>

                                    </StyledTableCell>

                                </TableRow>

                            ))}
                        </TableBody>
                      
                    </StyledTable>
                </TableContainer>
                {/* <div className="popup">

<GridContainers container spacing={0} >
    <LeftGrid item xs={2} sm={2} md={2} xl={2} lg={2}>
        <div>
            <img src={userContent?.profilePicture} alt='img' />
        </div>
    </LeftGrid>
    <RightGrid item xs={10} sm={10} md={10} xl={10} lg={10}>

        <div className="iconpara">
            <p>{userContent?.name} {userContent?._id}</p>
        </div>

    </RightGrid>
    </GridContainers>
    <GridContainers>
    <CenterGrid item xs={6} sm={6} md={6} xl={6} lg={6}>
       
            collection<br></br>128
       
    </CenterGrid>
    <CenterGrid item xs={6} sm={6} md={6} xl={6} lg={6}>
      
            Sold<br></br>128
       
    </CenterGrid>
</GridContainers>
<GridContainers>
   <div className='imgcontainer'>
    <img  src={nfts?.owner?.username} alt="img"/>
   </div>
   <div className='imgcontainer'>
    <img src={nfts?.owner?.username} alt="img" />
   </div>
   <div className='imgcontainer'>
    <img src={nfts?.owner?.username} alt="img" />
   </div>
</GridContainers>

</div> */}
            </GridContainer >
        </>

    );
};

export default memo(NftToptable);