import React from 'react';
import SliderMainZero from '../components/SliderMainZero1';
import FeatureBox from '../components/FeatureBox';
import ColumnNewsound from '../components/ColumnNewsound';
import AuthorListReduxNew from '../components/AuthorListReduxNew';
import CatgorNew from '../components/CatgorNew';
import Footer from '../components/footer';
import { createGlobalStyle } from 'styled-components';
import Logo from '../../assets/logo/logoSmall.webp'

const GlobalStyles = createGlobalStyle`

@media only screen and (max-width: 409px) {
  .footer-light .subfooter .social-icons .copy{
    width:220px
    
  }
}



  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 30px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }

  .footer_card .feature_card .featurecards .feature-box{
    height:340px;
  }
  @media only screen and (max-width: 1397px) { 
    .footer_card .feature_card .featurecards .feature-box{
      height:390px;
    }
  }
  @media only screen and (max-width: 1220px) { 
    .footer_card .feature_card .featurecards .feature-box{
      height:450px;
    }
  }
  @media only screen and (max-width: 992px) { 
    .footer_card .feature_card .featurecards .feature-box{
      height:340px;
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 768px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
    .container #cat_section{
      margin-top:-300px;
    } 
    
    }

    @media (max-width: 581px)  { 
      #ban .fraktion-show{
        margin-top:-350px;
      } 
    }
    @media only screen and (max-width: 768px) { 
      #ban .fraktion-show{
        margin-top:-350px;
      } 
      
    }

    @media (max-width: 576px)  { 
      #ban .fraktion-show  {
        margin-top:-250px;
        
      } 
      #ban .fraktion-show .row #count {
        text-align:center;
        
      } 
      .container #cat_section{
        margin-top:-150px;
      } 
    }


    @media only screen and (max-width: 990px){ 
      #ban .fraktion-show{
        padding-top:100px;
      }
    }
  @media (min-width: 350px) and (max-width: 477px) { 
    .col-white {   
      margin-top:10px; 
  }
  h1{
    font-size: 30px;
    color:red
   
  }
  .de_count{    
    h3{
      font-size: 20px;
      margin-bottom: 0;
      span {
        font-size: 15px;
      }
    }
  }

  .tot-num-warp {
    flex-wrap: initial;
    width: 38%;
    text-align: center;
  }
}

@media (min-width: 300px) and (max-width: 349px) { 
  .col-white {   
    margin-top:10px; 
}
h1{
  font-size: 30px;
  color:red
 
}
.de_count{    
  h3{
    font-size: 22px;
    margin-bottom: 0;
    span {
      font-size: 15px;
    }
  }
}

.tot-num-warp {
  flex-wrap: initial;
  width: 38%;
  text-align: center;
}
}

 
`;

const hometwo= () => (
  <div>
    <GlobalStyles />
      <section className="jumbotron no-bg h-vh" style={{background:'linear-gradient(360deg, rgba(9,60,121,1) 0%, rgba(50,152,208,1) 100%)',height:'100vh'}}>
         <br/><br/><br/><br/>
        
         <SliderMainZero/>
         
      </section>

      <section className='container-fluid py-0 footer_card'>

  
        
        <div className='container feature_card'>
          <FeatureBox/>
        </div>
      </section>

      <section className='container-fluid pb-0'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center new_big_font'>
              <h2>New Items</h2>
              <div className="small-border"></div>
            </div>
          </div>
        </div>
       <ColumnNewsound/>
      </div>
      </section>

      <section className='container pb-0'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Top Sellers</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <AuthorListReduxNew/>
          </div>
        </div>
      </section>

      <section className='container pt-5'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center new_big_font'>
              <h2>Join Community</h2>
              <div className="small-border"></div>
            </div>
          </div>
        </div>
        <CatgorNew/>
      </section>

    <section className='container pt-0'>
    <div className="row">
      <div className='col-lg-12'>
            <div className='text-center'>
              <h2>Latest News</h2>
              <div className="small-border"></div>
            </div>
          </div>

      <div className="col-lg-4 col-md-6 mb30">
        <div className="bloglist item">
            <div className="post-content">
                <div className="post-image">
                    <img alt="" src="./img/news/news-1.jpg" className="lazy"/>
                </div>
                <div className="post-text">
                    <span className="p-tagline">Tips &amp; Tricks</span>
                    <span className="p-date">October 28, 2020</span>
                    <h4><span>The next big trend in crypto<span></span></span></h4>
                    <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                    <span className="btn-main">Read more</span>
                </div>
            </div>
        </div>
      </div>
      
      <div className="col-lg-4 col-md-6 mb30">
        <div className="bloglist item">
            <div className="post-content">
                <div className="post-image">
                    <img alt="" src="./img/news/news-2.jpg" className="lazy"/>
                </div>
                <div className="post-text">
                    <span className="p-tagline">Tips &amp; Tricks</span>
                    <span className="p-date">October 28, 2020</span>
                    <h4><span>The next big trend in crypto<span></span></span></h4>
                    <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                    <span className="btn-main">Read more</span>
                </div>
            </div>
        </div>
      </div>
      
      <div className="col-lg-4 col-md-6 mb30">
        <div className="bloglist item">
            <div className="post-content">
                <div className="post-image">
                    <img alt="" src="./img/news/news-3.jpg" className="lazy"/>
                </div>
                <div className="post-text">
                    <span className="p-tagline">Tips &amp; Tricks</span>
                    <span className="p-date">October 28, 2020</span>
                    <h4><span>The next big trend in crypto<span></span></span></h4>
                    <p>Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat...</p>
                    <span className="btn-main">Read more</span>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>


    <Footer />

  </div>
);
export default hometwo;