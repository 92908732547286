import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../../components/footer";
import { createGlobalStyle } from "styled-components";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import auth, { authorUrl } from "../../../core/auth";
import request from "../../../core/auth/request";
import { navigate } from "@reach/router";
import api from "../../../core/api";
import { fetchAuthorList } from "../../../store/actions/thunks";
import * as selectors from "../../../store/selectors";
import axios from "axios";
import Axios from "../../../api-instance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import constants from "../../../constants";

const passwordValidator = require("password-validator");

var schema = new passwordValidator();

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const GlobalStylesDark = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid #ff343f;
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: #ff343f;
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 #ff343f;
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: rgba(33, 36, 40, .9);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: #fff !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{
    color: #fff !important;
    background: #ff343f;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: #ff343f;
  }
  @media only screen and (max-width: 1199px) { 
    .navbar {
      background: #212428;
    }
  }
`;

// Add properties to it
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(30) // Maximum length 100
  .has()
  .uppercase(1) // Must have uppercase letters
  .has()
  .lowercase(1) // Must have lowercase letters
  .has()
  .digits(1) // Must have at least 2 digits
  .has()
  .not()
  .spaces() // Should not have spaces
  .is()
  .not()
  .oneOf(["Passw0rd", "Password123"]);

const validationSchema = Yup.object().shape({
  username: Yup.lazy(() => Yup.string().required("Username is required")),
  wallet: Yup.lazy(() => Yup.string().required("Wallet is required")),
});

const Profile = ({ authorId }) => {
  const jwt = auth.getToken();
  const authorsState = useSelector(selectors.authorsState);
  const author = authorsState.data ? authorsState.data[0] : null;
  const [userId, setUserId] = React.useState('');
  const [coverPicture, setCoverPicture] = React.useState(false);
  const [profilePicture, setProfilePicture] = React.useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        const token = window.localStorage.getItem("token");

        const userData = await Axios.get(`/auth/getdata/${token}`);
       




        // if(window.location.pathname.split('/').pop()){
        //   if(userData?.data?.data?._id!== window.location.pathname.split('/').pop()){
        //        window.location.href=`${constants?.MainURL}/404`
        //   }
        // }
          setUserId(userData?.data?.data);
        

        




      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const initialValues = {
    username: "",
    about:  "",
    social: "",
    wallet:  "",
  };

  const [passwordSetValues, setPasswordSetValues] = useState({
    email: "",
    oldpassword: "",
    confirmpassword: "",
    newpassword: "",
  });

  const [sendPassword, setSendPassword] = useState(false);

  const [emailWarning, setEmailWarning] = useState(null);
  const [passwordWarning, setPasswordWarning] = useState(null);
  const [rePasswordWarning, setRePasswordWarning] = useState(null);
  const [oldPasswordWarning, setOldPasswordWarning] = useState(null);
  const [passwordValidator, setPasswordValidator] = useState(null);

  const handlePasswordVerifier = (e) => {
    if (passwordSetValues.newpassword !== e.target.value) {
      setSendPassword(true);
    } else if (passwordSetValues.newpassword === e.target.value) {
      setSendPassword(false);
      const { name, value } = e.target;
      setPasswordSetValues((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const handleResetPassword = (e) => {
    setEmailWarning(false);
    setPasswordWarning(false);
    setOldPasswordWarning(false);
    setRePasswordWarning(false);
    setPasswordValidator(false);

    const { name, value } = e.target;
    setPasswordSetValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const initialProfilePicture = {
    profile_image: "",
  };

  const initialProfileBanner = {
    profile_banner: "",
  };

  const dispatch = useDispatch();

  const redirectUser = (path) => {
    navigate(path);
  };


  const handleSubmitForm = async (data) => {
    try {
      const sendData = await Axios.put(
        "/auth/update",
        {
          _id: userId?._id,
          username: data?.username,
          about: data?.about,
          socialLink: data?.social,
          walletAddress:data?.wallet
        },
        {
          headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,PATCH,OPTIONS" },
          },
        }
      );
    } catch (error) {
      alert("error", error);
    }
  };

  const handleSubmitProfilePicture = async (file, field) => {
    // var formData = new FormData()
    // formData.append('files', file)
    // formData.append('ref', 'author') // link the image to a content type
    // formData.append('userId', authorId) // link the image to a specific entry
    // formData.append('about', field) // link the image to a specific field
    // await axios({
    //     method: 'put',
    //     url : `${api.baseUrl}/auth/update`,
    //     data: formData,
    //     headers: {
    //         Authorization: `Bearer ${jwt}`,
    //         "Content-Type": "multipart/form-data"
    //     }
    // }).then(res => {
    //     redirectUser(`/Author/${authorId}`);
    //     console.log(res);
    // }).catch(err => {
    //     console.log(err)
    // });
  };

  const [profileImage, setProfileImage] = useState();
  const [profileImageTemp, setProfileImageTemp] = useState();
  const [profileBanner, setProfileBanner] = useState();
  const [profileBannerTemp, setProfileBannerTemp] = useState();
  const [passwordReset, setPasswordReset] = useState(false);
  const handleProfilePicture = (event) => {
    let file = event.target.files[0];
    setProfileImage(file);
    let reader = new FileReader();
    reader.onloadend = () => {
      setProfileImageTemp(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleProfileBanner = (event) => {
    let file = event.target.files[0];
    setProfileBanner(file);
    let reader = new FileReader();
    reader.onloadend = () => {
      setProfileBannerTemp(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    dispatch(fetchAuthorList(authorId));
  }, [dispatch, authorId]);

  const [isDark, setIsDark] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("mode") === "dark") {
      setIsDark(true);
    } else if (localStorage.getItem("mode") === "light") {
      setIsDark(false);
    }
  }, []);

  var bodyformData = new FormData();
  const handleSubmitProfile = async () => {
    try {
      bodyformData.append("profilePicture", profileImage);
      bodyformData.append("_id", userId._id);

      const data = await Axios.put(`/auth/profile`, bodyformData, {
        headers: {
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,PATCH,OPTIONS" },
        },
      });
      setProfilePicture(data?.data?.data.profilePicture);
      window.location.reload();
    } catch (error) {
      alert("error", error);
    }
  };

  const handleSubmitCover = async () => {
    try {
      bodyformData.append("coverPicture", profileBanner);
      bodyformData.append("_id", userId._id);

      const data = await Axios.put(`/auth/cover`, bodyformData, {
        headers: {
          "Content-Type": "application/json",
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,PATCH,OPTIONS" },
        },
      });
      setCoverPicture(data?.data?.data?.coverPicture);

      window.location.reload();
    } catch (error) {
      alert("error", error);
    }
  };

  const handlePasswordChange = async () => {
    try {
      if (passwordSetValues?.email.trim() === "") {
        return setEmailWarning(true);
      }
      if (passwordSetValues?.oldpassword.trim() === "") {
        return setOldPasswordWarning(true);
      }
      if (passwordSetValues?.newpassword.trim() === "") {
        return setPasswordWarning(true);
      }
      if (passwordSetValues?.confirmpassword.trim() === "") {
        return setRePasswordWarning(true);
      }

      if (!schema.validate(passwordSetValues.newpassword)) {
        return setPasswordValidator(true);
      }

      if (passwordSetValues.newpassword !== passwordSetValues.confirmpassword) {
        return setSendPassword(true);
      }

      const reset = await Axios.post(
        `/auth/reset`,
        {
          _id: userId._id,
          email: passwordSetValues.email,
          oldPassword: passwordSetValues.oldpassword,
          newPassword: passwordSetValues.newpassword,
          confirmPassword: passwordSetValues.confirmpassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,PATCH,OPTIONS" },
          },
        }
      );
      setPasswordReset(reset.data);

      window.location.reload();
    } catch (error) {
      alert("error", error);
    }
  };

  return (
    <>
      {isDark ? (
        <div className="greyscheme">
          <GlobalStylesDark />
          <section
            id="profile_banner"
            className="jumbotron breadcumb no-bg myCollection_cover"
            style={{
              backgroundImage: `url(${
                userId?.coverPicture
                  ? userId.coverPicture
                  : api.baseUrl +
                    (author && author.banner && author.banner.url
                      ? author.banner.url
                      : "/uploads/4_1ec08f99e2.jpg")
              })`,
            }}
          >
            <div className="mainbreadcumb"></div>
          </section>

          <section id="section-main" aria-label="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 d-flex">
                  <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    validateOnMount={validationSchema.isValidSync(
                      initialValues
                    )}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      await handleSubmitForm(values);
                      setSubmitting(false);
                      resetForm();
                    }}
                  >
                    {({ values, isSubmitting, isValid }) => {
                      return (
                        <Form className="form-border w-100">
                          <div className="de_tab tab_simple">
                            <ul className="de_nav text-left m-0 mb-3">
                              <li className="active" style={{ opacity: 1 }}>
                                <span>
                                  <i className="fa fa-user"></i>Profile
                                </span>
                              </li>
                            </ul>

                            <div className="de_tab_content">
                              <div className="tab-1">
                                <div
                                  className="row wow fadeIn animated"
                                  style={{
                                    backgroundSize: "cover",
                                    visibility: "visible",
                                    animationName: "fadeIn",
                                  }}
                                >
                                  <div className="col-lg-8 mb-sm-20">
                                    <div className="field-set">
                                      <h5>Username</h5>
                                      <Field
                                        type="text"
                                        name="username"
                                        id="username"
                                        className="form-control"
                                        placeholder="Enter username"
                                      />
                                      <ErrorMessage
                                        name="username"
                                        component="div"
                                      />
                                      <div className="spacer-20"></div>

                                      <h5>About</h5>
                                      <Field
                                        component="textarea"
                                        name="about"
                                        id="about"
                                        className="form-control"
                                        placeholder="Tell the world who you are!"
                                      />
                                      <ErrorMessage
                                        name="about"
                                        component="div"
                                      />
                                      <div className="spacer-20"></div>

                                      <h5>Social</h5>
                                      <Field
                                        type="text"
                                        name="social"
                                        id="social"
                                        className="form-control"
                                        placeholder="Enter Social URLs like Instagram or Twitter"
                                      />
                                      <ErrorMessage
                                        name="social"
                                        component="div"
                                      />
                                      <div className="spacer-20"></div>

                                      <h5>Wallet</h5>
                                      <Field
                                        type="text"
                                        name="wallet"
                                        id="wallet"
                                        className="form-control"
                                        placeholder="Enter your Wallet Address"
                                      />
                                      <ErrorMessage
                                        name="wallet"
                                        component="div"
                                      />
                                      <div className="spacer-20"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <input
                            type="submit"
                            id="submit"
                            className="btn-main"
                            value="Update profile"
                          />
                        </Form>
                      );
                    }}
                  </Formik>
                  {/* different form for image and banner */}
                  <div id="sidebar" className="col-lg-4">
                    <Formik
                      initialValues={initialProfilePicture}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        await handleSubmitProfilePicture(
                          profileImage,
                          "avatar"
                        );
                        setSubmitting(false);
                        resetForm();
                      }}
                    >
                      {({ values, isSubmitting, isValid }) => {
                        return (
                          <Form>
                            <h5>
                              Profile image{" "}
                              <i
                                className="fa fa-info-circle id-color-2"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Recommend 400 x 400. Max size: 50MB. Click the image to upload."
                                aria-label="Recommend 400 x 400. Max size: 50MB. Click the image to upload."
                              ></i>
                            </h5>
                            <img
                              src={
                                userId?.profilePicture
                                  ? userId.profilePicture
                                  : profileBannerTemp
                              }
                              id="click_profile_img"
                              className="d-profile-img-edit img-fluid"
                              alt=""
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                              }}
                            />
                            <input
                              name="profile_image"
                              type="file"
                              id="upload_profile_img"
                              onChange={(event) => {
                                handleProfilePicture(event);
                              }}
                            />
                            <input
                              onClick={handleSubmitProfile}
                              type="submit"
                              className="btn-main mt-3"
                              value="Save Profile Image"
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                    <div className="spacer-30"></div>
                    <Formik
                      initialValues={initialProfileBanner}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        await handleSubmitProfilePicture(
                          profileBanner,
                          "banner"
                        );
                        setSubmitting(false);
                        resetForm();
                      }}
                    >
                      {({ values, isSubmitting, isValid }) => {
                        return (
                          <Form>
                            <h5>
                              Profile banner{" "}
                              <i
                                className="fa fa-info-circle id-color-2"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."
                                aria-label="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."
                              ></i>
                            </h5>
                            <img
                              src={
                                userId ? userId.coverPicture : profileBannerTemp
                              }
                              id="click_banner_img"
                              className="d-banner-img-edit img-fluid"
                              alt=""
                            />
                            <input
                              name="profile_banner"
                              type="file"
                              id="upload_banner_img"
                              onChange={(event) => {
                                handleProfileBanner(event);
                              }}
                            />
                            <ErrorMessage
                              name="profile_banner"
                              component="div"
                            />
                            <input
                              onClick={handleSubmitCover}
                              type="submit"
                              className="btn-main mt-3"
                              value="Save Profile Banner"
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      ) : (
        <div>
          <GlobalStyles />
          <section
            id="profile_banner"
            className="jumbotron breadcumb no-bg "
            style={{
              backgroundImage: `url(${
                userId?.coverPicture
                  ? userId.coverPicture
                  : "https://nft.wallex.global/grey-back.svg"
              })`,
              backgroundSize:'cover',
              backgroundPosition:'center'
            }}
          >




            <div className="mainbreadcumb"></div>
          </section>

          <section id="section-main " className="profile-container-section" aria-label="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    validateOnMount={validationSchema.isValidSync(
                      initialValues
                    )}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      await handleSubmitForm(values);
                      setSubmitting(false);
                      resetForm();
                    }}
                  >
                    {({ values, isSubmitting, isValid }) => {
                      return (
                        <div className="form-border w-100">
                          <div className="de_tab tab_simple">
                            <ul className="de_nav text-left m-0 mb-3">
                              <li className="active" style={{ opacity: 1 }}>
                                <span>
                                  <i className="fa fa-user" style={{ marginRight:'10px' }}></i>Profile
                                </span>
                              </li>
                            </ul>

                            <div className="de_tab_content">
                              <div className="tab-1">
                                <div
                                  className="row wow fadeIn"
                                  style={{
                                    backgroundSize: "cover",
                                    visibility: "visible",
                                    animationName: "fadeIn",
                                  }}
                                >
                                  <div className="col-lg-7 mx-3 mb-sm-20">
                                    {/* {
                                                             changePasswordSession? */}

                                    {/* : */}
                                    <div className="field-set">
                                      <h5 className="black-color">Username</h5>
                                      <Field
                                        type="text"
                                        name="username"
                                        id="username"
                                        className="form-control"
                                        placeholder="Enter username"
                                        
                                      />
                                      <ErrorMessage
                                        name="username"
                                        component="div"
                                      />
                                      <div className="spacer-20"></div>

                                      <h5 className="black-color">About</h5>
                                      <Field
                                        component="textarea"
                                        name="about"
                                        id="about"
                                        className="form-control"
                                        placeholder="Tell the world who you are!"
                                      />
                                      <ErrorMessage
                                        name="about"
                                        component="div"
                                      />
                                      <div className="spacer-20"></div>

                                      <h5 className="black-color">Social</h5>
                                      <Field
                                        type="text"
                                        name="social"
                                        id="social"
                                        className="form-control"
                                        placeholder="Enter Social URLs like Instagram or Twitter"
                                      />
                                      <ErrorMessage
                                        name="social"
                                        component="div"
                                      />
                                      <div className="spacer-20"></div>

                                      <h5 className="black-color">Wallet</h5>
                                      <Field
                                        type="text"
                                        name="wallet"
                                        id="wallet"
                                        className="form-control"
                                        placeholder="Enter your Wallet Address"
                                      />
                                      <ErrorMessage
                                        name="wallet"
                                        component="div"
                                      />
                                      <div className="spacer-20"></div>
                                    </div>
                                    <input
                                      onClick={handleSubmitForm}
                                      type="submit"
                                      id="submit"
                                      className="btn-main"
                                      value="Update profile"
                                    />
                                    <br />
                                    {/* } */}
                                    <div className="field-set">
                                      <ul className="de_nav text-left m-0 mb-3">
                                        <li
                                          className="active"
                                          style={{ opacity: 1 }}
                                        >
                                          <span>
                                            <i className="fa fa-user" style={{ marginRight:'10px' }}></i>Change
                                            Password
                                          </span>
                                        </li>
                                      </ul>
                                      <h5 className="black-color">Email</h5>
                                      <Field
                                        type="text"
                                        name="email"
                                        id="email"
                                        className="form-control"
                                        placeholder="Enter your email"
                                        value={passwordSetValues.email}
                                        onChange={handleResetPassword}
                                      />
                                      {emailWarning ? (
                                        <p
                                          style={{
                                            color: "red",
                                            textAlign: "center",
                                          }}
                                        >
                                          *Please enter this field
                                        </p>
                                      ) : null}
                                      <div className="spacer-20"></div>
                                      {passwordReset?.error ===
                                      "User Not Found" ? (
                                        <p style={{ color: "red" }}>
                                          Wrong Email Address
                                        </p>
                                      ) : null}
                                      <h5 className="black-color">Old Password</h5>
                                      <Field
                                        type="text"
                                        name="oldpassword"
                                        id="oldpassword"
                                        className="form-control"
                                        placeholder="Enter your old Password"
                                        value={passwordSetValues.oldpassword}
                                        onChange={handleResetPassword}
                                      />
                                      <ErrorMessage
                                        name="oldpassword"
                                        component="div"
                                      />
                                      <div className="spacer-20"></div>
                                      {oldPasswordWarning ? (
                                        <p
                                          style={{
                                            color: "red",
                                            textAlign: "center",
                                          }}
                                        >
                                          *Please enter this field
                                        </p>
                                      ) : null}
                                      {passwordReset.data ===
                                      "Password does not match" ? (
                                        <p style={{ color: "red" }}>
                                         Wrong Password
                                        </p>
                                      ) : null}
                                      <h5 className="black-color">New Password</h5>
                                      <Field
                                        type="text"
                                        name="newpassword"
                                        id="newpassword"
                                        className="form-control"
                                        placeholder="Enter your new Password"
                                        value={passwordSetValues.newpassword}
                                        onChange={handleResetPassword}
                                      />
                                      {passwordWarning ? (
                                        <p
                                          style={{
                                            color: "red",
                                            textAlign: "center",
                                          }}
                                        >
                                          *Please enter this field
                                        </p>
                                      ) : null}
                                      {passwordValidator ? (
                                        <p
                                          style={{
                                            color: "red",
                                            textAlign: "center",
                                          }}
                                        >
                                          *Password must contain lower, upper
                                          case, number and special character,
                                          Password must contain 7-15 character
                                        </p>
                                      ) : null}
                                      <div className="spacer-20"></div>

                                      <h5 className="black-color">Confirm Password</h5>

                                      <Field
                                        type="text"
                                        name="confirmpassword"
                                        id="confirmpassword"
                                        className="form-control"
                                        placeholder="Enter your confirm Password"
                                        value={
                                          passwordSetValues.confirmpassword
                                        }
                                        onChange={(e) => {
                                          handleResetPassword(e);
                                          handlePasswordVerifier(e);
                                        }}
                                      />
                                      <ErrorMessage
                                        name="confirmpassword"
                                        component="div"
                                      />
                                      {rePasswordWarning ? (
                                        <p
                                          style={{
                                            color: "red",
                                            textAlign: "center",
                                          }}
                                        >
                                          *Please enter this field
                                        </p>
                                      ) : null}
                                      {sendPassword ? (
                                        <p
                                          style={{
                                            color: "red",
                                            textAlign: "center",
                                          }}
                                        >
                                          *Password Mismatch
                                        </p>
                                      ) : null}
                                      <div className="spacer-20"></div>
                                      {passwordReset.data ===
                                      "Password Changed Successfully"
                                        ? alert("Password Changed Successfully")
                                        : null}

                                      {/* <p onClick={()=>setChangePasswordSession(false)}>back</p> */}
                                    </div>
                                  </div>
                                  <div id="sidebar" className="col-lg-4">
                    <Formik
                      initialValues={initialProfilePicture}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        await handleSubmitProfilePicture(
                          profileImage,
                          "avatar"
                        );
                        setSubmitting(false);
                        resetForm();
                      }}
                    >
                      {({ values, isSubmitting, isValid }) => {
                        return (
                          <div>
                            <h5 className="black-color">
                              Profile image{" "}
                              <i
                                className="fa fa-info-circle id-color-2"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Recommend 400 x 400. Max size: 50MB. Click the image to upload."
                                aria-label="Recommend 400 x 400. Max size: 50MB. Click the image to upload."
                              ></i>
                            </h5>
                            <img
                              src={
                                userId?.profilePicture
                                  ? userId.profilePicture
                                  : "https://nft.wallex.global/grey-back.svg"
                              }
                              id="click_profile_img"
                              className="d-profile-img-edit img-fluid"
                              alt=""
                              style={{
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                              }}
                            />
                            <input
                              name="profile_image"
                              type="file"
                              id="upload_profile_img"
                              onChange={(event) => {
                                handleProfilePicture(event);
                              }}
                            />
                            <input
                              onClick={handleSubmitProfile}
                              type="submit"
                              className="btn-main mt-3"
                              value="Save Profile Image"
                            />
                          </div>
                        );
                      }}
                    </Formik>
                    <div className="spacer-30"></div>
                    <Formik
                      initialValues={initialProfileBanner}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);
                        await handleSubmitProfilePicture(
                          profileBanner,
                          "banner"
                        );
                        setSubmitting(false);
                        resetForm();
                      }}
                    >
                      {({ values, isSubmitting, isValid }) => {
                        return (
                          <div>
                            <h5 className="black-color">
                              Profile banner{" "}
                              <i
                                className="fa fa-info-circle id-color-2"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title=""
                                data-bs-original-title="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."
                                aria-label="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."
                              ></i>
                            </h5>
                            <img
                              src={
                                userId ? userId.coverPicture : "https://nft.wallex.global/grey-back.svg"
                              }
                              id="click_banner_img"
                              className="d-banner-img-edit img-fluid"
                              alt=""
                            />
                            <input
                              name="profile_banner"
                              type="file"
                              id="upload_banner_img"
                              onChange={(event) => {
                                handleProfileBanner(event);
                              }}
                            />
                            <ErrorMessage
                              name="profile_banner"
                              component="div"
                            />
                            <input
                              onClick={handleSubmitCover}
                              type="submit"
                              className="btn-main mt-3"
                              value="Save Profile Banner"
                            />
                          </div>
                        );
                      }}
                    </Formik>
                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                          

                          <input
                            onClick={handlePasswordChange}
                            id="submit"
                            className="btn-main"
                            value="Submit"
                            style={{padding:'8px 0px'}}
                          />
                        </div>
                      );
                    }}
                  </Formik>
                  {/* different form for image and banner */}
                 
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};

export default memo(Profile);
