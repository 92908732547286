import React,{useState,useEffect} from 'react';
import WalletConnectProvider from '@walletconnect/web3-provider'

//importing web3 and web3modal
import Web3 from 'web3'
// import Web3Modal from 'web3modal'
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";


const Wallet= () => {

  // const POLLING_INTERVAL = 8000;
    
  //  const walletconnect = new WalletConnectConnector({
  //   rpc: { 1: "https://mainnet.infura.io/v3/1c3acca035dd41dfbf400abac71e59a7" },
  //   bridge: "https://bridge.walletconnect.org",
  //   qrcode: true,
  //   pollingInterval: POLLING_INTERVAL
  // });
  

    const connectMetamask = async () => {
        if (window?.ethereum) {
          try {
            const addressArray = await window.ethereum.request({
              method: "eth_requestAccounts",
            });
            window.localStorage.setItem('useraddress',addressArray[0])
            window.location.href="/"
            console.log(addressArray);
            const obj = {
              status: "👆🏽 Write a message in the text-field above.",
              address: addressArray[0],
            };
            return obj;
          } catch (err) {
            return {
              address: "",
              status: "😥 " + err.message,
            };
          }
        } else {
          return {
            address: "",
            status: (
              <span>
                <p>
                  {" "}
                  🦊{" "}
                  <a target="_blank"  href={"https://metamask.io/download.html"}>
                    You must install Metamask, a virtual Ethereum wallet, in your
                    browser.
                  </a>
                </p>
              </span>
            ),
          };
        }
      };


      const handleWalletConnect = async()=>{
      try{
      //  Create WalletConnect Provider
const provider = new WalletConnectProvider({
  infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
});

//  Enable session (triggers QR Code modal)
await provider.enable();
      }
      catch(error){
        console.log(error);
      }
      }
    

    return(
     
            <div className="row">
              <div className="col-lg-3 mb30" style={{cursor:'pointer'}} onClick={()=>connectMetamask()}>
                  <span className="box-url" style={{boxShadow:"5p 5px 10px white",background:"#070f20",borderRadius:'23px'}} >
                      {/* <span className="box-url-label">Most Popular</span> */}
                      <img src="./img/wallet/1.png" alt="" className="mb20" style={{width:'70px'}}/>
                      <h4>Metamask</h4>
                      <p>Start exploring blockchain applications in seconds.  Trusted by over 1 million users worldwide.</p>
                  </span>
              </div>
          
               <div className="col-lg-3 mb30" style={{cursor:'pointer'}} onClick={()=>handleWalletConnect()}>
                  <span className="box-url" style={{boxShadow:"5p 5px 10px white",background:"#070f20",borderRadius:'23px'}}>
                      <img src="./img/wallet/wallet-connect.png" alt="" className="mb20" style={{width:'70px'}}/>
                      <h4>Wallet Connect</h4>
                      <p>WalletConnect is an open protocol to communicate securely between mobile wallets.</p>
                  </span>
              </div>       
          
            {/*  <div className="col-lg-3 mb30">
                  <span className="box-url">
                      <img src="./img/wallet/3.png" alt="" className="mb20"/>
                      <h4>FortETH</h4>
                      <p>Let users access your Ethereum app from anywhere. No more browser extensions.</p>
                  </span>
              </div>    
          
              <div className="col-lg-3 mb30">
                  <span className="box-url" >
                      <img src="./img/wallet/4.png" alt="" className="mb20"/>
                      <h4>WalletConnect</h4>
                      <p>Open source protocol for connecting decentralised applications to mobile wallets.</p>
                  </span>
              </div>
          
              <div className="col-lg-3 mb30">
                  <span className="box-url">
                      <img src="./img/wallet/5.png" alt="" className="mb20"/>
                      <h4>Coinbase Wallet</h4>
                      <p>The easiest and most secure crypto wallet. ... No Coinbase account required.
                      </p>
                  </span>
              </div>
          
              <div className="col-lg-3 mb30">
                  <span className="box-url">
                      <img src="./img/wallet/6.png" alt="" className="mb20"/>
                      <h4>Arkane</h4>
                      <p>Make it easy to create blockchain applications with secure wallets solutions.</p>
                  </span>
              </div>       
          
              <div className="col-lg-3 mb30">
                  <span className="box-url">
                      <img src="./img/wallet/7.png" alt="" className="mb20"/>
                      <h4>Authereum</h4>
                      <p>Your wallet where you want it. Log into your favorite dapps with Authereum.</p>
                  </span>
              </div>    
          
              <div className="col-lg-3 mb30">
                  <span className="box-url">
                      <span className="box-url-label">Most Simple</span>
                      <img src="./img/wallet/8.png" alt="" className="mb20"/>
                      <h4>Torus</h4>
                      <p>Open source protocol for connecting decentralised applications to mobile wallets.</p>
                  </span>
              </div>                                   */}
          </div>
          
    )
}
export default Wallet;