const constants = {
    MainURL: 'https://nft.wallex.global',
    // MainURL: 'http://3.77.162.163',
    // MainURL: 'http://localhost:3000',
    port: 'https://nft.wallex.global/api',
    // port: 'http://3.77.162.163/api',
    // port: "http://localhost:5000/api",
    newCollectionDate: 1,
    etherscanWebsite: 'https://etherscan.io'
}

export default constants;
