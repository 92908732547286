import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Header from "../../menu/header";
import Footer from "../../components/footer";
import './HelpCenter.css'
import Axios from "../../../api-instance"
// import togglebtn from '../../img/togglebtn.png'
// import Menuclose from '../../img/Menuclose.png'
import { Link } from "@reach/router";
// import customer from '../../img/customer.svg'
// import maptrace from '../../img/maptrace.svg'
// import guarantee from '../../img/guarantee.svg'
// import bestseller from '../../img/bestseller.svg'
// import bill from '../../img/bill.svg'
// import product from '../../img/product.svg'
// import supplychain from '../../img/supplychain.svg'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles({
  footercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    padding: '90px 0 !important',
    '@media (max-width: 991.98px)': {
      padding: '30px 0 !important',
    }
  },
  headercls: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
  },

  bodyclass: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    position: 'relative'
  },
  helpcommoninner: {
    background: 'transparent !important',
    borderRadius: '0px !important',
    boxShadow: 'none !important',
    textAlign: 'left !important'
  }
});

const DetailPage = () => {

  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(true)
  const [faqData4, setFaqData4] = useState([])
  const [selectedCategory4, setSelectedCategory4] = useState('');
  const [selectedQuestion4, setSelectedQuestion4] = useState('')
  const [selectedAnswer4, setSelectedAnswer4] = useState('')
  const [selectedFAQ, setSelectedFAQ] = useState(0); //
  const openMenuHandler = () => {
    setMenuOpen(false)
  }

  const closeMenuHandler = () => {
    setMenuOpen(true)
  }

  const handleCategoryClick4 = (questionId) => {
    const selectedFaq = faqData4.find((faq) => faq._id === questionId);
    console.log(selectedFaq, "faqqq")
    if (selectedFaq) {
      // setSelectedQuestion(selectedFaq.question);
      setSelectedAnswer4(selectedFaq.answer);
    }
    setSelectedFAQ(questionId)
  };

  const faqProfileList = async () => {
    try {
      const payload = {
        name: 'Profile'

      }
      console.log(payload, "payyy")
      const response = await Axios.post('/admin/getquestByName', payload)
      console.log(response, "checkdata")
      if (response && response.status === 200) {
        const deta = response?.data?.data
        setFaqData4(deta)
        setSelectedAnswer4(deta[0].answer)
      } else {
        console.log("No Data Found")
      }



    } catch (error) {
      console.log(error, "error")

    }
  }

  useEffect(() => {
    faqProfileList();
  }, []);


  const [faq, setFaq] = useState([
    { name: "Is there an option to transfer my NFT?" },
    { name: "How can I track my activity within Wallex NFT?" },
    { name: "Where can I see my NFT history?" },
    { name: "How do I log out of my Wallex NFT account (or) switch crypto wallets?" },
    { name: "How to create an account in Wallex NFT?" },
    { name: "How to create a collection?" },
  ])


  const [val, setVal] = useState(0)

  console.log(val, 'val');

  const [selected, setSelected] = useState(false)
  const handleCloseSelect = () => {
    setSelected(false)
  }
  const handleVal = (ind) => {
    setVal(ind)
    setSelectedFAQ(ind)
    setSelected(true)
  }

  console.log(selectedAnswer4, 'selectedAnswer4');

  return (
    <div className="dashboard-page help-center-main">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Item className={classes.headercls}>
              <Header />
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="outer-bgcolor-container"
          >
            <Item className={classes.bodyclass}>
              <div className="togglebtn" onClick={openMenuHandler}></div>
              <div
                className="categories-menu"
                id={!menuOpen ? "openmenucls" : ""}
              >
                <div
                  className="togglebtn-close"
                  onClick={closeMenuHandler}
                ></div>
                <div className="categories-menu-inner">
                  {/* <Link><div className='iconic'><img src={customer} alt={customer} /></div>Customer</Link>
    <Link><div className='iconic'><img src={maptrace} alt={maptrace} /></div>Traceability</Link>
    <Link><div className='iconic'><img src={guarantee} alt={guarantee} /></div>Products</Link>
    <Link><div className='iconic'><img src={bestseller} alt={bestseller} /></div>Blockchain</Link>
    <Link><div className='iconic'><img src={bill} alt={bill} /></div>Post-sale</Link>
    <Link><div className='iconic'><img src={product} alt={product} /></div>Billing</Link>
    <Link><div className='iconic'><img src={supplychain} alt={supplychain} /></div>Sellers Authorization </Link> */}
                </div>
              </div>

              <div className="detail-pages-inner-outer">
                <Box sx={{ flexGrow: 1 }}>
                  <div className="breadcrum-helpcenter">
                    <Link to="/helpcenter-new">Help Center</Link> / Profile
                  </div>

                  <Grid
                    container
                    spacing={0}
                    className={classes.helpcenterbodycontain}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id="link-left-side-detail-id">
                      {selected ?
                        <>
                          <div style={{ textAlign: 'left', cursor: 'pointer', marginBottom: "30px" }} onClick={handleCloseSelect}>
                            <ArrowBackIcon />
                          </div>

                          <Item className={classes.helpcommoninner}>
                            {val === 0 && <>
                              <h2><strong>Is there an option to transfer my NFT?</strong></h2>
                              <p>Transferring an NFT is an easy way to send NFTs to friends, fellow community members, or perhaps just another wallet of yours. In this guide, we'll show you how to transfer NFTs using Wallex NFT and crypto wallet apps.<br /><br />Let's dive in &nbsp;</p>
                              <h2><strong>Transferring NFTs using Wallex NFT</strong></h2>
                              <p>There are two ways to transfer NFTs using Wallex NFT. The first way is to transfer it directly from your <strong>Profile</strong> page using <strong>Table View</strong>. Select the NFT you'd like to transfer. You can transfer one or multiple NFTs using this same method.</p>
                              <ol>
                                <li>On the bottom of the page in the action bar, click the <strong>Transfer </strong>button.&nbsp;</li>
                                <li>Enter the recipient's wallet address (ENS domains are also accepted) and click <strong>Transfer</strong>.</li>
                                <li><strong>Confirm</strong> the signature request in your wallet.</li>
                                <li>You'll see a confirmation message once the item has been transferred. You can also view the confirmation on Etherscan.</li>
                              </ol>
                              <p>You can also transfer an NFT directly from the item page.</p>
                            </>}
                            {val === 1 && <>
                              <h2><strong>How can I track my activity within Wallex NFT?</strong></h2><p>Etherscan is a useful tool for exploring the Ethereum blockchain. If you've sold an item on Ethereum, you can search your wallet address to see your token balance or enter a transaction hash to verify transaction history. It's an easy way to verify whether a transaction was completed.</p><p>If you're using the MetaMask wallet, click on your Ethereum balance. Then select the three dots at the top right of the extension, and select <strong>View Asset in explorer</strong>&nbsp;to view your token balance and transaction history on Etherscan.&nbsp;</p><p>Transactions such as NFT sales can be viewed under the "<strong>Internal Txns</strong>" tab on an Etherscan/Polygonscan account page.</p><p>Keep in mind the transaction records will show the fee received by Wallex NFT and, if any, creator earnings received by a project’s creator. Crypto wallets like MetaMask will not display internal transactions.</p>

                            </>}
                            {val === 2 && <>
                              <h2><strong>Where can I see my NFT history?</strong></h2><p>One of the unique benefits of NFTs is the ability to track every transaction on the blockchain. Every NFT has an owner, creator, and history, and this information or "provenance" is verifiable on-chain.&nbsp;</p><p>Each item page has a <strong>Details</strong> section where you can verify details about the contract used to create it.</p><p>Opening this section will show you important information about the NFT, such as:</p><ul><li>The contract address of the collection</li><li>The token ID of this particular NFT</li><li>The blockchain the NFT is on</li><li>Whether the NFT metadata is centralized or "Frozen"</li></ul>
                            </>}
                            {val === 3 && <>
                              <h2><strong>How do I log out of my Wallex NFT account (or) switch crypto wallets?</strong></h2><p>Need to log out of your Wallex NFT account? Do you see the wrong account when you log in? In this guide, we'll provide step-by-step directions on how to switch accounts/wallets.</p><h2><strong>Logging out of your profile</strong></h2><ol><li>On Wallex NFT, navigate to the top right of your screen and click the <strong>Profile icon</strong>.</li><li>From the dropdown menu, select <strong>Log Out</strong>.You'll see a confirmation message if you've logged out successfully.</li></ol><p>You can also log out of your account by clicking on your <strong>Wallet balance</strong>. Your wallet sidebar will appear. Click the down arrow next to your wallet address&nbsp;and select <strong>Log Out</strong>.</p><h2><strong>Switching crypto wallets</strong></h2><ol><li>Navigate to the top right of your screen and click your <strong>Wallet balance</strong>.</li><li>Click the down arrow next to your wallet address to see a list of your other crypto wallets.</li><li>Select a different crypto wallet.</li></ol><p>We switch from <strong>MetaMask</strong> to <strong>Phantom</strong>, a Solana crypto wallet, in the example below. You can switch from Ethereum wallets to non-Ethereum wallets on Solana or Klaytn. You can also switch from one Ethereum wallet to another, like <strong>MetaMask</strong> to <strong>Coinbase Wallet</strong>.</p>
                            </>}
                            {val === 4 && <>
                              <h2><strong>How to create an account in Wallex NFT?</strong></h2><ol><li>Click the SIGN UP button at the top right corner of the page.</li><li>Now register a new account by entering the following details.</li><li>Click the REGISTER NOW button to confirm your details and proceed.</li><li>A verification link will be sent to your email ID.</li><li>Check your inbox and click on the verification link to activate your account.</li></ol>
                            </>}
                            {val === 5 && <>
                              <h2><strong>How to create a collection?</strong></h2><p>On WallexNFT, creator earnings are either <strong>optional</strong> or <strong>enforced</strong>. If they’re optional, this means creators can specify their preferred creator earnings in their collection settings, but the NFT owner will ultimately choose whether to pay the suggested earnings when selling the item.</p><p>Optional creator earnings are surfaced at multiple points in-product, so sellers can easily select the creator's preferred earnings when creating a listing or accepting an offer, and buyers can easily search for listings that pay the creator’s suggested earnings.</p>
                            </>}






                          </Item>
                        </> :

                        <>
                          <h4>Article in this section</h4>
                          {faq.map((faq, index) => (
                            <div className={index === selectedFAQ ? "display-2 redirect-link-specific active" : "display-2 redirect-link-specific"} onClick={() => handleVal(index)} >
                              <div
                                key={faq.id}
                                // to="#"
                                style={{ cursor: 'pointer' }}


                              >
                                <p>{faq.name}</p>
                              </div>
                              <div>
                                <NavigateNextIcon />
                              </div>
                            </div>

                          ))}
                        </>}
                    </Grid>

                    {/* <Grid item xs={12} lg={2} xl={2} id="link-left-side-id">
                      <Item className="helpcommoninner">
                        <h4>Article in this section</h4>
                        {faq.map((faq, index) => (
                          <div
                            key={faq._id}
                            // to="#"
                            style={{ cursor: 'pointer' }}
                            className={ index === selectedFAQ  ? "redirect-link-specific active" : "redirect-link-specific"}
                            onClick={() => handleVal(index)}
                          >
                            <p>{faq.name}</p>
                          </div>
                        ))}
                      </Item>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={10} xl={10} id="link-left-side-detail-id">
                      <Item className={classes.helpcommoninner}>
                        {/* {selectedQuestion && <h2>{selectedQuestion}</h2>} */}
                        {/* {selectedAnswer4 && <div dangerouslySetInnerHTML={{ __html: selectedAnswer4 }}></div>} */}


                        {/* {val === 0 && <>
  <h2><strong>Is there an option to transfer my NFT?</strong></h2>
                        <p>Transferring an NFT is an easy way to send NFTs to friends, fellow community members, or perhaps just another wallet of yours. In this guide, we'll show you how to transfer NFTs using Wallex NFT and crypto wallet apps.<br /><br />Let's dive in &nbsp;</p>
                        <h2><strong>Transferring NFTs using Wallex NFT</strong></h2>
                        <p>There are two ways to transfer NFTs using Wallex NFT. The first way is to transfer it directly from your <strong>Profile</strong> page using <strong>Table View</strong>. Select the NFT you'd like to transfer. You can transfer one or multiple NFTs using this same method.</p>
                        <ol>
                          <li>On the bottom of the page in the action bar, click the <strong>Transfer </strong>button.&nbsp;</li>
                          <li>Enter the recipient's wallet address (ENS domains are also accepted) and click <strong>Transfer</strong>.</li>
                          <li><strong>Confirm</strong> the signature request in your wallet.</li>
                          <li>You'll see a confirmation message once the item has been transferred. You can also view the confirmation on Etherscan.</li>
                          </ol>
                          <p>You can also transfer an NFT directly from the item page.</p> 
</>}
{val === 1 && <>
  <h2><strong>How can I track my activity within Wallex NFT?</strong></h2><p>Etherscan is a useful tool for exploring the Ethereum blockchain. If you've sold an item on Ethereum, you can search your wallet address to see your token balance or enter a transaction hash to verify transaction history. It's an easy way to verify whether a transaction was completed.</p><p>If you're using the MetaMask wallet, click on your Ethereum balance. Then select the three dots at the top right of the extension, and select <strong>View Asset in explorer</strong>&nbsp;to view your token balance and transaction history on Etherscan.&nbsp;</p><p>Transactions such as NFT sales can be viewed under the "<strong>Internal Txns</strong>" tab on an Etherscan/Polygonscan account page.</p><p>Keep in mind the transaction records will show the fee received by Wallex NFT and, if any, creator earnings received by a project’s creator. Crypto wallets like MetaMask will not display internal transactions.</p> 

</>}
{val === 2 && <>
  <h2><strong>Where can I see my NFT history?</strong></h2><p>One of the unique benefits of NFTs is the ability to track every transaction on the blockchain. Every NFT has an owner, creator, and history, and this information or "provenance" is verifiable on-chain.&nbsp;</p><p>Each item page has a <strong>Details</strong> section where you can verify details about the contract used to create it.</p><p>Opening this section will show you important information about the NFT, such as:</p><ul><li>The contract address of the collection</li><li>The token ID of this particular NFT</li><li>The blockchain the NFT is on</li><li>Whether the NFT metadata is centralized or "Frozen"</li></ul> 
</>}
{val === 3 && <>
  <h2><strong>How do I log out of my Wallex NFT account (or) switch crypto wallets?</strong></h2><p>Need to log out of your Wallex NFT account? Do you see the wrong account when you log in? In this guide, we'll provide step-by-step directions on how to switch accounts/wallets.</p><h2><strong>Logging out of your profile</strong></h2><ol><li>On Wallex NFT, navigate to the top right of your screen and click the <strong>Profile icon</strong>.</li><li>From the dropdown menu, select <strong>Log Out</strong>.You'll see a confirmation message if you've logged out successfully.</li></ol><p>You can also log out of your account by clicking on your <strong>Wallet balance</strong>. Your wallet sidebar will appear. Click the down arrow next to your wallet address&nbsp;and select <strong>Log Out</strong>.</p><h2><strong>Switching crypto wallets</strong></h2><ol><li>Navigate to the top right of your screen and click your <strong>Wallet balance</strong>.</li><li>Click the down arrow next to your wallet address to see a list of your other crypto wallets.</li><li>Select a different crypto wallet.</li></ol><p>We switch from <strong>MetaMask</strong> to <strong>Phantom</strong>, a Solana crypto wallet, in the example below. You can switch from Ethereum wallets to non-Ethereum wallets on Solana or Klaytn. You can also switch from one Ethereum wallet to another, like <strong>MetaMask</strong> to <strong>Coinbase Wallet</strong>.</p> 
</>}
{val === 4 && <>
  <h2><strong>How to create an account in Wallex NFT?</strong></h2><ol><li>Click the SIGN UP button at the top right corner of the page.</li><li>Now register a new account by entering the following details.</li><li>Click the REGISTER NOW button to confirm your details and proceed.</li><li>A verification link will be sent to your email ID.</li><li>Check your inbox and click on the verification link to activate your account.</li></ol> 
</>}
{val === 5 && <>
  <h2><strong>How to create a collection?</strong></h2><p>On WallexNFT, creator earnings are either <strong>optional</strong> or <strong>enforced</strong>. If they’re optional, this means creators can specify their preferred creator earnings in their collection settings, but the NFT owner will ultimately choose whether to pay the suggested earnings when selling the item.</p><p>Optional creator earnings are surfaced at multiple points in-product, so sellers can easily select the creator's preferred earnings when creating a listing or accepting an offer, and buyers can easily search for listings that pay the creator’s suggested earnings.</p> 
</>}
                      */}














                      </Item>
                    </Grid>
                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={2}
                      xl={2}
                      id="link-left-side-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h4>Article in this section</h4>

                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How to create an account in Wallex NFT?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          Where can I see my NFT history?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How can I track my activity within Wallex NFT?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          Is there an option to transfer my NFT?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How to create a collection?
                        </Link>
                        <Link
                          to="/detail-page"
                          className="redirect-link-specific"
                        >
                          How do I log out of my Wallex NFT account (or) switch
                          crypto wallets?
                        </Link>
                      </Item>
                    </Grid> */}

                    {/* <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={10}
                      xl={10}
                      id="link-left-side-detail-id"
                    >
                      <Item className={classes.helpcommoninner}>
                        <h2>How to create an account in Wallex NFT?</h2>

                        <ol>
                          <li>
                            Click the SIGN UP button at the top right corner of
                            the page.
                          </li>
                          <li>
                            Now register a new account by entering the following
                            details.
                          </li>
                          <li>
                            Click the REGISTER NOW button to confirm your
                            details and proceed.
                          </li>
                          <li>
                            A verification link will be sent to your email ID.
                          </li>
                          <li>
                            Check your inbox and click on the verification link
                            to activate your account.
                          </li>
                        </ol>
                        <h4>Where can I see my NFT history?</h4>
                        <h4>How can I track my activity within Wallex NFT?</h4>
                        <h4>Is there an option to transfer my NFT?</h4>
                        <h4>How to create a collection?</h4>
                        <h4>
                          How do I log out of my Wallex NFT account (or) switch
                          crypto wallets?
                        </h4>
                      </Item>
                    </Grid> */}
                  </Grid>
                </Box>
              </div>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

            <Footer />

          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default DetailPage;
