import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import './accordions.css'
import CalendarViewMonthRoundedIcon from '@mui/icons-material/CalendarViewMonthRounded';
import Typography from '@mui/material/Typography';
import ExploreContentLoader from '../components/exploreContentLoader'
import ContractAddress from '../../web3/metamaskConnection/contractAddress/contractAddress'
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  borderRadius: '10px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(f, f, f, 1)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiTypography-root': {
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "600"
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions({ finalResult }) {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };


  return (
    <div style={{ "margin": "20px" }}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography> <ListAltRoundedIcon />Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className='details'><p>Contract Address</p><span>{`${ContractAddress.slice(0, 7)}...${ContractAddress.slice(38, 44)}`}</span></div>
          <div className='details'><p>Token</p><span>{finalResult?.mintTransaction?.events?.Transfer?.returnValues?.tokenId}</span></div>
          <div className='details'><p>Token Standard</p><h6>ERC721</h6></div>
          <div className='details'><p>Chain</p><h6>BNB</h6></div>
          <div className='details'><p>Last Updated</p><h6>23 May</h6></div>
          <div className='details'><p>Creator Earnings</p><h6>10%</h6></div>
          {/* {
                      isLoaded ?

                        datas?.length !== 0 ?
                          datas?.map((item, i) => {
                            return (
                              <div onClick={() => handleView(item)}>
                                {
                                  userData && !falseUser ?
                                    <Suspense fallback={<ExploreContentLoader />}>
                                      <NftCard setNftAddress={setNftAddress} nft={item} onImgLoad={onImgLoad} />
                                    </Suspense>
                                    :
                                    <Suspense fallback={<ExploreContentLoader />}>
                                      <NotNftCard nft={item} onImgLoad={onImgLoad} />
                                    </Suspense>
                                }
                              </div>
                            )
                          }) :


                          <h1 className='noDataFound' style={{ height: '100vh' }}>No data found...</h1>

                        :
                        Array(8).fill()?.map((item) => (
                          <ExploreContentLoader />
                        ))




                    } */}
        </AccordionDetails>
      </Accordion>

    </div>
  );
}
